import ModelBaseService from './model-base'
import { DataField } from '../util/data-field'

class AccountService extends ModelBaseService {
  protected modelName = 'account'

  public get fields(): DataField[] {
    return []
  }
}

export default new AccountService()
