import React from 'react'
import styled from 'styled-components'
import Avatar, { AvatarProps } from './Avatar'
import colors from '../util/colors'
import { Header } from '.'
import { GenericButtonProps } from './GenericButton'
import { useViewport } from '../hooks'

export interface AvatarHeaderProps extends AvatarProps {
  backgroundColor?: string
  buttons?: GenericButtonProps | GenericButtonProps[]
  title?: string
  subtitle?: string
  small?: boolean
  noTools?: boolean
  noBackButton?: boolean
}

const AvatarHeader: React.FC<AvatarHeaderProps> = ({
  backgroundColor,
  children,
  src,
  email,
  name,
  buttons,
  onImageSelected,
  title,
  small,
  noTools,
  subtitle,
  noBackButton,
  ...rest
}) => {
  const { width } = useViewport()

  return (
    <div {...rest}>
      {!noTools && (
        <Header buttons={buttons} noBackButton={noBackButton} alwaysVisible />
      )}
      <div className="avatar-container">
        <Avatar
          size={small || width < 600 ? 64 : 96}
          src={src}
          email={email}
          name={name}
          onImageSelected={onImageSelected}
          borderWidth={small ? 2 : 3}
          allowEdit={!!onImageSelected}
        />
        <div className="titles">
          {title && <h1 className="avatar-title">{title}</h1>}
          <p className="avatar-subtitle">{subtitle}</p>
        </div>
      </div>
    </div>
  )
}

export default styled(React.memo(AvatarHeader))`
  background: ${(props) => props.backgroundColor ?? 'var(--primary-gradient)'};

  color: ${(props) =>
    colors.isDark(props.backgroundColor ?? '#52609d')
      ? 'var(--ion-color-light)'
      : 'var(--ion-color-dark)'};

  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  ion-avatar {
    border-color: ${(props) =>
      colors.isDark(props.backgroundColor ?? '#52609d')
        ? 'white'
        : 'var(--ion-color-dark)'};
    background: var(--ion-color-medium);
  }

  ion-back-button {
    color: ${(props) =>
      colors.isDark(props.backgroundColor ?? '#52609d')
        ? 'var(--ion-color-light)'
        : 'var(--ion-color-dark)'} !important;
  }

  ion-avatar {
    margin-top: ${(props) => (!props.noTools ? 0 : 25)}px;
    transform: translateY(-10px);
  }

  ion-toolbar {
    --background: transparent;
  }

  .avatar-title {
    padding: 0;
    margin: 0;
  }

  .avatar-subtitle {
    color: ${(props) =>
      colors.isDark(props.backgroundColor ?? '#52609d')
        ? 'var(--ion-color-light)'
        : 'var(--ion-color-medium)'} !important;
    font-size: 14px;
    margin: 0 0 15px;
  }

  .avatar-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 25px;

    ion-avatar {
      margin: 0 15px 0 0;
    }

    > {
      flex: 0 0 auto;
    }
  }

  @media screen and (max-width: 600px) {
    ion-header {
      border-bottom: solid 1px rgb(255, 255, 255, 0.05);
    }

    .avatar-container {
      margin-top: 25px;
      margin-bottom: 10px;

      ion-avatar {
        transform: translateY(-10px);
      }
      text-align: center;
    }

    .avatar-title {
      font-size: 20px;
    }
  }
`
