import React, { useState, useRef, useImperativeHandle } from 'react'
import { IonContent } from '@ionic/react'
import LoadingSpinner from './LoadingSpinner'
import styled from 'styled-components'
import css from 'classnames'
import ZeroStateCTA, { ZeroStateCTAProps } from './ZeroStateCTA'

export interface ContentProps extends React.ComponentProps<typeof IonContent> {
  loading?: boolean
  noScrollIndicator?: boolean
  isZeroState?: boolean
  zeroState?: ZeroStateCTAProps
}

const Content: React.ForwardRefRenderFunction<any, ContentProps> = (
  { loading, noScrollIndicator, children, zeroState, isZeroState, ...rest },
  ref
) => {
  const content = useRef<any>()
  const [isScrolled, setIsScrolled] = useState(false)

  const onIonScroll = async (e: any) => {
    const element = await content.current?.getScrollElement()
    setIsScrolled(!noScrollIndicator && element?.scrollTop > 0)
  }

  useImperativeHandle(ref, () => content.current)

  return (
    <IonContent ref={content} scrollEvents onIonScroll={onIonScroll} {...rest}>
      <div slot="fixed" className={css('gradient', { scrolled: isScrolled })} />
      {loading ? (
        <LoadingSpinner />
      ) : isZeroState && zeroState ? (
        isZeroState && zeroState && <ZeroStateCTA {...zeroState} />
      ) : (
        children
      )}
    </IonContent>
  )
}

export default styled(React.forwardRef(Content))`
  position: relative;
  .gradient {
    background: linear-gradient(
      180deg,
      rgba(2, 0, 36, 0.15) 0%,
      rgba(0, 212, 255, 0) 100%
    );
    border-top: solid 1px rgb(var(--ion-color-medium-rgb), 0.2);
    transition: all linear 0.1s;
    height: 20px;
    pointer-events: none;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;

    &.scrolled {
      opacity: 1;
    }
  }
`
