import { lazy } from 'react'
export { default as Home } from './Home'
export { default as AppLoading } from './AppLoading'
export const Login = lazy(() => import('./Login'))
export const TeamVideos = lazy(() => import('./TeamVideos'))
export const Register = lazy(() => import('./Register'))
export const NotFound = lazy(() => import('./NotFound'))
export const ForgotPassword = lazy(() => import('./ForgotPassword'))
export const TeamInsights = lazy(() => import('./TeamInsights'))
export const TeamSchedule = lazy(() => import('./TeamSchedule'))
export const TeamRoster = lazy(() => import('./TeamRoster'))
export const TeamSettings = lazy(() => import('./TeamSettings'))
export const TeamBrandInsights = lazy(() => import('./TeamBrandInsights'))
export const TeamBrandPlayers = lazy(() => import('./TeamBrandPlayers'))
export const TeamBrandStaff = lazy(() => import('./TeamBrandStaff'))
export const TeamBrandSettings = lazy(() => import('./TeamBrandSettings'))
export const InvitationVideo = lazy(() => import('./InvitationVideo'))
export const TeamBrandSubscription = lazy(
  () => import('./TeamBrandSubscription')
)
export const TeamBrandPlayerDetail = lazy(
  () => import('./TeamBrandPlayerDetail')
)
export const TeamBrandStaffDetail = lazy(() => import('./TeamBrandStaffDetail'))
export const TeamPlayerDetail = lazy(() => import('./TeamPlayerDetail'))
export const TeamStaffDetail = lazy(() => import('./TeamStaffDetail'))
export const TeamGameDetail = lazy(() => import('./TeamGameDetail'))
export const TeamGameScoring = lazy(() => import('./TeamGameScoring'))
export const TeamGameSetup = lazy(() => import('./TeamGameSetup'))
export const TeamVideoDetail = lazy(() => import('./TeamVideoDetail'))
export const ResetPassword = lazy(() => import('./ResetPassword'))
export const TeamGameVideos = lazy(() => import('./TeamGameVideos'))
export const Profile = lazy(() => import('./Profile'))
export const Dashboard = lazy(() => import('./Dashboard'))
export const Privacy = lazy(() => import('./Privacy'))
export const Terms = lazy(() => import('./Terms'))
export const Pricing = lazy(() => import('./Pricing'))

/** ADMIN **/
export const AdminInvitationsList = lazy(
  () => import('./admin/AdminInvitiationsList')
)
