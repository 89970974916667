import React, { useState } from 'react'
import styled from 'styled-components'
import { PlayerInsights, PlayerInsight } from '../types/models'
import { IonPopover } from '@ionic/react'

export interface PlayerInsightsTableProps {
  data: PlayerInsights
  gameTime?: string
}

const PlayerInsightsTable: React.FC<PlayerInsightsTableProps> = ({
  data,
  gameTime,
  ...rest
}) => {
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
    message: '',
  })

  const customShowPopover = (e: any, message1?: string) => {
    e.persist()
    // setShowPopover({ showPopover: false, event: undefined, message: '' })
    if (message1 == undefined) {
      setShowPopover({ showPopover: false, event: undefined, message: '' })
    } else {
      setShowPopover({ showPopover: true, event: e, message: message1 })
    }
  }

  const showPopOverMessage = () => {
    return (
      <IonPopover
        mode="ios"
        show-backdrop="true"
        css-class="tooltip-class"
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() =>
          setShowPopover({ showPopover: false, event: undefined, message: '' })
        }
      >
        <p>{popoverState.message}</p>
      </IonPopover>
    )
  }

  return (
    <div {...rest}>
      <table>
        <thead>
          <tr>
            <th>{data.aggregateByTeam ? 'Opponent' : 'Player'}</th>
            <th></th>
            {!data.aggregateByTeam && (
              <>
                <th
                  onClick={(e: any) => {
                    customShowPopover(e, 'Games Played')
                  }}
                >
                  GP
                  {showPopOverMessage()}
                </th>
                <th
                  onClick={(e: any) => {
                    customShowPopover(e, 'Minutes Played')
                  }}
                >
                  MP
                  {showPopOverMessage()}
                </th>
              </>
            )}
            <th
              onClick={(e: any) => {
                customShowPopover(e, 'Field Goals')
              }}
            >
              FG
              {showPopOverMessage()}
            </th>
            <th
              onClick={(e: any) => {
                customShowPopover(e, '2 Point Field Goals')
              }}
            >
              2P {showPopOverMessage()}
            </th>
            <th
              onClick={(e: any) => {
                customShowPopover(e, '3 Point Field Goals')
              }}
            >
              3P
              {showPopOverMessage()}
            </th>
            <th
              onClick={(e: any) => {
                customShowPopover(e, 'Free Throws')
              }}
            >
              FT {showPopOverMessage()}
            </th>
            <th
              onClick={(e: any) => {
                customShowPopover(e, 'Assists')
              }}
            >
              AST {showPopOverMessage()}
            </th>
            {!data.aggregateByTeam && !gameTime && (
              <th
                onClick={(e: any) => {
                  customShowPopover(e, 'Assists Per Game')
                }}
              >
                APG {showPopOverMessage()}
              </th>
            )}
            <th
              onClick={(e: any) => {
                customShowPopover(e, 'Turnovers')
              }}
            >
              TO {showPopOverMessage()}
            </th>
            <th
              onClick={(e: any) => {
                customShowPopover(e, 'Assist to Turnover ratio')
              }}
            >
              AST/TO {showPopOverMessage()}
            </th>
            <th
              onClick={(e: any) => {
                customShowPopover(e, 'Offensive Rebounds')
              }}
            >
              OR {showPopOverMessage()}
            </th>
            <th
              onClick={(e: any) => {
                customShowPopover(e, 'Defensive Rebounds')
              }}
            >
              DR
              {showPopOverMessage()}
            </th>
            <th
              onClick={(e: any) => {
                customShowPopover(e, 'Total Rebounds')
              }}
            >
              TR {showPopOverMessage()}
            </th>
            {!data.aggregateByTeam && !gameTime && (
              <th
                onClick={(e: any) => {
                  customShowPopover(e, 'Rebounds Per Game')
                }}
              >
                RPG {showPopOverMessage()}
              </th>
            )}
            <th
              onClick={(e: any) => {
                customShowPopover(e, 'Steals')
              }}
            >
              STL{showPopOverMessage()}
            </th>
            <th
              onClick={(e: any) => {
                customShowPopover(e, 'Blocked Shots')
              }}
            >
              BLK{showPopOverMessage()}
            </th>
            <th
              onClick={(e: any) => {
                customShowPopover(e, 'Personal Fouls')
              }}
            >
              PF{showPopOverMessage()}
            </th>
            <th
              onClick={(e: any) => {
                customShowPopover(e, 'Technical Fouls')
              }}
            >
              TF{showPopOverMessage()}
            </th>
            <th
              onClick={(e: any) => {
                customShowPopover(e, 'Charge Taken')
              }}
            >
              CHG{showPopOverMessage()}
            </th>
            <th
              onClick={(e: any) => {
                customShowPopover(e, 'Total Points')
              }}
            >
              PTS {showPopOverMessage()}
            </th>
            {!data.aggregateByTeam && !gameTime && (
              <th
                onClick={(e: any) => {
                  customShowPopover(e, 'Points per game')
                }}
              >
                PPG{showPopOverMessage()}
              </th>
            )}
            <th
              onClick={(e: any) => {
                customShowPopover(e, 'Effective Field Goal Percentage')
              }}
            >
              EFG (%) {showPopOverMessage()}
            </th>

            <th
              onClick={(e: any) => {
                customShowPopover(e, 'True Shooting Percentage')
              }}
            >
              TS (%) {showPopOverMessage()}
            </th>
            <th
              style={{ display: 'none' }}
              onMouseEnter={(e: any) => {
                customShowPopover(e)
              }}
            >
              {showPopOverMessage()}
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.players?.map((it: PlayerInsight) => (
            <tr key={it.id}>
              <td>{it.fullName}</td>
              <td></td>
              {!data.aggregateByTeam && (
                <>
                  <td>{it.gamesPlayed}</td>
                  <td>{it.minutesPlayed.toFixed(2)}</td>
                </>
              )}
              <td>
                {it.fieldGoals === 'DNP'
                  ? it.fieldGoals
                  : `${it.fieldGoals.made} - ${it.fieldGoals.total}`}
              </td>
              <td>
                {it.twoPointers === 'DNP'
                  ? it.twoPointers
                  : `${it.twoPointers.made} - ${it.twoPointers.total}`}
              </td>
              <td>
                {it.threePointers === 'DNP'
                  ? it.threePointers
                  : `${it.threePointers.made} - ${it.threePointers.total}`}
              </td>
              <td>
                {it.freethrows === 'DNP'
                  ? it.freethrows
                  : `${it.freethrows.made} - ${it.freethrows.total}`}
              </td>
              <td>{it.assists}</td>
              {!data.aggregateByTeam && !gameTime && (
                <td>
                  {it.assistsPerGame === 'DNP'
                    ? it.assistsPerGame
                    : it.assistsPerGame.toFixed(2)}
                </td>
              )}
              <td>{it.turnovers}</td>
              <td>
                {(it.assists != 'DNP'
                  ? it.assists /
                    (it.turnovers != 'DNP' && it.turnovers != +0
                      ? +it.turnovers
                      : +1)
                  : 0
                ).toFixed(2)}
              </td>
              <td>{it.offensiveRebounds}</td>
              <td>{it.defensiveRebounds}</td>
              <td>{it.totalRebounds}</td>
              {!data.aggregateByTeam && !gameTime && (
                <td>
                  {it.reboundsPerGame === 'DNP'
                    ? it.reboundsPerGame
                    : it.reboundsPerGame.toFixed(2)}
                </td>
              )}
              <td>{it.steals}</td>
              <td>{it.blockedShots}</td>
              <td>{it.personalFouls}</td>
              <td>{it.technicalFouls}</td>
              <td>{it.chargesTaken}</td>
              <td>{it.points}</td>
              {!data.aggregateByTeam && !gameTime && (
                <td>
                  {it.pointsPerGame === 'DNP'
                    ? it.pointsPerGame
                    : it.pointsPerGame.toFixed(2)}
                </td>
              )}
              <td>
                {it.fieldGoals != 'DNP' && it.threePointers != 'DNP'
                  ? (
                      ((it.fieldGoals.made + it.threePointers?.made * 0.5) /
                        (it.fieldGoals.total == 0 ? 1 : it.fieldGoals.total)) *
                      100
                    ).toFixed(2)
                  : 'DNP'}
              </td>
              <td>
                {it.fieldGoals === 'DNP' ||
                it.points === 'DNP' ||
                it.freethrows === 'DNP'
                  ? 'DNP'
                  : +(
                      (it.points /
                        (2 *
                          (it.freethrows.total * 0.44 + it.fieldGoals.total))) *
                      100
                    ).toFixed(2)}
              </td>
            </tr>
          ))}
          <tr className="footer-row">
            <td></td>
            <td></td>
            {!data.aggregateByTeam && (
              <>
                <td>{data?.totals?.totalGames}</td>
                <td></td>
              </>
            )}
            <td>
              {data?.totals?.fieldGoals === 'DNP'
                ? data?.totals?.fieldGoals
                : `${data?.totals?.fieldGoals?.made} - ${data?.totals?.fieldGoals?.total}`}
            </td>
            <td>
              {data?.totals?.twoPointers === 'DNP'
                ? data?.totals?.twoPointers
                : `${data?.totals?.twoPointers?.made} - ${data?.totals?.twoPointers?.total}`}
            </td>
            <td>
              {data?.totals?.threePointers === 'DNP'
                ? data?.totals?.threePointers
                : `${data?.totals?.threePointers?.made} - ${data?.totals?.threePointers?.total}`}
            </td>
            <td>
              {data?.totals?.freethrows === 'DNP'
                ? data?.totals?.freethrows
                : `${data?.totals?.freethrows?.made} - ${data?.totals?.freethrows?.total}`}
            </td>
            <td>{data?.totals?.assists}</td>
            {!data.aggregateByTeam && !gameTime && (
              <td>
                {data?.totals?.assistsPerGame === 'DNP'
                  ? data?.totals?.assistsPerGame
                  : data?.totals?.assistsPerGame?.toFixed(2)}
              </td>
            )}
            <td>{data?.totals?.turnovers}</td>
            <td>
              {(data?.totals?.assists != 'DNP'
                ? data?.totals?.assists /
                  (data?.totals?.turnovers != 'DNP'
                    ? data?.totals?.turnovers
                    : 1)
                : 0
              ).toFixed(2)}
            </td>
            <td>{data?.totals?.offensiveRebounds}</td>
            <td>{data?.totals?.defensiveRebounds}</td>
            <td>{data?.totals?.totalRebounds}</td>
            {!data.aggregateByTeam && !gameTime && (
              <td>
                {data?.totals?.reboundsPerGame === 'DNP'
                  ? data?.totals?.reboundsPerGame
                  : data?.totals?.reboundsPerGame?.toFixed(2)}
              </td>
            )}
            <td>{data?.totals?.steals}</td>
            <td>{data?.totals?.blockedShots}</td>
            <td>{data?.totals?.personalFouls}</td>
            <td>{data?.totals?.technicalFouls}</td>
            <td>{data?.totals?.chargesTaken}</td>
            <td>{data?.totals?.points}</td>
            {!data.aggregateByTeam && !gameTime && (
              <td>
                {data?.totals?.pointsPerGame === 'DNP'
                  ? data?.totals?.pointsPerGame
                  : data?.totals?.pointsPerGame?.toFixed(2)}
              </td>
            )}
            <td>
              {data?.totals?.fieldGoals != 'DNP' &&
              data?.totals?.threePointers != 'DNP'
                ? (
                    ((data?.totals?.fieldGoals.made +
                      data?.totals?.threePointers?.made * 0.5) /
                      (data?.totals.fieldGoals.total == 0
                        ? 1
                        : data?.totals?.fieldGoals.total)) *
                    100
                  ).toFixed(2)
                : 'DNP'}
            </td>

            <td>
              {data?.totals?.fieldGoals === 'DNP' ||
              data?.totals?.points === 'DNP' ||
              data?.totals?.freethrows === 'DNP'
                ? 'DNP'
                : (
                    +(
                      data?.totals?.points /
                      (2 *
                        (data?.totals?.freethrows.total * 0.44 +
                          data?.totals?.fieldGoals.total))
                    ) * 100
                  ).toFixed(2)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default styled(PlayerInsightsTable)`
  width: 100%;
  max-width: 120%;
  overflow-x: auto;

  table {
    border-collapse: collapse;
    min-width: 110%;
    margin-left: 8em;
  }

  th {
    background: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
  }

  tbody tr td:first-child {
    font-weight: bold;
    position: absolute;
    width: 10em;
    margin-left: -8em;
  }

  tbody tr:nth-child(even) td:first-child {
    background: var(--ion-color-light);
  }

  tbody tr:nth-child(odd) td:first-child {
    background: var(--background);
  }

  tr th:first-child {
    position: absolute;
    width: 10em;
    margin-left: -8em;
  }

  th,
  td {
    padding: 10px;
    text-align: left;
  }

  td {
    border: solid 1px var(--ion-color-light);
    white-space: nowrap;
  }

  tr:nth-child(even) {
    background: var(--ion-color-light);
  }

  tbody tr:nth-last-child(2) {
    border-bottom: solid 2px var(--ion-color-primary);
  }

  .footer-row {
    font-weight: bold;
  }
`
