import appConfig from '../core/config'
import { authentication } from '../core'
import { EventEmitter } from 'events'
import ReconnectingEventSource from 'reconnecting-eventsource'
import config from '../core/config'
class EventsService {
  private eventSource?: any
  private emitter: EventEmitter
  constructor() {
    this.emitter = new EventEmitter()
    this.emitter.setMaxListeners(500)
  }

  async connect() {
    const accessToken = await authentication.getAccessToken()
    this.eventSource = new ReconnectingEventSource(
      `${appConfig.baseURL}/events/?access_token=${accessToken}`
    )

    this.eventSource.addEventListener('error', (e: any) => console.error(e))
    this.eventSource.addEventListener('message', this.onMessage.bind(this))
  }

  private onMessage(event: any) {
    const { data } = event
    const sse = JSON.parse(data)

    if (sse.type === 'ping') {
      return
    }

    if (config.isDevelopment || config.logEvents) {
      console.info(sse)
    }

    this.emitter.emit('event', sse)
    this.emitter.emit(sse.type, sse.detail)

    if (sse.detail.model) {
      this.emitter.emit(sse.detail.model, sse)
    }
  }

  addEventListener(event: string, handler: (event: any) => any) {
    this.emitter.addListener(event, handler)
  }

  removeEventListener(event: string, handler: (event: any) => any) {
    this.emitter.removeListener(event, handler)
  }

  clearListeners() {
    this.emitter.removeAllListeners()
  }
}

export default new EventsService()
