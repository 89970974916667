import { toastController, ToastOptions } from '@ionic/core'
import http from './http'
class AppNotifications {
  async toast(
    message: string,
    header?: string,
    options?: Partial<ToastOptions>
  ) {
    const toast = await toastController.create({
      message,
      header,
      duration: 5000,
      position: 'top',
      buttons: [
        {
          text: 'DISMISS',
          handler() {
            toast.dismiss()
          },
        },
      ],
      ...(options ?? {}),
    })

    await toast.present()
    return toast
  }

  async errorToast(e: Error) {
    this.toast(http.getErrorMessage(e), 'Error', { color: 'danger' })
  }

  async newVersion() {
    await this.toast(
      'There is a new version of JumpScout available.',
      'Update available',
      {
        buttons: [
          {
            text: 'Reload',
            handler() {
              window.location.reload(true)
            },
          },
          {
            text: 'Later',
            handler() {
              toastController.dismiss()
            },
          },
        ],
        duration: 0,
        color: 'alternate',
      }
    )
  }
}

export default new AppNotifications()
