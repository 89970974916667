import { useEffect, useState } from 'react'
import config from '../core/config'
import { useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react'
export default function (title?: string) {
  const [visible, setVisible] = useState(true)
  useEffect(() => {
    if (visible) {
      document.title = title ? `${title} | ${config.title}` : config.title
    }
  }, [title, visible])

  useIonViewWillEnter(() => {
    setVisible(true)
  })

  useIonViewWillLeave(() => {
    setVisible(false)
  })
}
