import React, { useContext, useMemo, useState } from 'react'
import styled from 'styled-components'
import { IonGrid, IonRow, IonCol } from '@ionic/react'
import { PlayerInsights } from '../types/models'
import { AppContext } from '../contexts/AppContext'
import _, { ceil } from 'lodash'
import 'chartjs-plugin-annotation'
//@ts-expect-error
import { Bar, Chart, HorizontalBar } from 'react-chartjs-2'
import { ChartCard } from '.'
import moment from 'moment'


Chart.plugins.register({
  id: 'chartjs-plugin-annotation',
})

export interface InsightsReboundChartsProps {
  data?: PlayerInsights,
  isOpponents?: boolean,
  isTeamPersonnelIdAbsent?: boolean,
}

const InsightsReboundCharts: React.FC<InsightsReboundChartsProps> = ({
  data,
  isOpponents,
  isTeamPersonnelIdAbsent,
  ...props
}) => {
  const { state } = useContext(AppContext)
  const league = useMemo(
    () => state.leagues.find((it) => it.id === state.teamBrand?.leagueId),
    [state]
  )
  const is_team_personnel_id_absent = useMemo(() => {
    return isTeamPersonnelIdAbsent
  },[isTeamPersonnelIdAbsent] )

  const groupByField = 'date_mascot'

  const rebounds = useMemo(() => {
    data?.rebounds?.map((obj: any, it: any) => {
      obj.date_mascot = obj.opponent_mascot != '' ? obj.date + ' (' + obj.opponent_mascot + ')' : obj.date
      return obj
  })
    let allReboundsGroupedByDate = _.groupBy(data?.rebounds, groupByField)
    if(Object.keys(allReboundsGroupedByDate).length > 1){
      allReboundsGroupedByDate['Overall'] = data?.rebounds ?? []
    }
    let homeTeamORPercentageList =[]
    let oppositionDRPercentageList =[]
    let homeTeamDRPercentageList =[]
    let oppositionORPercentageList =[]
    let oppositionMascotLabel
    let uniqueOppositionMascotLabels: any[] = []
    for (const [key, value] of Object.entries(allReboundsGroupedByDate)) {
      let homeTeamOffensiveReboundsTotal = value?.filter((it:any) => it.isOffensive && it.isMyTeam).length
      let homeTeamDefensiveReboundsTotal = value?.filter((it:any) => !it.isOffensive && it.isMyTeam).length
      let oppositionDefensiveReboundsTotal = value?.filter((it:any) => it.isOffensive && it.isOpponentTeam).length
      let oppositionOffensiveReboundsTotal = value?.filter((it:any) => !it.isOffensive && it.isOpponentTeam).length
      oppositionMascotLabel = value?.map((obj: any, it: any) => obj.opponent_mascot!='undefined' ? obj.opponent_mascot : '')[0]
      uniqueOppositionMascotLabels.push(oppositionMascotLabel)
      let a = +(homeTeamOffensiveReboundsTotal/(homeTeamOffensiveReboundsTotal + oppositionDefensiveReboundsTotal)).toFixed(4) *100
      homeTeamORPercentageList.push(
        a
      )
      let b = +(oppositionDefensiveReboundsTotal/(homeTeamOffensiveReboundsTotal + oppositionDefensiveReboundsTotal)).toFixed(4) * 100
      oppositionDRPercentageList.push(
        b
      )
      let den = homeTeamDefensiveReboundsTotal + oppositionOffensiveReboundsTotal
      if(den ==0){
        den = 1
      }
      let c = +((homeTeamDefensiveReboundsTotal/den).toFixed(4)) * 100
      homeTeamDRPercentageList.push(
        c
      )
      let d = +((oppositionOffensiveReboundsTotal/den).toFixed(4)) * 100
      oppositionORPercentageList.push(
        d
      )
      
    }
    return  {
      homeTeamORPercentageList,
      oppositionDRPercentageList,
      homeTeamDRPercentageList,
      oppositionORPercentageList,
      allReboundsGroupedByDate,
      uniqueOppositionMascotLabels
    }
  }, [data, isOpponents])

  const title1 =  !isOpponents? `${state?.teamBrand?.mascot} OR% vs Opponents DR%` : `Opponents OR% vs ${state?.teamBrand?.mascot} DR%`
  const title2 = !isOpponents? `${state?.teamBrand?.mascot} DR% vs Opponents OR%`: `Opponents DR% vs ${state?.teamBrand?.mascot} OR%`

  return (
    <div {...props}>
      <IonGrid>
        <IonRow className="ion-align-items-stretch">
        <IonCol>
        <ChartCard title={title1} subtitle="">
        {is_team_personnel_id_absent && (
              <Bar
                options={{
                  responsive: true,
                  scales: {
                    xAxes: [
                      {
                        stacked: true,
                        ticks: {
                          // autoSkip: true,
                          maxTicksLimit: 50,
                        },
                        scaleLabel: {
                          display: true,
                          labelString: 'Opponents',
                        },
                      },
                    ],
                    yAxes: [
                      {
                        stacked: true,
                        scaleLabel: {
                          display: true,
                          labelString: 'Percentage',
                        },
                        ticks: {
                          maxTicksLimit: 6,
                          autoSkip: true,
                          callback: (value: any) => `${value} %`,
                          max: 100,
                        },
                      },
                    ],
                  },
                  legend: {
                    display: false,
                  },
                  tooltips: {
                    enabled: true,
                    callbacks: {
                      label: (tooltipItems, data) => 
                      !isOpponents? `${tooltipItems.datasetIndex ==0 ? state?.teamBrand?.mascot + ' OR' : 'Opponent DR'}: ${tooltipItems.value && parseFloat(tooltipItems?.value).toFixed(2)}%`:
                      `${tooltipItems.datasetIndex ==0 ? state?.teamBrand?.mascot + ' DR' : 'Opponent OR'}: ${tooltipItems.value && parseFloat(tooltipItems?.value).toFixed(2)}%`
                      
                        ,
                    },
                  },
                }
              }
                data={{
                  labels: Object.keys(rebounds.allReboundsGroupedByDate ?? {}).map((val:string, idx:any)=> val) ?? [],
                  datasets: [
                    {
                      label:`${state?.teamBrand?.mascot} OR`, //'Home OR',
                      fill: false,
                      lineTension: 0.1,
                      backgroundColor: 'rgba(82, 96, 157,0.4)',
                      borderColor: 'rgba(82, 96, 157,1)',
                      borderCapStyle: 'butt',
                      borderDash: [],
                      borderDashOffset: 0.0,
                      borderJoinStyle: 'miter',
                      pointBorderColor: 'rgba(82, 96, 157,1)',
                      pointBackgroundColor: '#fff',
                      pointBorderWidth: 1,
                      pointHoverRadius: 5,
                      pointHoverBackgroundColor: 'rgba(82, 96, 157,1)',
                      pointHoverBorderColor: 'rgba(82, 96, 157,1)',
                      pointHoverBorderWidth: 2,
                      pointRadius: 1,
                      pointHitRadius: 10,
                      data:rebounds?.homeTeamORPercentageList, 
                    },
                    {
                      label: 'Opponent DR', //rebounds?.uniqueOppositionMascotLabels, //'Opposition DR',
                      fill: false,
                      lineTension: 0.1,
                      backgroundColor: 'rgba(45, 211, 111,0.4)',
                      borderColor: 'rgba(45, 211, 111,1)',
                      borderCapStyle: 'butt',
                      borderDash: [],
                      borderDashOffset: 0.0,
                      borderJoinStyle: 'miter',
                      pointBorderColor: 'rgba(45, 211, 111,1)',
                      pointBackgroundColor: '#fff',
                      pointBorderWidth: 1,
                      pointHoverRadius: 5,
                      pointHoverBackgroundColor: 'rgba(45, 211, 111,1)',
                      pointHoverBorderColor: 'rgba(45, 211, 111,1)',
                      pointHoverBorderWidth: 2,
                      pointRadius: 1,
                      pointHitRadius: 10,
                      data:rebounds?.oppositionDRPercentageList, 
                    }
                  ],
                }}
              />
            )}
            {!is_team_personnel_id_absent && ('Rebound charts for this filter are not currently available')}
            </ChartCard>
          </IonCol>
          <IonCol>
          <ChartCard title={title2} subtitle="">
          {is_team_personnel_id_absent && (  
              <Bar
                options={{
                  responsive: true,
                  scales: {
                    xAxes: [
                      {
                        stacked: true,
                        ticks: {
                          maxTicksLimit: 50,
                        },
                        scaleLabel: {
                          display: true,
                          labelString: 'Opponents',
                        },
                      },
                    ],
                    yAxes: [
                      {
                        stacked: true,
                        scaleLabel: {
                          display: true,
                          labelString: 'Percentage',
                        },
                        ticks: {
                          maxTicksLimit: 6,
                          autoSkip: true,
                          callback: (value: any) => `${value} %`,
                          max: 100,
                        },
                      },
                    ],
                  },
                  legend: {
                    display: false,
                  },
                  tooltips: {
                    enabled: true,
                    callbacks: {
                      label: (tooltipItems, data) => 
                      !isOpponents? `${tooltipItems.datasetIndex ==0 ? state?.teamBrand?.mascot + ' DR' : 'Opponent OR'}: ${tooltipItems.value && parseFloat(tooltipItems?.value).toFixed(2)}%`:
                      `${tooltipItems.datasetIndex ==0 ? state?.teamBrand?.mascot + ' OR' : 'Opponent DR'}: ${tooltipItems.value && parseFloat(tooltipItems?.value).toFixed(2)}%`
                    },
                  },
                }}
                data={{
                  labels: Object.keys(rebounds.allReboundsGroupedByDate ?? {}).map((val:string, idx:any)=> val) ?? [],
                  datasets: [
                    {
                      label: `${state?.teamBrand?.mascot} DR`, //'Home OR',
                      fill: false,
                      lineTension: 0.1,
                      backgroundColor: 'rgba(82, 96, 157,0.4)',
                      borderColor: 'rgba(82, 96, 157,1)',
                      borderCapStyle: 'butt',
                      borderDash: [],
                      borderDashOffset: 0.0,
                      borderJoinStyle: 'miter',
                      pointBorderColor: 'rgba(82, 96, 157,1)',
                      pointBackgroundColor: '#fff',
                      pointBorderWidth: 1,
                      pointHoverRadius: 5,
                      pointHoverBackgroundColor: 'rgba(82, 96, 157,1)',
                      pointHoverBorderColor: 'rgba(82, 96, 157,1)',
                      pointHoverBorderWidth: 2,
                      pointRadius: 1,
                      pointHitRadius: 10,
                      data:rebounds?.homeTeamDRPercentageList, 
                    },
                    {
                      label: 'Opponent OR',
                      fill: false,
                      lineTension: 0.1,
                      backgroundColor: 'rgba(45, 211, 111,0.4)',
                      borderColor: 'rgba(45, 211, 111,1)',
                      borderCapStyle: 'butt',
                      borderDash: [],
                      borderDashOffset: 0.0,
                      borderJoinStyle: 'miter',
                      pointBorderColor: 'rgba(45, 211, 111,1)',
                      pointBackgroundColor: '#fff',
                      pointBorderWidth: 1,
                      pointHoverRadius: 5,
                      pointHoverBackgroundColor: 'rgba(45, 211, 111,1)',
                      pointHoverBorderColor: 'rgba(45, 211, 111,1)',
                      pointHoverBorderWidth: 2,
                      pointRadius: 1,
                      pointHitRadius: 10,
                      data:rebounds?.oppositionORPercentageList, 
                    }
                  ],
                }}
              />
            )}
            {!is_team_personnel_id_absent && ('Rebound charts for this filter are not currently available')}
            </ChartCard>
          </IonCol>
          {/* <IonCol></IonCol> */}
        </IonRow>
      </IonGrid>
    </div>
  )
}

export default styled(React.memo(InsightsReboundCharts))``
