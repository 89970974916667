import { http } from '../core'
import qs from 'querystring'

export default abstract class BaseService {
  protected apiVersion: string = 'v1'

  protected abstract get route(): string
  public getEndpointUrl(
    id?: string | null,
    path?: string,
    search?: string,
    filters?: { [key: string]: any },
    isDefaults?: boolean
  ) {
    let url = `/${this.apiVersion}/${this.route}/${id ? `${id}/` : ''}`

    if (path) {
      url += `${path}/`
    }

    if (search || filters) {
      const params: any = {}
      if (search?.length) {
        params.search = search
      }

      if (filters && Object.keys(filters).length) {
        //Remove any !all params and send it through.
        //This is used for when there might be default filters
        //That still want to have an all option. Like Staff or Player isActive
        const f = Object.keys(filters).reduce((o: any, key: string) => {
          if (filters[key] !== '!all') {
            o[key] = filters[key]
          }
          return o
        }, {})

        params.filters = JSON.stringify(f)
      }

      //Set that it's default filters
      if (isDefaults === true) {
        params['!d'] = ''
      }

      url += `?${qs.stringify(params)}`
    }

    return url
  }

  async downloadPDF(url: string) {
    const res = await http.blobRequest({
      url,
    })

    return res.data as Blob
  }
}
