import React, { useMemo } from 'react'
import styled from 'styled-components'
import { IonItem, IonLabel, IonText } from '@ionic/react'
import { TeamBrandPersonnel } from '../types/models'
import { Avatar } from '.'
import { listsService } from '../services'
import helpers from '../util/helpers'

export interface TeamBrandPlayerRowProps extends React.ComponentProps<any> {
  player: TeamBrandPersonnel
}

const TeamBrandPlayerRow: React.FC<TeamBrandPlayerRowProps> = ({
  player,
  ...rest
}) => {
  const fullName = useMemo(() => `${player.firstName} ${player.lastName}`, [
    player,
  ])
  return (
    <IonItem
      lines="full"
      routerLink={`/a/program/players/${player.id}/`}
      routerDirection="forward"
      detail
      {...rest}
    >
      <Avatar
        slot="start"
        name={fullName}
        email={player.primaryEmail}
        src={player.avatarURL}
      />
      <IonLabel>
        <IonText className="ion-multiline">
          <h3>
            {player.lastName}, {player.firstName}
          </h3>
          <span className="subtext">
            {helpers.bullet([
              player.primaryEmail,
              player.primaryPhone,
              listsService.display(listsService.gradeLevels, player.gradeLevel),
            ])}
          </span>
        </IonText>
      </IonLabel>
    </IonItem>
  )
}

export default styled(React.memo(TeamBrandPlayerRow))``
