import React from 'react'
import moment from 'moment'
import styled from 'styled-components'

export interface GameDateProps extends React.ComponentProps<any> {
  date?: Date
}

const GameDate: React.FC<GameDateProps> = ({ date, ...rest }) => {
  return (
    <div {...rest}>
      {date ? (
        <>
          <span className="mm-dd">{moment(date).format('MM/DD')}</span>
          <span className="yyyy">{moment(date).format('YYYY')}</span>
        </>
      ) : (
        <span className="no-date">N/A</span>
      )}
    </div>
  )
}

export default styled(GameDate)`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  margin: 0 10px 0 0;
  padding: 0;
  min-width: 45px;
  text-align: center;

  .no-date {
    color: var(--ion-color-basketball);
    font-size: 14px;
    font-weight: bold;
    padding: 3px 0;
  }

  .mm-dd {
    color: var(--ion-color-basketball);
    border-bottom: solid 1px var(--ion-color-light);
    font-size: 14px;
    font-weight: bold;
    padding: 3px 0;
  }

  .yyyy {
    color: var(--ion-color-medium);
    font-size: 11px;
    font-weight: bold;
    padding: 3px 0;
  }
`
