import React from 'react'
import styled from 'styled-components'
import { IonItem, IonLabel, IonText } from '@ionic/react'
import { TeamBrandPersonnel } from '../types/models'
import { Avatar } from '.'
import { listsService } from '../services'
import helpers from '../util/helpers'

export interface TeamBrandStaffRowProps extends React.ComponentProps<any> {
  staff: TeamBrandPersonnel
}

const TeamBrandStaffRow: React.FC<TeamBrandStaffRowProps> = ({
  staff,
  ...rest
}) => {
  return (
    <IonItem
      lines="full"
      routerLink={`/a/program/staff/${staff.id}/`}
      routerDirection="forward"
      detail
      {...rest}
    >
      <Avatar
        slot="start"
        email={staff.primaryEmail}
        name={staff.fullName}
        src={staff.avatarURL}
      />
      <IonLabel>
        <IonText className="ion-multiline">
          <h3>{staff.fullName}</h3>
          <span className="subtext">
            {helpers.bullet([
              staff.primaryEmail,
              staff.primaryPhone,
              listsService.display(listsService.staffRoles, staff.role),
            ])}
          </span>
        </IonText>
      </IonLabel>
    </IonItem>
  )
}

export default styled(React.memo(TeamBrandStaffRow))``
