import { TeamPersonnelService } from './team-personnel'
import { DataField } from '../util/data-field'
import { listsService } from '.'

class TeamStaffService extends TeamPersonnelService {
  public get fields(): DataField[] {
    return [
      {
        key: 'role',
        label: 'Role',
        type: 'select',
        options: listsService.rosterRoles,
      },
    ]
  }
}

export default new TeamStaffService()
