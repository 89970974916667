import React, { useEffect, useContext } from 'react'
import { IonRouterOutlet, IonSplitPane, IonMenu } from '@ionic/react'
import { authentication, initialization } from '../core'
import AppNavigation from './AppNavigation'
import AuthenticatedRoute from './AuthenticatedRoute'
import { Redirect, Route } from 'react-router-dom'
import AnonymousRoute from './AnonymousRoute'
import { useTracking, useIsMarketing } from '../hooks'
import { AppContext } from '../contexts/AppContext'
import {
  TeamBrandWorkflow,
  TeamWorkflow,
  AcceptInvitationWorkflow,
  AdminAccountSetupWorkflow,
} from '../workflows'

import {
  ForgotPassword,
  TeamVideos,
  NotFound,
  Dashboard,
  Login,
  Register,
  Home,
  Profile,
  TeamInsights,
  TeamSchedule,
  TeamRoster,
  TeamSettings,
  TeamBrandSubscription,
  TeamBrandInsights,
  TeamBrandPlayers,
  TeamBrandStaff,
  TeamBrandPlayerDetail,
  TeamBrandStaffDetail,
  TeamPlayerDetail,
  TeamStaffDetail,
  TeamGameDetail,
  TeamGameScoring,
  TeamGameSetup,
  TeamVideoDetail,
  TeamGameVideos,
  ResetPassword,
  TeamBrandSettings,
  InvitationVideo,
  Privacy,
  Terms,
  AdminInvitationsList,
} from '../pages'
import Pricing from '../pages/Pricing'

const AppRouterOutlet: React.FC<any> = ({ ...props }) => {
  useTracking()
  const { dispatch, state } = useContext(AppContext)

  const isMarketing = useIsMarketing()

  useEffect(() => {
    if (!state.ready) {
      ;(async () => {
        const state = await initialization.initialize()
        dispatch({
          type: 'initialize',
          value: state,
        })

        initialization.complete()
      })()
    }
  }, [dispatch, state.ready])

  useEffect(() => {
    if (isMarketing) {
      document.body.classList.remove('app')
      document.body.classList.add('marketing')
    } else {
      document.body.classList.add('app')
      document.body.classList.remove('marketing')
    }
  }, [isMarketing])

  return (
    <IonSplitPane contentId="main" {...props}>
      <IonMenu
        disabled={isMarketing}
        className="menu"
        type="overlay"
        contentId="main"
      >
        {!isMarketing && <AppNavigation />}
      </IonMenu>
      <IonRouterOutlet id="main">
        {/* <AuthenticatedRoute
          exact
          path="/a/team/new/"
          subscriptionRequired
          component={TeamWorkflow}
        />
        <AuthenticatedRoute
          exact
          path="/a/team/videos/"
          subscriptionRequired
          component={TeamVideos}
        />
        <AuthenticatedRoute
          exact
          path="/a/team/videos/:id/"
          subscriptionRequired
          component={TeamVideoDetail}
        />
        <AuthenticatedRoute
          exact
          path="/a/team/insights/"
          subscriptionRequired
          component={TeamInsights}
        />
        <AuthenticatedRoute
          exact
          path="/a/team/schedule/"
          subscriptionRequired
          component={TeamSchedule}
        />
        <AuthenticatedRoute
          exact
          path="/a/team/roster/"
          subscriptionRequired
          component={TeamRoster}
        />
        <AuthenticatedRoute
          exact
          path="/a/team/player/:id/"
          subscriptionRequired
          component={TeamPlayerDetail}
        />
        <AuthenticatedRoute
          exact
          path="/a/team/staff/:id/"
          subscriptionRequired
          component={TeamStaffDetail}
        />
        <AuthenticatedRoute
          exact
          path="/a/team/game/:id/"
          subscriptionRequired
          component={TeamGameDetail}
        />
        <AuthenticatedRoute
          exact
          path="/a/team/game/:id/videos/"
          subscriptionRequired
          component={TeamGameVideos}
        />
        <AuthenticatedRoute
          exact
          path="/a/team/game/:id/setup/"
          subscriptionRequired
          component={TeamGameSetup}
        />
        <AuthenticatedRoute
          exact
          path="/a/team/game/:id/scoring/:type/"
          subscriptionRequired
          component={TeamGameScoring}
        />
        <AuthenticatedRoute
          exact
          path="/a/team/settings/"
          subscriptionRequired
          component={TeamSettings}
        />
        <AuthenticatedRoute
          exact
          path="/a/program/insights/"
          subscriptionRequired
          component={TeamBrandInsights}
        />
        <AuthenticatedRoute
          exact
          path="/a/program/new/"
          component={TeamBrandWorkflow}
        />
        <AuthenticatedRoute
          exact
          path="/a/program/players/"
          subscriptionRequired
          component={TeamBrandPlayers}
        />
        <AuthenticatedRoute
          exact
          path="/a/program/staff/"
          subscriptionRequired
          component={TeamBrandStaff}
        />
        <AuthenticatedRoute
          exact
          path="/a/program/players/:id/"
          subscriptionRequired
          component={TeamBrandPlayerDetail}
        />
        <AuthenticatedRoute
          exact
          path="/a/program/staff/:id/"
          subscriptionRequired
          component={TeamBrandStaffDetail}
        />
        <AuthenticatedRoute
          exact
          path="/a/program/subscription/"
          component={TeamBrandSubscription}
        />
        <AuthenticatedRoute
          exact
          path="/a/program/settings/"
          subscriptionRequired
          component={TeamBrandSettings}
        />
        <AuthenticatedRoute
          exact
          path="/a/admin/account-setup/"
          adminRequired
          component={AdminAccountSetupWorkflow}
        />
        <AuthenticatedRoute
          exact
          path="/a/admin/invitations/"
          adminRequired
          component={AdminInvitationsList}
        /> */}
        {/* <AuthenticatedRoute exact path="/a/profile/" component={Profile} /> */}
        {/* <AuthenticatedRoute
          exact
          path="/a/dashboard/"
          subscriptionRequired
          component={Dashboard}
        /> */}
        {/* <Redirect exact from="/a/" to="/a/dashboard/" /> */}
        {/* <Redirect exact from="/signup/" to="/register/" /> */}
        {/* <Redirect exact from="/signin/" to="/login/" /> */}
        {/* <Redirect exact path="/request-invite/complete/" to="/register/" /> */}
        {/* <Redirect exact path="/request-invite/" to="/register/" /> */}
        {/* <AnonymousRoute exact path="/reset/" component={ResetPassword} />
        <AnonymousRoute
          exact
          path="/invite/accept/"
          component={AcceptInvitationWorkflow}
        /> */}
        {/* <AnonymousRoute
          exact
          path="/forgot-password/"
          component={ForgotPassword}
        /> */}
        {/* <AnonymousRoute exact path="/video/" component={InvitationVideo} /> */}
        {/* <AnonymousRoute exact path="/login/" component={Login} /> */}
        {/* <AnonymousRoute exact path="/register/" component={Register} /> */}
        <AnonymousRoute exact path="/privacy/" component={Privacy} />
        <AnonymousRoute exact path="/terms/" component={Terms} />
        {/* <AnonymousRoute exact path="/pricing/" component={Pricing} /> */}
        <AnonymousRoute exact path="/" component={Home} />
        <Route
          exact
          path="/logout/"
          render={async () => {
            await authentication.clear()
            window.location.href = '/'
          }}
        />
        <AnonymousRoute component={NotFound} />
      </IonRouterOutlet>
    </IonSplitPane>
  )
}

export default AppRouterOutlet
