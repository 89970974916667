import SparkMD5 from 'spark-md5'
class FileHasher {
  async hashPrefixWithMetadata(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      try {
        const prefixBytes = 5242880 //5MB
        const spark = new SparkMD5.ArrayBuffer()
        const data = file.slice(0, Math.min(prefixBytes, file.size))
        const fileReader = new FileReader()

        fileReader.onload = (e) => {
          try {
            if (e.target?.result) {
              spark.append(e.target.result as any)
            }

            const prefixHash = spark.end()
            resolve(SparkMD5.hash(`${file.size}${file.type}${prefixHash}`))
          } catch (e) {
            reject(e)
          }
        }

        fileReader.readAsArrayBuffer(data)
      } catch (e) {
        reject(e)
      }
    })
  }

  hash(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      let chunkSize = 2097152, // Read in chunks of 2MB
        chunks = Math.ceil(file.size / chunkSize),
        currentChunk = 0,
        spark = new SparkMD5.ArrayBuffer(),
        fileReader = new FileReader()

      fileReader.onload = (e) => {
        if (e.target?.result) {
          spark.append(e.target.result as any)
        }

        currentChunk++

        if (currentChunk < chunks) {
          loadNext()
        } else {
          resolve(spark.end())
        }
      }

      fileReader.onerror = reject

      function loadNext() {
        var start = currentChunk * chunkSize,
          end = start + chunkSize >= file.size ? file.size : start + chunkSize

        fileReader.readAsArrayBuffer(file.slice(start, end))
      }

      loadNext()
    })
  }
}

export default new FileHasher()
