import { TeamGamePersonnelService } from './team-game-personnel'
import { DataField } from '../util/data-field'
import { listsService } from '.'

class TeamGamePlayerService extends TeamGamePersonnelService {
  public get fields(): DataField[] {
    return [
      ...this.standardFields,
      {
        key: 'position',
        label: 'Position',
        type: 'select',
        options: listsService.positions,
      },
      {
        key: 'jersey',
        label: 'Jersey',
        type: 'input',
      },
      {
        key: 'order',
        label: 'Order',
        type: 'input',
        inputType: 'number',
      },
      {
        key: 'isStarting',
        label: 'Starter',
        type: 'toggle',
      },
    ]
  }
}

export default new TeamGamePlayerService()
