import { UnmanagedTeamPersonnel } from './../types/models'
import ModelBaseService from './model-base'
import { DataField } from '../util/data-field'
import { http } from '../core'

class UnmanagedTeamService extends ModelBaseService {
  protected modelName = 'unmanaged-team'
  public get fields(): DataField[] {
    return []
  }

  public async addPersonnel(personnel: Partial<UnmanagedTeamPersonnel>) {
    const { data } = await http.authorizedRequest({
      method: 'POST',
      url: this.getEndpointUrl(personnel.unmanagedTeamId, 'personnel'),
      data: personnel,
    })

    return data
  }

  public async removePersonnel(id: string, personnelId: string) {
    const { data } = await http.authorizedRequest({
      method: 'DELETE',
      url: this.getEndpointUrl(id, 'personnel'),
      data: {
        id: personnelId,
      },
    })

    return data
  }
}

export default new UnmanagedTeamService()
