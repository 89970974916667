import React, { useContext, useMemo,useRef } from 'react'
import styled from 'styled-components'
import { IonGrid, IonRow, IonCol } from '@ionic/react'
import { PlayerInsights } from '../types/models'
import CourtCanvas from './CourtCanvas'
import { AppContext } from '../contexts/AppContext'
import _ from 'lodash'
import 'chartjs-plugin-annotation'
//@ts-expect-error
import { Line, Chart, HorizontalBar } from 'react-chartjs-2'
import { ChartCard } from '.'
import {annotation} from './ChartConfig'


Chart.plugins.register({
  id: 'chartjs-plugin-annotation',
})

export interface InsightsFieldGoalChartsProps {
  data?: PlayerInsights
}

const InsightsPointPerShotCharts: React.FC<InsightsFieldGoalChartsProps> = ({
  data,
  ...props
}) => {
  const courtCanvas = useRef<any>()
  const { state } = useContext(AppContext)
  const league = useMemo(
    () => state.leagues.find((it) => it.id === state.teamBrand?.leagueId),
    [state]
  )

  const court = useMemo(
    () => state.courts.find((it) => it.id === league?.id) ?? state.courts[0],
    [state, league]
  )

  const shotsByFoot = useMemo(() => {
    return data?.shots?.reduce?.((obj: any, it: any) => {
      const feet = Math.round(it.distance)
      if(feet < 31){
        obj[feet] = obj[feet] ?? []
        obj[feet].push(it)
      }
      return obj
    }, _.zipObject(_.range(0, 30)))
  }, [data])


  const pointsPerShotByFoot = useMemo(() => {
    let c = shotsByFoot
    Object.keys(c).map((obj)=>{
        if(c[obj]){
            let totalPointsAtFeet = _.sumBy(c[obj]?.filter((it:any)=> it.made===true), 'pointValue') 
            c[obj]['pps'] = totalPointsAtFeet/(c[obj]?.length ?? 0)
        }
    })
    return c
}, [data])

  return (
    <div {...props}>
      <IonGrid>
        <IonRow className="ion-align-items-stretch">
        <IonCol>
        <ChartCard title="Points per Field Goal Attempt Chart">
              <div className="top-half">
                <CourtCanvas
                  ref={courtCanvas}
                  points={data?.shots?.map((it: any) => ({
                    ...it,
                    size: 5,
                    color: '#444444', // : '#eb445a',
                    filled: it.made ? true: false
                  }))}
                  court={court}
                  type="TOPHALF"
                  fillcourtcolor = {false}
                  regions= {[{upperRangeNumber:10, lowerRangeNumber:0},
                            {upperRangeNumber:15,  lowerRangeNumber: 10},
                            {upperRangeNumber:20,  lowerRangeNumber:15},
                            {upperRangeNumber:25,  lowerRangeNumber:20},
                            {upperRangeNumber:30,  lowerRangeNumber:25},
                          ]}

                          // #52609D #818CBC #B3BAD7 #D9DCEB #D8FDCD #8BFA69 #3FF309 #269105
                  legends ={[{upperRangeNumber:0.25, color:'#52609D', lowerRangeNumber:0},
                              {upperRangeNumber:0.5, color:'#818CBC', lowerRangeNumber: 0.25},
                              {upperRangeNumber:0.75, color:'#B3BAD7', lowerRangeNumber: 0.5},
                              {upperRangeNumber:1, color:'#D9DCEB', lowerRangeNumber:0.75},
                              {upperRangeNumber:1.25, color:'#C5FCB4', lowerRangeNumber:1},
                              {upperRangeNumber:1.5, color:' #8BFA69', lowerRangeNumber:1.25},
                              {upperRangeNumber:1.75, color:'#3FF309', lowerRangeNumber:1.5},
                              {upperRangeNumber:3, color:' #269105', lowerRangeNumber:1.75},
                            ]}
                  nonInteractive
                />
              </div>
            </ChartCard>
          </IonCol>
          <IonCol>
            <ChartCard title="Points per Field Goal Attempt" subtitle="by Distance">
              <Line
                options={{
                  responsive: true,
                  annotation: annotation(court, 'vertical', 'x-axis-0'),
                  scales: {
                    xAxes: [
                      {
                        ticks: {
                          autoSkip: true,
                          maxTicksLimit: 6,
                        },
                        scaleLabel: {
                          display: true,
                          labelString: 'Distance (ft)',
                        },
                      },
                    ],
                    yAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: 'Points Per Shot',
                        },
                        ticks: {
                          callback: (value: any) => `${value}`,
                        },
                      },
                    ],
                  },
                  legend: {
                    display: false,
                  },
                }}
                data={{
                  labels: Object.keys(pointsPerShotByFoot ?? {}) ?? [],
                  datasets: [
                    {
                      label: 'Points per shot',
                      fill: false,
                      lineTension: 0.1,
                      backgroundColor: 'rgba(251, 94, 28,0.4)',
                      borderColor: 'rgba(251, 94, 28,1)',
                      borderCapStyle: 'butt',
                      borderDash: [],
                      borderDashOffset: 0.0,
                      borderJoinStyle: 'miter',
                      pointBorderColor: 'rgba(251, 94, 28,1)',
                      pointBackgroundColor: '#fff',
                      pointBorderWidth: 1,
                      pointHoverRadius: 5,
                      pointHoverBackgroundColor: 'rgba(251, 94, 28,1)',
                      pointHoverBorderColor: 'rgba(220,220,220,1)',
                      pointHoverBorderWidth: 2,
                      pointRadius: 1,
                      pointHitRadius: 10,
                      data: Object.values(pointsPerShotByFoot ?? {}).map((it: any) =>
                        it?.pps.toFixed(2) ?? 0
                      ),
                    },
                  ],
                }}
              />
            </ChartCard>
          </IonCol>
          <IonCol></IonCol>
        </IonRow>
      </IonGrid>
    </div>
  )
}

export default styled(React.memo(InsightsPointPerShotCharts))``
