import React, { useMemo, useRef, useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import { UserMenu, Logo, Page, MarketingSignupButton } from '.'
import moment from 'moment'
import {
  IonContent,
  IonRouterLink,
  useIonViewWillEnter,
  IonIcon,
} from '@ionic/react'
import css from 'classnames'
import { useRouter } from '../hooks'
import { AppContext } from '../contexts/AppContext'
import { device } from '../core'
import { menu } from 'ionicons/icons'

const MarketingPage: React.FC<any> = ({
  staticContext,
  children,
  className,
  banner,
  ...rest
}) => {
  const { state } = useContext(AppContext)
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const content = useRef<any>()
  const { match } = useRouter()
  const isHome = useMemo(() => match.path === '/', [match])

  useIonViewWillEnter(() => {
    content.current?.scrollToTop?.()
  })

  useEffect(() => {
    setShowMobileMenu(false)
  }, [match])

  const links = (
    <>
      {/* <IonRouterLink routerLink="/">Home</IonRouterLink> */}
      {/* <IonRouterLink routerLink="/pricing/">Pricing</IonRouterLink> */}
      {/* {state.ready && !state.isAuthenticated && !device.isReactSnap && (
        <>
          <IonRouterLink routerLink="/register/">Sign up</IonRouterLink>
          <IonRouterLink routerLink="/login/">Login</IonRouterLink>
        </>
      )} */}
    </>
  )

  return (
    <Page className={css(className, 'm')} {...rest}>
      <IonContent ref={content}>
        <div
          className={css('mobile-menu', { visible: showMobileMenu })}
          slot="fixed"
        >
          <div className="mobile-menu--links">{links}</div>
          {/* <MarketingSignupButton className="cta" /> */}
          <span
            className="link-button close-nav"
            onClick={() => setShowMobileMenu(false)}
          >
            Close Navigation
          </span>
        </div>
        <header
          className={css('marketing-nav', { home: isHome, banner: !!banner })}
        >
          {!isHome && (
            <IonRouterLink routerLink="/" className="marketing-nav--logo__link">
              <Logo
                className="marketing-nav--logo"
                color={banner ? 'white' : 'indigo'}
                depth={banner ? 'one' : 'full'}
                type={banner ? 'wordmark' : 'horizontal'}
              />
            </IonRouterLink>
          )}

          <IonIcon
            className="mobile-menu-button"
            onClick={() => setShowMobileMenu((v: boolean) => !v)}
            icon={menu}
          />

          <Logo
            className="marketing-nav--logo mobile"
            color="black"
            depth="one"
            type="wordmark"
          />

          <div className="marketing-nav--links">{links}</div>
          <UserMenu className="marketing-nav--user-menu" hideArrow />
          {banner && (
            <div className="marketing-page--banner header">{banner}</div>
          )}
        </header>
        {banner && (
          <div className="marketing-page--banner content">{banner}</div>
        )}
        {children}
        <footer className="marketing-footer">
          <div className="left">
            <div className="marketing-footer--nav">{links}</div>
            <Logo
              className="footer-logo"
              color="indigo"
              type="horizontal"
              depth="full"
            />
          </div>
          <div className="right">
            {/* <MarketingSignupButton noSubtext /> */}
            <IonRouterLink routerLink="/terms/">Terms of Service</IonRouterLink>
            <IonRouterLink routerLink="/privacy/">Privacy Policy</IonRouterLink>
            <span className="copyright">
              &copy; {moment().format('YYYY')} JumpScout
            </span>
          </div>
        </footer>
      </IonContent>
    </Page>
  )
}

export default styled(React.memo(MarketingPage))`
  .marketing-nav {
    align-items: center;
    display: grid;
    position: relative;

    &.home {
      grid-template-columns: 1fr auto;
    }

    @media (min-width: 501px) {
      &.banner {
        background: url('/assets/marketing/JumpScout_Team_Gradient@3x.png')
          no-repeat;
        background-size: cover;
        background-position: center center;
        grid-template-rows: 100px auto;

        .marketing-nav--links > * {
          color: white;
        }
      }
    }

    grid-template-columns: auto 1fr auto;
    flex: 0 0 auto;
    min-height: 100px;
    width: 100vw;

    .marketing-nav--logo__link {
      margin: 0 0 0 20px;

      .marketing-nav--logo {
        height: 80px;
      }
    }

    .marketing-nav--links {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: max-content;
      grid-template-rows: 1fr;
      grid-column-gap: 60px;
      padding: 0 60px;

      @media (max-width: 600px) {
        grid-column-gap: 20px;
        padding: 0 20px;

        > * {
          font-size: 14px;
        }
      }

      > * {
        color: var(--ion-color-primary);
        font-family: 'Roboto', sans-serif;
        font-style: italic;
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }

    .marketing-nav--logo.mobile {
      height: 40px;
      width: auto;
      display: none;
    }

    .mobile-menu-button {
      display: none;
    }
  }

  .marketing-page--banner {
    align-items: center;
    grid-column: span 3;
    width: 100vw;
    color: var(--ion-color-primary-contrast);
    display: flex;
    font-family: 'Fugaz One', cursive;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 40px;
    justify-content: center;
    height: 150px;
    text-align: center;

    &.content {
      background: url('/assets/marketing/JumpScout_Team_Gradient@3x.png')
        no-repeat;
      background-size: cover;
      background-position: center center;
    }

    @media (max-width: 500px) {
      &.header {
        display: none;
      }
    }

    @media (min-width: 501px) {
      &.content {
        display: none;
      }
    }
  }

  .marketing-footer {
    align-items: flex-start;
    display: grid;
    grid-template-columns: 1fr auto;

    @media screen and (max-width: 550px) {
      grid-template-columns: 1fr;
      grid-row-gap: 20px;

      .marketing-footer--nav {
        text-align: center;
        margin: auto;
      }
    }

    .marketing-footer--nav {
      display: grid;
      grid-auto-flow: row;
      grid-auto-rows: 1fr;

      > * {
        border-bottom: solid 2px var(--ion-color-primary);

        &:last-child {
          border-bottom: none;
        }

        display: inline-block;
        color: var(--ion-color-dark);
        font-family: 'Roboto', sans-serif;
        font-style: italic;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 1px;
        width: 100px;
        padding: 10px 5px;
        text-transform: uppercase;
        text-decoration: none;
      }
    }

    padding: 20px 50px;
    .footer-logo {
      margin: 0;
      width: 200px;
    }

    .left,
    .right {
      display: flex;
      flex-direction: column;
    }

    .left {
      flex: 1 1 auto;
    }

    .right {
      align-self: flex-end;
      flex: 0 0 auto;
      text-align: right;

      ion-router-link {
        font-size: 14px;
        margin-bottom: 7px;
        text-decoration: underline;
      }

      .footer-cta {
        margin: 10px 0 20px;
      }

      .copyright {
        margin-top: 15px;
        font-size: 12px;
      }
    }
  }

  .cta {
    border-radius: 32px;
    font-size: 15px;
    margin: 10px auto;
    padding: 10px 40px;
  }

  .mobile-menu {
    align-content: flex-start;
    display: grid;
    grid-template-rows: auto auto auto;
    justify-items: center;
    grid-row-gap: 20px;
    opacity: 0;
    pointer-events: none;
    transition: opacity ease-in-out 0.2s;

    .close-nav {
      color: var(--ion-color-dark);
      font-size: 12px;
    }

    .mobile-menu--links {
      display: grid;
      grid-auto-flow: row;
      grid-auto-rows: 1fr;

      > * {
        border-bottom: solid 2px var(--ion-color-primary);

        &:last-child {
          border-bottom: none;
        }

        display: inline-block;
        color: var(--ion-color-dark);
        font-family: 'Roboto', sans-serif;
        font-style: italic;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 1px;
        width: 120px;
        padding: 10px 5px;
        text-transform: uppercase;
        text-decoration: none;
        text-align: center;
      }
    }
  }

  @media (max-width: 500px) {
    .mobile-menu {
      background: rgba(255, 255, 255, 0.9);
      backdrop-filter: blur(7px);
      padding: 40px 20px 10px;
      position: absolute;
      top: 70px;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 10;

      &.visible {
        opacity: 1;
        pointer-events: unset;
      }
    }

    .marketing-footer {
      .left,
      .right {
        align-items: center;
        justify-content: center;
        text-align: center;
      }
    }

    .marketing-nav {
      background: white;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      min-height: 50px;
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
      z-index: 5;

      .marketing-nav--logo__link {
        display: none;
      }

      .marketing-nav--links {
        display: none;
      }

      .mobile-menu-button {
        cursor: pointer;
        display: block;
        height: 30px;
        position: absolute;
        top: 50%;
        left: 10px;
        width: 30px;
        transform: translateY(-50%);
      }

      .marketing-nav--user-menu {
        position: absolute;
        top: 5px;
        right: 0;

        --ion-color-light: var(--ion-color-medium);
      }

      .marketing-nav--logo.mobile {
        display: block;
      }
    }
  }
`
