import React from 'react'
import { IonItem, IonLabel, IonText, IonBadge } from '@ionic/react'
import styled from 'styled-components'
import { TeamGame } from '../types/models'
import { teamGameService } from '../services'
import { Avatar } from '.'
import helpers from '../util/helpers'
import GameDate from '../components/GameDate'

export interface TeamGameRowProps extends React.ComponentProps<typeof IonItem> {
  teamGame: TeamGame
}

const TeamGameRow: React.FC<TeamGameRowProps> = ({ teamGame, ...rest }) => {
  return (
    <IonItem
      lines="full"
      routerLink={`/a/team/game/${teamGame.id}/`}
      routerDirection="forward"
      detail
      {...rest}
    >
      <GameDate date={teamGame.gameStartTime} slot="start" />
      <div slot="start" className="home-status">
        {teamGame.isHome ? 'VS' : 'AT'}
      </div>
      <Avatar
        name={teamGameService.renderOpponentName(teamGame)}
        slot="start"
      />
      <IonLabel>
        <IonText className="ion-multiline">
          <h3>{teamGameService.renderOpponentName(teamGame)}</h3>
          <p className="game-time">
            {helpers.bullet([
              teamGameService.renderGameTime(teamGame),
              teamGame.location,
            ])}
          </p>
          {teamGame?.finalSummary && (
            <IonBadge className="final-summary" color="secondary">
              {teamGame.finalSummary}
            </IonBadge>
          )}
        </IonText>
      </IonLabel>
      {teamGame?.finalSummary && (
        <IonBadge className="final-summary inline" color="secondary" slot="end">
          {teamGame.finalSummary}
        </IonBadge>
      )}
    </IonItem>
  )
}

export default styled(React.memo(TeamGameRow))`
  h3 {
    margin: 0;
  }

  .game-time {
    color: var(--ion-color-medium);
    font-size: 12px;
    margin: 0;
    padding: 5px 0 0;
  }

  .home-status {
    font-size: 13px;
    font-weight: bold;
    margin: 0 10px 0 0;
    text-align: center;
    width: 30px;
  }

  .final-summary {
    display: none;

    &.inline {
      display: inline-block;
    }
  }

  @media (max-width: 700px) {
    .final-summary {
      display: inline-block;

      &.inline {
        display: none;
      }
    }
  }
`
