import React, { useImperativeHandle, useRef, useMemo, useContext } from 'react'
import { ScoringActivePlayer, ScoringRosterSelect, Content } from '.'
import styled from 'styled-components'
import { swapHorizontal } from 'ionicons/icons'
import { teamGameService } from '../services'
import { ScoringContext } from '../contexts/ScoringContext'

export interface ScoringActiveRosterProps extends React.ComponentProps<any> {
  isHomeTeam: boolean
}

const ScoringActiveRoster: React.ForwardRefRenderFunction<
  any,
  ScoringActiveRosterProps
> = ({ isHomeTeam, ...rest }, ref) => {
  const { state, dispatch } = useContext(ScoringContext)
  const select = useRef<any>()
  const player = useRef<any>()
  const index = useRef<any>()
  useImperativeHandle(ref, () => ({}))

  const starters = useMemo(() => {
    const roster = (isHomeTeam ? state.activeHome : state.activeAway) ?? []
    const length = roster.length
    if (length < 5) {
      return [...roster, ...Array(5 - length)]
    }

    return roster
  }, [state, isHomeTeam])

  const bench = useMemo(() => {
    return (isHomeTeam ? state.eligibleHome : state.eligibleAway) ?? []
  }, [state, isHomeTeam])

  const showTeamPlayers = useMemo(
    () => !state.isSingleTeam || isHomeTeam === state.isSingleTeamHome,
    [state.isSingleTeam, state.isSingleTeamHome, isHomeTeam]
  )

  const teamName = useMemo(
    () => teamGameService.getTeamNameByHome(state.teamGame, isHomeTeam),
    [state.teamGame, isHomeTeam]
  )

  return (
    <Content {...rest}>
      <div className="team">
        <h4>{teamName}</h4>
        {showTeamPlayers ? (
          <>
            <div className="team-starters">
              {teamGameService
                .sortByJerseyNumber(starters)
                .map((it: any, i: number) => (
                  <ScoringActivePlayer
                    key={it?.id ?? i}
                    player={{
                      id: it?.id,
                      number: it ? it.jersey : '--',
                      firstName: it?.firstName,
                      lastName: it?.lastName ?? 'Empty',
                      isHomeTeam,
                    }}
                    onClick={(e: any) => {
                      index.current = it ? starters.indexOf(it) : i
                      player.current = it
                      if (select.current) {
                        select.current.click()
                      }
                    }}
                    actionIcon={swapHorizontal}
                    showName
                    showFouls
                  />
                ))}
            </div>
            {!!bench?.length && (
              <div className="team-bench">
                {teamGameService
                  .sortByJerseyNumber(bench)
                  .map((it: any, i: number) => (
                    <ScoringActivePlayer
                      key={it?.id ?? i}
                      player={{
                        id: it.id,
                        number: it ? it.jersey : '--',
                        firstName: it?.firstName,
                        lastName: it?.lastName ?? 'Empty',
                        isHomeTeam,
                      }}
                      showName
                      showFouls
                    />
                  ))}
              </div>
            )}
          </>
        ) : (
          <div className="starters">
            <ScoringActivePlayer
              player={{
                id: null,
                number: '00',
                lastName: teamName,
                isHomeTeam,
              }}
              showName
            />
          </div>
        )}
      </div>
      <ScoringRosterSelect
        ref={select}
        okText="Swap"
        cancelText="Cancel"
        state={state}
        isHomeTeam={isHomeTeam}
        onSelected={(value: string) =>
          dispatch({
            type: 'swapActivePlayer',
            value: {
              isHomeTeam: isHomeTeam,
              from: player.current?.id,
              to: value,
              index: index.current,
            },
          })
        }
      />
    </Content>
  )
}

export default styled(React.forwardRef(ScoringActiveRoster))`
  --background: transparent;
  --color: white;
  --ion-item-background: transparent;
  --ion-item-color: white;
  overflow-x: hidden;
  overflow-y: auto;

  .team {
    align-items: center;
    display: flex;
    flex-direction: column;

    h4 {
      font-size: 12px;
    }

    .team-starters,
    .team-bench {
      display: grid;
      grid-template-rows: repeat(5, 1fr);
      grid-row-gap: 7px;
      width: 100%;
    }

    .team-bench {
      border-top: solid 1px var(--ion-color-medium);
      padding-top: 20px;
      margin-top: 20px;
    }
  }
`
