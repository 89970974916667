import React from 'react'
import Modal, { ModalProps } from './Modal'
import styled from 'styled-components'
import { TeamGame } from '../types/models'
import { useTeamData } from '../hooks'
import { LeagueSettings } from '../components'
import { teamGameService } from '../services'

export interface TeamGameLeagueSettingsProps extends ModalProps {
  teamGame: TeamGame | null | undefined
}

const TeamGameLeagueSettings: React.FC<TeamGameLeagueSettingsProps> = ({
  teamGame,
  ...rest
}) => {
  const { teamBrand } = useTeamData(teamGame?.teamId)

  if (!teamBrand || !teamGame) {
    return null
  }

  const saveLeagueOverride = async (key: string, val: string) => {
    const current = teamGame.leagueOverrides ?? {}
    await teamGameService.update(teamGame.id, {
      leagueOverrides: { ...current, [key]: val },
    })
  }

  const saveCourtOverride = async (key: string, val: string) => {
    const leagueOverrides = teamGame.leagueOverrides ?? {}
    const current = leagueOverrides.courtOverrides ?? {}
    await teamGameService.update(teamGame.id, {
      leagueOverrides: {
        ...leagueOverrides,
        courtOverrides: { ...current, [key]: val },
      },
    })
  }

  return (
    <Modal title="Edit Game League Settings" {...rest}>
      <LeagueSettings
        leagueId={teamGame.leagueId ?? teamBrand.leagueId}
        updateLeague={teamGameService.saveHandler(teamGame.id, 'leagueId')}
        saveLeagueOverride={saveLeagueOverride}
        saveCourtOverride={saveCourtOverride}
        overrides={teamGame.leagueOverrides}
        accountId={teamBrand.accountId}
      />
    </Modal>
  )
}

export default styled(TeamGameLeagueSettings)``
