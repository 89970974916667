import React, { useRef, useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import Modal, { ModalProps } from './Modal'
import loading from '../core/loading'
import {
  teamVideoService,
  teamGameService,
  teamBrandService,
  onedriveService,
} from '../services'
import { TeamVideo, TeamGame } from '../types/models'
import {
  GenericButton,
  FormInputField,
  FormTextareaField,
  FormDateField,
  FormToggleField,
  Banner,
} from '../components'
import {
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
  IonReorder,
  IonReorderGroup,
  IonText,
} from '@ionic/react'
import {
  basketballOutline,
  film,
  searchCircleOutline,
  warningOutline,
  logoGoogle,
  desktopOutline,
  logoWindows,
} from 'ionicons/icons'
import css from 'classnames'
import notifications from '../core/notifications'
import helpers from '../util/helpers'
import { usePortal, useTeamData } from '../hooks'
import _ from 'lodash'
import { List } from '../components'
import useSubscription from '../hooks/useSubscription'
import { GraphFileBrowser } from '@microsoft/file-browser'

export interface AddTeamVideoModalProps extends ModalProps {
  teamId: string
  teamGameId?: string
  teamGame?: TeamGame
  segment?: 'game' | 'scout' | 'other'
  onTeamVideosCreated?: (
    type: 'game' | 'scout' | 'other',
    videos: TeamVideo[]
  ) => any
}

const AddTeamVideoModal: React.FC<AddTeamVideoModalProps> = ({
  teamId,
  teamGame: tg,
  teamGameId: tgId = tg?.id,
  onTeamVideosCreated,
  onDidDismiss,
  segment,
  ...rest
}) => {
  const { teamBrand } = useTeamData(teamId)
  const { usage, subscription } = useSubscription({
    includeUsage: true,
  })

  const [files, setFiles] = useState<any>()
  const [googleDriveFiles, setGoogleDriveFiles] = useState<any>()
  const [oneDriveFiles, setOneDriveFiles] = useState<any>()
  const [homeTeam, setHomeTeam] = useState<any>()
  const [homeTeamMascot, setHomeTeamMascot] = useState<any>()
  const [homeTeamColor1Name, setHomeTeamColor1Name] = useState<any>()
  const [homeTeamColor2Name, setHomeTeamColor2Name] = useState<any>()
  const [awayTeam, setAwayTeam] = useState<any>()
  const [awayTeamMascot, setAwayTeamMascot] = useState<any>()
  const [awayTeamColor1Name, setAwayTeamColor1Name] = useState<any>()
  const [awayTeamColor2Name, setAwayTeamColor2Name] = useState<any>()
  const [homeScore, setHomeScore] = useState<any>()
  const [awayScore, setAwayScore] = useState<any>()
  const [gameDate, setGameDate] = useState<any>()
  const [location, setLocation] = useState<any>()
  const [title, setTitle] = useState<any>()
  const [notes, setNotes] = useState<any>()
  const [teamGameId, setTeamGameId] = useState<any>(tgId)
  const [teamGameValue, setTeamGameValue] = useState<any>(tg)
  const [step, setStep] = useState<number>(tgId ? 2 : segment ? 1 : 0)
  const [type, setType] = useState<any>(segment)
  const [ordering, setOrdering] = useState<any>([])
  const [upload, setUpload] = useState(true)
  const [totalDuration, setTotalDuration] = useState(0)
  const [hasUHD, setHasUHD] = useState(false)
  const [showOneDrive, setShowOneDrive] = useState(false)
  const fileInput = useRef<any>()

  const { Portal } = usePortal()

  const fileCount = useMemo(() => {
    const fcount = files?.length ?? 0
    const gcount = googleDriveFiles?.length ?? 0
    const ocount = oneDriveFiles?.length ?? 0
    return fcount + gcount + ocount
  }, [files, googleDriveFiles, oneDriveFiles])

  const hasLocalFiles = useMemo(() => {
    return (files?.length ?? 0) > 0
  }, [files])

  const chooseFiles = () => {
    fileInput.current?.click()
  }

  useEffect(() => setTeamGameValue(tg), [tg])

  const isOverQuota = useMemo(
    () =>
      usage &&
      hasLocalFiles &&
      teamVideoService.wouldPutOverQuota(usage, totalDuration),
    [usage, hasLocalFiles, totalDuration]
  )

  const uhdCloudDisabled = useMemo(
    () => hasUHD && !subscription?.plan?.supportsUHD,
    [subscription, hasUHD]
  )

  const cloudDisabled = useMemo(
    () =>
      (!subscription || !subscription.cardOnFile || uhdCloudDisabled) &&
      hasLocalFiles,
    [subscription, uhdCloudDisabled, hasLocalFiles]
  )

  const isGoogleConnected = useMemo(
    () => teamBrandService.isGoogleConnected(teamBrand),
    [teamBrand]
  )

  const isMicrosoftConnected = useMemo(
    () => teamBrandService.isMicrosoftConnected(teamBrand),
    [teamBrand]
  )

  useEffect(() => {
    if (cloudDisabled) {
      setUpload(false)
    }
  }, [cloudDisabled])

  useEffect(() => {
    ;(async () => {
      const { total, uhd } = await teamVideoService.getTotalDurations(files)
      setTotalDuration(total)
      setHasUHD(uhd > 0)
    })()
  }, [files])

  let modalTitle = 'Add Video'
  switch (type) {
    case 'game':
      modalTitle = 'Add Game Video'
      break
    case 'scout':
      modalTitle = 'Add Scout Video'
      break
    case 'other':
      modalTitle = 'Add Other Video'
  }

  const create = async () => {
    if (fileCount === 0) {
      return
    }

    try {
      await loading.create()

      let gameId = teamGameId

      if (type === 'scout') {
        const teamGame = await teamGameService.create({
          location,
          timezone: teamBrand?.timezone,
          gameStartTime: gameDate,
          homeTeamName: homeTeam,
          awayTeamName: awayTeam,
          homeFinalScore: homeScore,
          awayFinalScore: awayScore,
          homeTeamMascot,
          awayTeamMascot,
          homeTeamColor1Name,
          homeTeamColor2Name,
          awayTeamColor1Name,
          awayTeamColor2Name,
          notes,
          teamId,
        })

        gameId = teamGame.id
      }

      const defaultData = {
        teamId,
        teamGameId: gameId,
        correlationId: helpers.uuid(),
        notes,
      }

      if (teamGameValue?.videos?.length) {
        await teamVideoService.reorder(
          teamGameValue.teamId,
          teamGameValue.videos.map((it: any) => ({
            id: it.id,
            order: ordering.indexOf(it.id),
          }))
        )
      }

      const videos: TeamVideo[] = await Promise.all([
        ...(files?.map((file: File) => {
          const order = ordering.indexOf(file.name)
          const data = {
            ...defaultData,
            order,
            name: title?.length ? title : file.name,
          }
          return upload
            ? teamVideoService.upload(file, data)
            : teamVideoService.create({
                file,
                metadata: data,
              })
        }) ?? []),

        ...(googleDriveFiles?.map((file: any) => {
          const order = ordering.indexOf(file.name)
          const data = {
            ...defaultData,
            order,
            name: title?.length ? title : file.name,
          }
          return teamVideoService.linkCloudFile(
            file,
            teamBrand!.id,
            'GOOGLE',
            data
          )
        }) ?? []),

        ...(oneDriveFiles?.map((file: any) => {
          const order = ordering.indexOf(file.name)
          const data = {
            ...defaultData,
            order,
            name: title?.length ? title : file.name,
          }
          return teamVideoService.linkCloudFile(
            {
              id: `${file.parentReference.driveId}:${file.id}`,
              name: file.name,
              size: file.size,
            },
            teamBrand!.id,
            'MICROSOFT',
            data
          )
        }) ?? []),
      ])

      onTeamVideosCreated?.(type, videos)
      onDidDismiss?.()
    } catch (e) {
      notifications.errorToast(e)
      console.error(e)
    } finally {
      loading.dismiss()
    }
  }

  const canContinue = () => {
    let valid = true,
      message = null

    if (step === 1 && type === 'game' && !teamGameId) {
      valid = false
      message = 'Please select a game.'
    } else if (step === 1) {
      valid = type !== 'scout' || (!!homeTeam && !!awayTeam)
      message = 'Please fill out all of the fields.'
    } else if (step === 2) {
      valid = fileCount > 0
      message = 'Please select one or more video files.'
    }

    if (!valid && message) {
      notifications.toast(message, 'Error')
    }

    return valid
  }

  useEffect(() => {
    setOrdering([
      ...(_.sortBy(teamGameValue?.videos, 'order')?.map((it: any) => it.id) ??
        []),
      ...(files?.map?.((it: File) => it.name) ?? []),
      ...(googleDriveFiles?.map((it: any) => it.name) ?? []),
      ...(oneDriveFiles?.map((it: any) => it.name) ?? []),
    ])
  }, [googleDriveFiles, files, teamGameValue, oneDriveFiles])

  const nextStep = (force?: boolean) => {
    if (force === true || canContinue()) {
      setStep((step) => step + 1)
    }
  }

  const prevStep = () => {
    setStep((step) => step - 1)
  }

  const updateType = (t: string) => () => {
    setType(t)
    nextStep()
  }

  let content: any

  const typeOptions = [
    {
      id: 'game',
      text: 'Game',
      icon: basketballOutline,
    },
    {
      id: 'scout',
      text: 'Scout',
      icon: searchCircleOutline,
    },
    {
      id: 'other',
      text: 'Other',
      icon: film,
    },
  ]

  let details

  const reorderVideos = (e: any) => {
    const { detail } = e
    helpers.arrayMove(ordering, detail.from, detail.to)
    detail.complete()
  }

  const onGoogleFilesSelected = (res: any) => {
    if (res.action === 'picked') {
      setGoogleDriveFiles(res.docs)
    }
  }

  const onOneDriveFilesSelected = (res: any) => {
    helpers.withLoading(async () => {
      const paths = res?.map((it: any) => Object.values(it)).flat()
      const token = await teamBrandService.getMicrosoftToken(teamBrand!.id)
      const full = await Promise.all(
        paths.map((it: string[]) => onedriveService.getFileInfo(token, it))
      )
      setOneDriveFiles(
        full?.filter((it: any) => it.file?.mimeType?.startsWith('video/'))
      )
      setShowOneDrive(false)
    })
  }

  switch (type) {
    case 'game':
      details = (
        <List
          model="team-game"
          url={`/v1/team/${teamId}/videos/game-selector/`}
          renderItem={(item: any) => (
            <IonItem
              key={item.id}
              className="ion-activatable pointer"
              onClick={() => {
                setTeamGameId(item.id)
                setTeamGameValue(item)
                nextStep(true)
              }}
            >
              <IonLabel>
                <IonText className="ion-multiline ion-textwrap">
                  <h4>{teamGameService.renderGameTitle(item, false)}</h4>
                  <p className="subtext">
                    {teamGameService.renderGameTime(item)}
                  </p>
                </IonText>
              </IonLabel>
            </IonItem>
          )}
        />
      )
      break
    case 'scout':
      details = (
        <>
          <FormInputField
            label="Title"
            save={async (v: any) => setTitle(v)}
            value={title}
            noIndicator
          />
          <FormDateField
            label="Date"
            save={async (v: any) => setGameDate(v)}
            value={gameDate}
            withTime
            noIndicator
          />
          <FormInputField
            label="Location"
            save={async (v: any) => setLocation(v)}
            value={location}
            noIndicator
          />
          <FormInputField
            label="Home Team"
            save={async (v: any) => setHomeTeam(v)}
            value={homeTeam}
            required
            noIndicator
          />
          <FormInputField
            label="Home Mascot"
            save={async (v: any) => setHomeTeamMascot(v)}
            value={homeTeamMascot}
            required
            noIndicator
          />
          <FormInputField
            label="Home Color 1"
            save={async (v: any) => setHomeTeamColor1Name(v)}
            value={homeTeamColor1Name}
            noIndicator
          />
          <FormInputField
            label="Home Color 2"
            save={async (v: any) => setHomeTeamColor2Name(v)}
            value={homeTeamColor2Name}
            noIndicator
          />
          <FormInputField
            label="Away Team"
            save={async (v: any) => setAwayTeam(v)}
            value={awayTeam}
            required
            noIndicator
          />
          <FormInputField
            label="Away Mascot"
            save={async (v: any) => setAwayTeamMascot(v)}
            value={awayTeamMascot}
            required
            noIndicator
          />
          <FormInputField
            save={async (v: any) => setAwayTeamColor1Name(v)}
            label="Away Color 1"
            value={awayTeamColor1Name}
            noIndicator
          />
          <FormInputField
            save={async (v: any) => setAwayTeamColor2Name(v)}
            label="Away Color 2"
            value={awayTeamColor2Name}
            noIndicator
          />
          <FormInputField
            label="Home Score"
            save={async (v: any) => setHomeScore(v)}
            value={homeScore}
            noIndicator
          />
          <FormInputField
            label="Away Score"
            save={async (v: any) => setAwayScore(v)}
            value={awayScore}
            noIndicator
          />
          <FormTextareaField
            label="Notes"
            save={async (v: any) => setNotes(v)}
            value={notes}
            noIndicator
          />
        </>
      )
      break
    case 'other':
      details = (
        <>
          <FormInputField
            label="Title"
            save={async (v: any) => setTitle(v)}
            value={title}
            noIndicator
          />
          <FormTextareaField
            label="Notes"
            save={async (v: any) => setNotes(v)}
            value={notes}
            noIndicator
          />
        </>
      )
      break
  }

  switch (step) {
    case 0:
      content = (
        <div className="type-selection">
          {typeOptions.map((it) => (
            <div
              key={it.id}
              className={css({ selected: type === it.id })}
              onClick={updateType(it.id)}
            >
              <IonIcon icon={it.icon} />
              <h3>{it.text}</h3>
            </div>
          ))}
        </div>
      )
      break
    case 1:
      content = (
        <IonList className="game-details" lines="full">
          {details}
        </IonList>
      )
      break
    case 2:
      content = (
        <div className="select-videos">
          <IonIcon className="video-icon" icon={film} color="primary" />
          <div className="video-origin-options">
            <GenericButton
              slot="start"
              icon={desktopOutline}
              fill="clear"
              onClick={chooseFiles}
            >
              Upload Videos
            </GenericButton>
            {isGoogleConnected && (
              <GenericButton
                className="google-drive"
                icon={logoGoogle}
                color="basketball"
                fill="clear"
                onClick={() =>
                  teamBrandService.showGooglePicker(
                    teamBrand?.id ?? '',
                    onGoogleFilesSelected
                  )
                }
              >
                Google Drive
              </GenericButton>
            )}
            {isMicrosoftConnected && (
              <GenericButton
                className="onedrive"
                icon={logoWindows}
                color="alternate"
                fill="clear"
                onClick={() => setShowOneDrive(true)}
              >
                OneDrive
              </GenericButton>
            )}
          </div>
          <strong className="subtitle">
            {fileCount} Video{fileCount === 1 ? '' : 's'} Selected
          </strong>
          <input
            ref={fileInput}
            type="file"
            style={{ display: 'none' }}
            accept={teamVideoService.acceptedVideoExtensions}
            onChange={() => {
              const files = [...(fileInput.current?.files ?? [])]
              setFiles(files)
            }}
            multiple
          />
        </div>
      )
      break
    case 3:
      content = (
        <IonList lines="full" className="order-videos">
          {((isOverQuota && upload) || cloudDisabled) && (
            <Banner
              color="warning"
              icon={warningOutline}
              text={
                cloudDisabled
                  ? uhdCloudDisabled
                    ? 'Cloud has been disabled because 4K is not supported.'
                    : 'Cloud has been disabled because you do not have a credit card on file.'
                  : `Storing this video in the cloud will put you over your quota and you will be charged an additional $${
                      (subscription?.plan.videoQuota ?? 0) *
                      Math.ceil(totalDuration / 60 / 60)
                    } per year.`
              }
              small
            />
          )}
          {hasLocalFiles && (
            <FormToggleField
              label="Store in Cloud"
              checked={upload}
              disabled={cloudDisabled}
              save={setUpload}
              noIndicator
            />
          )}
          <IonReorderGroup
            disabled={ordering.length < 2 || type === 'other'}
            onIonItemReorder={reorderVideos}
          >
            {ordering?.map((id: any) => {
              const name =
                teamGameValue?.videos?.find((it: any) => it.id === id)?.name ??
                id
              return (
                <IonItem key={id}>
                  <IonLabel>{name}</IonLabel>
                  {ordering.length > 1 && type !== 'other' && <IonReorder />}
                </IonItem>
              )
            })}
          </IonReorderGroup>
        </IonList>
      )
  }

  return (
    <Modal
      title={modalTitle}
      closeButtonText="Cancel"
      onDidDismiss={onDidDismiss}
      backButton={{
        visible: step > 0 && (!tgId || step > 2),
        onClick: prevStep,
      }}
      headerAction={
        step === 3
          ? {
              children: upload && hasLocalFiles ? 'Upload' : 'Save',
              onClick: create,
            }
          : {
              children: 'Next',
              onClick: () => nextStep(),
              visible: step > 0,
            }
      }
      {...rest}
    >
      {content}
      {showOneDrive && (
        <Portal>
          <div className="one-drive-picker">
            <GraphFileBrowser
              getAuthenticationToken={async () =>
                await teamBrandService.getMicrosoftToken(teamBrand!.id)
              }
              itemMode="files"
              onSuccess={onOneDriveFilesSelected}
              onCancel={() => setShowOneDrive(false)}
            />
          </div>
        </Portal>
      )}
    </Modal>
  )
}

export default styled(AddTeamVideoModal)`
  .type-selection {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    > div {
      align-content: center;
      border: solid 1px var(--ion-color-light);
      border-left: none;
      border-bottom: none;
      cursor: pointer;
      display: grid;
      grid-template-rows: repeat(2, auto);
      justify-content: center;
      padding: 15px 10px;
      text-align: center;

      ion-icon {
        margin: auto;
        font-size: 64px;
      }

      :hover {
        background: var(--ion-color-light);
      }
    }
  }

  .select-videos {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    .video-icon {
      font-size: 128px;
      margin-bottom: 15px;
    }

    .subtitle {
      margin-top: 15px;
    }

    .google-drive {
      margin: 10px auto;
    }

    .video-origin-options {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .game-details {
    h3 {
      padding-left: 15px;
    }
  }
`
