import { useRef, useEffect } from 'react'
import { eventsService } from '../services'
export default function useSSEListener(handler: Function, model?: string) {
  const savedHandler = useRef<any>()
  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    const eventListener = (event: any) => savedHandler.current(event)

    // Add event listener
    if (model) {
      eventsService.addEventListener(model, eventListener)
    } else {
      eventsService.addEventListener('event', eventListener)
    }

    // Remove event listener on cleanup
    return () => {
      if (model) {
        eventsService.removeEventListener(model, eventListener)
      }

      eventsService.removeEventListener('event', eventListener)
    }
  }, [model])
}
