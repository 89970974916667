import React, {
  useRef,
  useImperativeHandle,
  useState,
  useEffect,
  useContext,
} from 'react'
import FormField, { FormFieldProps } from './FormField'
import { IonInput } from '@ionic/react'
import notifications from '../core/notifications'
import styled from 'styled-components'
import { GenericButton } from '.'
import { userService } from '../services'
import { dialogs } from '../core'
import { AppContext } from '../contexts/AppContext'
import Inputmask from 'inputmask'

export interface FormInputFieldProps
  extends React.ComponentProps<typeof IonInput> {
  label?: string
  position?: FormFieldProps['position']
  icon?: any
  save?: (val: string, e: any) => any
  saving?: boolean
  remove?: () => any
  onLabelEdit?: (label: string) => any
  noIndicator?: boolean
  verified?: boolean
  verifyType?: 'EMAIL' | 'PHONE'
  validate?: (val: string) => boolean
  transform?: (val: any, input: boolean) => any
  mask?: string
  onEnterPress?: (val: string) => any
  nullable?: boolean
  end?: any
}

const FormInputField: React.ForwardRefRenderFunction<
  any,
  FormInputFieldProps
> = (
  {
    type = 'text',
    className,
    icon,
    label,
    position,
    save,
    value,
    required,
    children,
    saving,
    remove,
    onLabelEdit,
    noIndicator,
    verified,
    verifyType,
    validate,
    transform,
    mask,
    onEnterPress,
    clearInput = false,
    nullable = required ? false : true,
    end,
    ...rest
  },
  ref
) => {
  const { dispatch } = useContext(AppContext)
  const [invalid, setInvalid] = useState(false)
  const [error, setError] = useState(false)
  const [isSaving, setIsSaving] = useState(saving)
  const [inputValue, setInputValue] = useState<any>(value)
  const input = useRef<any>()
  useImperativeHandle(ref, () => input.current)

  const setInput = async (e: any) => {
    input.current = e
    if (e && mask) {
      const input = await e.getInputElement()
      Inputmask({
        mask,
        greedy: false,
      }).mask(input)
    }
  }

  const onIonBlur = async (e: any) => {
    let newValue = transform ? transform(e.target.value, false) : e.target.value
    setInputValue(e.target.value)

    if (typeof newValue === 'string' && !newValue?.length && nullable) {
      newValue = null
    }

    if (required && newValue?.trim()?.length === 0) {
      return setInvalid(true)
    }

    if (validate && validate(newValue) === false) {
      return setInvalid(true)
    }

    setInvalid(false)
    if (save && value !== newValue) {
      setIsSaving(!noIndicator)
      setError(false)
      try {
        await save(newValue, e)
      } catch (e) {
        notifications.errorToast(e)
        setError(true)
      } finally {
        setIsSaving(false)
      }
    }
  }

  useEffect(() => {
    setIsSaving(saving)
  }, [saving])

  useEffect(() => {
    const val = transform ? transform(value, true) : value
    setInputValue(val)
  }, [value, transform])

  const onKeyDown = async (e: any) => {
    if ((e.which ?? e.keyCode) === 13 && onEnterPress) {
      e.preventDefault()
      e.stopPropagation()
      const value = input.current?.value
      const val = transform ? transform(value, true) : value
      onEnterPress(val)
    }
  }

  const verify = async ({ code }: any) => {
    try {
      if (!value || !verifyType) {
        return
      }

      const user = await userService.verify(value.toString(), verifyType, code)

      dispatch({
        type: 'set',
        value: { user },
      })

      notifications.toast(`${value} has been verified.`, 'Success')
    } catch (e) {
      notifications.errorToast(e)
    }
  }

  const showVerifyDialog = async () => {
    await dialogs.input(
      'Please enter your verification code.',
      [
        {
          name: 'code',
          type: 'number',
          placeholder: 'Code',
        },
      ],
      verify
    )
  }

  const resendVerification = async () => {
    try {
      if (!value || !verifyType) {
        return
      }

      await userService.resendVerification(value.toString(), verifyType)
      showVerifyDialog()
      notifications.toast('Code has been resent.', 'Success')
    } catch (e) {
      notifications.errorToast(e)
    }
  }

  return (
    <FormField
      label={label}
      icon={icon}
      position={position}
      saving={isSaving}
      invalid={invalid}
      className={className}
      remove={remove}
      onLabelEdit={onLabelEdit}
      error={error}
      required={required}
    >
      <IonInput
        ref={setInput}
        type={type}
        onIonBlur={onIonBlur}
        value={inputValue}
        required={required}
        onKeyDown={onKeyDown}
        clearInput={clearInput}
        {...rest}
      >
        {children}
      </IonInput>
      {inputValue && verifyType && !verified ? (
        <div slot="end">
          <GenericButton onClick={showVerifyDialog} size="small" fill="clear">
            Verify
          </GenericButton>
          <GenericButton
            onClick={resendVerification}
            size="small"
            color="basketball"
            fill="clear"
          >
            Resend
          </GenericButton>
        </div>
      ) : null}
      {end}
    </FormField>
  )
}

export default styled(React.memo(React.forwardRef(FormInputField)))``
