import React, { useMemo } from 'react'
import styled from 'styled-components'
import { SubscriptionUsage, TeamBrandSubscription } from '../types/models'
import { IonProgressBar } from '@ionic/react'
import css from 'classnames'

export interface UsageIndicatorProps extends React.ComponentProps<any> {
  data: SubscriptionUsage
  subscription?: TeamBrandSubscription
  excludeTeams?: boolean
  compact?: boolean
}

const Quota = ({ label, overagePrice, data, ...rest }: any) => {
  const used = useMemo(() => data.usage.totalHours, [data])
  const limit = useMemo(() => data.limits.totalHours, [data])

  const progress = useMemo(() => {
    if (used === 0) {
      return 0
    }

    return used / limit
  }, [used, limit])

  return (
    <div className="quota" {...rest}>
      <p className="subtext heading">{label}</p>
      <IonProgressBar className="progress-bar" value={progress} />
      <p className="subtext">
        {limit === 0 &&
          used === 0 &&
          `0 hours used at $${overagePrice} / hour per year.`}
        {limit > 0 && `${used} hours of ${limit} hours used.`}
      </p>
      {used > limit && (
        <p className="subtext">
          {used} additional hours used at ${overagePrice} / hour per year.
        </p>
      )}
    </div>
  )
}

const UsageIndicator: React.FC<UsageIndicatorProps> = ({
  data,
  subscription,
  compact,
  excludeTeams,
  className,
  ...rest
}) => {
  return (
    <div
      className={css(className, {
        compact,
      })}
      {...rest}
    >
      {!compact && <p className="label">Subscription Usage</p>}

      {!excludeTeams && (
        <div className="quota">
          <p className="subtext heading">Teams</p>
          <IonProgressBar
            className="progress-bar"
            value={data.usage.teams / data.limits.teams}
          />
          <p className="subtext">
            {data.usage.teams} / {data.limits.teams} Teams
          </p>
        </div>
      )}

      <Quota
        type="od"
        label="Hosted Video"
        overagePrice={subscription?.plan?.alaCarteVideoPriceUSD}
        data={data}
      />
    </div>
  )
}

export default styled(React.memo(UsageIndicator))`
  background: var(--background);
  border-bottom: solid 1px var(--ion-color-light);
  padding: 10px 15px;

  &.compact {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 5px 15px;

    .quota {
      border-bottom: none;

      p {
        margin: 5px 0;
      }
    }
  }

  .quota {
    border-bottom: solid 1px var(--ion-color-light);

    &:last-child {
      border-bottom: none;
    }

    .progress-bar {
      max-width: 400px;
    }

    .heading {
      font-weight: bold;
    }
  }
`
