import { IonSpinner, IonPage } from '@ionic/react'
import React from 'react'
import styled from 'styled-components'

const AppLoading: React.FC<any> = ({ staticContext, ...props }) => {
  return (
    <IonPage {...props}>
      <IonSpinner name="crescent" color="primary" />
    </IonPage>
  )
}

export default styled(AppLoading)`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100vh;
  width: 100%;
`
