import React, { useMemo, useState, useRef } from 'react'
import { IonItem, IonLabel, IonText, IonProgressBar } from '@ionic/react'
import styled from 'styled-components'
import { TeamGame } from '../types/models'
import { teamGameService } from '../services'
import { Avatar } from '.'
import css from 'classnames'
import { useEventListener, useSSEListener } from '../hooks'
import _ from 'lodash'

export interface TeamGameVideoRowProps
  extends React.ComponentProps<typeof IonItem> {
  data: TeamGame
}

const TeamGameVideoRow: React.FC<TeamGameVideoRowProps> = ({
  data,
  ...rest
}) => {
  const inflight = useRef<any>({})
  const [uploadCount, setUploadCount] = useState(0)
  const [videos, setVideos] = useState(data?.videos ?? [])
  const [progress, setProgress] = useState(0)

  useSSEListener((e: any) => {
    const { detail } = e
    if (detail.data.teamGameId === data.id) {
      setVideos((v: any) => [...v, detail.data])
    }
  }, 'team-video')

  useEventListener(`game:upload:${data.id}`, (e: any) => {
    const { type, status, videoId, chunkLoaded, totalSize, part } = e.detail
    switch (type) {
      case 'progress':
        if (!inflight.current[videoId]) {
          inflight.current[videoId] = {
            totalSize: 0,
            parts: {},
            totalComplete: 0,
          }
          setUploadCount((count: any) => count + 1)
        }

        inflight.current[videoId].totalSize = totalSize
        inflight.current[videoId].parts[part] = chunkLoaded
        inflight.current[videoId].totalComplete = _.sum(
          Object.values(inflight.current[videoId].parts)
        )

        const overallTotal = _.sumBy(
          Object.values(inflight.current),
          'totalSize'
        )
        const overallComplete = _.sumBy(
          Object.values(inflight.current),
          'totalComplete'
        )

        setProgress(overallComplete / overallTotal)
        break

      case 'status':
        if (status !== 'UPLOADING') {
          setUploadCount((count: any) => {
            if (count === 1) {
              inflight.current = {}
              setProgress(0)
              return 0
            }

            return count - 1
          })
        }
        break
    }
  })

  const opponentName = useMemo(
    () => teamGameService.getOpposingTeamName(data),
    [data]
  )

  return (
    <IonItem
      lines="full"
      routerLink={`/a/team/game/${data.id}/videos/`}
      routerDirection="forward"
      detail
      {...rest}
    >
      <div
        className={css('video-thumbnail', { empty: !videos.length })}
        slot="start"
      >
        <img src={videos[0]?.poster} alt="Video Thumbnail" slot="start" />
        {uploadCount > 0 && (
          <div className="upload-status">
            <IonProgressBar value={progress} />
          </div>
        )}
      </div>
      <Avatar name={opponentName} slot="start" />
      <IonLabel>
        <IonText className="ion-multiline">
          <h3>
            {data.isHome ? 'VS' : 'AT'} {opponentName}
          </h3>
          <p className="game-time">
            {teamGameService.renderGameTime(data)} • {data.location}
          </p>
        </IonText>
      </IonLabel>
    </IonItem>
  )
}

export default styled(React.memo(TeamGameVideoRow))`
  h3 {
    margin: 0;
  }

  .video-thumbnail {
    background-size: cover;
    display: flex;
    flex-direction: column;
    margin: 15px 15px 15px 0;
    position: relative;

    img {
      border-radius: 8px;
      min-height: 85px;
      height: auto;
      width: 180px;
    }

    .upload-status {
      position: absolute;
      left: 10px;
      right: 10px;
      bottom: 5px;
      z-index: 10;
    }
  }

  .game-time {
    color: var(--ion-color-medium);
    font-size: 12px;
    margin: 0;
    padding: 5px 0 0;
  }
`
