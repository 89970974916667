import ModelBaseService from './model-base'
import { DataField } from '../util/data-field'
import { listsService } from '.'
import {
  TeamGame,
  TeamGameManifest,
  TeamGamePersonnel,
  TeamGameState,
} from '../types/models'
import moment from 'moment'
import { http } from '../core'
import _ from 'lodash'
import validation from '../util/validation'
import helpers from '../util/helpers'
import colors from '../util/colors'
import { ScoringState } from '../types/interfaces'

class TeamGameService extends ModelBaseService {
  protected modelName = 'team-game'
  public get fields(): DataField[] {
    return [
      {
        key: 'isHome',
        label: 'Home Game',
        type: 'toggle',
      },
      {
        key: 'opponentName',
        label: 'Opponent',
        type: 'input',
        required: true,
      },
      {
        key: 'opponentMascot',
        label: 'Opponent Mascot',
        type: 'input',
        required: true,
      },
      {
        key: 'opponentColor1Name',
        label: 'Opponent Color 1',
        type: 'input',
        nullable: true,
      },
      {
        key: 'opponentColor2Name',
        label: 'Opponent Color 2',
        type: 'input',
        nullable: true,
      },
      {
        key: 'location',
        label: 'Location',
        type: 'input',
        required: true,
      },
      {
        key: 'gameStartTime',
        label: 'Start Time',
        type: 'datetime',
        dateTimeOptions: {
          minuteValues: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60],
        },
        nullable: true,
      },
      {
        key: 'timezone',
        label: 'Timezone',
        type: 'select',
        required: true,
        options: listsService.timezones,
      },
      {
        key: 'homeFinalScore',
        label: (e: any) => `${this.getHomeTeamName(e) ?? 'Home'} Score`,
        type: 'input',
        inputType: 'number',
        validate: validation.score,
        editOnly: true,
        nullable: true,
      },
      {
        key: 'awayFinalScore',
        label: (e: any) => `${this.getAwayTeamName(e) ?? 'Away'} Score`,
        type: 'input',
        inputType: 'number',
        validate: validation.score,
        editOnly: true,
        nullable: true,
      },
      {
        key: 'overtimePeriods',
        label: 'Overtime Periods',
        type: 'input',
        inputType: 'number',
        validate: validation.positiveInteger,
        editOnly: true,
        nullable: true,
      },
      {
        key: 'notes',
        label: 'Notes',
        type: 'textarea',
      },
    ]
  }

  public get filters(): DataField[] | undefined {
    return [
      {
        label: 'Home Game',
        key: 'isHome',
        type: 'filter-select',
        options: [
          [true, 'Home'],
          [false, 'Away'],
        ],
      },
    ]
  }

  renderGameTime(
    data: TeamGame | null | undefined,
    format = 'dddd, MMMM D, YYYY [at] h:mma'
  ) {
    if (!data) {
      return ''
    }

    return data.gameStartTime && moment(data.gameStartTime).format(format)
  }

  renderGameTitle(data?: TeamGame, includeDate = true) {
    if (!data) {
      return ''
    }

    const time = this.renderGameTime(data)
    const opponent = this.getOpposingTeamName(data)

    return `${data.isHome ? 'vs' : 'at'} ${opponent}${
      includeDate ? ` - ${time}` : ''
    }`
  }

  renderMatchup(teamGame: TeamGame | undefined) {
    return `${teamGame?.homeTeamFullName} ${teamGame?.isHome ? 'vs' : 'at'} ${
      teamGame?.awayTeamFullName
    }`
  }

  async addPersonnel(person: any) {
    const { data } = await http.authorizedRequest({
      method: 'POST',
      url: this.getEndpointUrl(person.teamGameId, 'personnel'),
      data: person,
    })

    return data
  }

  async removePersonnel(teamGameId: string, id: string) {
    const { data } = await http.authorizedRequest({
      method: 'DELETE',
      url: this.getEndpointUrl(teamGameId, 'personnel'),
      data: { id },
    })

    return data
  }

  getTeamName(teamGame: TeamGame | null | undefined) {
    return teamGame?.isHome
      ? this.getHomeTeamName(teamGame)
      : this.getAwayTeamName(teamGame)
  }

  getTeamNameByHome(
    teamGame: TeamGame | null | undefined,
    isHomeTeam: boolean
  ) {
    return isHomeTeam
      ? this.getHomeTeamName(teamGame)
      : this.getAwayTeamName(teamGame)
  }

  getOpposingTeamName(teamGame: TeamGame | null | undefined) {
    return teamGame?.opponentFullName
  }

  getOpposingTeamMascot(teamGame: TeamGame | null | undefined) {
    return teamGame?.opponentMascot
  }

  getHomeTeamName(teamGame: TeamGame | null | undefined) {
    return teamGame?.homeTeamFullName
  }

  getAwayTeamName(teamGame: TeamGame | null | undefined) {
    return teamGame?.awayTeamFullName
  }

  renderOpponentName(teamGame: TeamGame | null | undefined) {
    return teamGame?.opponentFullName
  }

  didTeamWin(teamGame: TeamGame | null | undefined) {
    if (!teamGame || !teamGame.homeFinalScore || !teamGame.awayFinalScore) {
      return null
    }

    return teamGame.isHome
      ? teamGame.homeFinalScore > teamGame.awayFinalScore
      : teamGame.awayFinalScore > teamGame.homeFinalScore
  }

  getTeamScore(teamGame: TeamGame | null | undefined) {
    if (!teamGame || !teamGame.homeFinalScore || !teamGame.awayFinalScore) {
      return 0
    }

    return teamGame.isHome ? teamGame.homeFinalScore : teamGame.awayFinalScore
  }

  getOpponentScore(teamGame: TeamGame | null | undefined) {
    if (!teamGame || !teamGame.homeFinalScore || !teamGame.awayFinalScore) {
      return 0
    }

    return teamGame.isHome ? teamGame.awayFinalScore : teamGame.homeFinalScore
  }

  getStartAndEndForManifestIndex(
    manifest: TeamGameManifest | undefined,
    i: number
  ) {
    if (!manifest) {
      return [0, 0]
    }
    const d = manifest.durations[i]
    const start = helpers.getCumulativeStartTimeForIndex(i, manifest)
    const end = start + d
    return [start, end]
  }

  getStaffName(person: TeamGamePersonnel) {
    const details = person.teamBrandPersonnel ?? person.unmanagedTeamPersonnel
    return details?.fullName
  }

  sortByJerseyNumber(collection?: any[]) {
    return _.sortBy(collection, (it: any) => (it ? parseInt(it.jersey) : 1000))
  }

  private filterTeamGamePlayers(
    teamGame: TeamGame,
    isHomeTeam: boolean,
    isStarting: boolean,
    ids?: string[]
  ) {
    const players = teamGame.personnel?.filter(
      (it) => it.isHomeTeam === isHomeTeam && helpers.isPlayer(it)
    )

    if (!ids?.length) {
      return players?.filter((it) => it.isStarting === isStarting)
    }

    return players?.filter((it) => {
      const index = ids.indexOf(it.id)
      return isStarting ? index > -1 : index === -1
    })
  }

  getEligibleTeamGamePlayers(
    teamGame: TeamGame,
    isHomeTeam: boolean,
    activeIds?: string[]
  ) {
    return this.filterTeamGamePlayers(teamGame, isHomeTeam, false, activeIds)
  }

  getActiveTeamGamePlayers(
    teamGame: TeamGame,
    isHomeTeam: boolean,
    activeIds?: string[]
  ) {
    return this.filterTeamGamePlayers(teamGame, isHomeTeam, true, activeIds)
  }

  async saveState(pageState: ScoringState) {
    const { teamGameId, teamGame } = pageState
    if (!teamGameId || !teamGame || !pageState.started) {
      return
    }

    const state: TeamGameState = {
      clockTime: pageState.clock,
      videoTime: pageState.videoTime,
      videoStart: pageState.videoStart,
      videoEnd: pageState.videoEnd,
      currentTeamVideoId: pageState.currentTeamVideoId,
      activeHomeRosterIds: pageState.activeHome?.map((it: any) => it.id),
      activeAwayRosterIds: pageState.activeAway?.map((it: any) => it.id),
      period: pageState.period,
      started: pageState.started,
      startVideoTime: pageState.startVideoTime ?? 0,
      startTimestamp: pageState.startTimestamp ?? Date.now(),
      homeTeamIsLeft: pageState.homeTeamIsLeft,
      homeRosterIsLeft: pageState.homeRosterIsLeft,
    }

    //If nothing has changed, don't save
    if (Object.keys(helpers.diff(state, teamGame.state ?? {})).length === 0) {
      return
    }

    return await this.update(teamGameId, { state })
  }

  private getDefaultStroke(color?: string) {
    if (!color) {
      return '#FFFFFF'
    }

    return colors.isDark(color) ? '#FFFFFF' : '#000000'
  }

  getJerseyFill(teamGame: TeamGame | undefined | null, isHomeTeam: boolean) {
    return isHomeTeam
      ? teamGame?.homeTeamJerseyFill ?? teamGame?.homeTeamPrimaryColor
      : teamGame?.awayTeamJerseyFill ?? teamGame?.awayTeamPrimaryColor
  }

  getJerseyStroke(teamGame: TeamGame | undefined | null, isHomeTeam: boolean) {
    return isHomeTeam
      ? teamGame?.homeTeamJerseyStroke ??
          this.getDefaultStroke(teamGame?.homeTeamPrimaryColor)
      : teamGame?.awayTeamJerseyStroke ??
          this.getDefaultStroke(teamGame?.awayTeamPrimaryColor)
  }
}

export default new TeamGameService()
