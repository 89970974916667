import { http } from '../core'
import BaseService from './base'

class AdminService extends BaseService {
  protected get route() {
    return 'admin'
  }
  async createAccountInvite(accountId: string, email: string, send: boolean) {
    const { data } = await http.authorizedRequest({
      method: 'POST',
      url: `/v1/admin/account-invite/`,
      data: {
        accountId,
        send,
        email,
      },
    })

    return data
  }

  async accountSetup(payload: any) {
    const { data } = await http.authorizedRequest({
      method: 'POST',
      url: `/v1/admin/account-setup/`,
      data: payload,
    })

    return data
  }

  async resetTeamGameScoring(teamGameId: string) {
    const { data } = await http.authorizedRequest({
      method: 'POST',
      url: `/v1/admin/team-game/${teamGameId}/reset/`,
    })

    return data
  }

  async deleteInvitation(invitationId: string) {
    const { data } = await http.authorizedRequest({
      method: 'DELETE',
      url: `/v1/admin/invitations/${invitationId}/`,
    })

    return data
  }
}

export default new AdminService()
