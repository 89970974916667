import { v4 } from 'uuid'
import _ from 'lodash'
import loading from '../core/loading'
import notifications from '../core/notifications'
import SparkMD5 from 'spark-md5'
import { TeamGameEvent, TeamGameManifest } from '../types/models'
import { ScoringState } from '../types/interfaces'

class Helpers {
  secondsToTimeString(value: number, options?: { [key: string]: boolean }) {
    const opts = {
      hours: true,
      millis: false,
      ...(options ?? {}),
    }
    const seconds = Math.floor(value)
    const date = new Date(0)
    date.setSeconds(seconds)
    date.setMilliseconds((value - seconds) * 1000)
    const start = opts.hours ? 11 : 14
    const end = opts.millis ? (opts.hours ? 12 : 9) : opts.hours ? 8 : 5
    return date.toISOString().substr(start, end)
  }

  secondsToMinutesAndSeconds(seconds: number) {
    const minutes = Math.floor(seconds / 60)
    return [minutes, seconds - minutes * 60]
  }

  arrayMove(arr: any, from: number, to: number) {
    arr.splice(to, 0, arr.splice(from, 1)[0])
    return arr
  }

  bullet(arr: any) {
    return arr.filter((it: any) => it && it.length).join(' • ')
  }

  uuid() {
    return v4()
  }

  arrayReplace(arr: any, updated: any, key = 'id') {
    const index = arr.findIndex((it: any) => it[key] === updated[key])
    if (index > -1) {
      arr.splice(index, 1, { ...arr[index], ...updated })
    }

    return arr
  }

  arrayDelete(arr: any, id: string) {
    const index = arr.findIndex((it: any) => it.id === id)
    if (index > -1) {
      arr.splice(index, 1)
    }

    return arr
  }

  isPlayer(person?: any) {
    return person?.role === 'PLAYER'
  }

  roleLabel(person?: any) {
    return _.startCase(person?.role?.toLowerCase())
  }

  async withLoading(fn: () => any, loadingText?: string) {
    try {
      await loading.create({ message: loadingText })
      await fn()
    } catch (e) {
      notifications.errorToast(e)
    } finally {
      await loading.dismiss()
    }
  }

  downloadURI(uri: string, filename: string) {
    const anchor = document.createElement('a')
    anchor.href = uri
    anchor.target = '_blank'
    anchor.download = filename
    anchor.click()
  }

  downloadBlob(blob: Blob, filename: string) {
    this.downloadURI(URL.createObjectURL(blob), filename)
  }

  async blobToBase64(blob: Blob) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader()
      reader.onload = function () {
        var dataUrl = reader.result as string
        resolve(dataUrl)
      }
      reader.onerror = reject
      reader.readAsDataURL(blob)
    })
  }

  openURI(uri: string) {
    const anchor = document.createElement('a')
    anchor.href = uri
    anchor.target = '_blank'
    anchor.click()
  }

  diff(object: any, base: any) {
    function changes(object: any, base: any) {
      return _.transform(object, (result: any, value: any, key: any) => {
        if (!_.isEqual(value, base[key])) {
          result[key] =
            _.isObject(value) && _.isObject(base[key])
              ? changes(value, base[key])
              : value
        }
      })
    }

    return changes(object, base)
  }

  clamp(value: number, min: number, max: number) {
    return Math.min(Math.max(value, min), max)
  }

  getPositionFromValue({ width, min, max, value }: any) {
    if (!width || min === max) {
      return 0
    }

    const diffMaxMin = max - min
    const diffValMin = value - min
    const percentage = Math.min(diffValMin / diffMaxMin, 1)

    return Math.round(percentage * width)
  }

  getValueFromPosition({ width, step, min, max, pos }: any) {
    const percentage = this.clamp(pos, 0, width) / (width || 1)
    const baseVal = step * Math.round((percentage * (max - min)) / step)
    const value = baseVal + min

    return this.clamp(value, min, max)
  }

  getInitials(name: string | null | undefined) {
    if (!name) {
      return ''
    }

    const initials = name.match(/\b\w/g) || []
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
  }

  getGravatarUrl(email: string) {
    const hash = SparkMD5.hash(email)
    return `https://www.gravatar.com/avatar/${hash}?d=404`
  }

  playerDisplay(
    jersey: string | null | undefined,
    name: string | null | undefined
  ) {
    return `${jersey ? '#' : ''}${jersey ?? ''}${jersey ? ' - ' : ''}${
      name ?? ''
    }`
  }

  getCumulativeStartTimeForIndex(index: number, manifest: TeamGameManifest) {
    if (index < 1) {
      return 0
    }

    return manifest.durations
      .slice(0, index)
      .reduce((i: number, v: number) => i + v, 0)
  }

  getCumulativeStartTimeForTeamVideoId(
    teamVideoId: string,
    manifest: TeamGameManifest
  ) {
    const index = manifest.ids.indexOf(teamVideoId)
    return this.getCumulativeStartTimeForIndex(index, manifest)
  }

  getCombinedVideoTime(event: TeamGameEvent, manifest: TeamGameManifest) {
    const videoStart = this.getCumulativeStartTimeForTeamVideoId(
      event.teamVideoId!,
      manifest
    )
    return videoStart + event.videoTime!
  }

  getCurrentAbsoluteVideoTime(state: ScoringState) {
    return state.videoTime
  }

  getCurrentRelativeVideoTime(state: ScoringState) {
    return state.videoTime - state.videoStart
  }

  getRelativeVideoTime(state: ScoringState, event: TeamGameEvent) {
    return event.videoTime
  }

  getVideoDetailsForAbsoluteTime(state: ScoringState, videoTime: number) {
    const { manifest } = state

    //If there are no videos or there is only one, absolute and relative are the same
    if (!manifest || manifest.ids.length === 1) {
      return {
        index: 0,
        absoluteTime: videoTime,
        relativeTime: videoTime,
        teamVideoId: manifest?.ids?.[0],
      }
    }
    let index = 0,
      relativeTime
    for (let i = 0; i <= manifest.durations.length; i++) {
      const start = this.getCumulativeStartTimeForIndex(i, manifest)
      const duration = manifest.durations[i]
      if (videoTime >= start && videoTime <= start + duration) {
        index = i
        relativeTime = videoTime - start
        break
      }
    }

    return {
      teamVideoId: manifest.ids[index],
      index,
      relativeTime,
      absoluteTime: videoTime,
    }
  }

  getAbsoluteVideoTime(state: ScoringState, event: TeamGameEvent) {
    return this.getCombinedVideoTime(event, state.manifest!)
  }
}

export default new Helpers()
