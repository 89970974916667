import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

export interface LogoProps {
  color: 'black' | 'white' | 'indigo' | 'blue'
  type: 'wordmark' | 'mark' | 'horizontal' | 'vertical'
  depth: 'one' | 'two' | 'full'
  className?: string
  height?: number
  width?: number
}

const defaultUrl = '/assets/logos/horizontal/one/black.svg'

const Logo: React.FC<LogoProps> = ({ color, depth, type, className }) => {
  const [src, setSrc] = useState<any>()
  useEffect(() => {
    setSrc(`/assets/logos/${type}/${depth}/${color}.svg`)
  }, [type, depth, color])

  const onError = (e: any) => {
    if (src !== defaultUrl) {
      setSrc(defaultUrl)
    }
  }

  return (
    <img
      src={src}
      onError={onError}
      alt="JumpScout Logo"
      className={className}
    />
  )
}

export default styled(React.memo(Logo))`
  display: block;
  height: ${(props) => (props.height ? `${props.height}px` : 'auto')};
  width: ${(props) => (props.width ? `${props.width}px` : 'auto')};
  margin: 15px auto;
`
