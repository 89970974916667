import React, { useMemo, useContext } from 'react'
import styled from 'styled-components'
import { teamBrandPlayerService } from '../services'
import { AppContext } from '../contexts/AppContext'
import CreateModal, { CreateModalProps } from './CreateModal'

export interface AddTeamBrandPlayerModalProps extends CreateModalProps {}

const AddTeamBrandPlayerModal: React.FC<AddTeamBrandPlayerModalProps> = ({
  title,
  service,
  ...rest
}) => {
  const { state } = useContext(AppContext)
  const { teamBrand } = state
  const initialData = useMemo(
    () => ({
      teamBrandId: teamBrand?.id,
      role: 'PLAYER',
    }),
    [teamBrand]
  )

  return (
    <CreateModal
      title="Add Player"
      service={teamBrandPlayerService}
      supportsAvatar
      initialData={initialData}
      fields={teamBrandPlayerService.fields}
      {...rest}
    />
  )
}

export default styled(React.memo(AddTeamBrandPlayerModal))``
