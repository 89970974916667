import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import css from 'classnames'
import { AppContext } from '../contexts/AppContext'
import { Avatar, GenericButton } from '.'
import { IonIcon, IonRouterLink, IonMenuToggle } from '@ionic/react'
import { notificationsOutline, notifications } from 'ionicons/icons'
import UserNotificationsFlyout from './UserNotificationsFlyout'
import { userService } from '../services'

const UserInfo: React.FC<any> = (props) => {
  const { state } = useContext(AppContext)
  const [showNotifications, setShowNotifications] = useState(false)

  return (
    <div {...props}>
      <IonRouterLink routerLink="/a/profile/">
        <IonMenuToggle autoHide={false}>
          <Avatar
            borderColor="white"
            src={state.user?.avatarURL}
            name={state.user?.fullName}
            email={userService.getPrimaryEmail(state.user)?.email}
            size={40}
          />
        </IonMenuToggle>
      </IonRouterLink>
      {state.user && (
        <IonRouterLink className="user-text" routerLink="/a/profile/">
          <h1>{state.user?.fullName}</h1>
          <p>{state.user?.username}</p>
        </IonRouterLink>
      )}
      <GenericButton
        className={css('notifications animate__animated', {
          animate__swing: state.hasNotifications,
        })}
        fill="clear"
        color={state.hasNotifications ? 'basketball' : 'medium'}
        size="small"
        onClick={() => setShowNotifications(!showNotifications)}
      >
        <IonIcon
          icon={state.hasNotifications ? notifications : notificationsOutline}
        />
      </GenericButton>
      {showNotifications && (
        <UserNotificationsFlyout
          onDidDismiss={() => setShowNotifications(false)}
        />
      )}
    </div>
  )
}

export default styled(UserInfo)`
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 15px 10px 10px;
  position: relative;
  width: 200px;

  ion-avatar {
    flex: 0 0 auto;
    margin-right: 8px;
  }

  .notifications {
    flex: 0 0 auto;
    --margin: 0;
    --padding-start: 4px;
    --padding-end: 0px;
  }

  .user-text {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: hidden;

    > * {
      margin: 0;
      padding: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    h1 {
      color: var(--ion-color-step-900);
      font-size: 13px;
    }

    p {
      color: var(--ion-color-medium);
      font-size: 11px;
    }
  }
`
