import React from 'react'
import styled from 'styled-components'
import { IonIcon } from '@ionic/react'
import { exitOutline } from 'ionicons/icons'

const ImpersonationNotice: React.FC = ({}) => {
  const switchToOriginalToken = () => {
    sessionStorage.removeItem('whistle')
    sessionStorage.removeItem('username')
    window.location.reload()
  }
  return (
    <div className="impersonation-active-message">
      <div className="inner-message">
        You are currently logged in as {sessionStorage.getItem('username')}
      </div>
      <div
        className="inner-icon"
        onClick={() => {
          switchToOriginalToken()
        }}
      >
        <IonIcon icon={exitOutline} className="inner-icon"></IonIcon>
      </div>
    </div>
  )
}
export default styled(React.memo(ImpersonationNotice))``
