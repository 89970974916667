class ShortcutsService {
  get scoring() {
    return [
      {
        key: 'z',
        description: 'Start Video / Stop Video & Clock',
      },

      {
        key: 'x',
        description: 'Start/Stop Video & Clock',
      },

      {
        key: 'Space',
        description: 'Start/Stop Clock',
      },

      {
        key: '←',
        description: 'Video Backwards 500ms',
      },

      {
        key: '→',
        description: 'Video Forwards 500ms',
      },

      {
        key: 'Ctrl + ←',
        description: 'Video Backwards 1s',
      },

      {
        key: 'Ctrl + →',
        description: 'Video Forwards 1s',
      },

      {
        key: 'Alt + ←',
        description: 'Video Backwards 10s',
      },

      {
        key: 'Alt + →',
        description: 'Video Forwards 10s',
      },

      {
        key: 'Ctrl + ]',
        description: 'Skip to Latest Event',
      },

      {
        key: '[',
        description: 'Previous Event',
      },

      {
        key: ']',
        description: 'Next Event',
      },

      {
        key: 'Alt + Z',
        description: 'Open Timeline Zoom',
      },

      {
        key: '?',
        description: 'Open This Menu',
      },

      {
        key: '`',
        description: 'Flag Event (Available when Event Dialog is open)',
      },
    ]
  }
}

export default new ShortcutsService()
