import React from 'react'
import styled from 'styled-components'
import colors from '../util/colors'

export interface JerseyProps extends React.ComponentProps<any> {
  number?: string
  stroke?: string
  color?: string
  height?: number
}

const Jersey: React.FC<JerseyProps> = ({
  number = '00',
  stroke,
  color,
  ...props
}) => {
  return (
    <svg viewBox="0 0 99 123" {...props}>
      <g id="Jersey" transform="matrix(1.06067,0,0,1.00849,-186.799,-195.204)">
        <rect
          x="176.115"
          y="193.561"
          width="92.617"
          height="121.284"
          style={{
            fill: 'none',
          }}
        />
        <g transform="matrix(0.942801,0,0,0.991582,-100.151,-18.0416)">
          <path
            className="jersey"
            d="M378.488,324.87L304.874,324.974L304.946,265.89C318.487,251.824 321.632,237.901 314.942,224.113L327.127,221.569C331.336,233.388 353.23,233.62 357.711,221.091L369.787,224.715C362.371,240.258 366.209,254.616 379.668,266.039L378.488,324.87Z"
          />
        </g>
        <text x="222" y="275" textAnchor="middle" className="jersey--number">
          {number}
        </text>
        <g transform="matrix(0.942801,0,0,0.991582,10.1816,-1.61141)">
          <path
            className="jersey--cutout"
            d="M210.101,204.999L210.37,216.196C213.826,219.416 217.284,221.511 220.742,222.492C227.293,224.35 233.848,222.208 240.407,216.128L240.827,204.553C234.288,217.861 215.592,216.203 210.101,204.999Z"
          />
        </g>
      </g>
    </svg>
  )
}

export default styled(React.memo(Jersey))`
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 1.5;
  height: ${(props) => props.height ?? 40}px;
  width: auto;
  --stroke: ${(props) =>
    props.stroke ??
    (props.color
      ? colors.isDark(props.color ?? '')
        ? 'white'
        : 'black'
      : 'black')};
  --fill: ${(props) => props.color ?? 'white'};

  .jersey,
  .jersey--cutout {
    stroke: var(--stroke);
    stroke-width: 1px;
  }

  .jersey--number {
    font-family: 'Roboto', sans-serif;
    font-style: italic;
    font-weight: 500;
    letter-spacing: 1px;
    fill: var(--stroke);
    font-size: 40px;
    text-align: center;
  }

  .jersey {
    fill: var(--fill);
  }

  .jersey--cutout {
    fill: rgb(95, 95, 95);
    fill-opacity: 0.4;
  }
`
