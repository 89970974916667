import { User } from './../types/models'
import { userService } from '../services'
import config from './config'
declare global {
  interface Window {
    Intercom: any
  }
}

const hiddenPages = ['/register/', '/login/', '/forgot-password/']

class IntercomService {
  onRouteChange() {
    window.Intercom?.('update', {
      hide_default_launcher: hiddenPages.indexOf(window.location.pathname) > -1,
    })
  }
  initialize() {
    window.Intercom?.('boot', {
      app_id: 'tbfusg52',
      hide_default_launcher: hiddenPages.indexOf(window.location.pathname) > -1,
    })
  }

  newMessage() {
    window.Intercom?.('showNewMessage')
  }

  show() {
    window.Intercom?.('show')
  }

  hide() {
    window.Intercom?.('hide')
  }

  showWidget() {
    window.Intercom?.('update', {
      hide_default_launcher: false,
    })
  }

  hideWidget() {
    window.Intercom?.('update', {
      hide_default_launcher: true,
    })
  }
  trackEvent(name: string) {
    window.Intercom?.('trackEvent', name)
  }

  async getVisitorId() {
    let tries = 0
    if (!config.isProduction) {
      return
    }

    //HACK: intercom might not be booted and active
    //So we'll retry to get the visitor ID 10 times
    return new Promise((resolve) => {
      const getId = () => {
        const id = window.Intercom('getVisitorId')
        if (id || tries > 10) {
          resolve(id)
        } else {
          setTimeout(getId, 500)
        }
      }

      setTimeout(getId, 500)
    })
  }

  setUserData(user: User) {
    if (config.isProduction) {
      window.Intercom?.('update', {
        email: userService.getPrimaryEmail(user)?.email,
        phone: userService.getPrimaryPhone(user)?.phone,
        avatar: user.avatarURL
          ? {
              type: 'avatar',
              image_url: user.avatarURL,
            }
          : undefined,
        title: user.jobTitle,
        created_at: user.createdAt,
        name: user.fullName,
        user_id: user.id,
        user_hash: user.hash,
        companies: user.teamBrands?.map((it) => ({
          id: it.id,
          name: `${it.fullName} - ${it.gender}`,
          created_at: it.createdAt,
          plan: it.subscriptions?.[0]?.plan?.name,
          plan_amount: it.subscriptions?.[0]?.plan?.priceUSD,
          monthly_spend: (it.subscriptions?.[0]?.plan?.priceUSD ?? 0) / 12,
          colors: [it.color1Name, it.color2Name, it.color3Name, it.color4Name]
            .filter((it) => !!it)
            .join(', '),
          mascot: it.mascot,
          gender: it.gender,
        })),
      })
    }
  }
}

export default new IntercomService()
