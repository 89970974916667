import React, { useRef, useImperativeHandle, useState, useEffect } from 'react'
import FormField, { FormFieldProps } from './FormField'
import { IonSelect, IonSelectOption, IonInput } from '@ionic/react'
import styled from 'styled-components'
import { listsService } from '../services'
import notifications from '../core/notifications'

export interface FormSelectFieldProps
  extends React.ComponentProps<typeof IonSelect> {
  label?: string
  position?: FormFieldProps['position']
  icon?: any
  save?: (val: string, e?: any) => any
  saving?: boolean
  options?: [any, any][]
  readonly?: boolean
  noIndicator?: boolean
  required?: boolean
  popoverClassName?: string
}

const FormSelectField: React.ForwardRefRenderFunction<
  any,
  FormSelectFieldProps
> = (
  {
    className,
    icon,
    label,
    position,
    save,
    value,
    children,
    saving,
    options = [],
    readonly,
    noIndicator,
    popoverClassName,
    ...rest
  },
  ref
) => {
  const [error, setError] = useState(false)
  const [isSaving, setIsSaving] = useState(saving)
  const [inputValue, setInputValue] = useState(value)
  const input = useRef<any>()
  useImperativeHandle(ref, () => input.current)

  const onIonChange = async (e: any) => {
    const newValue = e.target.value
    setInputValue(newValue)

    if (save && value !== newValue) {
      setIsSaving(!noIndicator)
      setError(false)
      try {
        await save(newValue, e)
      } catch (e) {
        notifications.errorToast(e)
        setError(true)
      } finally {
        setIsSaving(false)
      }
    }
  }

  useEffect(() => {
    setIsSaving(saving)
  }, [saving])

  useEffect(() => {
    setInputValue(value)
  }, [value])

  return (
    <FormField
      label={label}
      icon={icon}
      position="stacked"
      saving={isSaving}
      error={error}
      className={className}
      required={rest.required}
    >
      {readonly ? (
        <IonInput readonly value={listsService.display(options, value)} />
      ) : (
        <IonSelect
          ref={input}
          onIonChange={onIonChange}
          value={inputValue}
          interface="popover"
          interfaceOptions={{
            className: `form-select-popover ${popoverClassName}`,
          }}
          {...rest}
        >
          {options.map(([value, text]) => (
            <IonSelectOption key={value} value={value}>
              {text}
            </IonSelectOption>
          ))}
        </IonSelect>
      )}
      {children}
    </FormField>
  )
}

export default styled(React.memo(React.forwardRef(FormSelectField)))``
