import React, { useRef } from 'react'
import styled from 'styled-components'
import { IonFab, IonFabButton, IonIcon, IonFabList } from '@ionic/react'
import { add } from 'ionicons/icons'
import { useClickOutside } from '../hooks'

export interface ActionButtonListItem {
  icon: any
  color?: any
  onClick?: () => any
  routerLink?: string
  routerDirection?: HTMLIonFabButtonElement['routerDirection']
  label?: any
  toggleClick?: (a?: any) => any
}

export interface ActionButtonProps extends React.ComponentProps<typeof IonFab> {
  list?: ActionButtonListItem[]
  listSide?: HTMLIonFabListElement['side']
  icon?: any
  color?: string
}

const ActionButton: React.FC<ActionButtonProps> = ({
  list,
  listSide = 'bottom',
  icon = add,
  color = 'warning',
  title,
  ...rest
}) => {
  const ref = useRef<any>()
  useClickOutside(ref, () => ref.current?.close())
  return (
    <IonFab
      ref={ref}
      vertical="top"
      edge
      slot="fixed"
      horizontal="end"
      {...rest}
    >
      <IonFabButton title={title ?? ''} size="small" color={color}>
        <IonIcon icon={icon} />
      </IonFabButton>
      {!!list?.length && (
        <IonFabList side={listSide}>
          {list.map((it, i) => (
            <IonFabButton
              key={i}
              color={it.color ?? 'medium'}
              className="list-item"
              data-label={it.label}
              routerDirection={it.routerDirection}
              routerLink={it.routerLink}
              onClick={
                it.toggleClick ? () => it.toggleClick?.(true) : it.onClick
              }
            >
              <IonIcon icon={it.icon} />
            </IonFabButton>
          ))}
        </IonFabList>
      )}
    </IonFab>
  )
}

export default styled(React.memo(ActionButton))`
  // > ion-fab-button {
  //   margin-bottom: 80px;
  //   margin-right: 20px;
  // }

  // ion-fab-list {
  //   bottom: 70px;
  // }

  ion-fab-list {
    background: white;
    border-radius: 10px;
  }

  .list-item::after {
    background: white;
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;
    display: block;
    color: var(--ion-color-medium);
    content: attr(data-label);
    font-weight: bold;
    left: -8px;
    top: 50%;
    padding: 5px 10px;
    position: absolute;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transform: translate(-100%, -50%);
  }
`
