import ModelBaseService from './model-base'
import { DataField } from '../util/data-field'
import { TeamGameEventType, ScoringPageEvent } from '../types/interfaces'
import { TeamGameEvent } from '../types/models'
import { http } from '../core'

export const TeamGameEventTypes = {
  get jumpball(): TeamGameEventType {
    return {
      id: 'jumpball',
      name: 'Jumpball',
      pointValueRelevant: false,
      sharesOriginLocation: true,
      shortcut: '',
      sharesOriginTeam: true,
      allowOtherTeamSelection: true,
      flagIfAbsent: false,
      allowsOriginPlayer: false,
    }
  },

  get substitution(): TeamGameEventType {
    return {
      id: 'substitution',
      name: 'Substituted',
      pointValueRelevant: false,
      sharesOriginLocation: true,
      shortcut: '',
      sharesOriginTeam: false,
      allowOtherTeamSelection: false,
      flagIfAbsent: false,
      allowsOriginPlayer: false,
    }
  },

  get score_adjustment(): TeamGameEventType {
    return {
      id: 'score_adjustment',
      name: 'Score Adjustment',
      pointValueRelevant: true,
      sharesOriginLocation: true,
      shortcut: '',
      sharesOriginTeam: true,
      allowOtherTeamSelection: false,
      flagIfAbsent: false,
      hidden: true,
      allowsOriginPlayer: false,
    }
  },

  get clock_start(): TeamGameEventType {
    return {
      id: 'clock_start',
      name: 'Game Clock Start',
      pointValueRelevant: false,
      sharesOriginLocation: true,
      shortcut: '',
      sharesOriginTeam: false,
      allowOtherTeamSelection: false,
      flagIfAbsent: false,
      hidden: true,
      allowsOriginPlayer: false,
    }
  },

  get clock_stop(): TeamGameEventType {
    return {
      id: 'clock_stop',
      name: 'Game Clock Stop',
      pointValueRelevant: false,
      sharesOriginLocation: true,
      shortcut: '',
      sharesOriginTeam: false,
      allowOtherTeamSelection: false,
      flagIfAbsent: false,
      hidden: true,
      allowsOriginPlayer: false,
    }
  },

  get freethrow_made(): TeamGameEventType {
    return {
      id: 'freethrow_made',
      name: 'Freethrow Made',
      icon: '/assets/team-game-events/freethrow.svg',
      pointValueRelevant: true,
      sharesOriginLocation: false,
      shortcut: 'g',
      sharesOriginTeam: false,
      allowOtherTeamSelection: false,
      flagIfAbsent: true,
      hidden: false,
      allowsOriginPlayer: true,
    }
  },

  get freethrow_violation(): TeamGameEventType {
    return {
      id: 'freethrow_violation',
      name: 'Freethrow Violation',
      icon: '/assets/team-game-events/freethrow.svg',
      pointValueRelevant: false,
      sharesOriginLocation: false,
      shortcut: 'g',
      sharesOriginTeam: false,
      allowOtherTeamSelection: false,
      flagIfAbsent: true,
      hidden: false,
      allowsOriginPlayer: true,
    }
  },

  get freethrow_miss(): TeamGameEventType {
    return {
      id: 'freethrow_miss',
      name: 'Freethrow Missed',
      icon: '/assets/team-game-events/freethrow.svg',
      pointValueRelevant: false,
      sharesOriginLocation: false,
      shortcut: 'h',
      sharesOriginTeam: false,
      allowOtherTeamSelection: false,
      flagIfAbsent: true,
      hidden: false,
      allowsOriginPlayer: true,
      related: [this.rebound],
    }
  },

  get freethrow_miss_deadball(): TeamGameEventType {
    return {
      id: 'freethrow_miss_deadball',
      name: 'Freethrow Missed (Dead Ball)',
      icon: '/assets/team-game-events/freethrow.svg',
      pointValueRelevant: false,
      sharesOriginLocation: false,
      shortcut: 'h',
      sharesOriginTeam: false,
      allowOtherTeamSelection: false,
      flagIfAbsent: true,
      hidden: false,
      allowsOriginPlayer: true,
      related: [this.rebound],
    }
  },

  get steal(): TeamGameEventType {
    return {
      id: 'steal',
      name: 'Steal',
      icon: '/assets/team-game-events/steal.svg',
      pointValueRelevant: false,
      sharesOriginLocation: true,
      shortcut: 'a',
      sharesOriginTeam: false,
      allowOtherTeamSelection: false,
      flagIfAbsent: false,
      allowsOriginPlayer: false,
    }
  },
  get blocked_shot(): TeamGameEventType {
    return {
      id: 'blocked_shot',
      name: 'Blocked Shot',
      icon: '/assets/team-game-events/blocked_shot.svg',
      pointValueRelevant: false,
      sharesOriginLocation: true,
      shortcut: 's',
      sharesOriginTeam: false,
      allowOtherTeamSelection: false,
      flagIfAbsent: false,
      allowsOriginPlayer: false,
    }
  },
  get rebound(): TeamGameEventType {
    return {
      id: 'rebound',
      name: 'Rebound',
      icon: '/assets/team-game-events/rebound.svg',
      pointValueRelevant: false,
      sharesOriginLocation: false,
      shortcut: 'a',
      sharesOriginTeam: false,
      allowOtherTeamSelection: true,
      flagIfAbsent: true,
      allowsOriginPlayer: true,
    }
  },
  get charge(): TeamGameEventType {
    return {
      id: 'charge',
      name: 'Charge Taken',
      icon: '/assets/team-game-events/charge.svg',
      pointValueRelevant: false,
      sharesOriginLocation: true,
      shortcut: 'a',
      sharesOriginTeam: false,
      allowOtherTeamSelection: true,
      flagIfAbsent: false,
      allowsOriginPlayer: false,
    }
  },
  get foul(): TeamGameEventType {
    return {
      id: 'foul',
      name: 'Foul',
      icon: '/assets/team-game-events/foul.svg',
      headerText: 'Foul committed',
      related: [this.charge],
      options: [
        {
          name: 'type',
          display: 'Foul Type',
          values: [
            {
              id: 'shooting',
              name: 'Shooting Foul',
              icon: '/assets/team-game-events/foul_shooting.svg',
              shortcut: 'a',
              defaultFor: ['shot_made'],
            },
            {
              id: 'non_shooting',
              name: 'Non-Shooting Foul',
              icon: '/assets/team-game-events/foul_nonshooting.svg',
              shortcut: 's',
            },
            {
              id: 'offensive',
              name: 'Offensive Foul',
              icon: '/assets/team-game-events/foul_offensive.svg',
              shortcut: 'd',
            },
            {
              id: 'technical',
              name: 'Technical Foul',
              icon: '/assets/team-game-events/foul_technical.svg',
              shortcut: 'f',
            },
          ],
        },
        {
          name: 'award',
          display: 'Freethrows Awarded',
          getSuggestedValue(event, events, state) {
            switch (event.options?.type) {
              case 'offensive':
                return 0
              case 'technical':
                return 2
              case 'shooting':
                return event.pointValue
              case 'non_shooting':
                const league = state.league
                if (league.firstFoulThreshold && league.secondFoulThreshold) {
                  const fouls = events.filter(
                    (it) =>
                      (it.isHomeTeam === event.isHomeTeam &&
                        it.type === 'foul') ||
                      it.rel?.some(
                        (it) =>
                          it.isHomeTeam === event.isHomeTeam &&
                          it.type === 'foul'
                      )
                  )

                  let count = fouls.length

                  switch (league.foulReset) {
                    case 'HALF':
                      count =
                        fouls.filter((it) =>
                          state.period === 1 ? it.period === 1 : it.period > 1
                        )?.length ?? 0
                      break
                    case 'PERIOD':
                      count =
                        fouls.filter((it) =>
                          state.period < 4
                            ? it.period === state.period
                            : it.period > 3
                        )?.length ?? 0
                      break
                  }

                  //Account for the foul in question.
                  count++

                  if (count >= league.secondFoulThreshold) {
                    return 2
                  }

                  if (count >= league.firstFoulThreshold) {
                    return '1&1'
                  }
                }
                return 0
            }
          },
          values: [
            {
              id: 1,
              name: '1',
              shortcut: '1',
              defaultFor: ['shot_made'],
            },
            {
              id: 2,
              name: '2',
              shortcut: '2',
            },
            {
              id: 3,
              name: '3',
              shortcut: '3',
            },
            {
              id: '1&1',
              name: '1&1',
              shortcut: '4',
            },
            {
              id: 0,
              name: '0',
              shortcut: '5',
            },
          ],
        },
      ],
      pointValueRelevant: false,
      sharesOriginLocation: true,
      shortcut: 'f',
      sharesOriginTeam: false,
      allowOtherTeamSelection: true,
      flagIfAbsent: false,
      allowsOriginPlayer: false,
    }
  },
  get turnover(): TeamGameEventType {
    return {
      id: 'turnover',
      name: 'Turnover',
      icon: '/assets/team-game-events/turnover.svg',
      related: [this.steal],
      toggles: [
        {
          name: 'deadBall',
          display: 'Dead Ball',
          defaultValue: false,
          shortcut: 's',
        },
      ],
      pointValueRelevant: false,
      sharesOriginLocation: true,
      shortcut: 'd',
      sharesOriginTeam: false,
      allowOtherTeamSelection: true,
      flagIfAbsent: false,
      allowsOriginPlayer: false,
    }
  },
  get assist(): TeamGameEventType {
    return {
      id: 'assist',
      name: 'Assist',
      icon: '/assets/team-game-events/assist.svg',
      pointValueRelevant: false,
      sharesOriginLocation: false,
      shortcut: 'a',
      sharesOriginTeam: true,
      allowOtherTeamSelection: false,
      flagIfAbsent: false,
      allowsOriginPlayer: false,
    }
  },
  get shot_made(): TeamGameEventType {
    return {
      id: 'shot_made',
      name: 'Shot Made',
      icon: '/assets/team-game-events/shot_made.svg',
      related: [
        this.assist,
        { ...this.foul, shortcut: 's', allowOtherTeamSelection: false },
      ],
      pointValueRelevant: true,
      sharesOriginLocation: true,
      shortcut: 'a',
      sharesOriginTeam: true,
      allowOtherTeamSelection: true,
      flagIfAbsent: false,
      zeroToSixOffset: 0.75,
      sixToEighteenOffset: 1,
      eighteenPlusOffset: 1.25,
      allowsOriginPlayer: false,
    }
  },
  get shot_miss(): TeamGameEventType {
    return {
      id: 'shot_miss',
      name: 'Shot Missed',
      icon: '/assets/team-game-events/shot_miss.svg',
      related: [this.rebound, this.blocked_shot],
      pointValueRelevant: true,
      sharesOriginLocation: true,
      shortcut: 's',
      sharesOriginTeam: true,
      allowOtherTeamSelection: true,
      flagIfAbsent: false,
      zeroToSixOffset: 1.25,
      sixToEighteenOffset: 1.75,
      eighteenPlusOffset: 2.25,
      allowsOriginPlayer: false,
    }
  },
}

class TeamGameEventService extends ModelBaseService {
  protected modelName = 'team-game-event'
  public readonly types = TeamGameEventTypes

  public get typesList() {
    const types: any = this.types
    return Object.keys(types).reduce((arr: [string, string][], it: string) => {
      //Don't add hidden events
      if (types[it].hidden) {
        return arr
      }

      arr.push([it, types[it].name])
      return arr
    }, [])
  }

  getTypeById(type: string) {
    const types: any = this.types
    return types[type]
  }

  public get initiatorEventTypes() {
    return ['shot_made', 'shot_miss', 'turnover', 'foul']
  }

  public get initiatorEvents() {
    return this.initiatorEventTypes.map(this.getTypeById.bind(this))
  }

  public get fields(): DataField[] {
    return [
      {
        key: 'isHomeTeam',
        label: 'Home Team',
        type: 'toggle',
      },
      {
        key: 'type',
        label: 'Type',
        type: 'select',
        options: this.typesList,
      },
      {
        key: 'side',
        label: 'Side',
        type: 'select',
        options: [
          ['left', 'Left'],
          ['right', 'Right'],
        ],
      },
      {
        key: 'pointValue',
        label: 'Point Value',
        type: 'input',
        inputType: 'number',
      },
      {
        key: 'clockTime',
        label: 'Game Clock',
        type: 'input',
        required: true,
      },
      {
        key: 'videoTime',
        label: 'Video Time',
        type: 'input',
        required: true,
      },
    ]
  }

  toTeamGameEvent(event: ScoringPageEvent): TeamGameEvent {
    return {
      isHomeTeam: event.isHomeTeam,
      type: event.type.id,
      ppi: event.location?.ppi,
      x: event.location?.x,
      y: event.location?.y,
      distance: event.location?.distance,
      hoopSide: event.location?.hoopSide,
      teamGamePersonnelId:
        event.teamGamePersonnelId ?? event.teamGamePersonnel?.id,
      pointValue: event.pointValue,
      attributes: event.options,
      originEventId: event.originEventId,
      side: event.location?.side,
      courtId: event.court?.id,
      leagueId: event.league?.id,
      clockTime: event.clockTime,
      videoTime: event.videoTime,
      period: event.period,
      teamId: event.teamId,
      teamVideoId: event.teamVideoId,
      userFlagged: event.userFlagged,
    } as TeamGameEvent
  }

  toScoreGamePageEvent(event: TeamGameEvent): ScoringPageEvent {
    return {
      id: event.id,
      isHomeTeam: event.isHomeTeam,
      type: this.getTypeById(event.type),
      location: {
        ppi: event.ppi,
        x: event.x,
        y: event.y,
        side: event.side,
        distance: event.distance,
        hoopSide: event.hoopSide,
      },
      isOrigin: !!event.originEventId,
      teamGamePersonnel: event.teamGamePersonnel,
      teamVideoId: event.teamVideoId,
      pointValue: event.pointValue,
      options: event.attributes,
      clockTime: event.clockTime,
      videoTime: event.videoTime,
      period: event.period,
      teamId: event.teamId,
      league: event.league,
      court: event.court,
      userFlagged: event.userFlagged,
    } as ScoringPageEvent
  }

  async createEvent(
    event: ScoringPageEvent,
    additional: { [key: string]: any }
  ) {
    const payload = { ...this.toTeamGameEvent(event), ...additional }
    return await this.create(payload)
  }

  async bulkCreate(
    teamGameId: string | undefined,
    origin: ScoringPageEvent,
    additional: { [key: string]: any },
    rel?: ScoringPageEvent[],
    freethrows?: ScoringPageEvent[]
  ) {
    const payload = {
      origin: {
        ...this.toTeamGameEvent(origin),
        ...additional,
      },
      rel: rel?.map((it) => ({ ...this.toTeamGameEvent(it), ...additional })),
      freethrows: freethrows?.map((it) => ({
        ...this.toTeamGameEvent(it),
        ...additional,
      })),
      teamGameId,
    }

    const { data } = await http.authorizedRequest({
      method: 'PUT',
      url: this.getEndpointUrl(),
      data: payload,
    })

    return data
  }

  getEventPlayerDetails(event: TeamGameEvent) {
    return (
      event.teamGamePersonnel?.teamBrandPersonnel ??
      event.teamGamePersonnel?.unmanagedTeamPersonnel
    )
  }

  getEventType(type: string) {
    const types: any = this.types
    return types[type]
  }

  filterRoster(
    collection?: any[],
    event?: ScoringPageEvent,
    origin?: ScoringPageEvent
  ) {
    if (!collection || !event || !origin || event.type.allowsOriginPlayer) {
      return collection
    }

    return collection?.filter(
      (it: any) =>
        event.isOrigin === true || it.id !== origin.teamGamePersonnelId
    )
  }

  getPointsForEvent(event: TeamGameEvent) {
    let points =
      event.type === 'shot_made' ||
      event.type === 'score_adjustment' ||
      event.type === 'freethrow_made'
        ? event.pointValue ?? 0
        : 0
    return {
      home: event.isHomeTeam ? points : 0,
      away: !event.isHomeTeam ? points : 0,
    }
  }

  tally(events: TeamGameEvent[], event?: TeamGameEvent) {
    const tally = {
      home: 0,
      away: 0,
    }

    if (!events?.length) {
      return tally
    }

    const index = event ? events.indexOf(event) : 0
    const all = events.slice(index)

    return all.reduce((obj: any, e) => {
      const ep = this.getPointsForEvent(e)
      obj.home += ep.home
      obj.away += ep.away
      return obj
    }, tally)
  }

  isPlayerFoul(id: string, event: TeamGameEvent) {
    return event.type === 'foul' && event.teamGamePersonnelId === id
  }
}

export default new TeamGameEventService()
