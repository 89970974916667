import { useIonViewDidEnter, useIonViewDidLeave } from '@ionic/react'
import useMetaTags from 'react-metatags-hook'
import useRouter from './useRouter'
import appConfig from '../core/config'
import { useState } from 'react'

export interface MetaTagConfig {
  title?: string
  description?: string
  socialDescription?: string
  keywords?: string | string[]
  socialImageURL?: string
}

const defaultTitle = document.title

export default function useMeta(config: MetaTagConfig) {
  const { match } = useRouter()
  const { url } = match
  const [active, setActive] = useState(true)
  const title = config.title
    ? `${config.title} | ${appConfig.title}`
    : defaultTitle
  const description =
    config.description ??
    `Get access to the platform designed for high school basketball coaches to maximize their team's performance, providing insights, video hosting, and breakdown tools.`
  const socialDescription =
    config.socialDescription ??
    `JumpScout is gametime analytics software designed for basketball coaches.  It is the only platform using CourtSight Technology to empower high school, club, and youth basketball coaches to maximize their team's performance.  Providing live court-side insights, video hosting, and gameplay breakdown tools — empowering coaches to look back so they can prepare forward.`

  useIonViewDidEnter(() => {
    setActive(true)
  })

  useIonViewDidLeave(() => {
    setActive(false)
  })

  useMetaTags(
    active
      ? {
          title,
          description,
          charset: 'utf8',
          lang: 'en',
          metas: [
            {
              name: 'keywords',
              content: Array.isArray(config.keywords)
                ? config.keywords.join(',')
                : config.keywords,
            },
            { name: 'robots', content: 'index, follow' },
            { name: 'DC.Title', content: config.title },
            { name: 'url', content: window.location.href },
          ],
          links: [{ rel: 'canonical', href: window.location.href }],
          openGraph: {
            title,
            image:
              config.socialImageURL ??
              'https://www.jumpscout.com/assets/social/og_image.jpg',
            site_name: 'JumpScout',
            description: socialDescription,
          },
          twitter: {
            card: 'summary',
            creator: '@jump_scout',
            title,
            description: socialDescription,
            image:
              config.socialImageURL ??
              'https://www.jumpscout.com/assets/social/og_image.jpg',
          },
        }
      : {},
    [url, active]
  )
}
