import React from 'react'
import styled from 'styled-components'
import { IonIcon, IonToolbar, IonText } from '@ionic/react'
import css from 'classnames'

export interface BannerProps extends React.ComponentProps<typeof IonToolbar> {
  icon?: any
  title?: string
  text?: string
  small?: boolean
  center?: boolean
  bold?: boolean
}

const Banner: React.FC<BannerProps> = ({
  small,
  className,
  icon,
  title,
  text,
  center,
  bold,
  ...rest
}) => {
  return (
    <IonToolbar
      className={css(className, {
        small,
        center,
        bold,
        clickable: !!rest.onClick,
      })}
      {...rest}
    >
      {icon && <IonIcon slot="start" icon={icon} />}
      <IonText className="ion-multiline ion-text-wrap">
        <h4>{title}</h4>
        <p>{text}</p>
      </IonText>
    </IonToolbar>
  )
}

export default styled(React.memo(Banner))`
  &.center {
    text-align: center;
  }

  &.clickable {
    cursor: pointer;
  }

  &.bold {
    font-weight: 500;
  }

  ion-icon {
    font-size: 26px;
    margin: auto 20px;
  }

  ion-text > * {
    margin: 0;
    padding: 0;
  }

  &.small {
    font-size: 12px;
    --padding-top: 5px;
    --padding-bottom: 5px;
    --min-height: 30px;

    ion-icon {
      font-size: 18px;
      margin: auto 10px;
    }
  }
`
