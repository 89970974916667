import React, { useRef, useImperativeHandle, useState, useEffect } from 'react'
import FormField, { FormFieldProps } from './FormField'
import notifications from '../core/notifications'
import PopoverSelector from './PopoverSelector'
import { IonText } from '@ionic/react'
import css from 'classnames'

export interface FormPopoverSelectorFieldProps
  extends React.ComponentProps<any> {
  label?: string
  position?: FormFieldProps['position']
  save?: (val: string, meta?: any, e?: any) => Promise<any>
  icon?: any
  saving?: boolean
  remove?: () => any
  onLabelEdit?: (label: string) => any
  noIndicator?: boolean
  required?: boolean
  value?: any
  items?: any[]
  customPopover?: React.FC
  customPopoverProps?: any
  renderSelectedText?: (items: any, value: any) => any
}

const FormPopoverSelectorField: React.ForwardRefRenderFunction<
  any,
  FormPopoverSelectorFieldProps
> = (
  {
    className,
    icon,
    label,
    position,
    save,
    value,
    required,
    children,
    saving,
    remove,
    onLabelEdit,
    noIndicator,
    onItemSelected,
    items,
    renderSelectedText = (items: any, value: any) =>
      items?.find(([val]: any) => val === value)?.[1],
    customPopover: CustomPopover = PopoverSelector,
    customPopoverProps = {},
    ...rest
  },
  ref
) => {
  const [error, setError] = useState(false)
  const [isSaving, setIsSaving] = useState(saving)
  const [inputValue, setInputValue] = useState(value)
  const [isOpen, setIsOpen] = useState(false)
  const input = useRef<any>()
  useImperativeHandle(ref, () => input.current)

  const itemSelected = async (val: any, meta: any) => {
    if (save) {
      setIsSaving(!noIndicator)
      try {
        await save(val, meta, {})
      } catch (e) {
        notifications.errorToast(e)
        setError(true)
      } finally {
        setIsSaving(false)
      }
    }

    onItemSelected?.(val)
  }

  useEffect(() => {
    setIsSaving(saving)
  }, [saving])

  useEffect(() => {
    setInputValue(value)
  }, [value])

  return (
    <>
      <FormField
        label={label}
        icon={icon}
        position={position}
        saving={isSaving}
        className={css(className, 'ion-activatable pointer')}
        onClick={() => setIsOpen(true)}
        remove={remove}
        onLabelEdit={onLabelEdit}
        error={error}
        required={required}
      >
        <IonText
          style={{
            padding: '5px 0 10px',
          }}
        >
          {renderSelectedText(items, value) ?? 'Not Selected'}
        </IonText>
        {children}
      </FormField>
      <CustomPopover
        items={items}
        isOpen={isOpen}
        value={inputValue}
        onSelected={itemSelected}
        onDidDismiss={() => setIsOpen(false)}
        {...customPopoverProps}
        {...rest}
      />
    </>
  )
}

export default React.memo(React.forwardRef(FormPopoverSelectorField))
