import { TeamGamePersonnelService } from './team-game-personnel'
import { DataField } from '../util/data-field'
import { listsService } from '.'

class TeamGameStaffService extends TeamGamePersonnelService {
  public get fields(): DataField[] {
    return [
      ...this.standardFields,
      {
        key: 'role',
        label: 'Role',
        type: 'select',
        options: listsService.staffRoles,
      },
    ]
  }
}

export default new TeamGameStaffService()
