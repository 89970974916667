import React, { useState, useMemo } from 'react'
import Modal, { ModalProps } from './Modal'
import { Avatar } from '../components'
import styled from 'styled-components'
import { IonList } from '@ionic/react'
import ModelBaseService from '../services/model-base'
import loading from '../core/loading'
import notifications from '../core/notifications'
import { renderDataFields, DataField } from '../util/data-field'

export interface CreateModalProps extends ModalProps {
  title: string
  nameTemplate?: (item: any) => string
  onDidCreate?: (res?: any) => any

  service: ModelBaseService

  initialData?: { [key: string]: any }
  supportsAvatar?: boolean
  fields?: DataField[]
}

const CreateModal: React.FC<CreateModalProps> = ({
  onDidCreate,
  children,
  title,
  service,
  nameTemplate,
  initialData = {},
  supportsAvatar,
  fields,
  ...rest
}) => {
  const [avatar, setAvatar] = useState<any>()
  const [data, setData] = useState<any>(initialData)

  const update = (key: string) => async (val: any) =>
    setData({ ...data, [key]: val })

  const avatarURL = useMemo(
    () => (avatar ? URL.createObjectURL(avatar) : null),
    [avatar]
  )

  const fullName = useMemo(() => nameTemplate?.(data) ?? '', [
    data,
    nameTemplate,
  ])

  const save = async () => {
    try {
      await loading.create()
      let res = await service.create(data)
      if (avatar) {
        res = await service.uploadAvatar(res.id, avatar)
      }
      rest.onDidDismiss?.()
      onDidCreate?.(res)
    } catch (e) {
      notifications.errorToast(e)
    } finally {
      loading.dismiss()
    }
  }

  return (
    <Modal
      title={title}
      closeButtonText="Cancel"
      headerAction={{ children: 'Save', onClick: save }}
      {...rest}
    >
      <IonList>
        {supportsAvatar && (
          <Avatar
            size={96}
            name={fullName}
            src={avatarURL}
            allowEdit
            onImageSelected={async (file: File) => setAvatar(file)}
          />
        )}
        {renderDataFields(true, fields ?? service.fields, data, update)}
        {children}
      </IonList>
    </Modal>
  )
}

export default styled(React.memo(CreateModal))`
  ion-avatar {
    margin: 15px auto;
  }
`
