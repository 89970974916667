import React, { useState, useRef, useEffect, useMemo, useContext } from 'react'
import styled from 'styled-components'
import { TeamGameEvent, TeamGame } from '../types/models'
import { useEventListener, useRect, useShortcuts } from '../hooks'
import helpers from '../util/helpers'
import { teamGameEventService } from '../services'
import css from 'classnames'
import { IonIcon } from '@ionic/react'
import { searchCircleOutline, closeCircleOutline } from 'ionicons/icons'
import VideoEventTimelineZoom, { ZoomParams } from './ScoringEventTimelineZoom'
import { ScoringContext } from '../contexts/ScoringContext'
import _ from 'lodash'

export interface ScoringVideoEventTimelineProps
  extends React.ComponentProps<any> {
  step?: number
  onChange?: (time: number) => any
  onStartSeek?: (e: MouseEvent) => any
  onEndSeek?: (e: MouseEvent) => any
  max?: number
  min?: number
  value?: number
  leftSlot?: any
  rightSlot?: any
  debounce?: number
  allowZoom?: boolean
  maxZoom?: number
  dispatch: any
  teamGame?: TeamGame
}

function getPositionFromValue({ slider, min, max, value }: any) {
  const s = slider.current
  if (!s) {
    return 0
  }

  const { width } = s.getBoundingClientRect()
  return helpers.getPositionFromValue({
    width,
    min,
    max,
    value,
  })
}

function getValueFromPosition({ slider, step, min, max, pos }: any) {
  const s = slider.current
  if (!s) {
    return 0
  }

  const { width } = s.getBoundingClientRect()
  return helpers.getValueFromPosition({ width, step, min, max, pos })
}

const EventsTimeline = ({
  scrollTo,
  events,
  visibleEvents,
  zoomLevel,
  panLeft,
  selectedEventIndex,
  min,
  max,
  slider,
  manifest,
  isHomeTeam,
}: any) => {
  return (
    <div
      className={css('timeline timeline--container', {
        'has-selection': selectedEventIndex > -1,
      })}
      style={{
        left: panLeft,
        width: `${zoomLevel * 10}%`,
      }}
    >
      {visibleEvents?.map((it: TeamGameEvent) => {
        const index = events.indexOf(it)
        return (
          <span
            className={css('timeline--event with-marker', {
              selected: index === selectedEventIndex,
              'is-home-team': isHomeTeam,
              [it.type]: !it.flagged,
              flagged: it.flagged,
            })}
            id={it.id}
            key={it.id}
            onClick={scrollTo(index)}
            style={{
              left: getPositionFromValue({
                value: helpers.getCombinedVideoTime(it, manifest),
                min,
                max,
                slider,
              }),
            }}
          />
        )
      })}
    </div>
  )
}

const ScoringVideoEventTimeline: React.FC<ScoringVideoEventTimelineProps> = ({
  onChange,
  onStartSeek,
  onEndSeek,
  onTrackClick,
  max = 10,
  min = 0,
  step = 1,
  value = 2.56,
  leftSlot,
  rightSlot,
  debounce = 50,
  allowZoom = true,
  dispatch,
  teamGame,
  ...rest
}) => {
  const { state, feed } = useContext(ScoringContext)
  const slider = useRef<any>()
  const handle = useRef<any>()
  const timer = useRef<any>()
  const [visibleEvents, setVisibleEvents] = useState<any>()
  const [filters] = useState(teamGameEventService.initiatorEventTypes)
  const [seeking, setSeeking] = useState(false)
  const [currentValue, setCurrentValue] = useState(value)
  const [left, setLeft] = useState(0)
  const [selectedEventIndex, setSelectedEventIndex] = useState<number>(-1)
  const [showZoomControls, setShowZoomControls] = useState(false)
  const [zoomLevel, setZoomLevel] = useState(1)
  const [panLeft, setPanLeft] = useState<any>(0)
  const { events, manifest } = state

  const processMove = (clientX: number) => {
    if (slider.current) {
      const { x, width } = slider.current.getBoundingClientRect()
      const pos = helpers.clamp(clientX - x, 0, width)
      setLeft(pos)
      const value = getValueFromPosition({
        slider,
        step,
        min,
        max,
        pos,
      })
      clearTimeout(timer.current)
      timer.current = setTimeout(() => onChange?.(value), debounce)
      setCurrentValue(value)
    }
  }

  const onHandleDown = (e: any) => {
    setSeeking(true)
    onStartSeek?.(e)
  }

  const onHandleUp = (e: any) => {
    setSeeking(false)
    onEndSeek?.(e)
  }

  const onHandleMove = (e: any) => {
    if (seeking) {
      e.preventDefault()
      processMove(e.clientX)
    }
  }

  const onSliderClick = (e: any) => {
    //Handle if they clicked the track to advance to that spot
    if (e.target === slider.current) {
      processMove(e.clientX)
    }
  }

  const onResize = () => {
    const pos = getPositionFromValue({ min, max, value: currentValue, slider })
    setLeft(pos)
  }

  const onZoomChanged = (e: ZoomParams) => {
    if (slider.current) {
      const { width } = slider.current.getBoundingClientRect()
      const current = width / zoomLevel
      const newWidth = e.level * current
      const { pan } = e.window
      const val = pan ? (newWidth - current) * e.window.pan * -1 : 0
      setZoomLevel(e.level)
      setPanLeft(val)
      onResize()
    }
  }

  const scrollTo = (index: number) => () => {
    if (selectedEventIndex !== index) {
      setSelectedEventIndex(index)
      feed?.current?.scrollToItem?.(index, true)
      const eventVideoTime = helpers.getCombinedVideoTime(
        events?.[index],
        manifest!
      )
      const eventClockTime = events?.[index]?.clockTime
      if (eventVideoTime) {
        dispatch({
          type: 'set',
          value: {
            videoTime: Math.max(eventVideoTime - 4, 0),
            clock: Math.max(eventClockTime + 4, 0),
          },
        })
      }
    } else {
      setSelectedEventIndex(-1)
      feed?.current?.clearHighlight?.()
    }
  }

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  useEffect(() => {
    _.defer(() =>
      setLeft(getPositionFromValue({ min, max, value: currentValue, slider }))
    )
  }, [currentValue, min, max])

  const { width } = useRect(slider)

  useEffect(() => {
    if (width) {
      setVisibleEvents(
        events?.filter((it) => filters.indexOf(it.type) > -1) ?? []
      )
    }
  }, [events, filters, zoomLevel, width])

  useEffect(() => {
    if (!showZoomControls) {
      setZoomLevel(1)
      setPanLeft(0)
    }
  }, [showZoomControls])

  const homeEvents = useMemo(
    () => visibleEvents?.filter((it: TeamGameEvent) => it.isHomeTeam),
    [visibleEvents]
  )
  const awayEvents = useMemo(
    () => visibleEvents?.filter((it: TeamGameEvent) => !it.isHomeTeam),
    [visibleEvents]
  )

  const advanceSelectedIndex = (dir: 'forward' | 'backward') => () => {
    setSelectedEventIndex((index) => {
      const updatedIndex = !events?.length
        ? -1
        : helpers.clamp(
            index + (dir === 'forward' ? -1 : 1),
            0,
            events.length - 1
          )

      if (updatedIndex > -1) {
        scrollTo(updatedIndex)()
      }

      return updatedIndex
    })
  }

  useShortcuts({
    ']': advanceSelectedIndex('forward'),
    '[': advanceSelectedIndex('backward'),
    'Alt+z': () => setShowZoomControls(!showZoomControls),
  })

  useEventListener('mouseup', onHandleUp)
  useEventListener('touchend', onHandleUp)
  useEventListener('mousemove', onHandleMove)
  useEventListener('touchmove', onHandleMove)
  useEventListener('resize', onResize)

  return (
    <div {...rest}>
      <span className="slot left">{leftSlot}</span>
      <span className="team-initials">
        <span>{helpers.getInitials(teamGame?.homeTeamFullName)}</span>
        <span>{helpers.getInitials(teamGame?.awayTeamFullName)}</span>
      </span>
      <div className="timeline-range--container">
        {showZoomControls && (
          <VideoEventTimelineZoom
            minTime={min}
            maxTime={max}
            events={visibleEvents}
            onZoom={onZoomChanged}
            manifest={manifest}
            selectedEventIndex={selectedEventIndex}
          />
        )}
        <EventsTimeline
          scrollTo={scrollTo}
          events={events}
          visibleEvents={homeEvents}
          zoomLevel={zoomLevel}
          panLeft={panLeft}
          selectedEventIndex={selectedEventIndex}
          min={min}
          max={max}
          manifest={manifest}
          slider={slider}
          isHomeTeam
        />
        <div
          ref={slider}
          aria-valuemin={min}
          aria-valuemax={max}
          aria-valuenow={currentValue}
          onClick={onSliderClick}
          className="slider"
          onMouseDown={onHandleDown}
          onTouchStart={onHandleDown}
          style={{
            left: panLeft,
            width: `${zoomLevel * 100}%`,
          }}
        >
          <div ref={handle} className="handle" style={{ left }} />
        </div>
        <EventsTimeline
          scrollTo={scrollTo}
          events={events}
          visibleEvents={awayEvents}
          zoomLevel={zoomLevel}
          panLeft={panLeft}
          manifest={manifest}
          selectedEventIndex={selectedEventIndex}
          min={min}
          max={max}
          slider={slider}
          isHomeTeam={false}
        />
      </div>
      <span className="slot right">
        {rightSlot}
        {allowZoom && (
          <IonIcon
            className="zoom"
            icon={showZoomControls ? closeCircleOutline : searchCircleOutline}
            onClick={() => setShowZoomControls(!showZoomControls)}
          />
        )}
      </span>
    </div>
  )
}

export default styled(React.memo(ScoringVideoEventTimeline))`
  align-items: flex-end;
  display: flex;
  margin: 10px 0;
  user-select: none;

  .team-initials {
    display: grid;
    flex-direction: grid;
    grid-template-rows: auto auto;
    grid-row-gap: 8px;
    font-size: 12px;
    font-weight: bold;
    margin-right: 6px;
    transform: translateY(-5px);
  }

  .timeline-range--container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: hidden;
    padding: 0 7px 3px;

    .slider {
      background: #a0a0a0;
      border-radius: 20px;
      flex: 0 0 7px;
      cursor: pointer;
      position: relative;
      height: 7px;

      .handle {
        background: white;
        border-radius: 100%;
        cursor: grab;
        height: 13px;
        width: 13px;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 3;
      }
    }

    .timeline {
      flex: 0 0 16px;
      position: relative;

      .timeline--event {
        cursor: pointer;
        margin-left: -5px;

        &:not(.is-home-team) {
          top: 6px;
          transform: rotate(-180deg);
        }
      }
    }
  }

  .slot {
    font-size: 10px;
    transform: translateY(-17px);

    &.left {
      margin-right: 10px;
    }

    &.right {
      margin-left: 10px;
      white-space: nowrap;
    }

    .zoom {
      cursor: pointer;
      font-size: 20px;
      margin-left: 10px;
      transform: translateY(6px);
    }
  }
`
