import React from 'react'
import styled from 'styled-components'
import { IonSpinner } from '@ionic/react'

const LoadingSpinner = styled((props) => {
  return (
    <div {...props}>
      <IonSpinner name="crescent" />
    </div>
  )
})`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  ion-spinner {
    height: 50px;
    width: 50px;
  }
`

export default LoadingSpinner
