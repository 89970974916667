import React, { useMemo } from 'react'
import FormSelectField, { FormSelectFieldProps } from './FormSelectField'
import { useData } from '../hooks'

export interface FormDynamicSelectFieldProps extends FormSelectFieldProps {
  url?: string
  includeAllOption?: boolean
  allOptionText?: string
}

const FormDynamicSelectField: React.FC<FormDynamicSelectFieldProps> = ({
  url,
  options,
  includeAllOption,
  allOptionText = 'All',
  ...rest
}) => {
  const { data, ready } = useData(url)
  const opts = useMemo(() => {
    const opts = [...(data ?? [])]

    if (includeAllOption) {
      opts.unshift([null, allOptionText])
    }

    return opts
  }, [data, includeAllOption, allOptionText])

  if (!ready) {
    return null
  }

  return <FormSelectField options={opts} {...rest} />
}

export default React.memo(FormDynamicSelectField)
