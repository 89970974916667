import React, { useState, useEffect, useRef } from 'react'
import { IonItem, IonLabel, IonIcon, IonSpinner, IonText } from '@ionic/react'
import {
  checkmarkCircleOutline,
  warningOutline,
  removeCircleOutline,
  pencil,
  closeCircleOutline,
} from 'ionicons/icons'
import css from 'classnames'
import styled from 'styled-components'
import { dialogs } from '../core'

export interface FormFieldProps extends React.ComponentProps<typeof IonItem> {
  label?: any
  icon?: any
  position?: HTMLIonLabelElement['position']
  saving?: boolean
  error?: boolean
  invalid?: boolean
  onLabelEdit?: (label: string) => any
  remove?: () => any
  text?: string
  required?: boolean
}

const FormField: React.FC<FormFieldProps> = ({
  position = 'stacked',
  label,
  icon,
  children,
  saving,
  error,
  invalid,
  onLabelEdit,
  remove,
  text,
  required,
  className,
  ...rest
}) => {
  const saveTimer = useRef<any>()
  const wasSaving = useRef<any>(false)
  const [hasSaved, setHasSaved] = useState(saving)
  const [showDonemark, setShowDonemark] = useState(false)
  const [saveErrored, setSaveErrored] = useState(false)

  useEffect(() => {
    if (!saving && wasSaving.current) {
      setShowDonemark(true)
    } else {
      setShowDonemark(false)
    }

    if (saving) {
      setHasSaved(true)
    }

    wasSaving.current = saving
  }, [saving])

  useEffect(() => {
    setSaveErrored(!!error)
  }, [error])

  useEffect(() => {
    clearTimeout(saveTimer.current)

    const timer = setTimeout(() => setShowDonemark(false), 3000)
    saveTimer.current = timer

    return () => clearTimeout(timer)
  }, [showDonemark])

  const editLabel = () => {
    if (onLabelEdit) {
      dialogs.input('Edit Label', [{ value: label }], (e: any) =>
        onLabelEdit(e[0])
      )
    }
  }

  return (
    <IonItem className={css(className, { invalid, saving, error })} {...rest}>
      {icon && <IonIcon icon={icon} slot="start" />}
      <IonLabel
        color="medium"
        className={css('subtitle', {
          empty: !label,
          editable: !!onLabelEdit,
          required,
        })}
        position={position}
        onClick={editLabel}
      >
        {label}
        {onLabelEdit && <IonIcon className="edit-label-icon" icon={pencil} />}
        <div className="indicators">
          {invalid && (
            <IonIcon
              color="warning"
              className="animate__animated animate__headShake animate_fast invalid-indicator"
              icon={warningOutline}
            />
          )}
          {saving && <IonSpinner color="medium" name="crescent" />}
          <IonIcon
            className={css('donemark animate__faster animate__animated', {
              visible: !saving && hasSaved,
              animate__fadeIn: showDonemark,
              animate__fadeOut: !showDonemark,
            })}
            color={saveErrored ? 'danger' : 'success'}
            icon={saveErrored ? closeCircleOutline : checkmarkCircleOutline}
          />
        </div>
      </IonLabel>
      {text && <IonText>{text}</IonText>}
      {children}
      {remove && (
        <IonIcon
          className="remove-item"
          color="danger"
          icon={removeCircleOutline}
          slot="end"
          onClick={remove}
        />
      )}
    </IonItem>
  )
}

export default styled(FormField)`
  .remove-item {
    cursor: pointer;
    margin-top: 18px;
    opacity: 0;
    pointer-events: none;
    transition: opacity linear 0.2s;
  }

  &.invalid {
    ion-label {
      color: var(--ion-color-warning);
    }

    ion-textarea,
    ion-input,
    ion-select {
      --color: var(--ion-color-warning);
    }
  }

  &.error {
    ion-label {
      color: var(--ion-color-danger);
    }
  }

  ion-label.empty {
    display: none;
  }

  ion-select {
    margin-top: auto;
    margin-bottom: auto;
  }

  ion-label.editable {
    color: var(--ion-color-primary);
    pointer-events: all;
    cursor: pointer;
    > * {
      cursor: pointer;
    }

    .edit-label-icon {
      font-size: 14px;
      margin-left: 5px;
    }
  }

  ion-label {
    position: relative;
  }

  ion-label.required {
    padding-left: 15px;
    ::after {
      color: var(--ion-color-danger);
      display: block;
      content: '*';
      font-size: 18px;
      font-weight: bold;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  :hover .remove-item {
    pointer-events: all;
    opacity: 1;
  }

  .indicators {
    display: inline-block;
    position: absolute;
    top: 2px;
    transform: translateX(5px);
    height: 20px;
    width: 20px;

    font-size: 16px;

    ion-spinner {
      height: 16px;
      width: 16px;
    }

    .donemark {
      visibility: hidden;

      &.visible {
        visibility: visible;
      }
    }
  }
`
