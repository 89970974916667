import ModelBaseService from './model-base'
import { DataField } from '../util/data-field'

export class TeamGamePersonnelService extends ModelBaseService {
  protected modelName = 'team-game-personnel'

  protected get standardFields(): DataField[] {
    return [
      {
        key: 'isHomeTeam',
        label: 'Home Team',
        type: 'toggle',
      },
    ]
  }
  public get fields(): DataField[] {
    return this.standardFields
  }

  public get filters(): DataField[] | undefined {
    return [
      {
        label: 'Home Team',
        key: 'isHomeTeam',
        type: 'filter-select',
        options: [
          [true, 'Yes'],
          [false, 'No'],
        ],
      },
    ]
  }
}

export default new TeamGamePersonnelService()
