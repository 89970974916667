import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import { IonProgressBar, IonIcon } from '@ionic/react'
import { useEventListener } from '../hooks'
import _ from 'lodash'
import { cloudUploadOutline } from 'ionicons/icons'
import css from 'classnames'

const UploadProgress: React.FC<any> = ({ className, ...rest }) => {
  const inflight = useRef<any>({})
  const [count, setCount] = useState(0)
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    if (count > 0) {
      window.onbeforeunload = (e: any) => {
        e.preventDefault()
        return 'You have a file upload in progress.'
      }
    } else {
      window.onbeforeunload = null
    }
  }, [count])

  useEventListener('video:upload', (e: any) => {
    const { type, status, videoId, totalLoaded, totalSize } = e.detail
    switch (type) {
      case 'progress':
        inflight.current[videoId].totalSize = totalSize
        inflight.current[videoId].totalComplete = totalLoaded

        const overallTotal = _.sumBy(
          Object.values(inflight.current),
          'totalSize'
        )
        const overallComplete = _.sumBy(
          Object.values(inflight.current),
          'totalComplete'
        )

        setProgress(overallComplete / overallTotal)
        break

      case 'status':
        if (status === 'UPLOADING') {
          inflight.current[videoId] = {
            totalSize: 0,
            parts: {},
            totalComplete: 0,
          }
          setCount((count: any) => count + 1)
        } else {
          setCount((count: any) => {
            if (count === 1) {
              inflight.current = {}
              setProgress(0)
              return 0
            }

            return count - 1
          })
        }
        break
    }
  })

  return (
    <div className={css(className, { visible: count > 0 })} {...rest}>
      <IonIcon color="dark" icon={cloudUploadOutline} />
      <div className="progress">
        <label className="subtitle">
          Uploading {count} video{count === 1 ? '' : 's'}...
        </label>
        <IonProgressBar value={progress} />
      </div>
    </div>
  )
}

export default styled(UploadProgress)`
  align-items: center;
  background-color: var(--ion-color-light);
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 15px 10px;
  pointer-events: none;
  position: absolute;
  opacity: 0;
  left: -100%;
  bottom: 0;
  width: 100%;
  transition: opacity ease-in-out 0.15s;

  &.visible {
    left: 0;
    opacity: 1;
  }

  ion-icon {
    font-size: 24px;
    margin-right: 10px;
  }

  ion-progress-bar {
    border-radius: 8px;
    margin-top: 5px;
  }

  .progress {
    font-size: 10px;
    margin-bottom: 3px;
  }
`
