import React, { useRef, useState, useMemo } from 'react'
import {
  IonItem,
  IonLabel,
  IonIcon,
  IonProgressBar,
  IonModal,
  IonText,
  IonReorder,
} from '@ionic/react'
import { teamVideoService } from '../services'
import { useVideoUploadStatus } from '../hooks'
import styled from 'styled-components'
import {
  cloudDoneOutline,
  cloudOutline,
  cloudOfflineOutline,
  desktopOutline,
  syncCircleOutline,
  logoGoogle,
  logoWindows,
} from 'ionicons/icons'
import notifications from '../core/notifications'
import loading from '../core/loading'
import moment from 'moment'
import { TextBadge } from '.'

const TeamVideoVideoModal = styled(({ src, poster, ...rest }) => {
  return (
    <IonModal isOpen={true} {...rest}>
      <video src={src} poster={poster} controls autoPlay />
    </IonModal>
  )
})`
  --height: calc(90vw * ${(props) => props.aspectRatio});
  --width: 90vw;
  video {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`

const TeamVideoRow = styled(({ data, noControls, allowReorder, ...rest }) => {
  const [showVideo, setShowVideo] = useState(false)
  const [localFile, setLocalFile] = useState(
    teamVideoService.localFiles[data.id]
  )
  const action = useRef<any>()
  const fileInput = useRef<any>()
  const {
    progress,
    error,
    status = data.uploadCompleted ? 'COMPLETE' : 'INCOMPLETE',
  } = useVideoUploadStatus(data.id)

  const isStatusComplete = status === 'COMPLETE'
  const isUploading = status === 'UPLOADING'
  const isProcessing = isStatusComplete && !data.jobCompleted
  const isComplete =
    (!!data.uploadCompleted || isStatusComplete) && !!data.jobCompleted
  const isGoogle = data.storageLocation === 'GOOGLE'
  const isMicrosoft = data.storageLocation === 'MICROSOFT'

  const videoSource = useMemo(
    () => (localFile ? URL.createObjectURL(localFile) : data.src),
    [data, localFile]
  )

  // const chooseFile = (a: 'upload' | 'view') => () => {
  //   const isUpload = a === 'upload'
  //   if (isUpload && (data.uploadCompleted || isUploading)) {
  //     return
  //   }
  //   if (isUpload && localFile) {
  //     return teamVideoService.resume(data, localFile)
  //   }

  //   action.current = a
  //   fileInput.current?.click()
  // }

  const onFileSelected = async (e: any) => {
    switch (action.current) {
      case 'upload':
        return await resume(e)
      case 'view':
        const file = e.target.files[0]
        const hash = await teamVideoService.getFileHash(file)
        if (hash === data.hash) {
          teamVideoService.setLocalFile(data, file)
          setLocalFile(file)
          setShowVideo(true)
        } else {
          notifications.toast(
            'The file selected does not match this video.',
            'Error'
          )
        }
        break
    }
  }

  const resume = async (e: any) => {
    try {
      const file = e.target.files[0]
      await loading.create()
      await teamVideoService.resume(data, file)
    } catch (e) {
      notifications.errorToast(e)
    } finally {
      loading.dismiss()
    }
  }

  let icon = desktopOutline,
    iconTitle = 'Local file'

  if (isGoogle) {
    icon = logoGoogle
    iconTitle = 'Google Drive'
  } else if (isMicrosoft) {
    icon = logoWindows
    iconTitle = 'Microsoft OneDrive'
  } else if (isComplete) {
    icon = cloudDoneOutline
    iconTitle = 'Upload and processing complete'
  } else if (isUploading) {
    icon = syncCircleOutline
    iconTitle = 'Uploading video'
  } else if (isProcessing) {
    icon = cloudOutline
    iconTitle = 'Processing video'
  } else if (error) {
    icon = cloudOfflineOutline
    iconTitle = isProcessing
      ? 'Error processing video'
      : 'Error uploading video'
  }

  return (
    <IonItem routerLink={`/a/team/videos/${data?.id}/`} detail {...rest}>
      <img
        src={
          data.thumbnail
            ? data.poster
            : '/assets/placeholders/video_no_preview.jpg'
        }
        alt={data.name}
        slot="start"
      />
      <IonLabel>
        <IonText className="ion-multiline">
          <h4 className="video-title">{data.name}</h4>
          <div className="video-attributes">
            <TextBadge>{teamVideoService.getVideoBadgeText(data)}</TextBadge>
            <TextBadge color="medium">
              {data?.width}x{data?.height}
            </TextBadge>
            <TextBadge color="medium">
              {moment()
                .startOf('day')
                .seconds(data.duration)
                .format('HH:mm:ss')}
            </TextBadge>
            <IonIcon
              className="cloud-status-icon"
              color="medium"
              icon={icon}
              title={iconTitle}
            />
          </div>
        </IonText>
        {isUploading && (
          <div className="upload-status">
            <IonProgressBar value={progress} />
          </div>
        )}
      </IonLabel>
      {allowReorder && (
        <IonReorder
          slot="end"
          onClick={(e: any) => {
            e.stopPropagation()
          }}
        />
      )}
      {!isComplete && (
        <input
          ref={fileInput}
          type="file"
          style={{ display: 'none' }}
          onChange={onFileSelected}
          accept={teamVideoService.acceptedVideoExtensions}
          multiple
        />
      )}
      {showVideo && (
        <TeamVideoVideoModal
          src={videoSource}
          poster={data.poster}
          aspectRatio={data.aspectRatio}
          onDidDismiss={() => setShowVideo(false)}
        />
      )}
    </IonItem>
  )
})`
  user-select: none;
  img {
    background: rgb(50, 50, 50);
    border-radius: 9px;
    min-height: 85px;
    margin: 15px 15px 15px 0;
    width: 180px;
    height: auto;
    object-fit: contain;
  }

  .cloud-status-icon {
    margin-right: 10px;
    font-size: 20px;
  }

  .video-title {
    font-size: 16px;
    margin-top: 0;
    padding: 0;
    margin-bottom: 3px;
  }

  .resumable,
  .choose-local-file {
    cursor: pointer;
  }

  .upload-status {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 10px;

    ion-progress-bar {
      border-radius: 8px;
      max-width: 200px;
    }
  }

  .video-attributes {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .toolbar {
    margin-top: 10px;
  }
`

export default TeamVideoRow
