import { http } from '../core'
import { listsService } from '.'
import { DataField } from '../util/data-field'
import BaseService from './base'

export default abstract class ModelBaseService extends BaseService {
  protected abstract modelName: string
  protected get route() {
    return this.modelName
  }
  protected apiVersion: string = 'v1'
  public abstract get fields(): DataField[]
  public get filters(): DataField[] | undefined {
    return undefined
  }
  public hasWriteAccess(entityOrRole?: any) {
    const role =
      typeof entityOrRole === 'string' ? entityOrRole : entityOrRole?.role
    return listsService.writeableRoles.indexOf(role ?? '') > -1
  }

  async downloadPDF(url: string) {
    const res = await http.blobRequest({
      url,
    })

    return res.data as Blob
  }

  private async getAvatarUploadURL(id?: string | null) {
    const { data } = await http.authorizedRequest({
      method: 'POST',
      url: `${this.getEndpointUrl(id)}avatar/`,
      data: {},
    })

    return data
  }

  public readonly saveHandler = (
    id: string | null | undefined,
    key: string
  ) => async (val: any) => {
    if (!id) {
      return
    }

    return await this.update(id, { [key]: val })
  }

  public readonly entitySaveHandler = (id: string) => (key: string) => async (
    val: any
  ) => await this.update(id, { [key]: val })

  private async getAvatarImageBlob(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!file.type.startsWith('image')) {
        return reject('NOT_IMAGE')
      }

      const image = document.createElement('img')
      image.onerror = reject
      image.onload = () => {
        var canvas = document.createElement('CANVAS') as HTMLCanvasElement
        const { height, width } = image
        const isLandscape = width > height
        const ar = isLandscape ? height / width : width / height
        const max = Math.min(512, isLandscape ? width : height)
        const adjustedWidth = isLandscape ? max : max * ar
        const adjustedHeight = isLandscape ? max * ar : max
        canvas.width = adjustedWidth
        canvas.height = adjustedHeight
        const context = canvas.getContext('2d')

        if (context) {
          context.imageSmoothingEnabled = true
          context.drawImage(image, 0, 0, canvas.width, canvas.height)
        }

        canvas.toBlob(resolve, 'image/jpeg', 0.9)
      }

      image.src = URL.createObjectURL(file)
    })
  }

  async uploadAvatar(
    id: string | null,
    file: File,
    onUploadProgress?: (e: any) => any
  ) {
    const { avatarURL, uploadURL } = await this.getAvatarUploadURL(id)
    const data = await this.getAvatarImageBlob(file)
    await http.request({
      method: 'PUT',
      baseURL: '',
      url: uploadURL,
      data,
      onUploadProgress,
      headers: {
        'Content-Type': 'image/jpeg',
      },
    })

    return await this.update(id, { avatarURL })
  }

  async create(payload: any) {
    const { data } = await http.authorizedRequest({
      method: 'POST',
      url: this.getEndpointUrl(),
      data: payload,
    })

    return data
  }

  async update(id: string | null, changes: { [key: string]: any }) {
    const { data } = await http.authorizedRequest({
      method: 'PATCH',
      url: this.getEndpointUrl(id),
      data: changes,
    })

    return data
  }

  async destroy(id: string) {
    const { data } = await http.authorizedRequest({
      method: 'DELETE',
      url: this.getEndpointUrl(id),
    })

    return data
  }

  async restore(id: string) {
    const { data } = await http.authorizedRequest({
      method: 'POST',
      url: this.getEndpointUrl(id, 'restore'),
    })

    return data
  }
}
