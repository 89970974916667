import { IonPage } from '@ionic/react'
import React from 'react'
import styled from 'styled-components'
import css from 'classnames'
import { useHistory } from 'react-router'

interface PageProps extends React.ComponentProps<any> {}

const Page: React.FC<PageProps> = ({
  staticContext,
  history: ph,
  location,
  match,
  className,
  ...rest
}) => {
  const history = useHistory()

  return (
    <IonPage
      className={css(className, { 'has-history': history.length > 0 })}
      {...rest}
    />
  )
}

export default styled(React.memo(Page))`
  &:not(.has-history) ion-header.back-only {
    display: none;
  }

  &.has-history ion-back-button {
    color: white;
    display: block;
  }
`
