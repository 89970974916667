import React from 'react'
import styled from 'styled-components'
import { ScoringPageEvent, TeamGameEventType } from '../types/interfaces'
import { teamGameEventService } from '../services'
import { CircleIconButton } from '.'

export interface ScoringEventInitiatorsProps extends React.ComponentProps<any> {
  origin: ScoringPageEvent
  onUpdate: (event: ScoringPageEvent) => any
}

const ScoringEventInitiators: React.FC<ScoringEventInitiatorsProps> = ({
  origin,
  onUpdate,
  ...rest
}) => {
  const update = (type: TeamGameEventType) => () => {
    origin.type = type
    onUpdate(origin)
  }

  return (
    <div {...rest}>
      {teamGameEventService.initiatorEvents.map((it) => (
        <div key={it.id} onClick={update(it)}>
          <CircleIconButton
            icon={it.icon}
            shortcut={it.shortcut}
            onClick={update(it)}
          />
          <div>{it.name}</div>
        </div>
      ))}
    </div>
  )
}

export default styled(React.memo(ScoringEventInitiators))`
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-row-gap: 10px;
  padding: 20px;

  > div {
    align-items: center;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 10px;
  }
`
