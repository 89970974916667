import React, { useState, useMemo } from 'react'
import styled from 'styled-components'
import { IonSearchbar, IonChip, IonIcon, IonLabel } from '@ionic/react'
import { DataField, renderDataFields } from '../util/data-field'
import Modal from '../modals/Modal'
import { GenericButton } from '.'
import { closeCircle, optionsOutline } from 'ionicons/icons'

export interface FiltersBarProps {
  allowSearch?: boolean
  fields?: DataField[]
  filters?: { [key: string]: any }
  onSearch?: (val: string) => any
  setFilter?: (key: string, val: any) => any
  clearFilter?: (key: string) => any
  resetFilters?: () => any
  defaults?: { [key: string]: any }
}

const FiltersBar: React.FC<FiltersBarProps> = ({
  allowSearch = true,
  onSearch,
  filters,
  fields,
  defaults,
  setFilter,
  clearFilter,
  resetFilters,
  ...rest
}) => {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false)

  const onKeyPress = (e: any) => {
    if (e.which === 13) {
      onSearch?.(e.target.value)
    }
  }

  const update = (key: string) => (val: any) => {
    if (val === '!all' && defaults?.[key] === undefined) {
      clearFilter?.(key)
    } else {
      setFilter?.(key, val)
    }
  }

  const clearSearch = () => onSearch?.('')

  //Only show filters where the default value doesn't match
  //the current value. Only used if defaults are passed
  const visibleFilters = useMemo(
    () =>
      defaults && filters
        ? Object.keys(filters).reduce((o: any, key: string) => {
            if (defaults[key] !== filters[key]) {
              o[key] = filters[key]
            }
            return o
          }, {})
        : filters,
    [filters, defaults]
  )

  return (
    <div {...rest}>
      <div className="bar">
        {allowSearch && (
          <IonSearchbar
            mode="ios"
            onIonClear={clearSearch}
            onKeyPress={onKeyPress}
          />
        )}
        {fields && (
          <GenericButton
            className="btn-filters"
            icon={optionsOutline}
            color="dark"
            fill="clear"
            size="small"
            onClick={() => setIsFiltersOpen(!isFiltersOpen)}
          />
        )}
      </div>
      {visibleFilters && !!Object.keys(visibleFilters).length && (
        <div className="filters-row">
          {Object.entries(visibleFilters ?? {}).map(([key, val]) => (
            <IonChip key={key} mode="ios" outline>
              <IonLabel>
                <strong>
                  {fields?.find((it) => it.key === key)?.label ?? key}
                </strong>
                :
                {typeof val === 'boolean'
                  ? val
                    ? 'Yes'
                    : 'No'
                  : val === '!all'
                  ? 'All'
                  : val}
              </IonLabel>
              <IonIcon
                className="clear-filter"
                icon={closeCircle}
                onClick={() => {
                  if (defaults && defaults[key] !== undefined) {
                    setFilter?.(key, defaults[key])
                  } else {
                    clearFilter?.(key)
                  }
                }}
              />
            </IonChip>
          ))}
        </div>
      )}
      {fields && isFiltersOpen && (
        <Modal
          title="Filters"
          headerAction={{
            children: 'Reset',
            color: 'danger',
            onClick: resetFilters,
          }}
          maxWidth={320}
          maxHeight={300}
          closeButtonText="Done"
          onDidDismiss={() => setIsFiltersOpen(false)}
        >
          {renderDataFields(true, fields, filters, update)}
        </Modal>
      )}
    </div>
  )
}

export default styled(FiltersBar)`
  background: var(--primary-gradient-90);
  padding-bottom: 10px;

  ion-button,
  ion-input,
  ion-chip,
  ion-icon {
    border-color: white;
    color: white;
  }

  ion-searchbar {
    --background: white;
  }

  ion-chip {
    background: rgba(255, 255, 255, 0.1);
  }

  .btn-filters {
    --padding-top: 10px;
    --padding-bottom: 10px;
    margin: auto 15px auto 0;
    --padding-start: 1px;
    --padding-end: 1px;
  }

  .filters-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0 8px 5px;
    overflow-y: hidden;
    overflow-x: auto;

    ion-chip {
      flex: 0 0 auto;
    }
  }

  .bar {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
`
