import React, { useMemo, useCallback, useContext } from 'react'
import styled from 'styled-components'
import { ScoringFreethrowAttempt } from '../types/interfaces'
import { IonSegment, IonSegmentButton, IonIcon } from '@ionic/react'
import { flag } from 'ionicons/icons'
import { useShortcuts } from '../hooks'
import { ScoringContext } from '../contexts/ScoringContext'
import helpers from '../util/helpers'

export interface ScoringEventFreethrowsProps {
  attempt: ScoringFreethrowAttempt
  attempts: ScoringFreethrowAttempt[]
  onUpdate?: (attempt: ScoringFreethrowAttempt) => any
  onAttemptClicked?: (attempt: ScoringFreethrowAttempt) => any
}

const ScoringEventFreethrowsComponent: React.FC<ScoringEventFreethrowsProps> = ({
  attempt,
  attempts,
  onUpdate,
  onAttemptClicked,
  ...rest
}) => {
  const { state } = useContext(ScoringContext)
  //Using clickHandler because onIonChange sometimes mistriggers when the value changes
  const updateStatus = useCallback(
    (value: any) => () => {
      //@ts-ignore
      attempt.status = value
      attempt.reboundPlayer = null
      attempt.clockTime = state.clock
      attempt.videoTime = helpers.getCurrentRelativeVideoTime(state)
      onUpdate?.(attempt)
    },
    [attempt, onUpdate, state]
  )

  const shortcuts = useMemo(
    () => ({
      a: updateStatus('made'),
      s: updateStatus('missed'),
      d: updateStatus('violation'),
    }),
    [updateStatus]
  )

  useShortcuts(shortcuts)

  return (
    <div {...rest}>
      {attempts?.map((it: ScoringFreethrowAttempt) => {
        return (
          <div
            key={it.attempt}
            className="rebound--attempt"
            onClick={() => {
              if (it !== attempt) {
                onAttemptClicked?.(it)
              }
            }}
          >
            <p>Freethrow #{it.attempt + 1}</p>
            <div>
              {attempt === it ? (
                <IonSegment mode="ios" value={it.status}>
                  <IonSegmentButton onClick={updateStatus('made')} value="made">
                    Made (a)
                  </IonSegmentButton>
                  <IonSegmentButton
                    onClick={updateStatus('missed')}
                    value="missed"
                  >
                    Missed (s)
                  </IonSegmentButton>
                  <IonSegmentButton
                    onClick={updateStatus('violation')}
                    value="violation"
                  >
                    <div className="violation">
                      <IonIcon icon={flag} /> <span>(d)</span>
                    </div>
                  </IonSegmentButton>
                </IonSegment>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default styled(ScoringEventFreethrowsComponent)`
  padding: 10px 15px;

  .violation {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    grid-column-gap: 5px;
  }

  .rebound--attempt {
    border-bottom: solid 1px var(--ion-color-dark-tint);
    cursor: pointer;

    ion-segment {
      margin-bottom: 10px;
    }
  }

  .rebound--overview {
    padding: 10px 0;
  }
`
