import React, { useImperativeHandle, useEffect } from 'react'
import {
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonRefresher,
  IonRefresherContent,
  IonItem,
  IonLabel,
} from '@ionic/react'
import { useSSEListener, useData } from '../hooks'
import { ZeroStateCTA } from '.'
import LoadingSpinner from './LoadingSpinner'
import { ZeroStateCTAProps } from './ZeroStateCTA'
import LoadError from './LoadError'

export interface ListProps extends React.ComponentProps<typeof IonList> {
  url: string | null | undefined
  renderItem: (item: any, index: number) => any
  zeroState?: ZeroStateCTAProps
  noResultsText?: string
  model?: string
  onDataChange?: (data?: any) => any
  orderBy?: string | string[] | [string[], string[]]
  removeOnInactive?: boolean
  filters?: any
  paged?: boolean
  ignoredEvents?: string[]
}

const List: React.ForwardRefRenderFunction<any, ListProps> = (
  {
    url,
    model,
    renderItem,
    zeroState,
    onDataChange,
    orderBy,
    removeOnInactive,
    filters,
    paged = true,
    noResultsText = 'No Results',
    ignoredEvents,
    ...rest
  },
  ref
) => {
  const {
    updateData,
    replaceData,
    removeData,
    hasNext,
    loadNext,
    data,
    loading,
    error,
    refresh,
    isZeroState,
    isFiltered,
    isEmpty,
    count,
    orderData,
  } = useData(url, { paged })

  useImperativeHandle(ref, () => ({
    appendData: (data: any) => updateData(data),
    prependData: (data: any) => updateData(data, true),
    replaceData: (data: any) => replaceData(data),
    removeData: (data: any) => removeData(data),
    hasNext,
    loadNext,
    data,
    count,
    loading,
    error,
    refresh,
    orderData,
  }))

  useEffect(() => {
    onDataChange?.(data)
  }, [onDataChange, data])

  useSSEListener((e: any) => {
    if (model) {
      //Remove inactive rows if the component chooses the option
      //and the filters line up
      if (
        removeOnInactive &&
        e.detail.changes &&
        typeof e.detail.changes.isActive === 'boolean'
      ) {
        if (
          !filters ||
          typeof filters.isActive === 'undefined' ||
          (filters.isActive !== '!all' &&
            filters.isActive !== e.detail.data.isActive)
        ) {
          return removeData(e.detail.data)
        } else if (filters.isActive !== '!all') {
          return updateData(e.detail.data)
        }
      }

      //If the list has the ignored event, skip doing anything
      if (!ignoredEvents || ignoredEvents.indexOf(e.type) === -1) {
        if (e.type === 'db:destroy') {
          //If the model was destroyed, remove it
          removeData(e.detail.data)
        } else if (e.type === 'db:create') {
          //If the model was created, add it
          updateData(e.detail.data)
        } else {
          //Update the data with the updated version
          replaceData(e.detail.data)
        }
      }

      //If ordering is specified, reorder
      if (orderBy) {
        orderData(orderBy)
      }
    }
  }, model)

  const onIonInfinite = async (e: any) => {
    try {
      await loadNext()
    } finally {
      e.target.complete()
    }
  }

  const onIonRefresh = async (e: any) => {
    try {
      await refresh()
    } finally {
      e.target.complete()
    }
  }

  const noResults = (
    <IonItem lines="none">
      <IonLabel className="ion-text-center no-results">
        {noResultsText}
      </IonLabel>
    </IonItem>
  )

  let cta = noResults

  if (zeroState) {
    cta = <ZeroStateCTA {...zeroState} />
  }

  return (
    <>
      {isZeroState && cta}
      {isFiltered && isEmpty && noResults}
      <IonRefresher slot="fixed" onIonRefresh={onIonRefresh}>
        <IonRefresherContent />
      </IonRefresher>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <IonList style={{ padding: 0, margin: 0 }} {...rest}>
          {error ? (
            <LoadError data={error} refresh={refresh} />
          ) : !isZeroState ? (
            data?.map?.(renderItem)
          ) : null}
        </IonList>
      )}
      <IonInfiniteScroll disabled={!hasNext} onIonInfinite={onIonInfinite}>
        <IonInfiniteScrollContent />
      </IonInfiniteScroll>
    </>
  )
}

export default React.memo(React.forwardRef(List))
