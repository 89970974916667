import React, { useRef, useImperativeHandle } from 'react'
import { Plugins, HapticsImpactStyle } from '@capacitor/core'
import { IonButton, IonIcon, IonSpinner } from '@ionic/react'
import { device, dialogs } from '../core'
import styled from 'styled-components'
import css from 'classnames'

const { Haptics } = Plugins

export interface GenericButtonProps
  extends React.ComponentProps<typeof IonButton> {
  loading?: boolean
  hapticTouch?: boolean
  icon?: any
  indicatorIcon?: any
  toggleClick?: (val: any) => any
  visible?: boolean
  confirm?: { message: string; handler: () => any }
}

const GenericButton: React.ForwardRefRenderFunction<any, GenericButtonProps> = (
  {
    loading,
    hapticTouch = true,
    className,
    onClick,
    type,
    children,
    icon,
    toggleClick,
    confirm,
    visible = true,
    indicatorIcon,
    ...rest
  },
  ref
) => {
  const element = useRef<any>()

  useImperativeHandle(ref, () => element.current)

  const clickHandler = (e: any) => {
    if (device.isNative && hapticTouch) {
      Haptics.impact({
        style: HapticsImpactStyle.Light,
      })
    }

    if (confirm) {
      dialogs.confirm(confirm.message, 'Confirm', confirm.handler)
    }

    toggleClick?.(true)
    onClick?.(e)
  }

  const iconOnly = !!icon && !children

  if (!visible) {
    return null
  }

  return (
    <IonButton
      ref={element}
      onClick={clickHandler}
      type={type}
      className={css(className, { 'icon-only': iconOnly })}
      {...rest}
    >
      {loading && <IonSpinner name="crescent" className="loading" />}
      {icon && <IonIcon icon={icon} slot={iconOnly ? 'icon-only' : ''} />}
      {children}
      {/* add in a hidden input submit so enter presses work  */}
      {type === 'submit' && (
        <input
          type="submit"
          style={{
            visibility: 'hidden',
            height: 0,
            width: 0,
            position: 'absolute',
          }}
        />
      )}
      {indicatorIcon && (
        <div className="indicator-icon" slot="start">
          <IonIcon icon={indicatorIcon} />
        </div>
      )}
    </IonButton>
  )
}

export default styled(React.forwardRef(GenericButton))`
  filter: ${(props) => (props.loading ? 'grayscale(100%)' : 'none')};
  transition: filter ease-in-out 0.15s;
  position: relative;
  .indicator-icon {
    align-items: center;
    background-color: var(--ion-color-dark);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    height: 14px;
    width: 14px;
    top: 0px;
    left: -8px;
    z-index: 1;

    > ion-icon {
      flex: 0 0 auto;
      margin: 0 !important;
    }
  }

  &:not(.icon-only) {
    --padding-start: 20px;
    --padding-end: 20px;

    .loading {
      margin-right: 5px;
    }

    ion-icon {
      margin-right: 5px;
    }
  }
`
