import React, { useContext, useMemo } from 'react'
import styled from 'styled-components'
import { personSharp } from 'ionicons/icons'
import css from 'classnames'
import { IonIcon } from '@ionic/react'
import Jersey from './Jersey'
import { ScoringContext } from '../contexts/ScoringContext'
import { teamGameService, teamGameEventService } from '../services'
import _ from 'lodash'

export interface ScoringActivePlayerProps extends React.ComponentProps<any> {
  player: any
  icon?: any
  showName?: boolean
  actionIcon?: any
  shortcut?: string
  showFouls?: boolean
}

const ScoringActivePlayer: React.FC<ScoringActivePlayerProps> = ({
  player,
  icon = personSharp,
  showName = false,
  className,
  actionIcon,
  shortcut,
  showFouls,
  ...rest
}) => {
  const { state } = useContext(ScoringContext)
  const fill = useMemo(
    () => teamGameService.getJerseyFill(state.teamGame, player.isHomeTeam),
    [state.teamGame, player]
  )

  const stroke = useMemo(
    () => teamGameService.getJerseyStroke(state.teamGame, player.isHomeTeam),
    [state.teamGame, player]
  )

  const fouls = useMemo(
    () =>
      showFouls
        ? _.orderBy(
            state.events?.reduce((arr: any[], it) => {
              if (teamGameEventService.isPlayerFoul(player.id, it)) {
                arr.push(it)
              } else {
                const related = it.rel?.find((it) =>
                  teamGameEventService.isPlayerFoul(player.id, it)
                )

                if (related) {
                  arr.push(related)
                }
              }

              return arr
            }, []),
            ['attributes.type']
          )
        : [],
    [player, showFouls, state.events]
  )

  return (
    <div
      className={css(className, {
        'with-name': showName,
        'with-action': !!rest.onClick,
      })}
      {...rest}
    >
      <span className="shortcut">{shortcut}</span>
      <Jersey
        className="player-number"
        number={player.number}
        height={35}
        color={fill}
        stroke={stroke}
      />
      <div className="player-info">
        <span className="player-name">
          {player.lastName}
          {player.firstName ? `, ${player.firstName[0]}.` : ''}
        </span>
        {!!fouls?.length && (
          <div className="player-fouls">
            {fouls?.map((it) => (
              <span key={it.id} className="foul">
                {it.attributes?.type === 'technical' ? 'T' : 'P'}
              </span>
            ))}
          </div>
        )}
      </div>
      {actionIcon && (
        <IonIcon className="action-icon" icon={actionIcon} color="light" />
      )}
    </div>
  )
}
export default styled(ScoringActivePlayer)`
  align-items: center;
  border-radius: 10px;
  display: grid;
  grid-template-columns: ${(props) =>
      props.shortcut?.toString() ? '20px' : 'auto'} 42px 1fr 30px;
  padding: 5px 0 5px 15px;
  width: 100%;

  .shortcut {
    font-size: 10px;
    color: var(--ion-color-medium);
  }

  .player-fouls {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-items: flex-start;

    .foul {
      align-items: center;
      background: var(--ion-color-medium);
      border-radius: 100%;
      color: var(--ion-color-dark);
      display: flex;
      font-size: 8px;
      justify-content: center;
      margin: 0 3px 5px 0;
      text-align: center;
      height: 11px;
      width: 11px;
    }
  }

  .action-icon {
    display: none;
  }

  &.with-name button {
    margin-right: 15px;
  }

  &.with-action {
    cursor: pointer;
    transition: background ease-in-out 0.15s;
    &:hover {
      background: rgba(255, 255, 255, 0.05);
      .action-icon {
        display: block;
      }
    }
  }

  .player-number {
  }

  .player-name {
    font-size: 14px;
    color: var(--ion-color-medium);
    overflow: hidden;
    text-wrap: ellipsis;
    white-space: nowrap;
  }
`
