import { AppContext } from '../contexts/AppContext'
import { useContext, useMemo, useCallback } from 'react'
import { useData } from './useData'

interface UseSubscriptionOptions {
  includePaymentData?: boolean
  includeUsage?: boolean
}

export default function useSubscription(options?: UseSubscriptionOptions) {
  const { state } = useContext(AppContext)

  const teamBrandId = useMemo(() => state.teamBrand?.id, [state.teamBrand])

  const subscription = useMemo(() => state.teamBrand?.subscriptions?.[0], [
    state.teamBrand,
  ])

  const active = useMemo(() => subscription?.status === 'ACTIVE', [
    subscription,
  ])

  const { data: usage, loading: usageLoading, refresh: refreshUsage } = useData(
    options?.includeUsage === true && !!subscription
      ? `/v1/billing/team-brand/${teamBrandId}/usage/`
      : null
  )

  const {
    data: payment,
    loading: paymentLoading,
    refresh: refreshPayment,
  } = useData(
    options?.includePaymentData === true
      ? `/v1/billing/${teamBrandId}/payment/`
      : null
  )

  const loading = useMemo(() => paymentLoading || usageLoading, [
    paymentLoading,
    usageLoading,
  ])

  const refresh = useCallback(() => {
    refreshPayment()
    refreshUsage()
  }, [refreshPayment, refreshUsage])

  return {
    teamBrand: state.teamBrand,
    subscription,
    active,
    usage,
    payment,
    loading,
    refresh,
  }
}
