import React, { useRef, useImperativeHandle, useState, useEffect } from 'react'
import FormField, { FormFieldProps } from './FormField'
import { IonTextarea } from '@ionic/react'
import notifications from '../core/notifications'

export interface FormTextareaFieldProps
  extends React.ComponentProps<typeof IonTextarea> {
  label?: string
  position?: FormFieldProps['position']
  icon?: any
  save?: (val: string, e: any) => Promise<any>
  saving?: boolean
  remove?: () => any
  onLabelEdit?: (label: string) => any
  noIndicator?: boolean
}

const FormTextareaField: React.ForwardRefRenderFunction<
  any,
  FormTextareaFieldProps
> = (
  {
    className,
    icon,
    label,
    position,
    save,
    value,
    required,
    children,
    saving,
    remove,
    onLabelEdit,
    noIndicator,
    ...rest
  },
  ref
) => {
  const [error, setError] = useState(false)
  const [invalid, setInvalid] = useState(false)
  const [isSaving, setIsSaving] = useState(saving)
  const [inputValue, setInputValue] = useState(value)
  const input = useRef<any>()
  useImperativeHandle(ref, () => input.current)

  const onIonBlur = async (e: any) => {
    const newValue = e.target.value
    setInputValue(newValue)

    if (required && newValue?.trim()?.length === 0) {
      return setInvalid(true)
    }

    setInvalid(false)
    if (save && value !== newValue) {
      setIsSaving(!noIndicator)
      setError(false)
      try {
        await save(newValue, e)
      } catch (e) {
        notifications.errorToast(e)
        setError(true)
      } finally {
        setIsSaving(false)
      }
    }
  }

  useEffect(() => {
    setIsSaving(saving)
  }, [saving])

  useEffect(() => {
    setInputValue(value)
  }, [value])

  return (
    <FormField
      label={label}
      icon={icon}
      position={position}
      saving={isSaving}
      error={error}
      invalid={invalid}
      className={className}
      remove={remove}
      onLabelEdit={onLabelEdit}
      required={required}
    >
      <IonTextarea
        ref={input}
        onIonBlur={onIonBlur}
        value={inputValue}
        required={required}
        {...rest}
      />
      {children}
    </FormField>
  )
}

export default React.memo(React.forwardRef(FormTextareaField))
