import useRouter from './useRouter'
import { useEffect } from 'react'
import config from '../core/config'
import { initialization, intercom } from '../core'
declare global {
  interface Window {
    gtag: any
  }
}

export default function useTracking(event?: string) {
  const { location } = useRouter()

  useEffect(() => {
    if (config.isProduction && initialization.analyticsEnabled) {
      const page = location.pathname
      window.gtag?.('event', 'page_view', {
        page_title: document.title,
        page_location: window.location.href,
        page_path: page,
        send_to: config.gaStreamId,
      })

      if (event) {
        intercom.trackEvent(event)
      }
    }

    intercom.onRouteChange()
  }, [location, event])
}
