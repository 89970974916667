import { Court } from '../types/models'
import 'chartjs-plugin-annotation'
import ChartJsAnnotation from 'chartjs-plugin-annotation'

export const annotation = (court:Court, mode:'vertical'|'horizontal', scaleID:string): ChartJsAnnotation.AnnotationConfig => {
    return {
      drawTime: 'afterDatasetsDraw',
      annotations: [
        {
          type: 'line',
          mode: mode,
          scaleID: scaleID,
          value:  court.threePointArcInches / 12,
          borderColor: 'gray',
          borderWidth: 1,
          label: {
            enabled: true,
            position: 'top',
            content: '3pt',
          },
        }
      ],
    }
  }

  export const scales = (xLabelString: string, yLabelString: string)=>{
    return {
      xAxes: [
        {
          ticks: {
            autoSkip: true,
            maxTicksLimit: 6,
          },
          scaleLabel: {
            display: true,
            labelString: xLabelString,
          },
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: yLabelString,
          },
          ticks: {
            callback: (value: any) => `${value}%`,
          },
        },
      ],
    }
  }

export const datasetOptions = () =>{
    return {
                label: '% of Shots',
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgba(251, 94, 28,0.4)',
                borderColor: 'rgba(251, 94, 28,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(251, 94, 28,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(251, 94, 28,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
        }
}