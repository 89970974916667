import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import css from 'classnames'
import { IonIcon } from '@ionic/react'

export interface MarketingLinkButtonProps
  extends React.ComponentProps<typeof Link> {
  icon?: any
  iconSide?: 'left' | 'right'
}

const MarketingLinkButton: React.FC<MarketingLinkButtonProps> = ({
  children,
  icon,
  iconSide,
  className,
  ...rest
}) => {
  return (
    <Link className={css(className, { icon })} {...rest}>
      {children}
      {icon && <IonIcon icon={icon} />}
    </Link>
  )
}
export default styled(MarketingLinkButton)`
  align-items: center;
  align-content: center;
  background: var(--ion-color-primary);
  border: none;
  border-radius: 28px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  color: var(--ion-color-primary-contrast);
  font-family: 'Roboto', sans-serif;
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  justify-content: center;
  outline: none;
  position: relative;
  padding: 7px 20px;
  text-decoration: none;

  &.icon {
    padding-${(props) => props.iconSide ?? 'left'}: 50px;
  }

  ion-icon {
    font-size: 18px;
    position: absolute;
    top: 50%;
    ${(props) => props.iconSide ?? 'left'}: 20px;
    transform: translateY(-50%);
  }

  &:hover {
    background: var(--ion-color-primary-tint);
  }
`
