import React, { useContext, useMemo } from 'react'
import { IonPopover } from '@ionic/react'
import { ScoringContext } from '../contexts/ScoringContext'
import styled from 'styled-components'
import Jersey from './Jersey'
import helpers from '../util/helpers'
import { teamGameService } from '../services'

export interface ScoringJumpballPopover extends React.ComponentProps<any> {
  event: any
}

const ScoringJumpballPopover: React.FC<any> = ({ event, ...props }) => {
  const { state, dispatch } = useContext(ScoringContext)

  const logJumpball = (isHomeTeam: boolean) => () => {
    dispatch({
      type: 'jumpball',
      value: isHomeTeam,
    })
  }

  const homeFill = useMemo(
    () => teamGameService.getJerseyFill(state.teamGame, true),
    [state.teamGame]
  )

  const homeStroke = useMemo(
    () => teamGameService.getJerseyStroke(state.teamGame, true),
    [state.teamGame]
  )

  const awayFill = useMemo(
    () => teamGameService.getJerseyFill(state.teamGame, false),
    [state.teamGame]
  )

  const awayStroke = useMemo(
    () => teamGameService.getJerseyStroke(state.teamGame, false),
    [state.teamGame]
  )

  return (
    <IonPopover
      isOpen={state.showJumpballPopover}
      onDidDismiss={() =>
        dispatch({
          type: 'set',
          value: {
            showJumpballPopover: false,
          },
        })
      }
      mode="ios"
      event={event}
      backdropDismiss={false}
      showBackdrop={false}
      {...props}
    >
      <h4 className="text">Who won the jump ball?</h4>
      <div className="jumpball--teams">
        <div className="jumpball--team" onClick={logJumpball(true)}>
          <Jersey
            color={homeFill}
            stroke={homeStroke}
            number={helpers.getInitials(state.teamGame?.homeTeamFullName)}
          />
          <span>{state.teamGame?.homeTeamFullName}</span>
        </div>
        <div className="jumpball--team" onClick={logJumpball(false)}>
          <Jersey
            color={awayFill}
            stroke={awayStroke}
            number={helpers.getInitials(state.teamGame?.awayTeamFullName)}
          />
          <span>{state.teamGame?.awayTeamFullName}</span>
        </div>
      </div>
    </IonPopover>
  )
}

export default styled(React.memo(ScoringJumpballPopover))`
  text-align: center;
  --min-width: 250px;

  .text {
    font-size: 14px;
  }

  .jumpball--teams {
    align-items: center;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 20px;
    justify-content: center;
    margin: 20px 30px;

    .jumpball--team {
      align-items: center;
      border-radius: 8px;
      cursor: pointer;
      display: grid;
      grid-template-rows: 1fr 1fr;
      justify-items: center;
      padding: 10px;

      span {
        font-size: 13px;
        font-weight: bold;
      }

      &:hover {
        background: var(--ion-color-light);
      }
    }
  }
`
