import { TeamVideoChunkManifest } from './../types/models'
import { authentication, http } from '../core'
import { TeamVideo } from '../types/models'
import _ from 'lodash'
import fileHasher from '../util/file-hasher'
import ModelBaseService from './model-base'
import { DataField } from '../util/data-field'
import { TeamVideoPlayer } from '../components'
import Queue from './queue'
import config from '../core/config'
import helpers from '../util/helpers'

interface UploadResponse {}

interface VideoAttributes {
  size: number
  height: number
  width: number
  aspectRatio: number
  thumbnail: Blob
  hash: string
  duration: number
  name: string
  type: string
  isUHD: boolean
}

interface VideoMetadata {
  teamId: string
  order: number
}

class VideoUpload {
  public chunkCount: number
  public chunks: any[]
  public chunkSize: number
  public totalLoaded: number
  public totalSize: number
  public completedChunks: number = 0
  public teamVideo: TeamVideo
  public file: File
  private chunkStatus: any = {}
  constructor(
    teamVideo: TeamVideo,
    file: File,
    manifest: TeamVideoChunkManifest
  ) {
    this.teamVideo = teamVideo
    this.chunkCount = teamVideo.chunks
    this.totalSize = file.size
    this.chunkSize = manifest.chunkSize
    this.chunks = manifest.chunks
    this.file = file
    this.totalLoaded = 0

    this.emitStatus('UPLOADING')

    if (manifest.completedBytes > 0) {
      this.totalLoaded = manifest.completedBytes
      this.emitProgress('existing', this.totalLoaded, this.totalLoaded)
    } else {
      this.emitProgress('existing', this.totalLoaded, this.chunkCount)
    }
  }

  onError(error: any) {
    this.emitStatus('ERROR', { error })
  }

  onComplete() {
    this.emitStatus('COMPLETE')
  }

  onPartProgress(part: number, loaded: number, total: number) {
    const status = this.chunkStatus[part] ?? {
      loaded: 0,
    }
    this.totalLoaded += loaded - status.loaded
    this.emitProgress(part, loaded, total)
    status.loaded = loaded
    this.chunkStatus[part] = status
  }

  private emitStatus(
    status: 'ERROR' | 'UPLOADING' | 'COMPLETE',
    data: { [key: string]: any } = {}
  ) {
    this.emit('status', { status, ...data })
  }

  private emitProgress(
    part: number | 'existing',
    chunkLoaded: number,
    chunkTotal: number
  ) {
    this.emit('progress', {
      part,
      chunkLoaded,
      chunkTotal,
      totalSize: this.totalSize,
      totalLoaded: this.totalLoaded,
      percentComplete: this.totalLoaded / this.totalSize,
    })
  }

  private emit(type: 'status' | 'progress', data: { [key: string]: any }) {
    const teamVideoId = this.teamVideo.id
    const teamGameId = this.teamVideo.teamGameId
    const detail = {
      type,
      ...data,
    }

    window.dispatchEvent(
      new CustomEvent(`video:${teamVideoId}`, {
        detail,
      })
    )

    window.dispatchEvent(
      new CustomEvent(`video:upload`, {
        detail: {
          videoId: teamVideoId,
          ...detail,
        },
      })
    )

    if (teamGameId) {
      window.dispatchEvent(
        new CustomEvent(`game:upload:${teamGameId}`, {
          detail: {
            videoId: teamVideoId,
            ...detail,
          },
        })
      )
    }
  }
}

class TeamVideoService extends ModelBaseService {
  protected modelName = 'team-video'
  public localFiles: { [key: string]: File } = {}
  public uploads: { [key: string]: VideoUpload } = {}
  private queue = new Queue()
  async getFileHash(file: File): Promise<string> {
    return await fileHasher.hashPrefixWithMetadata(file)
  }

  public get fields(): DataField[] {
    return [
      {
        key: 'video',
        type: 'custom',
        label: '',
        customComponent: TeamVideoPlayer,
      },
      {
        key: 'name',
        label: 'Name',
        type: 'input',
        required: true,
      },
      {
        key: 'notes',
        label: 'Notes',
        type: 'textarea',
      },
    ]
  }

  setLocalFile(video: TeamVideo, file: File) {
    this.localFiles[video.id] = file
  }

  private async createThumbnail(
    attrs: any,
    video: HTMLVideoElement
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      var canvas = document.createElement('CANVAS') as HTMLCanvasElement
      canvas.width = 1280
      canvas.height = 1280 * attrs.aspectRatio
      const context = canvas.getContext('2d')

      if (context) {
        context.imageSmoothingEnabled = true
        context.drawImage(video, 0, 0, canvas.width, canvas.height)
      }

      canvas.toBlob(resolve, 'image/jpeg', 0.9)
    })
  }

  wouldPutOverQuota(usage: any, minutes: number) {
    const limit = usage.limits.total
    const current = usage.usage.total
    return current + minutes > limit
  }

  async getTotalDurations(files?: File[]) {
    let hd = 0
    let uhd = 0

    if (files?.length) {
      for (var i = 0; i < files.length; i++) {
        const attrs = await this.getVideoAttributes(files[i])
        if (attrs.isUHD) {
          uhd += attrs.duration
        } else {
          hd += attrs.duration
        }
      }
    }

    return { hd, uhd, total: hd + uhd }
  }

  private async getVideoAttributes(file: File): Promise<VideoAttributes> {
    let video = document.createElement('VIDEO') as HTMLVideoElement
    return new Promise((resolve, reject) => {
      video.onloadeddata = async () => {
        try {
          const width = video.videoWidth
          const height = video.videoHeight
          const isLandscape = width > height
          const size = file.size
          const aspectRatio = isLandscape ? height / width : width / height
          const duration = video.duration

          const attributes = {
            size,
            width,
            height,
            aspectRatio,
            duration,
            type: file.type,
            name: file.name,
            isUHD: Math.max(height, width) > 1920,
          }

          resolve({
            ...attributes,
            hash: await this.getFileHash(file),
            thumbnail: await this.createThumbnail(attributes, video),
          })
        } catch (e) {
          reject(e)
        }
      }
      video.onerror = reject
      video.src = URL.createObjectURL(file)
      video.currentTime = 2
    })
  }

  private async getCloudVideoAttributes(
    teamBrandId: string,
    provider: string,
    fileId: string
  ) {
    return new Promise(async (resolve, reject) => {
      let video = document.createElement('VIDEO') as HTMLVideoElement
      video.onloadeddata = async () => {
        video.currentTime = 2
      }

      video.onseeked = async () => {
        try {
          const width = video.videoWidth
          const height = video.videoHeight
          const isLandscape = width > height
          const aspectRatio = isLandscape ? height / width : width / height
          const duration = video.duration

          const attributes = {
            width,
            height,
            aspectRatio,
            duration,
            isUHD: Math.max(height, width) > 1920,
          }

          const thumbnailBlob = await this.createThumbnail(attributes, video)
          resolve({
            ...attributes,
            thumbnail: await helpers.blobToBase64(thumbnailBlob),
          })
        } catch (e) {
          reject(e)
        }
      }
      const token = await authentication.getAccessToken()
      video.crossOrigin = 'anonymous'
      video.onerror = reject
      video.src = `${
        config.baseURL
      }/v1/team-brand/${teamBrandId}/${provider.toLowerCase()}/video/${fileId}/?access_token=${token}`
    })
  }

  private async createFile(
    file: File,
    metadata: VideoMetadata
  ): Promise<[TeamVideo, VideoAttributes]> {
    const attrs = await this.getVideoAttributes(file)
    const { width, height, aspectRatio, duration, hash } = attrs

    const { data } = await http.authorizedRequest({
      method: 'POST',
      url: `/v1/team-video/`,
      data: {
        name: file.name,
        filename: file.name,
        originalType: file.type,
        size: file.size,
        width,
        height,
        aspectRatio,
        duration,
        hash,
        ...metadata,
      },
    })

    return [data, attrs]
  }

  private async getChunks(video: TeamVideo) {
    const { data } = await http.authorizedRequest({
      method: 'POST',
      url: `/v1/team-video/${video.id}/upload/`,
      data: {},
    })

    return data
  }

  private async uploadThumbnail(url: string, attrs: VideoAttributes) {
    return await http.request({
      method: 'PUT',
      baseURL: '',
      url,
      data: attrs.thumbnail,
      headers: {
        'Content-Type': 'image/png',
      },
    })
  }

  private async uploadChunks(upload: VideoUpload) {
    this.queue.add(async () => {
      try {
        const { chunkSize, totalSize, file } = upload
        const batches = _.chunk(upload.chunks, 3)

        while (batches.length) {
          const batch = batches.shift() as any
          await Promise.all(
            batch?.map((chunk: any) => {
              const start = chunkSize * (chunk.part - 1)
              const end = Math.min(start + chunkSize, totalSize)
              const data = file.slice(start, end)

              return http.request({
                method: 'PUT',
                baseURL: '',
                url: chunk.url,
                data,
                headers: {
                  'Content-Type': '',
                },
                timeout: 0,
                onUploadProgress: (event: ProgressEvent) => {
                  const { loaded, total } = event
                  upload.onPartProgress(chunk.part, loaded, total)
                },
              })
            })
          )
        }
        await this.complete(upload)
      } catch (e) {
        upload.onError(e)
      }
    })
  }

  private async complete(upload: VideoUpload) {
    try {
      const { teamVideo } = upload
      const { data } = await http.authorizedRequest({
        method: 'POST',
        url: `/v1/team-video/${teamVideo.id}/upload/complete/`,
        data: {},
      })

      upload.onComplete()
      delete this.uploads[teamVideo.id]

      return data
    } catch (e) {
      upload.onError(e)
    }
  }

  private async doUpload(
    video: TeamVideo,
    attrs: VideoAttributes,
    file: File,
    thumbnailOnly?: boolean
  ) {
    this.setLocalFile(video, file)
    const manifest = await this.getChunks(video)
    await this.uploadThumbnail(manifest.thumbnail, attrs)

    if (thumbnailOnly || !manifest.uploadAllowable) {
      return video
    }

    const upload = new VideoUpload(video, file, manifest)
    this.uploads[video.id] = upload

    this.uploadChunks(upload)
    return video
  }

  async create(payload: { [key: string]: any }) {
    try {
      const { file, metadata } = payload
      const [video, attrs] = await this.createFile(file, metadata)
      await this.doUpload(video, attrs, file, true)
      return video
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  async upload(file: File, metadata: VideoMetadata) {
    try {
      const [video, attrs] = await this.createFile(file, metadata)
      await this.doUpload(video, attrs, file)
      return video
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  async abort(video: TeamVideo) {
    const { data } = await http.authorizedRequest({
      method: 'POST',
      url: `/v1/team-video/${video.id}/abort/`,
      data: {},
    })

    return data
  }

  async resume(video: TeamVideo, file: File) {
    try {
      const attrs = await this.getVideoAttributes(file)

      if (video.size !== attrs.size.toString() || video.hash !== attrs.hash) {
        throw new Error(
          `This file doesn't appear to match the file you're attempting to resume.`
        )
      }

      await this.doUpload(video, attrs, file)
      return video
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  get acceptedVideoExtensions() {
    return '.avi,.m2ts,.mod,.mov,.mp4,.mpeg,.mpg,.mts,.scmov,.sczip,.ts,.vob,.wmv,.m4v'
  }

  requiresLocalFile(video: TeamVideo) {
    return !video.uploadCompleted
  }

  getVideoBadgeText(video: TeamVideo) {
    const max = Math.max(video.height, video.width)
    if (max > 1920) {
      return 'UHD'
    } else if (max > 1280) {
      return 'HD'
    } else {
      return 'SD'
    }
  }

  async reorder(teamId: string, videos: { [key: string]: any }[]) {
    const { data } = await http.authorizedRequest({
      method: 'PATCH',
      url: this.getEndpointUrl(null, 'reorder'),
      data: {
        teamId,
        videos,
      },
    })

    return data
  }

  async linkCloudFile(
    file: any,
    teamBrandId: string,
    provider: 'GOOGLE' | 'MICROSOFT',
    metadata: VideoMetadata
  ) {
    const attrs = (await this.getCloudVideoAttributes(
      teamBrandId,
      provider,
      file.id
    )) as any

    const { data } = await http.authorizedRequest({
      method: 'POST',
      url: this.getEndpointUrl(null, 'link'),
      data: {
        providerId: file.id,
        provider,
        ...metadata,
        aspectRatio: attrs.aspectRatio,
        duration: attrs.duration,
        height: attrs.height,
        width: attrs.width,
        thumbnail: attrs.thumbnail,
      },
    })

    return data
  }
}

export default new TeamVideoService()
