import React, { Suspense } from 'react'
import { Route } from 'react-router-dom'
import FallbackPage from './FallbackPage'

const AnonymousRoute = ({ component: Component, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Suspense fallback={<FallbackPage />}>
          <Component {...props} />
        </Suspense>
      )}
    />
  )
}

export default AnonymousRoute
