import { useIonViewDidEnter, useIonViewDidLeave } from '@ionic/react'
import { useRef, useEffect } from 'react'
import { Shortcuts } from 'shortcuts'
export default function useShortcuts(mapping: {
  [key: string]: (e: any) => any
}) {
  const enabled = useRef<any>(true)
  const instance = useRef<any>(new Shortcuts())
  useEffect(() => {
    const shortcuts = instance.current

    //create new definitions
    const defs = Object.entries(mapping).map(([shortcut, handler]) => ({
      shortcut,
      handler(e: any) {
        if (document.activeElement?.nodeName === 'INPUT' || !enabled.current) {
          return false
        }

        return handler(e) ?? true
      },
    }))

    //cleanup old bindings
    shortcuts.reset()
    //add in shortcuts
    shortcuts.add(defs)
    return () => {
      shortcuts.reset()
    }
  }, [mapping])

  useIonViewDidEnter(() => {
    enabled.current = true
  })

  useIonViewDidLeave(() => {
    enabled.current = false
  })
}
