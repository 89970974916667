import { Court } from '../types/models'
import paper, { PointText, Shape } from 'paper'
import _ from 'lodash'
export interface CourtPosition {
  x: number
  y: number
  side?: 'left' | 'right'
  hoopSide?: 'left' | 'right'
  distance?: number
}

export interface CourtDimensions {
  height: number
  width: number
  ppi: number
}

export interface MarkerOptions {
  x: number
  y: number
  ppi: number
  pointValue?: number
  distance?: number
  side: 'left' | 'right'
  color?: string
  size?: number
  filled?: boolean
}

export interface LegendOptions {
  x: number
  y: number
  upperRangeNumber: number
  lowerRangeNumber: number
  color: string
  ppsValue: string
}

function getLocationInInches(x: number, y: number) {
  return Math.abs(Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2)))
}

function getLocationInFeet(x: number, y: number, ppi: number) {
  return (getLocationInInches(x, y) * ppi) / 12
}

export default class CourtRenderer {
  public height: number
  public width: number
  public canvas: HTMLCanvasElement
  public lineColor: paper.Color
  public courtColor: paper.Color
  public paintColor: paper.Color
  public lineWidth: number
  public ppi: number
  public court: Court
  private rimCenter: CourtPosition
  private courtAspectRatio: number
  public threePointArc: number
  public type: 'FULL' | 'TOPHALF'
  public fillcourtcolor?: boolean
  private scope: any
  constructor(
    canvas: HTMLCanvasElement,
    court: Court,
    overrides?: Partial<Court>,
    type?: 'FULL' | 'TOPHALF',
    fillcourtcolor?: boolean
  ) {
    court = { ...court, ...(overrides ?? {}) }
    type = type ?? 'FULL'
    fillcourtcolor = fillcourtcolor ?? true
    this.courtAspectRatio = court.widthInches / court.heightInches
    this.canvas = canvas
    this.court = court
    this.type = type
    this.fillcourtcolor = fillcourtcolor
    this.width = canvas.clientWidth
    this.scope = new paper.PaperScope()
    this.ppi = this.court.widthInches / this.width

    if (type === 'TOPHALF') {
      this.ppi = this.court.heightInches / this.width

      this.height =
        (canvas.clientWidth * (court.widthInches / court.heightInches)) / 2
      canvas.style.height = `${this.height}px`

      this.rimCenter = {
        y: this.court.baseToCenterRimInches / this.ppi,
        x: this.width / 2,
      }
    } else {
      this.height =
        canvas.clientWidth * (court.heightInches / court.widthInches)

      canvas.style.height = `${this.height}px`

      this.rimCenter = {
        x: this.court.baseToCenterRimInches / this.ppi,
        y: this.height / 2,
      }
    }

    this.courtColor = new paper.Color(court.courtColor)
    this.lineColor = new paper.Color(court.lineColor)
    this.paintColor = new paper.Color(court.paintColor)
    this.threePointArc = this.court.threePointArcInches / this.ppi
    this.lineWidth = Math.round(this.court.lineWidthInches / this.ppi)
  }

  private drawTopHalfLane() {
    const width = this.court.laneHeightInches / this.ppi
    const height = this.court.laneWidthInches / this.ppi
    const x = this.width / 2 - width / 2

    const left = new paper.Shape.Rectangle(
      new paper.Rectangle(x, this.lineWidth / 2, width, height)
    )
    if (this.fillcourtcolor) left.fillColor = this.paintColor
    left.strokeColor = this.lineColor
    left.strokeWidth = this.lineWidth
  }

  private drawLanes() {
    const width = this.court.laneWidthInches / this.ppi
    const height = this.court.laneHeightInches / this.ppi
    const y = this.height / 2 - height / 2

    const left = new paper.Shape.Rectangle(
      new paper.Rectangle(this.lineWidth / 2, y, width, height)
    )
    left.fillColor = this.paintColor
    left.strokeColor = this.lineColor
    left.strokeWidth = this.lineWidth

    const x = this.width - width
    const right = new paper.Shape.Rectangle(
      new paper.Rectangle(x, y, width - this.lineWidth / 2, height)
    )

    right.fillColor = this.paintColor
    right.strokeColor = this.lineColor
    right.strokeWidth = this.lineWidth
  }

  private drawTopHalfFreethrowCircle() {
    const laneWidth = this.court.laneWidthInches / this.ppi
    const laneHeight = this.court.laneHeightInches / this.ppi
    const x = this.width / 2 - laneHeight / 2
    const y = laneWidth

    const end = new paper.Path.Arc(
      new paper.Point(x, y),
      new paper.Point(x, y + this.lineWidth),
      new paper.Point(x + laneHeight, y)
    )

    end.strokeWidth = this.lineWidth
    end.strokeColor = this.lineColor

    if (this.court.showDashedFreeThrow) {
      const start = new paper.Path.Arc(
        new paper.Point(x, y),
        new paper.Point(x, y - 1),
        new paper.Point(x + laneHeight, y)
      )
      start.strokeWidth = this.lineWidth
      start.strokeColor = this.lineColor
      start.dashArray = [10, 4]
    }
  }

  private drawFreeThrowCircles() {
    const laneWidth = this.court.laneWidthInches / this.ppi
    const laneHeight = this.court.laneHeightInches / this.ppi
    const y = this.height / 2 - laneHeight / 2
    const leftX = laneWidth

    const leftEnd = new paper.Path.Arc(
      new paper.Point(leftX, y),
      new paper.Point(leftX + this.lineWidth, y),
      new paper.Point(leftX, y + laneHeight)
    )

    leftEnd.strokeWidth = this.lineWidth
    leftEnd.strokeColor = this.lineColor

    if (this.court.showDashedFreeThrow) {
      const leftStart = new paper.Path.Arc(
        new paper.Point(leftX, y),
        new paper.Point(leftX - 1, y),
        new paper.Point(leftX, y + laneHeight)
      )

      leftStart.strokeWidth = this.lineWidth
      leftStart.strokeColor = this.lineColor
      leftStart.dashArray = [10, 4]
    }

    const rightX = this.width - laneWidth

    const rightStart = new paper.Path.Arc(
      new paper.Point(rightX, y),
      new paper.Point(rightX - this.lineWidth, y),
      new paper.Point(rightX, y + laneHeight)
    )

    rightStart.strokeWidth = this.lineWidth
    rightStart.strokeColor = this.lineColor

    if (this.court.showDashedFreeThrow) {
      const rightEnd = new paper.Path.Arc(
        new paper.Point(rightX, y),
        new paper.Point(rightX + 1, y),
        new paper.Point(rightX, y + laneHeight)
      )

      rightEnd.strokeWidth = this.lineWidth
      rightEnd.strokeColor = this.lineColor
      rightEnd.dashArray = [10, 4]
    }
  }

  private drawCourtRect() {
    const court = new paper.Shape.Rectangle(
      new paper.Rectangle(0, 0, this.width, this.height)
    )
    court.strokeWidth = this.lineWidth * 2
    court.strokeColor = this.lineColor
    if (this.fillcourtcolor) court.fillColor = this.courtColor
  }

  private drawDivisionLine() {
    const line = new paper.Path()
    line.strokeWidth = this.lineWidth
    line.strokeColor = this.lineColor
    const center = this.width / 2
    line.moveTo(new paper.Point(center, 0))
    line.lineTo(new paper.Point(center, this.height))
  }

  private drawCenterCircles() {
    const x = this.width / 2
    const y = this.height / 2
    const center = new paper.Point(x, y)
    const circle = new paper.Shape.Circle(
      center,
      this.court.centerCircleInches / this.ppi
    )

    circle.strokeColor = this.lineColor
    circle.strokeWidth = this.lineWidth
    circle.fillColor = this.paintColor
  }

  private drawTopHalfCenterCourtCircle() {
    const x = this.width / 2
    const y = this.height
    const center = new paper.Point(x, y)
    const circle = new paper.Shape.Circle(
      center,
      this.court.centerCircleInches / this.ppi
    )

    circle.strokeColor = this.lineColor
    circle.strokeWidth = this.lineWidth
    if (this.fillcourtcolor) circle.fillColor = this.paintColor
  }

  private drawTopHalfThreePointLine() {
    const lines = new paper.Path()
    const arc = this.court.threePointArcInches / this.ppi
    const x1 = this.rimCenter.x - this.court.threePointArcInches / this.ppi
    const x2 = this.rimCenter.x + this.court.threePointArcInches / this.ppi
    const y = this.court.baseToThreePointArcInches / this.ppi
    lines.moveTo(new paper.Point(x1, 0))
    lines.lineTo(new paper.Point(x1, y))

    lines.arcTo(
      new paper.Point(
        this.rimCenter.x,
        this.rimCenter.y + arc + this.lineWidth
      ),
      new paper.Point(x2, y)
    )
    lines.lineTo(new paper.Point(x2, 0))
    lines.strokeWidth = this.lineWidth
    lines.strokeColor = this.lineColor
  }

  private drawThreePointLines() {
    const leftLines = new paper.Path()
    const arc = this.court.threePointArcInches / this.ppi
    const y1 = this.rimCenter.y - this.court.threePointArcInches / this.ppi
    const y2 = this.rimCenter.y + this.court.threePointArcInches / this.ppi
    const x = this.court.baseToThreePointArcInches / this.ppi
    leftLines.moveTo(new paper.Point(0, y1))
    leftLines.lineTo(new paper.Point(x, y1))

    leftLines.arcTo(
      new paper.Point(
        this.rimCenter.x + arc + this.lineWidth,
        this.rimCenter.y
      ),
      new paper.Point(x, y2)
    )
    leftLines.lineTo(new paper.Point(0, y2))
    leftLines.strokeWidth = this.lineWidth
    leftLines.strokeColor = this.lineColor

    const rightLines = new paper.Path()
    rightLines.moveTo(new paper.Point(this.width, y1))
    rightLines.lineTo(new paper.Point(this.width - x, y1))

    rightLines.arcTo(
      new paper.Point(
        this.width - this.rimCenter.x - arc - this.lineWidth,
        this.rimCenter.y
      ),
      new paper.Point(this.width - x, y2)
    )
    rightLines.lineTo(new paper.Point(this.width, y2))
    rightLines.strokeWidth = this.lineWidth
    rightLines.strokeColor = this.lineColor
  }

  private drawTopHalfHoop() {
    const radius = this.court.rimDiameterInches / 2 / this.ppi + this.lineWidth
    const { x, y } = this.rimCenter
    const backboardHeight = this.court.backboardInches / this.ppi
    const backboardWidth = 8 / this.ppi
    const backboardY =
      y -
      this.court.backboardToCenterRimInches / this.ppi -
      backboardWidth -
      this.lineWidth

    const backboard = new paper.Path()
    backboard.moveTo(new paper.Point(x - backboardHeight / 2, backboardY))
    backboard.lineTo(new paper.Point(x + backboardHeight / 2, backboardY))
    backboard.strokeColor = this.lineColor
    backboard.strokeWidth = backboardWidth

    const connector = new paper.Path()
    connector.moveTo(new paper.Point(x, y - radius))
    connector.lineTo(new paper.Point(x, backboardY))
    connector.strokeColor = this.lineColor
    connector.strokeWidth = backboardWidth

    const rim = new paper.Shape.Circle(new paper.Point(x, y), radius)

    rim.strokeColor = this.lineColor
    rim.strokeWidth = this.lineWidth
  }

  private drawHoops() {
    const radius = this.court.rimDiameterInches / 2 / this.ppi + this.lineWidth
    const { x, y } = this.rimCenter
    const backboardHeight = this.court.backboardInches / this.ppi
    const backboardWidth = 8 / this.ppi
    const backboardX =
      x -
      this.court.backboardToCenterRimInches / this.ppi -
      backboardWidth -
      this.lineWidth

    const leftBackboard = new paper.Path()
    leftBackboard.moveTo(new paper.Point(backboardX, y - backboardHeight / 2))
    leftBackboard.lineTo(new paper.Point(backboardX, y + backboardHeight / 2))
    leftBackboard.strokeColor = this.lineColor
    leftBackboard.strokeWidth = backboardWidth

    const leftConnector = new paper.Path()
    leftConnector.moveTo(new paper.Point(x - radius, y))
    leftConnector.lineTo(new paper.Point(backboardX, y))
    leftConnector.strokeColor = this.lineColor
    leftConnector.strokeWidth = backboardWidth

    const leftRim = new paper.Shape.Circle(new paper.Point(x, y), radius)

    leftRim.strokeColor = this.lineColor
    leftRim.strokeWidth = this.lineWidth

    const rightBackboard = new paper.Path()
    rightBackboard.moveTo(
      new paper.Point(this.width - backboardX, y - backboardHeight / 2)
    )
    rightBackboard.lineTo(
      new paper.Point(this.width - backboardX, y + backboardHeight / 2)
    )
    rightBackboard.strokeColor = this.lineColor
    rightBackboard.strokeWidth = backboardWidth
    const rightConnector = new paper.Path()
    rightConnector.moveTo(new paper.Point(this.width - x + radius, y))
    rightConnector.lineTo(new paper.Point(this.width - backboardX, y))
    rightConnector.strokeColor = this.lineColor
    rightConnector.strokeWidth = backboardWidth

    const rightRim = new paper.Shape.Circle(
      new paper.Point(this.width - x, y),
      radius
    )

    rightRim.strokeColor = this.lineColor
    rightRim.strokeWidth = this.lineWidth
  }

  private drawFreeThrowLine(x: number, y: number, y2: number, width: number) {
    const path = new paper.Path()
    path.moveTo(new paper.Point(x, y))
    path.lineTo(new paper.Point(x, y2))
    path.strokeColor = this.lineColor
    path.strokeWidth = width
  }

  private drawFreeThrowLines(
    count: number,
    startX: number,
    y: number,
    y2: number,
    ltr = true
  ) {
    const spacing = this.court.freeThrowLaneLineSpacerInches / this.ppi
    for (let i = 1; i <= count; i++) {
      let x = ltr ? startX + spacing * i : startX - spacing * i
      this.drawFreeThrowLine(x, y, y2, this.lineWidth)
    }
  }

  private drawTopHalfFreeThrowLine(
    y: number,
    x: number,
    x2: number,
    width: number
  ) {
    const path = new paper.Path()
    path.moveTo(new paper.Point(x, y))
    path.lineTo(new paper.Point(x2, y))
    path.strokeColor = this.lineColor
    path.strokeWidth = width
  }

  private drawTopHalfFreeThrowLines(
    count: number,
    startY: number,
    x: number,
    x2: number
  ) {
    const spacing = this.court.freeThrowLaneLineSpacerInches / this.ppi
    for (let i = 1; i <= count; i++) {
      let y = startY + spacing * i
      this.drawTopHalfFreeThrowLine(y, x, x2, this.lineWidth)
    }
  }

  private drawTopHalfFreeThrowLaneLines() {
    const laneHeight = this.court.laneHeightInches / this.ppi
    const lineHeight = this.court.freeThrowLaneLineHeightInches / this.ppi
    const blockWidth = this.court.blockWidthInches / this.ppi
    const x1 = this.width / 2 - laneHeight / 2
    const startY = this.court.baseToBlockInches / this.ppi
    const x2 = x1 + laneHeight

    this.drawTopHalfFreeThrowLine(startY, x1, x1 - lineHeight, blockWidth)
    this.drawTopHalfFreeThrowLine(startY, x2, x2 + lineHeight, blockWidth)
    this.drawTopHalfFreeThrowLines(3, startY, x1, x1 - lineHeight)
    this.drawTopHalfFreeThrowLines(3, startY, x2, x2 + lineHeight)
  }

  private drawFreeThrowLaneLines() {
    const laneHeight = this.court.laneHeightInches / this.ppi
    const lineHeight = this.court.freeThrowLaneLineHeightInches / this.ppi
    const blockWidth = this.court.blockWidthInches / this.ppi
    const y1 = this.height / 2 - laneHeight / 2
    const startX = this.court.baseToBlockInches / this.ppi
    const y2 = y1 + laneHeight

    //Left
    this.drawFreeThrowLine(startX, y1, y1 - lineHeight, blockWidth)
    this.drawFreeThrowLine(startX, y2, y2 + lineHeight, blockWidth)
    this.drawFreeThrowLines(3, startX, y1, y1 - lineHeight)
    this.drawFreeThrowLines(3, startX, y2, y2 + lineHeight)

    //Right
    const startRightX = this.width - startX
    this.drawFreeThrowLine(startRightX, y1, y1 - lineHeight, blockWidth)
    this.drawFreeThrowLine(startRightX, y2, y2 + lineHeight, blockWidth)
    this.drawFreeThrowLines(3, startRightX, y1, y1 - lineHeight, false)
    this.drawFreeThrowLines(3, startRightX, y2, y2 + lineHeight, false)
  }

  private drawTopHalfRestrictedArc() {
    if (this.court.showRestrictedArc) {
      const y =
        this.rimCenter.y -
        this.lineWidth -
        this.court.backboardToCenterRimInches / this.ppi
      const throughY =
        this.rimCenter.y + this.court.restrictedArcInches / this.ppi
      const x = this.rimCenter.x + y
      const x2 = this.rimCenter.x - y

      const arc = new paper.Path()
      arc.strokeColor = this.lineColor
      arc.strokeWidth = this.lineWidth
      arc.moveTo(new paper.Point(x, y))
      arc.lineTo(new paper.Point(x, this.rimCenter.y))
      arc.arcTo(
        new paper.Point(this.rimCenter.x, throughY),
        new paper.Point(x2, this.rimCenter.y)
      )
      arc.lineTo(new paper.Point(x2, y))
    }
  }

  private drawRestrictedArc() {
    if (this.court.showRestrictedArc) {
      const x =
        this.rimCenter.x -
        this.lineWidth -
        this.court.backboardToCenterRimInches / this.ppi
      const throughX =
        this.rimCenter.x + this.court.restrictedArcInches / this.ppi
      const y = this.rimCenter.y - x
      const y2 = this.rimCenter.y + x

      //Left
      const left = new paper.Path()
      left.strokeColor = this.lineColor
      left.strokeWidth = this.lineWidth
      left.moveTo(new paper.Point(x, y))
      left.lineTo(new paper.Point(this.rimCenter.x, y))
      left.arcTo(
        new paper.Point(throughX, this.rimCenter.y),
        new paper.Point(this.rimCenter.x, y2)
      )
      left.lineTo(new paper.Point(x, y2))

      //Right
      const right = new paper.Path()
      right.strokeColor = this.lineColor
      right.strokeWidth = this.lineWidth
      right.moveTo(new paper.Point(this.width - x, y))
      right.lineTo(new paper.Point(this.width - this.rimCenter.x, y))
      right.arcTo(
        new paper.Point(this.width - throughX, this.rimCenter.y),
        new paper.Point(this.width - this.rimCenter.x, y2)
      )
      right.lineTo(new paper.Point(this.width - x, y2))
    }
  }

  private drawHLine() {
    //Debugging
    const line2 = new paper.Path()
    line2.strokeWidth = this.lineWidth
    line2.strokeColor = this.lineColor
    const hcenter = this.height / 2
    line2.moveTo(new paper.Point(0, hcenter))
    line2.lineTo(new paper.Point(this.width, hcenter))
  }

  drawPoint(options: MarkerOptions) {
    const screenBased = this.toScreenBased(
      options.x,
      options.y,
      options.ppi,
      options.side
    )

    const radius = (options.size ?? 7) / this.ppi
    const center = new paper.Point(screenBased.x, screenBased.y)
    const circle = new paper.Shape.Circle(center, radius)

    circle.strokeColor = new paper.Color(options.color ?? '#3da5c2')
    circle.strokeWidth = this.lineWidth
    if (options.filled !== false) {
      circle.fillColor = circle.strokeColor
    }
  }

  drawRegion(options: LegendOptions, idx: number, points: MarkerOptions[]) {
    let distance1 = (options.lowerRangeNumber * 12) / this.ppi // multiply by 12 to convert them to inches.
    let distance2 = (options.upperRangeNumber * 12) / this.ppi
    const line = new paper.Path()
    line.strokeWidth = this.lineWidth
    const inner_x1 = this.rimCenter.x - distance1 /// this.ppi
    const outer_x1 = this.rimCenter.x - distance2 /// this.ppi
    const inner_x2 = this.rimCenter.x + distance1 /// this.ppi
    const outer_x2 = this.rimCenter.x + distance2 /// this.ppi
    let y = this.rimCenter.y /// this.ppi
    line.moveTo(new paper.Point(inner_x1, 0))
    line.lineTo(new paper.Point(outer_x1, 0))
    line.lineTo(new paper.Point(outer_x1, y))
    line.arcTo(new paper.Point(outer_x2, y), false)
    line.lineTo(new paper.Point(outer_x2, 0))
    line.lineTo(new paper.Point(inner_x2, 0))
    line.lineTo(new paper.Point(inner_x2, y))
    line.arcTo(new paper.Point(inner_x1, y), true)
    line.lineTo(new paper.Point(inner_x1, 0))

    if (points?.length) {
      let c = points.filter(
        (it: any) =>
          it?.distance <= options.upperRangeNumber &&
          it?.distance > options.lowerRangeNumber
      )
      let pps = 0
      let totalShots = 0
      var successfulShots = c.filter((it: any) => it?.made)
      var totalPointsAtFeet = _.sumBy(successfulShots, 'pointValue')
      totalShots = c.length
      pps = totalPointsAtFeet / totalShots
      line.opacity = 1
      if (pps >= 0 && pps <= 0.25) {
        line.strokeColor = new paper.Color('#52609D')
      }
      if (pps > 0.25 && pps <= 0.5) {
        line.strokeColor = new paper.Color('#818CBC')
      }
      if (pps > 0.5 && pps <= 0.75) {
        line.strokeColor = new paper.Color('#B3BAD7')
      }
      if (pps > 0.75 && pps <= 1) {
        line.strokeColor = new paper.Color('#D9DCEB')
      }
      if (pps > 1 && pps <= 1.25) {
        line.strokeColor = new paper.Color('#C5FCB4')
      }
      if (pps > 1.25 && pps <= 1.5) {
        line.strokeColor = new paper.Color('#8BFA69')
      }
      if (pps > 1.5 && pps <= 1.75) {
        line.strokeColor = new paper.Color('#3FF309')
      }
      if (pps > 1.75 && pps <= 3) {
        line.strokeColor = new paper.Color('#269105')
      }
      line.fillColor = line.strokeColor
      line.sendToBack()

      const court1 = new paper.Shape.Rectangle(
        new paper.Rectangle(0, 0, this.width, this.height)
      )
      court1.fillColor = new paper.Color(this.court.courtColor)
      var layer = new paper.Layer(court1)
      layer.sendToBack()
    }
  }

  drawLegend(options: LegendOptions, idx: number, points: MarkerOptions[]) {
    var legendBoxSize = 25 / this.ppi
    options.x = this.width - 222 / this.ppi + idx * legendBoxSize
    options.y = this.height - 25
    let fontSize = 7
    let pps = 0
    let totalShots = 0
    if (points?.length) {
      let c = points.filter(
        (it: any) =>
          it?.distance <= options.upperRangeNumber &&
          it?.distance > options.lowerRangeNumber
      )
      var totalPointsAtFeet = _.sumBy(c, 'pointValue')
      totalShots = c.length
      pps = totalPointsAtFeet / totalShots
    }
    let rectangle = new Shape.Rectangle(
      new paper.Point(options.x, options.y),
      new paper.Size(legendBoxSize, legendBoxSize)
    )
    rectangle.strokeColor = new paper.Color(options.color)
    rectangle.fillColor = rectangle.strokeColor
    rectangle.opacity = 1
    var textY = options.y - 15 / this.ppi

    if (idx == 0) {
      var staticX = options.x + 75 / this.ppi
      var staticY = options.y - 40 / this.ppi
      var staticText = new PointText(new paper.Point(staticX, staticY))
      staticText.fontWeight = 'BOLD'
      staticText.fontSize = fontSize
      staticText.content = 'PP-FGA'
      var text = new PointText(new paper.Point(options.x, textY))
      text.content = (Math.round(options.lowerRangeNumber * 100) / 100)
        .toFixed(2)
        .toString()
      text.fontSize = 7
      text.rotate(300)
    }
    var textX = options.x + legendBoxSize
    var text = new PointText(new paper.Point(textX, textY))
    text.fontSize = 7
    text.content = (Math.round(options.upperRangeNumber * 100) / 100)
      .toFixed(2)
      .toString()
    text.rotate(300)
    if (options.upperRangeNumber == 3) {
      text.content = '>2'
    }
  }

  toRimBased(x: number, y: number, side: 'left' | 'right') {
    const isRight = side === 'right' && this.type !== 'TOPHALF'
    const { width, height } = this.canvas.getBoundingClientRect()
    let rimX = this.rimCenter.x

    if (isRight) {
      rimX = width - rimX
    }

    const basedY = y - this.rimCenter.y
    const basedX = isRight ? (x - rimX) * -1 : x - rimX

    return {
      ppi: this.ppi,
      x: basedX,
      y: basedY,
      height,
      width,
      side,
      distance: this.getLocationFeet(basedX, basedY),
      hoopSide: CourtRenderer.getSideOfHoop(basedY, side),
    }
  }

  toScreenBased(x: number, y: number, ppi: number, side: 'left' | 'right') {
    if (this.type === 'TOPHALF') {
      const yy = y
      y = x
      x = side === 'right' ? yy : yy * -1
    }

    const { width } = this.canvas.getBoundingClientRect()
    const isRight = side === 'right' && this.type !== 'TOPHALF'
    const offset = ppi / this.ppi
    const y1 = this.rimCenter.y
    const x1 = isRight ? width - this.rimCenter.x : this.rimCenter.x
    const y2 = y * offset
    const x2 = x * offset
    return {
      y: y1 + y2,
      x: isRight ? x1 - x2 : x1 + x2,
    }
  }

  toRimBasedFromClickEvent(e: MouseEvent) {
    const rect = this.canvas.getBoundingClientRect()
    const x = e.clientX - rect.x
    const y = e.clientY - rect.y
    const halfCourt = rect.width / 2
    const side = x <= halfCourt ? 'left' : 'right'
    return this.toRimBased(x, y, side)
  }

  getLocationInches(x: number, y: number) {
    return getLocationInInches(x, y)
  }

  static getLocationInches(x: number, y: number) {
    return getLocationInInches(x, y)
  }

  getLocationPointValue(x: number, y: number) {
    const s = this.getLocationInches(x, y)
    const isThreePoint = s > this.threePointArc
    return isThreePoint ? 3 : 2
  }

  getLocationFeet(x: number, y: number) {
    return getLocationInFeet(x, y, this.ppi)
  }

  static getLocationFeet(x: number, y: number, ppi: number) {
    return getLocationInFeet(x, y, ppi)
  }

  static getSideOfHoop(y: number, courtSide: 'left' | 'right') {
    const isLeft = courtSide === 'left' ? y > 0 : y < 0
    return isLeft ? 'left' : 'right'
  }

  private renderFullCourt() {
    this.drawCourtRect()
    this.drawCenterCircles()
    this.drawDivisionLine()
    this.drawLanes()
    this.drawFreeThrowCircles()
    this.drawHoops()
    this.drawThreePointLines()
    this.drawFreeThrowLaneLines()
    this.drawRestrictedArc()
  }

  private renderTopHalfCourt() {
    this.drawCourtRect()
    this.drawTopHalfLane()
    this.drawTopHalfCenterCourtCircle()
    this.drawTopHalfHoop()
    this.drawTopHalfFreethrowCircle()
    this.drawTopHalfThreePointLine()
    this.drawTopHalfFreeThrowLaneLines()
    this.drawTopHalfRestrictedArc()
  }

  dispose() {
    this.scope?.view?.remove?.()
    this.scope?.remove?.()
  }

  render() {
    if (this.scope.view) {
      this.scope.view.remove()
    }

    this.scope.setup(this.canvas)
    this.scope.activate()
    switch (this.type) {
      case 'TOPHALF':
        this.renderTopHalfCourt()
        break
      case 'FULL':
        this.renderFullCourt()
        break
    }
  }
}
