import React, { useRef } from 'react'
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonSelect,
  IonSelectOption,
  IonIcon,
  IonMenuButton,
} from '@ionic/react'
import styled from 'styled-components'
import css from 'classnames'
import { GenericButtonProps } from './GenericButton'
import { GenericButton } from '.'
import { chevronDown } from 'ionicons/icons'
import { useLastSessionURL, useTitle } from '../hooks'

export interface ChangerProps {
  options: [any, any][]
  value?: any
  headerText?: string
  onChange?: (id: any) => any
}

export interface HeaderProps extends React.ComponentProps<typeof IonHeader> {
  alwaysVisible?: boolean
  title?: string
  pageTitle?: string
  buttons?: GenericButtonProps | GenericButtonProps[]
  changer?: ChangerProps
  defaultBackURL?: string
  noBackButton?: boolean
}

const buttonDefaults = {
  fill: 'clear',
  color: 'white',
} as Partial<GenericButtonProps>

const Header: React.FC<HeaderProps> = ({
  title,
  className,
  buttons,
  pageTitle,
  alwaysVisible = !!title || !!buttons,
  children,
  changer,
  defaultBackURL,
  noBackButton,
  ...rest
}) => {
  const defaultURL = useLastSessionURL(defaultBackURL)
  const select = useRef<any>()
  const btns = Array.isArray(buttons) ? buttons : buttons ? [buttons] : []

  useTitle(pageTitle ?? title)

  return (
    <IonHeader
      className={css(className, { 'back-only': !alwaysVisible })}
      mode="md"
      {...rest}
    >
      <IonToolbar>
        <IonButtons slot="start">
          {!noBackButton && <IonBackButton defaultHref={defaultURL} />}
          <IonMenuButton autoHide={true} />
        </IonButtons>
        {title && (
          <IonTitle>
            <div
              className={css('title-container title', {
                changer: !!changer,
              })}
              onClick={() => select.current?.click()}
            >
              {title}
              {changer && <IonIcon icon={chevronDown} />}
            </div>
          </IonTitle>
        )}
        {children}
        <IonButtons slot="end">
          {btns.map((it: any, i: number) => (
            <GenericButton key={i} slot="end" {...buttonDefaults} {...it} />
          ))}
        </IonButtons>
      </IonToolbar>
      {changer && (
        <IonSelect
          ref={select}
          style={{ display: 'none' }}
          value={changer.value}
          onIonChange={(e: any) => changer.onChange?.(e.target.value)}
          interfaceOptions={{
            header: changer.headerText,
          }}
        >
          {changer.options?.map(([val, text]) => (
            <IonSelectOption key={val} value={val}>
              {text}
            </IonSelectOption>
          ))}
        </IonSelect>
      )}
    </IonHeader>
  )
}

export default styled(React.memo(Header))`
  &::after {
    display: none;
  }

  ion-toolbar {
    --background: var(--primary-gradient-90);
    color: white;
    ion-title {
      padding: 0 5px;
    }

    --border-color: transparent;
  }

  ion-menu-button,
  ion-back-button {
    color: white;
  }

  .title-container {
    align-items: center;
    border-radius: 18px;
    display: inline-flex;
    padding: 5px;
    transition: background-color ease-in-out 0.2s;

    &.changer {
      cursor: pointer;
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }

    ion-icon {
      color: var(--ion-color-light);
      margin-left: 10px;
    }
  }
`
