import React from 'react'
import styled from 'styled-components'

export default styled(({ color = 'primary', minWidth = 50, ...rest }) => (
  <div {...rest} />
))`
  background-color: ${(props) =>
    `var(--ion-color-${props.color ?? 'primary'})`};
  border-radius: 4px;
  color: ${(props) => `var(--ion-color-${props.color ?? 'primary'}-contrast)`};
  display: inline-block;
  font-size: 10px;
  font-weight: bold;
  margin-right: 5px;
  min-width: ${(props) => props.minWidth ?? 50}px;
  padding: 3px 10px;
  text-align: center;
`
