import React, { useImperativeHandle, useMemo, useRef, useState } from 'react'
import { FixedSizeList, ListChildComponentProps, areEqual } from 'react-window'
import { useData, useSSEListener } from '../hooks'
import AutoSizer from 'react-virtualized-auto-sizer'
import LoadingSpinner from './LoadingSpinner'
import { ZeroStateCTAProps } from './ZeroStateCTA'
import LoadError from './LoadError'
import {
  IonItem,
  IonLabel,
  IonRefresherContent,
  IonRefresher,
} from '@ionic/react'
import { ZeroStateCTA } from '.'

export interface VirtualListRenderProps extends ListChildComponentProps {
  highlighted?: boolean
}

export interface VirtualListProps extends React.ComponentProps<any> {
  url?: string
  items?: any[]
  renderItem: (props: VirtualListRenderProps) => any
  itemSize: number
  zeroState?: ZeroStateCTAProps
  ignoredEvents?: string[]
  noResultsText?: string
  model?: string
  orderBy?: string | string[] | [string[], string[]]
  removeOnInactive?: boolean
  filters?: any
  onLoad?: (data: any) => any
}

const VirtualList: React.ForwardRefRenderFunction<any, VirtualListProps> = (
  {
    url,
    items,
    model,
    renderItem,
    zeroState,
    orderBy,
    removeOnInactive,
    filters,
    noResultsText = 'No Results',
    ignoredEvents,
    itemSize,
    onLoad,
  },
  ref
) => {
  const [highlightedIndex, setHighlightedIndex] = useState(-1)
  const list = useRef<any>()
  const {
    updateData,
    replaceData,
    removeData,
    hasNext,
    loadNext,
    data,
    loading,
    error,
    refresh,
    isZeroState,
    isFiltered,
    isEmpty,
    orderData,
  } = useData(url, { paged: true, infinite: true, onLoad })

  useImperativeHandle(ref, () => ({
    appendData: (data: any) => updateData(data),
    prependData: (data: any) => updateData(data, true),
    replaceData: (data: any) => replaceData(data),
    removeData: (data: any) => removeData(data),
    hasNext,
    loadNext,
    data,
    loading,
    error,
    refresh,
    orderData,
    scrollToItem: (index: number, highlight?: boolean) => {
      if (typeof highlight === 'boolean') {
        setHighlightedIndex(index)
      } else {
        setHighlightedIndex(-1)
      }

      list.current?.scrollToItem?.(index, 'start')
    },
    setHighlight: (index: number) => {
      setHighlightedIndex(index)
    },
    clearHighlight: () => {
      setHighlightedIndex(-1)
    },
  }))

  const onIonRefresh = async (e: any) => {
    try {
      await refresh()
    } finally {
      e.target.complete()
    }
  }

  useSSEListener((e: any) => {
    if (model) {
      //Remove inactive rows if the component chooses the option
      //and the filters line up
      if (
        removeOnInactive &&
        e.detail.changes &&
        typeof e.detail.changes.isActive === 'boolean'
      ) {
        if (
          !filters ||
          typeof filters.isActive === 'undefined' ||
          (filters.isActive !== '!all' &&
            filters.isActive !== e.detail.data.isActive)
        ) {
          return removeData(e.detail.data)
        } else if (filters.isActive !== '!all') {
          return updateData(e.detail.data)
        }
      }

      //If the list has the ignored event, skip doing anything
      if (!ignoredEvents || ignoredEvents.indexOf(e.type) === -1) {
        if (e.type === 'db:destroy') {
          //If the model was destroyed, remove it
          removeData(e.detail.data)
        } else if (e.type === 'db:create') {
          //If the model was created, add it
          updateData(e.detail.data)
        } else {
          //Update the data with the updated version
          replaceData(e.detail.data)
        }
      }

      //If ordering is specified, reorder
      if (orderBy) {
        orderData(orderBy)
      }
    }
  }, model)

  const noResults = (
    <IonItem lines="none">
      <IonLabel className="ion-text-center no-results">
        {noResultsText}
      </IonLabel>
    </IonItem>
  )

  let cta = noResults

  if (zeroState) {
    cta = <ZeroStateCTA {...zeroState} />
  }

  const render = useMemo(
    () =>
      React.memo(
        (details: any) =>
          renderItem({
            ...details,
            highlighted: highlightedIndex === details.index,
          }),
        areEqual
      ),
    [renderItem, highlightedIndex]
  )

  return (
    <>
      <IonRefresher slot="fixed" onIonRefresh={onIonRefresh}>
        <IonRefresherContent />
      </IonRefresher>
      {isZeroState && cta}
      {isFiltered && isEmpty && noResults}
      {error ? (
        <LoadError data={error} refresh={refresh} />
      ) : loading ? (
        <LoadingSpinner />
      ) : (
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              ref={list}
              height={height}
              width={width}
              itemData={data}
              itemCount={data.length}
              itemSize={itemSize}
            >
              {render}
            </FixedSizeList>
          )}
        </AutoSizer>
      )}
    </>
  )
}

export default React.memo(React.forwardRef(VirtualList))
