import { http } from '../core'

interface CreateSubscriptionParams {
  teamBrandId: string
  planId: string
  coupon?: string
  token: string
}

interface UpdateSubscriptionParams {
  teamBrandId: string
  subscriptionId: string
  planId?: string
  coupon?: string
}

interface PurchaseParams {
  teamBrandId: string
  item: string
  qty: number
  coupon?: string
  token: string
}

class BillingService {
  getUsageEndpointURL(entity: 'team' | 'team-brand' | 'account', id: string) {
    return `/v1/billing/${entity}/${id}/usage/`
  }

  private async getUsage(
    entity: 'team' | 'team-brand' | 'account',
    id: string
  ) {
    const { data } = await http.authorizedRequest({
      method: 'GET',
      url: this.getUsageEndpointURL(entity, id),
    })

    return data
  }

  async getAccountUsage(accountId: string) {
    return await this.getUsage('account', accountId)
  }

  async getTeamBrandUsage(teamBrandId: string) {
    return await this.getUsage('team-brand', teamBrandId)
  }

  async getTeamUsage(teamId: string) {
    return await this.getUsage('team', teamId)
  }

  async createSubscription(params: CreateSubscriptionParams) {
    const { teamBrandId, ...rest } = params
    const { data } = await http.authorizedRequest({
      method: 'POST',
      url: `/v1/billing/${teamBrandId}/subscription/`,
      data: rest,
    })

    return data
  }

  async updateSubscription(params: UpdateSubscriptionParams) {
    const { subscriptionId, teamBrandId, ...rest } = params
    const { data } = await http.authorizedRequest({
      method: 'PATCH',
      url: `/v1/billing/${teamBrandId}/subscription/${subscriptionId}/`,
      data: rest,
    })

    return data
  }

  async cancelSubscription(teamBrandId: string, subscriptionId: string) {
    const { data } = await http.authorizedRequest({
      method: 'DELETE',
      url: `/v1/billing/${teamBrandId}/subscription/${subscriptionId}/`,
    })

    return data
  }

  async restoreSubscription(teamBrandId: string, subscriptionId: string) {
    const { data } = await http.authorizedRequest({
      method: 'POST',
      url: `/v1/billing/${teamBrandId}/subscription/${subscriptionId}/restore/`,
    })

    return data
  }

  async updateCard(teamBrandId: string, token: string, coupon?: string) {
    const { data } = await http.authorizedRequest({
      method: 'POST',
      url: `/v1/billing/${teamBrandId}/card/`,
      data: {
        token,
        coupon,
      },
    })

    return data
  }

  async purchase(params: PurchaseParams) {
    const { teamBrandId, ...rest } = params
    const { data } = await http.authorizedRequest({
      method: 'POST',
      url: `/v1/billing/${teamBrandId}/purchase/`,
      data: rest,
    })

    return data
  }
}

export default new BillingService()
