import React from 'react'
import styled from 'styled-components'
import GenericButton, { GenericButtonProps } from './GenericButton'
import { IonIcon } from '@ionic/react'

export interface ZeroStateCTAProps extends React.ComponentProps<any> {
  icon?: any
  title: string
  text?: string
  button?: GenericButtonProps
  readonly?: boolean
  small?: boolean
}

const ZeroStateCTA: React.FC<ZeroStateCTAProps> = ({
  icon,
  title,
  text,
  button,
  readonly,
  small,
  ...rest
}) => {
  return (
    <div {...rest}>
      {icon && <IonIcon className="cta-icon" icon={icon} />}
      <h2>{title}</h2>
      {!readonly && <p className="subtitle">{text}</p>}
      {!readonly && button && <GenericButton {...button} />}
    </div>
  )
}

export default styled(React.memo(ZeroStateCTA))`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  .cta-icon {
    color: var(--ion-color-basketball);
    font-size: ${(props) => (props.small ? 40 : 120)}px;
    margin-bottom: 10px;
  }

  h2 {
    padding: 0 10px;
    margin: 0;
    color: var(--ion-color-primary);
    font-size: ${(props) => (props.small ? '16px' : '30px')};
    text-align: center;
  }

  p {
    color: var(--ion-color-medium);
    font-size: ${(props) => (props.small ? '10px' : '14px')};
    margin: 5px 0 15px;
    padding: 0 10px;
    text-align: center;
  }
`
