import React, { useImperativeHandle, useRef, useMemo, useContext } from 'react'
import { IonSelect, IonSelectOption } from '@ionic/react'
import { teamGameEventService } from '../services'
import { ScoringPageEvent } from '../types/interfaces'
import { ScoringContext } from '../contexts/ScoringContext'
import helpers from '../util/helpers'

export interface ScoringRosterSelectProps extends React.ComponentProps<any> {
  onSelected?: (id: string) => any
  event: ScoringPageEvent
  origin: ScoringPageEvent
  isHomeTeam: boolean
}

const ScoringRosterSelect: React.ForwardRefRenderFunction<
  any,
  ScoringRosterSelectProps
> = ({ onSelected, isHomeTeam, event, origin, ...rest }, ref) => {
  const { state } = useContext(ScoringContext)
  const select = useRef<any>()
  useImperativeHandle(ref, () => select.current)
  const roster = useMemo(
    () => (isHomeTeam ? state.eligibleHome : state.eligibleAway),
    [state, isHomeTeam]
  )
  return (
    <IonSelect
      ref={select}
      style={{ display: 'none' }}
      onIonChange={(e: any) => onSelected?.(e.target.value)}
      {...rest}
    >
      {teamGameEventService.filterRoster(roster, event, origin)?.map((it) => (
        <IonSelectOption key={it.id} value={it.id}>
          {helpers.playerDisplay(it.jersey, `${it.lastName}, ${it.firstName}`)}
        </IonSelectOption>
      ))}
    </IonSelect>
  )
}

export default React.forwardRef(ScoringRosterSelect)
