import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from 'react'
import styled from 'styled-components'
import { GenericButton } from '.'
import ScoringEventOptions from './ScoringEventOptions'
import ScoringEventPlayerSelection from './ScoringEventPlayerSelection'
import ScoringEventRelatedEvents from './ScoringEventRelatedEvents'
import { usePortal, useShortcuts } from '../hooks'
import {
  ScoringPageEvent,
  TeamGameEventType,
  TeamGameEventOption,
  ScoringPlayerSelection,
  ScoringFreethrowAttempt,
} from '../types/interfaces'
import { teamGameEventService } from '../services'
import ScoringEventFreethrows from './ScoringEventFreethrows'
import { ScoringContext, renderEventSummary } from '../contexts/ScoringContext'
import { TeamGameEventTypes } from '../services/team-game-event'
import css from 'classnames'
import notifications from '../core/notifications'
import { IonIcon } from '@ionic/react'
import { flag, flagOutline, arrowBack } from 'ionicons/icons'
import ScoringEventInitiators from './ScoringEventInitiators'
import helpers from '../util/helpers'

export interface ScoringEventDetailPopoverProps
  extends React.ComponentProps<any> {}

function eventHasOptions(event: ScoringPageEvent) {
  return (
    !!event.type.options &&
    Object.keys(event.options ?? {}).length < event.type.options.length
  )
}

const countdownSeconds = 5

const ScoringEventDetailPopover: React.FC<ScoringEventDetailPopoverProps> = ({
  className,
  ...rest
}) => {
  const { state, dispatch, gameClock, videoPlayer } = useContext(ScoringContext)
  const { events: existingEvents, teamGameId } = state
  const { Portal } = usePortal({
    bindTo: document.getElementById('app') ?? document.body,
  })
  const closeTimer = useRef<any>()
  const countdown = useRef<any>(10)
  const [origin, setOrigin] = useState(state.activeEvent!)
  const [changeOriginType, setChangeOriginType] = useState(false)
  const [isUserFlagged, setIsUserFlagged] = useState(false)
  const [countdownDisplay, setCountdownDisplay] = useState(countdown.current)
  const [events, setEvents] = useState<ScoringPageEvent[]>([])
  const [currentEvent, setCurrentEvent] = useState<ScoringPageEvent>(origin)
  const [selectPlayer, setSelectPlayer] = useState(true)
  const [freethrowPlayer, setFreethrowPlayer] = useState<any>()
  const [freethrowAttempts, setFreethrowAttempts] = useState<any>()
  const [freethrowAttempt, setFreethrowAttempt] = useState<any>()
  const created = useRef<any>(false)
  const [hasOptions, setHasOptions] = useState(eventHasOptions(origin))
  const foul = useMemo(
    () =>
      currentEvent.type.id === 'foul'
        ? currentEvent
        : events?.find((it) => it.type.id === 'foul'),
    [events, currentEvent]
  )

  const isOneAndOne = useMemo(() => foul?.options?.award === '1&1', [foul])
  const isOneAndOneComplete = useMemo(() => {
    const first = freethrowAttempts?.[0]
    return (
      isOneAndOne &&
      ((first?.status === 'missed' && first?.reboundPlayer) ||
        first?.status === 'violation')
    )
  }, [isOneAndOne, freethrowAttempts])

  const foulAward = useMemo(() => {
    const award = foul?.options?.award
    if (!award || award === 0) {
      return 0
    }

    if (isOneAndOne) {
      return 2
    }

    return award
  }, [foul, isOneAndOne])

  const freethrowsCompleted = useMemo(
    () =>
      !freethrowAttempt &&
      (isOneAndOneComplete ||
        (freethrowAttempts?.length &&
          freethrowAttempts.every(
            (it: ScoringFreethrowAttempt) =>
              !!it.status &&
              (!it.allowRebound || it.status !== 'missed' || !!it.reboundPlayer)
          ))),
    [freethrowAttempts, freethrowAttempt, isOneAndOneComplete]
  )

  const freethrows = useMemo(
    () =>
      currentEvent.type.id === 'foul' &&
      !!currentEvent.options?.award &&
      !freethrowsCompleted,
    [currentEvent, freethrowsCompleted]
  )

  const isFreethrowRebound = useMemo(
    () =>
      freethrowAttempts &&
      freethrowAttempt?.status === 'missed' &&
      freethrowAttempt.allowRebound &&
      !freethrowAttempt.reboundPlayer,
    [freethrowAttempt, freethrowAttempts]
  )

  useEffect(() => {
    if (origin.type.id === 'shot_made') {
      setFreethrowPlayer({
        teamGamePersonnel: origin.teamGamePersonnel,
        teamGamePersonnelId: origin.teamGamePersonnelId,
        teamId: origin.teamId,
        isHomeTeam: origin.isHomeTeam,
        jerseyName: origin.jerseyName,
        jerseyNumber: origin.jerseyNumber,
      })
    } else if (
      state.isSingleTeam &&
      state.isSingleTeamHome === currentEvent.isHomeTeam
    ) {
      setFreethrowPlayer({
        isHomeTeam: !state.isSingleTeamHome,
        teamId: state.teamId,
      })
    }

    setFreethrowAttempts((val: any) => {
      if (val?.length) {
        return val
      }

      return [...Array(foulAward)].map((it, i) => ({
        attempt: i,
        status: null,
        reboundPlayer: null,
        allowRebound: isOneAndOne || (i === foulAward - 1 && currentEvent?.options?.type != 'technical') ,
      }))
    })
  }, [
    origin,
    foulAward,
    isOneAndOne,
    state.isSingleTeam,
    state.isSingleTeamHome,
    state.teamId,
    currentEvent,
  ])

  useEffect(() => {
    if (isOneAndOneComplete) {
      return setFreethrowAttempt(null)
    }

    const next = freethrowAttempts?.find(
      (it: any) =>
        !it.status ||
        (it.status === 'missed' && it.allowRebound && !it.reboundPlayer)
    )
    setFreethrowAttempt(next)
  }, [freethrowAttempts, isOneAndOneComplete])

  const showClose = !selectPlayer && !hasOptions && !freethrows
  const createEvents = useCallback(async () => {
    if (created.current) {
      return
    }

    origin.userFlagged = isUserFlagged

    await teamGameEventService.bulkCreate(
      teamGameId,
      origin,
      {
        teamGameId,
        teamVideoId: state.currentTeamVideoId,
      },
      events
    )
    created.current = true
  }, [origin, events, isUserFlagged, state.currentTeamVideoId, teamGameId])

  const createFreethrows = useCallback(async () => {
    if (!freethrowAttempts?.length) {
      return
    }

    const events = freethrowAttempts?.filter(
      (it: ScoringFreethrowAttempt) => !!it.status
    )

    for (var i = 0; i < events.length; i++) {
      const it = events[i]
      let type

      switch (it.status) {
        case 'made':
          type = TeamGameEventTypes.freethrow_made
          break
        case 'missed':
          type = TeamGameEventTypes.freethrow_miss
          break
        case 'violation':
          type = TeamGameEventTypes.freethrow_violation
          break
      }

      const isViolation = it.status === 'violation'

      const event = {
        ...origin,
        type,
        order: i,
        pointValue: 1,
        clockTime: it.clockTime,
        videoTime: it.videoTime,
        teamGamePersonnel: isViolation
          ? null
          : freethrowPlayer.teamGamePersonnel,
        teamGamePersonnelId: isViolation
          ? null
          : freethrowPlayer.teamGamePersonnel?.id,
        teamId: freethrowPlayer.teamId,
        isHomeTeam: freethrowPlayer.isHomeTeam,
      } as ScoringPageEvent

      let rebound = []

      if (it.reboundPlayer) {
        rebound.push({
          ...origin,
          type: teamGameEventService.types.rebound,
          teamGamePersonnelId: it.reboundPlayer.teamGamePersonnel?.id,
          teamId: it.reboundPlayer.teamId,
          isHomeTeam: it.reboundPlayer.isHomeTeam,
        } as ScoringPageEvent)
      }

      await teamGameEventService.bulkCreate(
        teamGameId,
        event,
        {
          teamGameId,
          teamVideoId: state.currentTeamVideoId,
        },
        rebound
      )
    }
  }, [
    teamGameId,
    state.currentTeamVideoId,
    freethrowAttempts,
    freethrowPlayer,
    origin,
  ])

  const onDidDismiss = useCallback(() => {
    dispatch({
      type: 'set',
      value: {
        activeEvent: null,
        locked: state.locked || events.length > 0,
      },
    })

    const wasFoulOrDeadBall = [origin, ...(events ?? [])]?.some(
      (it) => it.type.id === TeamGameEventTypes.foul.id || it.options?.deadBall
    )

    if (!origin.id) {
      if (!state.isLive && !wasFoulOrDeadBall) {
        gameClock.current?.start?.(null, false)
      }

      videoPlayer.current?.play?.()
    }
  }, [
    dispatch,
    events,
    state.locked,
    gameClock,
    videoPlayer,
    state.isLive,
    origin,
  ])

  const dismiss = useCallback(async () => {
    clearInterval(closeTimer.current)
    ;(async () => {
      try {
        await createEvents()
        await createFreethrows()
      } catch (e) {
        notifications.errorToast(e)
        throw e
      }
    })()
    onDidDismiss()
  }, [onDidDismiss, createEvents, createFreethrows])

  const cancel = useCallback(() => {
    if (events.length > 0) {
      setCurrentEvent(origin)
      setSelectPlayer(false)
      setEvents([])
    } else {
      clearInterval(closeTimer.current)
      onDidDismiss()
    }
  }, [onDidDismiss, origin, events])

  const cancelOrDismiss = useCallback(() => {
    if (showClose) {
      dismiss()
    } else {
      cancel()
    }
  }, [dismiss, cancel, showClose])

  useShortcuts({
    q: cancelOrDismiss,
    '`': () => setIsUserFlagged((cur: boolean) => !cur),
  })

  useEffect(() => {
    const currentTimer = closeTimer.current

    const clear = () => {
      clearInterval(currentTimer)
      countdown.current = countdownSeconds
      closeTimer.current = null
    }

    if (selectPlayer || hasOptions || freethrows) {
      clear()
      setCountdownDisplay(countdownSeconds)
    } else if (!currentTimer) {
      closeTimer.current = setInterval(() => {
        countdown.current--
        setCountdownDisplay(countdown.current)
        if (countdown.current === 0) {
          dismiss()
          clear()
        }
      }, 1000)
    }
  }, [selectPlayer, hasOptions, freethrows, dismiss])

  useEffect(() => {
    if (!hasOptions) {
      setCurrentEvent(origin)
    }
  }, [hasOptions, origin])

  const onFreethrowAttemptUpdate = (attempt: ScoringFreethrowAttempt) => {
    setFreethrowAttempts((attempts: any) => [...attempts])
    if (
      attempt.status === 'missed' &&
      (isOneAndOne ||
        freethrowAttempts.indexOf(attempt) === freethrowAttempts.length - 1)
    ) {
      gameClock.current?.start?.(null, false)
    }
  }

  const onPlayerSelected = (selection: ScoringPlayerSelection) => {
    if (freethrows && !freethrowPlayer) {
      return setFreethrowPlayer(selection)
    }

    if (
      freethrowAttempt &&
      freethrowAttempt.allowRebound &&
      freethrowAttempt.status === 'missed' &&
      !freethrowAttempt.reboundPlayer
    ) {
      freethrowAttempt.reboundPlayer = selection
      onFreethrowAttemptUpdate(freethrowAttempt)
      return
    }

    //if editing player, just dismiss immediately
    if (currentEvent.id) {
      if (selection.teamGamePersonnel) {
        teamGameEventService.update(currentEvent.id, {
          teamGamePersonnelId: selection.teamGamePersonnel?.id,
        })
      }

      dispatch({
        type: 'set',
        value: {
          activeEvent: null,
        },
      })

      return
    }

    setCurrentEvent((event: ScoringPageEvent) => {
      event.jerseyName = selection.jerseyName
      event.jerseyNumber = selection.jerseyNumber
      event.teamGamePersonnel = selection.teamGamePersonnel
      event.teamGamePersonnelId = selection.teamGamePersonnel?.id
      event.teamId = selection.teamId
      event.isHomeTeam = selection.isHomeTeam === true

      if (event.isOrigin) {
        setOrigin({ ...event })
      }

      return event
    })

    setSelectPlayer(false)
    setHasOptions(eventHasOptions(currentEvent))
  }

  const onNewRelatedEvent = (type: TeamGameEventType) => {
    const event = {
      type,
      league: origin.league,
      isHomeTeam: type.sharesOriginTeam
        ? !!origin.isHomeTeam
        : !origin.isHomeTeam,
      court: origin.court,
      pointValue: origin.pointValue,
      period: origin.period,
      clockTime: origin.clockTime,
      videoTime: origin.videoTime,
      location: origin.location,
      isOrigin: false,
    } as ScoringPageEvent

    if (eventHasOptions(event)) {
      const defaults = event.type.options?.reduce(
        (obj: any, it: TeamGameEventOption) => {
          const def = it.values.find(
            (it) => it.defaultFor && it.defaultFor.indexOf(origin.type.id) > -1
          )
          if (def) {
            obj[it.name] = def.id
          }

          return obj
        },
        {}
      )

      event.options = defaults
    }

    setEvents((events) => [...events, event])
    setCurrentEvent(event)
    setSelectPlayer(true)
  }

  const onEditEvent = (event: ScoringPageEvent) => {
    setCurrentEvent(event)
    setSelectPlayer(true)
  }

  const onEditFreethrow = (attempt: ScoringFreethrowAttempt) => {
    setFreethrowAttempt(attempt)
  }

  const onOptionSelected = (option: TeamGameEventOption, value: any) => {
    setCurrentEvent((event: ScoringPageEvent) => {
      const options = event.options ?? {}
      options[option.name] = value
      event.options = options
      setHasOptions(eventHasOptions(event))

      if (event.isOrigin) {
        setOrigin({ ...event })
      }

      return { ...event }
    })
  }

  let content

  const playerEventDetails = useMemo(
    () => ({
      ...currentEvent,
      isHomeTeam:
        freethrows && !freethrowPlayer
          ? !currentEvent.isHomeTeam
          : isFreethrowRebound
          ? !freethrowPlayer.isHomeTeam
          : !!currentEvent.isHomeTeam,
    }),
    [currentEvent, freethrows, freethrowPlayer, isFreethrowRebound]
  )

  if (changeOriginType) {
    content = (
      <ScoringEventInitiators
        origin={origin!}
        onUpdate={(updated: ScoringPageEvent) => {
          setOrigin({ ...updated })
          setChangeOriginType(false)
        }}
      />
    )
  } else if (
    selectPlayer ||
    (freethrows && !freethrowPlayer) ||
    isFreethrowRebound
  ) {
    content = (
      <ScoringEventPlayerSelection
        origin={origin}
        event={playerEventDetails}
        onSelection={onPlayerSelected}
        isFreethrowRebound={isFreethrowRebound}
        subtitle={
          isFreethrowRebound
            ? 'Freethrow Rebounded By...'
            : freethrows && !freethrowPlayer
            ? 'Freethrow Attempts By...'
            : ''
        }
      />
    )
  } else if (hasOptions) {
    content = (
      <ScoringEventOptions
        origin={origin}
        event={currentEvent}
        events={existingEvents}
        onOptionSelected={onOptionSelected}
      />
    )
  } else if (freethrows) {
    content = (
      <ScoringEventFreethrows
        attempt={freethrowAttempt}
        attempts={freethrowAttempts}
        onUpdate={onFreethrowAttemptUpdate}
        onAttemptClicked={setFreethrowAttempt}
      />
    )
  } else {
    content = (
      <ScoringEventRelatedEvents
        origin={origin}
        current={currentEvent}
        events={events}
        freethrows={freethrowAttempts}
        onEditFreethrow={onEditFreethrow}
        onNewEvent={onNewRelatedEvent}
        onEditEvent={onEditEvent}
        isOneAndOne={isOneAndOne}
      />
    )
  }

  return (
    <Portal>
      <div
        style={{
          top: Math.min(
            state.activeEvent?.location?.screenY ?? 0,
            window.innerHeight - 420
          ),
          left: Math.min(
            state.activeEvent?.location?.screenX ?? 0,
            window.innerWidth - 180
          ),
        }}
        className={css(className, {
          'with-arrow':
            window.innerHeight - 420 >
              (state.activeEvent?.location?.screenY ?? 0) &&
            window.innerWidth - 160 >
              (state.activeEvent?.location?.screenX ?? 0),
        })}
        {...rest}
      >
        <div
          className={css('event-heading', {
            'with-back':
              events.length === 0 &&
              !origin.teamGamePersonnelId &&
              !changeOriginType,
          })}
        >
          <IonIcon
            className="back-button"
            icon={arrowBack}
            onClick={() => setChangeOriginType(true)}
          />
          <div className="event-heading--text">
            {changeOriginType ? (
              <div className="origin">Choose initiating event</div>
            ) : (
              renderEventSummary(origin, state, true)
            )}
            {events.map((it) => renderEventSummary(it, state))}
            {freethrowAttempts
              ?.filter((it: ScoringFreethrowAttempt) => !!it.status)
              ?.map((it: ScoringFreethrowAttempt) => (
                <div key={it.attempt}>
                  Freethrow #{it.attempt + 1} {it.status}
                  {it.reboundPlayer
                    ? it.reboundPlayer.jerseyNumber
                      ? `, Rebound by ${helpers.playerDisplay(
                          it.reboundPlayer.jerseyNumber,
                          it.reboundPlayer.jerseyName
                        )}`
                      : `, Rebounded by ${
                          it.reboundPlayer.isHomeTeam
                            ? state.teamGame?.homeTeamFullName
                            : state.teamGame?.awayTeamFullName
                        }`
                    : ''}
                </div>
              ))}
          </div>
          <IonIcon
            className="origin-flag"
            icon={isUserFlagged ? flag : flagOutline}
            color={isUserFlagged ? 'basketball' : 'light'}
            title="Flag this event for later review. Keyboard shortcut: '`'"
            onClick={() => setIsUserFlagged((cur: boolean) => !cur)}
          />
        </div>
        {content}
        {showClose && (
          <GenericButton
            fill="clear"
            size="small"
            className="close-button"
            onClick={dismiss}
          >
            Close ({countdownDisplay}) (q)
          </GenericButton>
        )}
        {!showClose && (
          <GenericButton
            size="small"
            fill="clear"
            color="danger"
            className="cancel-button"
            onClick={cancel}
          >
            Cancel (q)
          </GenericButton>
        )}
      </div>
    </Portal>
  )
}

export default styled(React.memo(ScoringEventDetailPopover))`
  background: var(--ion-color-dark);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  color: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  position: absolute;
  transform: translate(-50%, 20px);
  opacity: 0.9;
  width: 320px;

  .origin-flag {
    cursor: pointer;
    font-size: 20px;
  }

  .event-heading {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-column-gap: 5px;
    border-bottom: solid 1px #43474f;
    flex: 0 0 auto;
    font-size: 13px;
    padding: 15px 15px 10px;

    &.with-back {
      grid-template-columns: auto 1fr auto;
      .back-button {
        display: block;
      }
    }

    .back-button {
      cursor: pointer;
      font-size: 20px;
      display: none;
    }

    .event-heading--text > div {
      margin-bottom: 3px;

      &.origin {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }

  .close-button {
    box-sizing: border-box;
    flex: 0 0 auto;
    margin: 0 15px 15px;
  }

  &.with-arrow::before {
    border: solid 20px transparent;
    border-bottom-color: var(--ion-color-dark);
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
  }

  .cancel-button {
    margin: 0 15px 5px;
  }
`
