import React from 'react'
import {
  IonPopover,
  IonItem,
  IonList,
  IonIcon,
  IonItemDivider,
} from '@ionic/react'
import styled from 'styled-components'
import css from 'classnames'
import { checkmark } from 'ionicons/icons'

export interface PopoverSelectorProps {
  items?: [any, any, any?][]
  onSelected?: (item: any, meta?: any) => any
  onDidDismiss?: () => any
  isOpen: boolean
  value?: any
  header?: any
}

const PopoverSelector: React.FC<PopoverSelectorProps> = ({
  items,
  onSelected,
  onDidDismiss,
  isOpen,
  value,
  header,
  ...rest
}) => {
  const select = (val: any, meta?: any) => () => {
    onSelected?.(val, meta)
    onDidDismiss?.()
  }

  return (
    <IonPopover isOpen={isOpen} onDidDismiss={onDidDismiss} {...rest}>
      <IonList>
        {header && <IonItemDivider sticky>{header}</IonItemDivider>}
        {items?.map(([val, text, meta]) => (
          <IonItem
            className={css('ion-activatable pointer', {
              selected: val === value,
            })}
            key={val}
            onClick={select(val, meta)}
          >
            {text}
            {val === value && (
              <IonIcon slot="end" color="alternate" icon={checkmark} />
            )}
          </IonItem>
        ))}
      </IonList>
    </IonPopover>
  )
}

export default styled(React.memo(PopoverSelector))`
  --min-width: 500px;
  --max-height: 60vh;

  @media screen and (max-width: 500px) {
    --min-width: 90vw;
  }
`
