import React, { useContext, useMemo } from 'react'
import styled from 'styled-components'
import { MarketingLinkButton } from '.'
import { AppContext } from '../contexts/AppContext'
import { arrowForward } from 'ionicons/icons'

export interface MarketingSignupButtonProps extends React.FC<any> {
  noSubtext?: boolean
}

const MarketingSignupButton: React.FC<any> = ({ noSubtext, ...rest }) => {
  const { state } = useContext(AppContext)
  const link = useMemo(() => {
    return state.isAuthenticated ? '/a/dashboard/' : '/register/'
  }, [state])

  return (
    <div {...rest}>
      <MarketingLinkButton
        to={link}
        className="cta"
        icon={arrowForward}
        iconSide="right"
      >
        {state.ready ? (
          <span>{state.isAuthenticated ? 'Go to App' : 'Sign Up'}</span>
        ) : (
          <span>&nbsp;</span>
        )}
      </MarketingLinkButton>
      {!noSubtext && state.ready && !state.isAuthenticated && (
        <span className="subtext">Free trial, no credit card required.</span>
      )}
    </div>
  )
}
export default styled(MarketingSignupButton)`
  .cta {
    display: inline-block;
  }

  .subtext {
    margin-left: 10px;

    @media (max-width: 990px) {
      display: block;
    }
  }
`
