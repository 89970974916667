interface RGBChannels {
  red: number
  blue: number
  green: number
}

class ColorsUtil {
  hexToRGB(h?: string) {
    if (!h) {
      return null
    }

    let r: any = 0,
      g: any = 0,
      b: any = 0

    if (h.length === 4) {
      r = '0x' + h[1] + h[1]
      g = '0x' + h[2] + h[2]
      b = '0x' + h[3] + h[3]
    } else if (h.length === 7) {
      r = '0x' + h[1] + h[2]
      g = '0x' + h[3] + h[4]
      b = '0x' + h[5] + h[6]
    }

    return `${+r}, ${+g}, ${+b}`
  }
  hexToChannels(hex: string) {
    const matches = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    if (matches) {
      const red = parseInt(matches[1], 16)
      const green = parseInt(matches[2], 16)
      const blue = parseInt(matches[3], 16)
      return { red, green, blue }
    }
  }
  hslToChannels(hsl: string) {
    const matches = /(\d{1,3}),\s?(\d{1,3})%,\s?(\d{1,3})%/.exec(hsl)
    if (matches) {
      var h = parseInt(matches[1]),
        s = parseInt(matches[2]),
        l = parseInt(matches[3])
      if (h > 0) {
        h /= 1000
      }
      if (s > 0) {
        s /= 100
      }
      if (l > 0) {
        l /= 100
      }
      let c = (1 - Math.abs(2 * l - 1)) * s
      let hp = h / 60.0
      let x = c * (1 - Math.abs((hp % 2) - 1))
      let rgb1 = [0, 0, 0]
      if (isNaN(h)) rgb1 = [0, 0, 0]
      else if (hp <= 1) rgb1 = [c, x, 0]
      else if (hp <= 2) rgb1 = [x, c, 0]
      else if (hp <= 3) rgb1 = [0, c, x]
      else if (hp <= 4) rgb1 = [0, x, c]
      else if (hp <= 5) rgb1 = [x, 0, c]
      else if (hp <= 6) rgb1 = [c, 0, x]
      let m = l - c * 0.5
      const red = Math.round(255 * (rgb1[0] + m))
      const green = Math.round(255 * (rgb1[1] + m))
      const blue = Math.round(255 * (rgb1[2] + m))
      return { red, green, blue }
    }
  }
  rgbStrToChannels(str: string) {
    const matches = /(\d{1,3}),\s?(\d{1,3}),\s?(\d{1,3})/.exec(str) as any
    const red = parseInt(matches?.[1]) ?? 0
    const green = parseInt(matches?.[2]) ?? 0
    const blue = parseInt(matches?.[3]) ?? 0
    return { red, green, blue }
  }
  getBrightness(channels: RGBChannels) {
    return (
      (0.299 * channels.red + 0.587 * channels.green + 0.114 * channels.blue) /
      255
    )
  }
  isLight(color: string) {
    var channels
    if (color.match(/^rgb/i)) {
      channels = this.rgbStrToChannels(color)
    } else if (color.match(/^hsl/i)) {
      channels = this.hslToChannels(color)
    } else if (color.match(/^#/i)) {
      channels = this.hexToChannels(color)
    }
    if (!channels) {
      return false
    }
    const brightness = this.getBrightness(channels)
    return brightness > 0.5
  }
  isDark(color: string) {
    return !this.isLight(color)
  }
}

export default new ColorsUtil()
