import React, { useRef, useImperativeHandle, useState, useEffect } from 'react'
import FormField, { FormFieldProps } from './FormField'
import { IonDatetime } from '@ionic/react'
import styled from 'styled-components'
import notifications from '../core/notifications'

export interface FormDateFieldProps
  extends React.ComponentProps<typeof IonDatetime> {
  label?: string
  position?: FormFieldProps['position']
  icon?: any
  save?: (val: string, e: any) => Promise<any>
  saving?: boolean
  readonly?: boolean
  noIndicator?: boolean
  withTime?: boolean
  required?: boolean
}

const FormDateField: React.ForwardRefRenderFunction<any, FormDateFieldProps> = (
  {
    className,
    icon,
    label,
    position,
    save,
    value,
    children,
    saving,
    readonly,
    noIndicator,
    withTime,
    ...rest
  },
  ref
) => {
  const [error, setError] = useState(false)
  const [isSaving, setIsSaving] = useState(saving)
  const [inputValue, setInputValue] = useState(value)
  const input = useRef<any>()
  useImperativeHandle(ref, () => input.current)

  const onIonChange = async (e: any) => {
    const newValue = e.target.value
    setInputValue(newValue)

    if (save && value !== newValue) {
      setIsSaving(!noIndicator)
      setError(false)
      try {
        await save(newValue, e)
      } catch (e) {
        notifications.errorToast(e)
        setError(true)
      } finally {
        setIsSaving(false)
      }
    }
  }

  useEffect(() => {
    setIsSaving(saving)
  }, [saving])

  useEffect(() => {
    setInputValue(value)
  }, [value])

  return (
    <FormField
      label={label}
      icon={icon}
      position="stacked"
      saving={isSaving}
      error={error}
      className={className}
      required={rest.required}
    >
      <IonDatetime
        ref={input}
        onIonChange={onIonChange}
        value={inputValue}
        readonly={readonly}
        displayFormat={withTime ? 'MMM/DD/YY h:mma' : 'MMM/DD/YY'}
        {...rest}
      />
      {children}
    </FormField>
  )
}

export default styled(React.memo(React.forwardRef(FormDateField)))``
