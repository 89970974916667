import React, {
  useRef,
  useImperativeHandle,
  useState,
  useLayoutEffect,
  useEffect,
  useMemo,
} from 'react'
import { IonInput, IonItem, IonIcon } from '@ionic/react'
import styled from 'styled-components'
import usePortal from 'react-useportal'
import { List, Content } from '.'
import css from 'classnames'
import { useShortcuts } from '../hooks'
import { search } from 'ionicons/icons'

export interface AutoCompleteProps
  extends React.ComponentProps<typeof IonInput> {
  url?: string
  onItemSelected?: (id: string, value: string) => any
  renderItemLabel?: (item: any) => any
  idValue?: string
}

const AutoCompleteResults = styled(
  ({
    url,
    inputValue,
    idValue,
    isOpen,
    setIsOpen,
    position,
    onItemSelected,
    renderItemLabel = (it: any) => it.id,
    ...rest
  }: any) => {
    const content = useRef<any>()
    const infiniteScroll = useRef<any>()
    const [selectedIndex, setSelectedIndex] = useState(0)

    const setIndex = (offset: number) => () => {
      const newIndex =
        offset > 0
          ? Math.min(
              (infiniteScroll.current?.count ?? 1) - 1,
              selectedIndex + 1
            )
          : Math.max(0, selectedIndex - 1)
      setSelectedIndex(newIndex)
      content.current?.scrollToPoint(0, Math.max(newIndex - 1, 0) * 50, 100)
    }

    const chooseItem = () => {
      const item = infiniteScroll.current?.data?.[selectedIndex]
      if (item) {
        onItemSelected?.(item.id, renderItemLabel(item))
      }
    }

    const searchUrl = useMemo(
      () =>
        url && inputValue && isOpen ? `${url}?search=${inputValue}` : undefined,
      [url, inputValue, isOpen]
    )

    useEffect(() => {
      setSelectedIndex(0)
    }, [searchUrl])

    useShortcuts({
      up: setIndex(-1),
      down: setIndex(1),
      esc: () => setIsOpen(false),
      enter: chooseItem,
      tab: chooseItem,
    })

    return (
      <div {...rest}>
        <Content ref={content}>
          <List
            ref={infiniteScroll}
            url={searchUrl}
            renderItem={(item: any, i: number) => {
              const label = renderItemLabel(item)
              return (
                <IonItem
                  className={css('ion-activatable', {
                    selected: selectedIndex === i,
                  })}
                  key={item.id}
                  onClick={() => onItemSelected?.(item.id, label)}
                  lines="full"
                >
                  {label}
                </IonItem>
              )
            }}
          />
        </Content>
      </div>
    )
  }
)`
  background: var(--ion-color-light);
  border: solid 1px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  position: absolute;
  overflow: hidden;
  top: ${(props) =>
    props.position ? props.position.y + props.position.height : 0}px;
  left: ${(props) => (props.position ? props.position.x : 0)}px;
  height: 100%;
  max-height: 150px;
  width: ${(props) => props.position.width ?? 300}px;
  z-index: 1;

  ion-content {
    --background: var(--ion-color-light);
  }

  ion-item {
    cursor: pointer;
    --ion-item-background: var(--ion-color-light);
    --ion-item-border-color: white;
    --height: 100%;

    &.selected {
      --background: rgba(var(--ion-color-primary-rgb), 0.2);
    }
  }
`

const AutoComplete: React.ForwardRefRenderFunction<any, AutoCompleteProps> = (
  { onIonChange, url, value, onItemSelected, renderItemLabel, ...rest },
  ref
) => {
  const { Portal } = usePortal()
  const input = useRef<any>()
  const timer = useRef<any>()
  const ignore = useRef<any>(false)
  const [position, setPosition] = useState<any>()
  const [isOpen, setIsOpen] = useState(false)
  const [inputValue, setInputValue] = useState<any>(value)
  useImperativeHandle(ref, () => input)

  useLayoutEffect(() => {
    const rect = input.current?.getBoundingClientRect()
    setPosition(rect)
  }, [input, isOpen])

  const onChange = (e: any) => {
    //Ignore if changed from selection
    if (ignore.current) {
      ignore.current = false
      return
    }

    const val = e.target.value
    clearTimeout(timer.current)
    timer.current = setTimeout(() => {
      setIsOpen(val.length > 0)
      setInputValue(val)
      onIonChange?.(e)
    }, 500)
  }

  const onSelected = (id: any, val: any) => {
    ignore.current = true
    onItemSelected?.(id, val)
    setIsOpen(false)
    setInputValue(val)
  }

  return (
    <>
      <IonInput ref={input} onIonChange={onChange} value={inputValue} {...rest}>
        <IonIcon icon={search} slot="start" />
      </IonInput>
      {isOpen && (
        <Portal>
          <AutoCompleteResults
            isOpen={isOpen}
            inputValue={inputValue}
            url={url}
            position={position}
            onItemSelected={onSelected}
            setIsOpen={setIsOpen}
            renderItemLabel={renderItemLabel}
          />
        </Portal>
      )}
    </>
  )
}

export default styled(React.memo(React.forwardRef(AutoComplete)))`
  ion-icon {
    color: var(--ion-color-primary);
    margin-right: 5px;
  }
`
