import ModelBaseService from './model-base'
import { DataField } from '../util/data-field'
import { listsService } from '.'
import { http, dialogs } from '../core'
import loading from '../core/loading'
import notifications from '../core/notifications'
import { TeamPersonnel } from '../types/models'

export class TeamPersonnelService extends ModelBaseService {
  protected modelName = 'team-personnel'

  public get fields(): DataField[] {
    return []
  }

  public get filters(): DataField[] | undefined {
    return [
      {
        label: 'Starter',
        key: 'isStarter',
        type: 'filter-select',
        options: [
          [true, 'Yes'],
          [false, 'No'],
        ],
      },
      {
        label: 'Position',
        key: 'position',
        type: 'filter-select',
        options: listsService.positions,
      },
    ]
  }

  async isInFutureGames(id: string) {
    const { data } = await http.authorizedRequest({
      method: 'GET',
      url: this.getEndpointUrl(id, 'games'),
    })

    return data.inFutureGames
  }

  async removeFromFutureGames(id: string) {
    await http.authorizedRequest({
      method: 'DELETE',
      url: this.getEndpointUrl(id, 'games'),
    })
  }

  async checkFutureGames(changes: any, res: TeamPersonnel) {
    const fullName = res.teamBrandPersonnel.fullName
    if (changes.isActive === false) {
      const isInFutureGames = await this.isInFutureGames(res.id)
      if (isInFutureGames) {
        const onConfirm = async () => {
          try {
            await loading.create()
            await this.removeFromFutureGames(res.id)
            notifications.toast(
              `${fullName} has been removed from future games.`,
              'Success'
            )
          } catch (e) {
            notifications.errorToast(e)
          } finally {
            loading.dismiss()
          }
        }

        await dialogs.confirmYesNo(
          `${fullName} is in future games. Would you like to remove the player from them?`,
          'Warning',
          onConfirm
        )
      }
    }
  }
}

export default new TeamPersonnelService()
