import { userService, courtService, leagueService } from '../services'

class CoreData {
  async load() {
    const promises = await Promise.all([
      userService.load(),
      courtService.load(),
      leagueService.load(),
    ])

    return promises.reduce((state: any, it: any) => {
      return { ...state, ...it }
    }, {})
  }
}

export default new CoreData()
