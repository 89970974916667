import { loadingController, LoadingOptions } from '@ionic/core'

class AppLoading {
  async create(options?: LoadingOptions) {
    const loader = await loadingController.create(options ?? {})
    await loader.present()
    return loader
  }

  async dismiss() {
    try {
      await loadingController.dismiss()
    } finally {
    }
  }
}

export default new AppLoading()
