function numberBetween(
  val: string | null | undefined,
  from: number,
  to: number
) {
  if (!val) {
    return true
  }

  const n = parseInt(val)
  return n >= from && n <= to
}

class Validation {
  usPhone(val: string | null | undefined) {
    if (!val) {
      return true
    }

    const sanitized = val?.replace(/\D/g, '')
    return (
      sanitized.length === 10 ||
      (sanitized.startsWith('1') && sanitized.length === 11)
    )
  }

  positiveInteger(val: string | null | undefined) {
    if (!val) {
      return true
    }

    return parseInt(val) > -1
  }

  score(val: string | null | undefined) {
    if (!val) {
      return true
    }

    const n = parseInt(val)

    return n > -1 && n < 500
  }

  jersey(val: string | null | undefined) {
    return numberBetween(val, 0, 99)
  }

  shotClock(val: string | null | undefined) {
    return numberBetween(val, 20, 60)
  }

  overtime(val: string | null | undefined) {
    return numberBetween(val, 60, 1200)
  }

  period(val: string | null | undefined) {
    return numberBetween(val, 60, 1800)
  }

  restrictedArc(val: string | null | undefined) {
    return numberBetween(val, 36, 72)
  }

  courtWidth(val: string | null | undefined) {
    return numberBetween(val, 480, 600)
  }

  courtLength(val: string | null | undefined) {
    return numberBetween(val, 648, 1128)
  }
}

export default new Validation()
