import React from 'react'
import { Logo, MarketingSignupButton, Banner } from '../components'
import { device } from '../core'
import { Redirect } from 'react-router'
import styled from 'styled-components'
import MarketingPage from '../components/MarketingPage'
import {
  useMeta,
  useInvitationDetails,
  useRouter,
  useEventTracking,
} from '../hooks'
import { cashOutline } from 'ionicons/icons'

const Home: React.FC = ({ ...rest }) => {
  useMeta({})
  const { details } = useInvitationDetails()
  const { pushURL } = useRouter()
  useEventTracking('home-page-view')

  if (device.isCapacitor) {
    return <Redirect to="/a/dashboard/" />
  }

  return (
    <MarketingPage {...rest}>
      {details?.invite?.coupon && (
        <Banner
          icon={cashOutline}
          color="secondary"
          onClick={() => pushURL('/register/')}
          text="Get $199 credit when you sign up this month!"
          center
          bold
        />
      )}
      <section className="hero">
        <div className="hero-image">
          <picture>
            <source
              srcSet="/assets/marketing/mbp-stats.webp"
              type="image/webp"
            />
            <source srcSet="/assets/marketing/mbp-stats.png" type="image/png" />
            <img src="/assets/marketing/mbp-stats.png" alt="Screenshot" />
          </picture>
        </div>
        <div className="hero-text">
          <Logo
            className="logo"
            color="indigo"
            type="horizontal"
            depth="full"
          />
          <h1>Gametime analytics software designed for basketball coaches.</h1>
          <p>
            JumpScout is the only platform using{' '}
            <u>
              <strong>CourtSight Technology</strong>
            </u>{' '}
            to empower high school, club, and youth basketball coaches to
            maximize their team's performance, providing live courtside
            insights, video hosting, and gameplay breakdown tools &mdash;
            empowering coaches to look back so they can prepare forward.
          </p>
        </div>
      </section>

      <section className="more-info">
        <h2>Our CourtSight Technology Platform provides:</h2>
        <div className="info-boxes">
          <div>
            <h3>IN-GAME INSIGHTS FOR EVERY GAME.</h3>
            <p>
              For athletes and coaches alike, a winning mindset is one of
              agility — the ability to pivot in rapidly evolving situations.
              JumpScout maximizes your in-game insight, enabling
              moment-by-moment decisions and actions.
            </p>
            <ul>
              <li>
                Intuitive yet detailed gameplay tracking, designed for
                statisticians to keep up.
              </li>
              <li>
                Back up an observation or uncover an a-ha moment while reviewing
                detailed insights.
              </li>
              <li>Make in-game decisions with enhanced speed and precision.</li>
            </ul>
          </div>

          <div>
            <h3>COMPREHENSIVE ANALYTICS EVERY SEASON.</h3>
            <p>
              Set the stage for tomorrow's victories when you have the right
              system. JumpScout puts the power to optimize your process in your
              hands, crunching the numbers to provide a clear picture of where
              you've been and where you need to go.
            </p>

            <ul>
              <li>
                Secure and convenient cloud-based video hosting and data on all
                aspects of your team.
              </li>
              <li>
                Quickly review or edit tracked gameplay—or easily break video
                down on your own.
              </li>
              <li>
                Share data-driven game plans with your team, empowering
                limitless opportunities.
              </li>
            </ul>
          </div>

          <div>
            <h3>AFFORDABLE ACCESS FOR EVERY PROGRAM.</h3>
            <p>
              Theres a bigger picture than simply providing schools with
              cutting-edge coaching technology. We believe equal access for
              student athletes shouldn't be determined by zip code or big
              budgets.
            </p>
            <ul>
              <li>
                JumpScout is designed to be an affordable, financial no-brainer
                for programs at all levels— jam-packed to ensure the lion's
                share of your budget goes to building and fulfilling your
                programs needs.
              </li>
              <li>Fair and flexible pricing: pay only for what you need.</li>
            </ul>
          </div>
        </div>
      </section>
    </MarketingPage>
  )
}

export default styled(Home)`
  .hero {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-column-gap: 50px;
    width: 80vw;
    max-width: 1000px;
    margin: 0 auto 100px;

    .hero-image {
      min-width: 300px;
    }

    .hero-text {
      display: grid;
      grid-template-rows: repeat(4, auto);

      .logo {
        height: auto;
        width: 400px;
      }

      > * {
        flex: 0 0 auto;
      }

      h1 {
        color: var(--ion-color-primary);
      }

      p {
        line-height: 1.6;
      }
    }

    @media screen and (max-width: 1080px) {
      margin: 30px auto 80px;
      h1 {
        font-size: 26px;
      }

      p {
        font-size: 15px;
      }

      .hero-image {
        min-width: 200px;
      }
    }

    @media screen and (max-width: 800px) {
      margin: 0 auto 40px;
      grid-template-columns: 1fr;
      h1 {
        font-size: 22px;
      }

      p {
        font-size: 14px;
      }

      .hero-image {
        margin-top: 50px;
        order: 1;
      }

      .home-cta {
        text-align: center;
      }
    }
  }

  .more-info {
    background: url('/assets/marketing/JumpScout_HCourt_Gradient@2x.png');
    background-size: cover;
    background-position: center center;
    padding: 20px;
    text-align: center;

    h2 {
      color: white;
    }

    .info-boxes {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 50px;
      max-width: 1100px;
      margin: 60px auto;

      @media screen and (max-width: 1080px) {
        grid-column-gap: 20px;
      }

      @media screen and (max-width: 800px) {
        grid-template-columns: 1fr;
        grid-row-gap: 20px;

        > div {
          max-width: 400px;
          margin: auto;
        }
      }

      > div {
        background: white;
        border-radius: 5px;
        padding: 20px;

        h3 {
          color: var(--ion-color-primary);
          font-size: 18px;
        }

        h3::after {
          border-bottom: solid 1px var(--ion-color-alternate);
          display: block;
          content: '';
          margin: 20px auto;
          height: 1px;
          width: 100px;
        }

        p {
          font-size: 12px;
          margin-bottom: 20px;
          min-height: 100px;
        }

        ul {
          width: 100%;
          text-align: left;
          padding-left: 20px;

          li {
            color: var(--ion-color-primary);
            font-size: 12px;
            font-weight: bold;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
`
