import React from 'react'
import { IonItem, IonLabel, IonText, IonBadge } from '@ionic/react'
import styled from 'styled-components'
import { Avatar } from '.'
import { TeamPersonnel } from '../types/models'
import helpers from '../util/helpers'
import css from 'classnames'

export interface TeamPersonnelRowProps
  extends React.ComponentProps<typeof IonItem> {
  teamPersonnel: TeamPersonnel
}

const TeamPersonnelRow: React.FC<TeamPersonnelRowProps> = ({
  teamPersonnel,
  className,
  ...rest
}) => {
  const isStaff = !helpers.isPlayer(teamPersonnel)

  return (
    <IonItem
      lines="full"
      routerLink={`/a/team/${
        helpers.isPlayer(teamPersonnel) ? 'player' : 'staff'
      }/${teamPersonnel.id}/`}
      routerDirection="forward"
      detail
      className={css(className, { staff: isStaff, player: !isStaff })}
      {...rest}
    >
      <Avatar
        slot="start"
        name={teamPersonnel.fullName}
        email={teamPersonnel.primaryEmail}
        src={teamPersonnel.avatarURL}
      />
      <IonLabel>
        <IonText className="ion-multiline">
          <h3>
            {teamPersonnel.lastName}, {teamPersonnel.firstName}
          </h3>
          <span className="subtext">
            {isStaff
              ? helpers.bullet([
                  'Staff',
                  teamPersonnel.primaryEmail,
                  teamPersonnel.primaryPhone,
                ])
              : `#${teamPersonnel.homeJersey} / #${teamPersonnel.awayJersey} • 
            ${teamPersonnel.position}`}
          </span>
        </IonText>
      </IonLabel>
      <div className="badges" slot="end">
        {teamPersonnel.isStarter && (
          <IonBadge color="primary">Starter</IonBadge>
        )}
        {isStaff && (
          <IonBadge color="secondary">
            {helpers.roleLabel(teamPersonnel)}
          </IonBadge>
        )}
      </div>
    </IonItem>
  )
}

export default styled(React.memo(TeamPersonnelRow))`
  ion-badge {
    margin-right: 10px;
  }
`
