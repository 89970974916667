import React from 'react'
import { IonItem, IonLabel, IonIcon } from '@ionic/react'
import css from 'classnames'
export interface ButtomItemProps extends React.ComponentProps<typeof IonItem> {
  icon?: any
}

const ButtonItem: React.FC<ButtomItemProps> = ({
  className,
  lines = 'full',
  icon,
  children,
  ...rest
}) => {
  return (
    <IonItem
      className={css(className, 'ion-activatable pointer')}
      lines={lines}
      {...rest}
    >
      {icon && <IonIcon slot="start" icon={icon} />}
      <IonLabel>{children}</IonLabel>
    </IonItem>
  )
}

export default ButtonItem
