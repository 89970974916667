const measurement = (val: any, input: boolean) => {
  if (!val) {
    return val
  }

  if (input) {
    const feet = Math.floor(val / 12)
    const inches = Math.round(12 * (val / 12 - feet))
    return `${feet}' ${inches}"`
  }

  const s = val.split(`' `)
  const feet = parseInt(s[0])
  const inchesString = s[1]?.replace(/\D/g, '')
  const inches = inchesString?.length > 0 ? parseInt(inchesString) : 0
  return feet * 12 + inches
}
class Transforms {
  height(val: any, input: boolean) {
    return measurement(val, input)
  }

  width(val: any, input: boolean) {
    return measurement(val, input)
  }

  minutesAndSeconds(val: any, input: boolean) {
    if (!val) {
      return val
    }

    if (input) {
      const minutes = Math.floor(val / 60)
      const seconds = Math.round(60 * (val / 60 - minutes))
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
    }

    const split = val.split(':')
    const minutes = parseInt(split[0])
    const seconds = parseInt(split[1])
    return minutes * 60 + seconds
  }
}

export default new Transforms()
