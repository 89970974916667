import React, { useMemo, useContext } from 'react'
import styled from 'styled-components'
import css from 'classnames'
import { CircleIconButton } from '.'
import { ScoringPageEvent, TeamGameEventOption } from '../types/interfaces'
import { TeamGameEvent } from '../types/models'
import { ScoringContext } from '../contexts/ScoringContext'

export interface ScoringEventOptionsProps {
  event: ScoringPageEvent
  origin: ScoringPageEvent
  events: TeamGameEvent[]
  onOptionSelected: (option: TeamGameEventOption, value: any) => any
}

const ScoringEventOptionsComponent: React.FC<ScoringEventOptionsProps> = ({
  event,
  origin,
  events,
  onOptionSelected,
  ...rest
}) => {
  const { state } = useContext(ScoringContext)
  const selectOption = (option: TeamGameEventOption, value: string) => () => {
    onOptionSelected(option, value)
  }

  const option = useMemo(() => {
    return event.type.options?.find(
      (it) => Object.keys(event.options ?? {}).indexOf(it.name) === -1
    )
  }, [event])

  const existing = Object.entries(event.options ?? {}).find(
    ([key]) => key === option?.name
  )

  const suggested = useMemo(() => {
    if (existing) {
      return null
    }

    return option?.getSuggestedValue?.(event, events, state)
  }, [option, existing, event, state, events])

  return (
    <div {...rest}>
      <strong className="event-option--display">{option?.display}</strong>
      <div key={option?.name} className="event-option">
        {option?.values?.map((val) => (
          <div
            key={val.name}
            onClick={selectOption(option, val.id)}
            className={css('event-option-value', {
              selected: existing?.[1] === val.id,
            })}
          >
            <CircleIconButton
              onClick={selectOption(option, val.id)}
              image={val.icon}
              shortcut={val.shortcut}
            />
            <label onClick={selectOption(option, val.id)}>{val.name}</label>
            {val.id === suggested && (
              <label className="suggestion">Suggested</label>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default styled(React.memo(ScoringEventOptionsComponent))`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: space-evenly;
  padding: 15px 0;

  .event-option--display {
    padding: 0 15px 10px;
  }

  .event-option {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-bottom: 15px;
  }

  .event-option-value {
    align-items: center;
    display: flex;
    padding: 5px 15px;
    position: relative;
    width: 100%;
    button {
      height: 50px;
      width: 50px;
    }

    .suggestion {
      background: var(--ion-color-warning);
      border-radius: 4px;
      color: var(--ion-color-warning-contrast);
      font-size: 11px;
      padding: 3px 10px;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      text-transform: uppercase;
    }
  }
`
