import React from 'react'
import styled from 'styled-components'
import css from 'classnames'
import { IonIcon } from '@ionic/react'
import { checkmark } from 'ionicons/icons'

export interface PickOneProps {
  options?: [any, any][]
  value?: any
  save?: (val: string, e: any) => Promise<any>
  readonly?: boolean
  required?: boolean
  noIndicator?: boolean
}

const PickOne: React.FC<PickOneProps> = ({
  options,
  required,
  readonly,
  save,
  value,
  noIndicator,
  ...rest
}) => {
  const choose = (val: any) => (e: any) => save?.(val, e)

  return (
    <ul {...rest}>
      {options?.map(([key, val]) => {
        const selected = value === key
        return (
          <li key={key} onClick={choose(key)} className={css({ selected })}>
            <span>{val}</span>
            {selected && <IonIcon icon={checkmark} />}
          </li>
        )
      })}
    </ul>
  )
}

export default styled(React.memo(PickOne))`
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    align-items: center;
    border-bottom: solid 1px var(--ion-color-light);
    color: black;
    cursor: pointer;
    display: flex;
    padding: 15px;
    text-align: left;

    &:hover {
      background: var(--ion-color-light);
    }

    > span {
      flex: 1 1 auto;
    }

    &.selected {
      background: rgba(var(--ion-color-primary-rgb), 0.15);

      ion-icon {
        color: var(--ion-color-alternate);
        flex: 0 0 auto;
        margin-left: 10px;
      }
    }
  }
`
