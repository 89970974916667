import React, { useContext, useState } from 'react'
import { IonIcon } from '@ionic/react'
import {
  Content,
  Page,
  ScoringCourt,
  ScoringEventDetailPopover,
  Header,
  ScoringVideoPlayer,
  GenericButton,
  ScoringGameClock,
  ScoringActiveRoster,
  ScoringEventFeed,
} from '.'
import { EditTeamGameEventModal, ScoringGameSummaryModal } from '../modals'
import styled from 'styled-components'
import { shortcutsService } from '../services'
import { useData, useShortcuts, useViewport, useHideIntercom } from '../hooks'
import Banner from './Banner'
import {
  warningOutline,
  helpCircleOutline,
  swapHorizontal,
} from 'ionicons/icons'
import { device } from '../core'
import { KeyboardShortcutsModal } from '../modals'
import {
  ScoringContextProvider,
  ScoringContext,
} from '../contexts/ScoringContext'
import { AppLoading } from '../pages'

const ScoringPage: React.FC<any> = ({ className }) => {
  const [showKeyboardShortcuts, setShowKeyboardShortcuts] = useState(false)
  const { state, dispatch } = useContext(ScoringContext)
  const { width } = useViewport()

  useShortcuts({
    'Shift+/': () => setShowKeyboardShortcuts(true),
  })

  useHideIntercom()

  const courtComponent = (
    <>
      <ScoringCourt />
      <ScoringGameClock />
    </>
  )

  const rosters = (
    <>
      {state.locked === false && (
        <Banner
          className="lock-banner"
          icon={warningOutline}
          color="warning"
          text="Once you add events, you will no longer be able to reorder or upload new videos for this game."
          small
        />
      )}
      <div className="rosters">
        <ScoringActiveRoster isHomeTeam={state.homeRosterIsLeft} />
        <IonIcon
          className="rosters--swap"
          onClick={() =>
            dispatch({
              type: 'set',
              value: {
                homeRosterIsLeft: !state.homeRosterIsLeft,
              },
            })
          }
          icon={swapHorizontal}
        />
        <ScoringActiveRoster isHomeTeam={!state.homeRosterIsLeft} />
      </div>
    </>
  )

  return (
    <Page className={className}>
      <Header title={state.title} pageTitle="Score Game" />
      <Content
        className="featured-content"
        scrollY={width <= 1280}
        scrollX={false}
      >
        <div className="scoring">
          <div className="main-container">
            {state.isLive ? courtComponent : <ScoringVideoPlayer />}
            {!state.isLive && width < 1280 && courtComponent}
            {width < 1280 && rosters}
            <ScoringEventFeed />
          </div>
          <div className="side-bar">
            {!state.isLive && width >= 1280 && courtComponent}
            {width >= 1280 && rosters}
          </div>
          {state.activeEvent && <ScoringEventDetailPopover />}
        </div>
      </Content>
      {state.editEvent && <EditTeamGameEventModal />}
      {showKeyboardShortcuts && (
        <KeyboardShortcutsModal
          section="Scoring"
          shortcuts={shortcutsService.scoring}
          onDidDismiss={() => setShowKeyboardShortcuts(false)}
        />
      )}
      {device.isDesktopWeb && (
        <GenericButton
          className="shortcuts-button"
          color="light"
          size="small"
          fill="clear"
          toggleClick={setShowKeyboardShortcuts}
          icon={helpCircleOutline}
        />
      )}
      {state.showGameSummaryDialog && <ScoringGameSummaryModal />}
    </Page>
  )
}

export default styled(({ type, teamGameId, ...rest }) => {
  const { data: setup, loading } = useData(`/v1/team-game/${teamGameId}/setup/`)

  if (loading || !setup) {
    return <AppLoading />
  }

  return (
    <ScoringContextProvider type={type} setup={setup}>
      <ScoringPage {...rest} />
    </ScoringContextProvider>
  )
})`
  background: var(--ion-color-dark);

  ion-toolbar,
  .featured-content {
    --background: var(--ion-color-dark);
    --color: white;
    --ion-item-background: transparent;
    --ion-item-color: white;
  }

  .shortcuts-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }

  .lock-banner {
    margin-bottom: 15px;
  }

  &.can-go-back ion-back-button {
    color: white !important;
  }

  .main-content {
    padding: 0;
  }

  .scoring {
    display: grid;
    grid-template-columns minmax(500px, 70vw) minmax(350px, 30vw);
    height: 100%;
    padding: 0 15px;

    .main-container {
      display: flex;
      flex-direction: column;
      padding-right: 15px;
    }

    .side-bar {
      display: flex;
      flex-direction: column;
      position: relative;
    }
  }

  .rosters {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    flex: 1 1 auto;

    .rosters--swap {
      cursor: pointer;
      margin: 14px 5px 0;
    }
  }

  @media (max-width: 1280px) {
    .scoring {
      grid-template-columns: 1fr;
    }
  }
`
