import { TeamBrandPersonnelService } from './team-brand-personnel'
import { DataField } from '../util/data-field'
import { listsService } from '.'
import masks from '../util/masks'
import transforms from '../util/transforms'

class TeamBrandPlayerService extends TeamBrandPersonnelService {
  public get fields(): DataField[] {
    return [
      ...this.standardFields,
      {
        label: 'Grade Level',
        key: 'gradeLevel',
        type: 'select',
        options: listsService.gradeLevels,
      },
      {
        label: 'Gender',
        key: 'gender',
        type: 'select',
        options: listsService.genders,
      },
      {
        label: 'Height (in)',
        key: 'height',
        type: 'input',
        mask: masks.height,
        transform: transforms.height,
      },
    ]
  }
}

export default new TeamBrandPlayerService()
