import React, { useMemo, useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { CircleIconButton } from '.'
import {
  ScoringPageEvent,
  TeamGameEventType,
  ScoringFreethrowAttempt,
} from '../types/interfaces'
import { IonItem, IonLabel, IonToggle } from '@ionic/react'
import { useShortcuts } from '../hooks'
import { ScoringContext } from '../contexts/ScoringContext'
import helpers from '../util/helpers'

export interface ScoringEventRelatedEventsProps {
  origin: ScoringPageEvent
  current: ScoringPageEvent
  events: ScoringPageEvent[]
  freethrows?: ScoringFreethrowAttempt[]
  isOneAndOne?: boolean
  onEditFreethrow: (attempt: ScoringFreethrowAttempt) => any
  onNewEvent: (type: TeamGameEventType) => any
  onEditEvent: (event: ScoringPageEvent) => any
}

const ScoringEventRelatedEventsComponent: React.FC<ScoringEventRelatedEventsProps> = ({
  origin,
  current,
  events,
  onNewEvent,
  onEditEvent,
  onEditFreethrow,
  freethrows,
  isOneAndOne,
  ...rest
}) => {
  const { state } = useContext(ScoringContext)
  const [originEvent, setOriginEvent] = useState(origin)

  const select = (type: TeamGameEventType, existing?: ScoringPageEvent) => () =>
    !existing ? onNewEvent(type) : onEditEvent(existing)

  const related = useMemo(
    () =>
      typeof originEvent.type.related === 'function'
        ? originEvent.type.related(originEvent)
        : originEvent.type.related,
    [originEvent]
  )

  useEffect(() => {
    setOriginEvent(origin)
  }, [origin])

  const filteredFreethrows = useMemo(() => {
    if (!isOneAndOne) {
      return freethrows
    }

    if (freethrows?.length && freethrows?.[0].status !== 'made') {
      return [freethrows?.[0]]
    }

    return freethrows
  }, [isOneAndOne, freethrows])

  const shortcuts = useMemo(
    () =>
      originEvent.type.toggles?.reduce((obj: any, it) => {
        if (it.shortcut) {
          obj[it.shortcut] = () => {
            originEvent.options = originEvent.options ?? {}
            originEvent.options[it.name] = !originEvent.options[it.name]
            setOriginEvent({ ...originEvent })
          }
        }
        return obj
      }, {}) ?? {},
    [originEvent]
  )

  useShortcuts(shortcuts)

  return (
    <div {...rest}>
      {related?.map((it, i) => {
        const existing = events.find((e) => e.type === it)
        return (
          <div key={i} className="event-type" onClick={select(it, existing)}>
            <CircleIconButton
              image={it.icon}
              shortcutAction={select(it, existing)}
              shortcut={it.shortcut}
            />
            <label>
              {existing
                ? `${it.name} by ${existing.teamGamePersonnelId ? '#' : ''}${
                    existing.jerseyNumber ?? ''
                  } ${
                    existing.jerseyName ?? existing.isHomeTeam
                      ? state.teamGame?.homeTeamFullName
                      : state.teamGame?.awayTeamFullName
                  }`
                : `Add ${it.name}${
                    it.id === 'foul' && originEvent.type.id === 'shot_made'
                      ? ' (and one)'
                      : ''
                  }`}
            </label>
          </div>
        )
      })}

      {filteredFreethrows?.map((it) => {
        return (
          <div
            key={it.attempt}
            className="event-type"
            onClick={() => onEditFreethrow(it)}
          >
            <CircleIconButton
              shortcut={it.attempt + 1}
              shortcutAction={() => onEditFreethrow(it)}
            />
            <label>
              Freethrow #{it.attempt + 1} {it.status}
              {it.reboundPlayer
                ? `, Rebound by ${
                    it.reboundPlayer.jerseyNumber
                      ? helpers.playerDisplay(
                          it.reboundPlayer.jerseyNumber,
                          it.reboundPlayer.jerseyName
                        )
                      : it.reboundPlayer.isHomeTeam
                      ? state.teamGame?.homeTeamFullName
                      : state.teamGame?.awayTeamFullName
                  }`
                : ''}
            </label>
          </div>
        )
      })}

      {originEvent.type.toggles?.map((it) => (
        <IonItem key={it.name} lines="none">
          <IonLabel>
            {it.display}
            {it.shortcut ? ` (${it.shortcut})` : ''}
          </IonLabel>
          <IonToggle
            onIonChange={(e) => {
              const val = e.detail.checked
              originEvent.options = originEvent.options ?? {}
              originEvent.options[it.name] = val
              setOriginEvent({ ...originEvent })
            }}
            checked={originEvent.options?.[it.name] ?? it.defaultValue}
          />
        </IonItem>
      ))}
    </div>
  )
}

export default styled(React.memo(ScoringEventRelatedEventsComponent))`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: space-evenly;
  padding: 15px 0;

  .event-type {
    align-items: center;
    display: flex;
    margin-bottom: 15px;
    padding: 0 15px;
    width: 100%;
    button {
      height: 50px;
      width: 50px;
      flex: 0 0 50px;
    }
  }

  ion-item {
    --background: transparent;
    --border: none;
    --color: white;
  }
`
