import { DataField } from './../util/data-field'
import ModelBaseService from './model-base'
import { http, intercom } from '../core'

class InvitationService extends ModelBaseService {
  public modelName = 'invitation'

  public get fields(): DataField[] {
    return []
  }

  decodeInvite(query: any) {
    const v = Object.keys(query)[0]?.replace('?', '')
    if (v) {
      const data = atob(v).split(':')
      return { id: data[0], token: data[1] }
    }

    return {}
  }

  async getInviteDetails(id: string, token: string) {
    const visitorId = await intercom.getVisitorId()
    const { data } = await http.request({
      method: 'POST',
      url: this.getEndpointUrl(id, 'details'),
      data: {
        token,
        visitorId,
      },
    })

    return data
  }
}

export default new InvitationService()
