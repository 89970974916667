import { useContext, useMemo } from 'react'
import { AppContext } from '../contexts/AppContext'
export function useTeamData(teamId?: string | null | undefined) {
  const { state } = useContext(AppContext)
  const { user } = state

  const team = useMemo(() => user?.teams?.find((it) => it.id === teamId), [
    teamId,
    user,
  ])

  const teamBrand = useMemo(
    () => user?.teamBrands?.find((it) => it.id === team?.teamBrandId),
    [team, user]
  )

  return { team, teamBrand }
}

export function useTeamBrandData(teamBrandId?: string | null | undefined) {
  const { state } = useContext(AppContext)
  const { user } = state

  const teamBrand = useMemo(
    () => user?.teamBrands?.find((it) => it.id === teamBrandId),
    [teamBrandId, user]
  )

  const teams = useMemo(
    () =>
      user?.teams?.filter(
        (it) => !teamBrandId || it.teamBrandId === teamBrand?.id
      ),
    [teamBrandId, teamBrand, user]
  )

  return { teamBrand, teams }
}
