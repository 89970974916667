import config from './config'
import http from './http'
import notifications from './notifications'
import device from './device'
import pkg from '../../package.json'

class Version {
  private interval: any
  public readonly currentVersion: string = config.version
  private uploadsInProgress = 0
  constructor() {
    if (config.isProduction && !device.isNative) {
      this.interval = setInterval(this.checkVersion.bind(this), 60000)
    }

    this.bindEventListener()
    console.info(`version: ${this.currentVersion}`)
  }
  private bindEventListener() {
    window.addEventListener('video:upload', (e: any) => {
      const { status, type } = e.detail
      if (type === 'status') {
        if (status === 'UPLOADING') {
          this.uploadsInProgress += 1
        } else {
          this.uploadsInProgress -= 1
        }
      }
    })
  }
  get fullVersion() {
    return `${pkg.version} (${this.currentVersion})`
  }

  get isAppPage() {
    return window.location.pathname.startsWith('/a/')
  }

  async checkVersion() {
    try {
      const { data } = await http.request({
        method: 'GET',
        url: `/build.json?d=${Date.now()}`,
        baseURL: '',
      })

      if (
        this.currentVersion !== data.version &&
        this.uploadsInProgress === 0 &&
        this.isAppPage
      ) {
        clearTimeout(this.interval)
        notifications.newVersion()
      }
    } catch (e) {
      console.error('[VERSION] Check Failed', e)
    }
  }
}

export default new Version()
