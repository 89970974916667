import { pickerController, PickerColumn } from '@ionic/core'

export interface PickerOptions {
  onCancel?: () => any
  onSave?: (value: any) => any
}
class Pickers {
  async create(columns: PickerColumn[], options?: PickerOptions) {
    const picker = await pickerController.create({
      columns,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: options?.onCancel,
        },
        {
          text: 'Save',
          handler: options?.onSave,
        },
      ],
    })

    await picker.present()

    return picker
  }
}

export default new Pickers()
