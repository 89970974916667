import React, { useCallback, useEffect, useMemo } from 'react'
import css from 'classnames'
import styled from 'styled-components'
import { IonIcon } from '@ionic/react'
import { close } from 'ionicons/icons'

export interface WheelMenuItem {
  icon?: string
  text?: string
  image?: string
  title?: string
  handler?: (e?: any) => any
  shortcut?: string
}

export interface WheelMenuPosition {
  x: number
  y: number
}

export interface WheelMenuProps extends React.ComponentProps<any> {
  items: WheelMenuItem[]
  isOpen: boolean
  onWillDismiss: () => any
  position?: WheelMenuPosition
}

const WheelMenu: React.FC<WheelMenuProps> = ({
  items,
  style,
  isOpen,
  position,
  onWillDismiss,
  ...rest
}) => {
  const isOffscreen = useMemo(
    () => (position?.x ?? 0) + 50 >= window.innerWidth,
    [position]
  )

  const setup = useCallback(() => {
    var it: any = document.querySelectorAll('.menuItem')
    if (!position) {
      return
    }

    for (var i = 0, l = it.length; i < l; i++) {
      let left = isOffscreen
        ? `50%`
        : (
            51 -
            35 * Math.cos(-0.5 * Math.PI - 2 * (1 / l) * i * Math.PI)
          ).toFixed(4) + '%'

      let top = isOffscreen
        ? `calc(50% + ${45 * (i + 1)}px)`
        : (
            50 +
            35 * Math.sin(-0.5 * Math.PI - 2 * (1 / l) * i * Math.PI)
          ).toFixed(4) + '%'

      it[i].style.left = left
      it[i].style.top = top
    }
  }, [position, isOffscreen])

  useEffect(() => setup(), [setup, items])

  return (
    <div {...rest}>
      <div className={css('ring', { offscreen: isOffscreen })}>
        {items.map((item: WheelMenuItem, i: number) => (
          <span
            key={i}
            className={css('menuItem', {
              text: !!item.text,
            })}
            onClick={() => {
              onWillDismiss?.()
              item.handler?.()
            }}
          >
            {!item.text && item.icon && <IonIcon icon={item.icon} />}
            {item.image && (
              <img className="custom-image" src={item.image} alt={item.title} />
            )}
            {item.shortcut ? (
              <span className="shortcut">{item.shortcut}</span>
            ) : (
              ''
            )}
            {item.text}
          </span>
        ))}
      </div>
      <IonIcon
        icon={close}
        className="center"
        color="dark"
        onClick={onWillDismiss}
      />
    </div>
  )
}

export default styled(WheelMenu)`
  a {
    font-size: 14px;
    text-decoration: none;
    outline: 0;
  }

  &,
  * {
    pointer-events: ${(props) => (props.isOpen ? 'all' : 'none')};
  }

  margin: 0 auto;
  z-index: 100;
  top: ${(props) => props.position?.y ?? 0}px;
  left: ${(props) => props.position?.x ?? 0}px;
  transform: translate(-50%, -50%);
  position: absolute;

  .ring.offscreen {
    transition: opacity 0.2s ease-out;
    transform: rotate(0deg);
  }

  .ring {
    border-radius: 50%;
    opacity: 0;
    height: 100px;
    transition: all 0.2s ease-out;
    transform-origin: 50% 50%;
    transform: scale(0.1) rotate(-270deg);
    position: relative;
    width: 100px;
    user-select: none;
  }

  .custom-image {
    flex: 0 0 22px;
    height: 22px;
    width: 22px;
  }

  .shortcut {
    color: white;
    font-size: 10px;
    flex: 0 0 auto;
  }

  ${(props) =>
    props.isOpen
      ? `.ring {
          opacity: 1;
          -webkit-transform: scale(1) rotate(0);
          -moz-transform: scale(1) rotate(0);
          transform: scale(1) rotate(0);
        }`
      : ''}

  .center {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    bottom: 0;
    color: var(--ion-color-danger-shade);
    height: 25px;
    left: 0;
    line-height: 25px;
    margin: auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 25px;
    display: ${(props) => (props.isOpen ? 'block' : 'none')};
    transition: all 0.4s ease-out;
  }

  .menuItem {
    align-items: center;
    background: var(--ion-color-dark);
    border: solid 1px white;
    border-radius: 50%;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    height: 40px;
    margin-left: -20px;
    margin-top: -20px;
    justify-content: center;
    position: absolute;
    text-align: center;
    width: 40px;
  }

  .menuItem.text {
    font-size: 20px;
  }
`
