import { isPlatform } from '@ionic/react'
import { Plugins } from '@capacitor/core'

const { Network } = Plugins

class Device {
  private _browserOnline = window.navigator.onLine
  private _deviceOnline = true
  private _connectionType = 'unknown'

  constructor() {
    if (!this.isNative) {
      window.addEventListener('online', () =>
        this.updateBrowserOnlineStatus(true)
      )
      window.addEventListener('offline', () =>
        this.updateBrowserOnlineStatus(false)
      )
    } else {
      this.updateDeviceStatus()
      Network.addListener('networkStatusChange', (status) => {
        this._deviceOnline = status.connected
        this._connectionType = status.connectionType
      })
    }
  }

  private async updateDeviceStatus() {
    const status = await Network.getStatus()
    this._deviceOnline = status.connected
    this._connectionType = status.connectionType
  }

  private updateBrowserOnlineStatus(online: boolean) {
    this._browserOnline = online
  }

  get isNative() {
    return isPlatform('capacitor')
  }

  get isiOS() {
    return this.isNative && isPlatform('ios')
  }

  get isDesktopWeb() {
    return !this.isDesktopNative && isPlatform('desktop')
  }

  get isMobileWeb() {
    return !this.isNative && isPlatform('mobileweb')
  }

  get isAndroid() {
    return this.isNative && isPlatform('android')
  }

  get isDesktopNative() {
    return isPlatform('electron')
  }

  get isCapacitor() {
    return isPlatform('capacitor')
  }

  get isReactSnap() {
    return window.navigator.userAgent === 'ReactSnap'
  }

  async getDetails() {
    return {}
  }

  get isOnline() {
    return this.isNative ? this._deviceOnline : this._browserOnline
  }

  get connectionType() {
    return this._connectionType
  }

  get timezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'US/Eastern'
  }
}

export default new Device()
