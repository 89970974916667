import React, { useContext, useState, useMemo } from 'react'
import Modal from './Modal'
import { ScoringContext } from '../contexts/ScoringContext'
import { FormInputField, FormToggleField } from '../components'

const ScoringGameSummaryModal: React.FC = () => {
  const { state, dispatch, score } = useContext(ScoringContext)
  const isHalftime = useMemo(
    () => (state.periodType === 'H' ? state.period === 1 : state.period === 2),
    [state.period, state.periodType]
  )

  const [resetStarters, setResetStarters] = useState(isHalftime)

  return (
    <Modal
      isOpen={true}
      backdropDismiss={false}
      title={`End of ${state.periodType === 'Q' ? 'Quarter' : 'Half'} ${
        state.period
      }`}
      onDidDismiss={() => {
        const updatedHomeScore = parseInt(
          (document.getElementById('homeScoreAdjustment') as any)?.value ??
            score.home.toString()
        )
        const updatedAwayScore = parseInt(
          (document.getElementById('awayScoreAdjustment') as any)?.value ??
            score.away.toString()
        )

        dispatch({
          type: 'gameSummaryUpdate',
          value: {
            currentHomeScore: score.home,
            currentAwayScore: score.away,
            updatedHomeScore,
            updatedAwayScore,
            resetStarters,
          },
        })
      }}
      closeButtonText="Continue"
    >
      <FormInputField
        id="homeScoreAdjustment"
        label={`${state.teamGame?.homeTeamFullName} Score`}
        value={score.home}
      />
      <FormInputField
        id="awayScoreAdjustment"
        label={`${state.teamGame?.awayTeamFullName} Score`}
        value={score.away}
      />
      {isHalftime && (
        <FormToggleField
          label="Reset Starters"
          checked={resetStarters}
          save={setResetStarters}
          noIndicator
        />
      )}
    </Modal>
  )
}

export default React.memo(ScoringGameSummaryModal)
