class Masks {
  get usPhone() {
    return '(999) 999-9999'
  }

  get height() {
    return `9{*}' 9[9]"`
  }

  get width() {
    return `9{*}' 9[9]"`
  }

  get minutesAndSeconds() {
    return `9[9]:99`
  }
}

export default new Masks()
