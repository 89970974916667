import React from 'react'
import Modal, { ModalProps } from './Modal'
import EditContent, { EditContentProps } from '../components/EditContent'

export type Combined = ModalProps & EditContentProps

export interface EditModalProps extends Combined {}

const EditModal: React.FC<Combined> = ({
  service,
  supportsAvatar,
  getParentEntity,
  parentRequired,
  buttons,
  children,
  renderName,
  entityId,
  entity,
  readonlyAvatar,
  backgroundColor,
  actionButton,
  allowDestroy,
  afterDestroyURL,
  renderSubtitle,
  renderAvatarURL,
  toolbarButtons,
  destroyText,
  destroyDialogText,
  afterUpdate,
  model,
  banner,
  ...rest
}) => {
  const contentProps = {
    service,
    supportsAvatar,
    getParentEntity,
    parentRequired,
    buttons,
    children,
    renderName,
    entityId,
    entity,
    readonlyAvatar,
    renderAvatarURL,
    backgroundColor,
    noHeader: true,
    actionButton,
    allowDestroy,
    afterDestroyURL,
    renderSubtitle,
    toolbarButtons,
    destroyText,
    destroyDialogText,
    afterUpdate,
    model,
    banner,
  }

  return (
    <Modal closeButtonText="Done" {...rest}>
      <EditContent {...contentProps} />
    </Modal>
  )
}

export default EditModal
