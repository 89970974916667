import { Plugins } from '@capacitor/core'
import moment from 'moment'
import ModelBaseService from './model-base'
const { Storage } = Plugins

export default abstract class StateBaseService extends ModelBaseService {
  protected abstract stateKey: string

  private cacheTimeout: number = 6000 //60s default
  private allowCache: boolean = false

  abstract async request(arg?: any): Promise<any>
  protected abstract async additionalState(data: any): Promise<any>

  protected async selectedItemOrFirst(data: any, key: string) {
    const selected = await this.getStorage(key)
    const item = data?.find?.((it: any) => it.id === selected) ?? data?.[0]
    this.setStorage(key, item?.id)
    return item
  }

  protected async setStorage(key: string, value: any) {
    if (value) {
      await Storage.set({
        key,
        value: typeof value === 'object' ? JSON.stringify(value) : value,
      })
    } else {
      await Storage.remove({
        key,
      })
    }
  }

  protected async getStorage(key: string): Promise<any> {
    const res = await Storage.get({
      key,
    })

    let value = res.value

    try {
      value = JSON.parse(res.value as string)
    } finally {
      return value
    }
  }

  async evict() {
    return await Storage.remove({ key: this.stateKey })
  }

  async load(arg?: any) {
    const { d, a, ts } = (await this.getStorage(this.stateKey)) ?? {}
    const useCached =
      this.allowCache &&
      ts &&
      moment().diff(moment(ts)) <= this.cacheTimeout &&
      a === arg

    let data

    if (useCached) {
      data = d
    } else {
      data = await this.request(arg)
      if (this.allowCache) {
        this.setStorage(this.stateKey, {
          d: data,
          a: arg,
          ts: Date.now(),
        })
      }
    }

    const addtl = await this.additionalState(data)

    return {
      [this.stateKey]: data,
      ...addtl,
    }
  }
}
