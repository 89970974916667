import { useMemo, useEffect } from 'react'
import useRouter from './useRouter'

export function useSessionHistory() {
  const { location, history } = useRouter()

  //Store the stack in sessionStorage so you can go back on refresh
  useEffect(() => {
    const stack = (sessionStorage.getItem('urlStack') ?? '').split(',') ?? []

    if (history.action === 'REPLACE') {
      stack.shift()
      return sessionStorage.setItem('urlStack', stack.join(','))
    }

    if (stack[0] === location.pathname) {
      return
    }

    const updated = [location.pathname, ...stack]
    if (updated.length > 20) {
      updated.length = 20
    }
    sessionStorage.setItem('urlStack', updated.join(','))
  }, [location, history])
}

export function useLastSessionURL(defaultBackURL?: string) {
  const { location } = useRouter()

  const defaultURL = useMemo(() => {
    const urlStack = sessionStorage.getItem('urlStack')
    if (!urlStack?.length) {
      return undefined
    }

    const urls = urlStack.split(',')
    const url = urls[1]
    return !url.length || url === location.pathname ? defaultBackURL : url
  }, [location, defaultBackURL])

  return defaultURL
}
