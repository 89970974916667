import React, { useContext, useMemo } from 'react'
import styled from 'styled-components'
import { AppContext, AppState } from '../contexts/AppContext'
import { UserInfo, ContextSwitcher, UploadProgress, Logo, Content } from '.'
import {
  homeOutline,
  barChartOutline,
  filmOutline,
  calendarOutline,
  peopleOutline,
  cogOutline,
  basketOutline,
  basketballOutline,
  addCircle,
  helpBuoyOutline,
  peopleCircleOutline,
  mailOutline,
} from 'ionicons/icons'
import { IonIcon, IonMenuToggle } from '@ionic/react'
import css from 'classnames'
import { Link } from 'react-router-dom'
import { useRouter, useSessionHistory, useSubscription } from '../hooks'
import { teamService, teamBrandService } from '../services'

export interface NavItemButton extends React.ComponentProps<any> {
  canAccess?: (state: AppState) => boolean
  routerLink?: string
  handler?: () => any
}

export interface NavItem {
  id: string
  path?: string
  name?: string
  icon?: any
  canAccess?: (state: AppState) => boolean
  subscriptionRequired?: boolean
  adminRequired?: boolean
  component?: any
  button?: NavItemButton
  disabled?: boolean | ((state: AppState) => boolean)
  disabledTooltip?: string
}

const nav: NavItem[] = [
  {
    id: 'help',
    path: 'https://help.jumpscout.com/',
    name: 'Help Center',
    icon: helpBuoyOutline,
  },

  {
    id: 'brand',
    name: 'Program',
    canAccess(state: AppState) {
      return !!state.teamBrand
    },
    button: {
      icon: addCircle,
      routerLink: '/a/program/new/',
    },
  },

  {
    id: 'dashboard',
    path: '/a/dashboard/',
    name: 'Dashboard',
    icon: homeOutline,
    subscriptionRequired: true,
  },

  // {
  //   id: 'brand-insights',
  //   path: '/a/program/insights/',
  //   name: 'Insights',
  //   icon: barChartOutline,
  //   canAccess(state: AppState) {
  //     return !!state.teamBrand
  //   },
  // },

  {
    id: 'brand-players',
    path: '/a/program/players/',
    name: 'Players',
    icon: basketballOutline,
    subscriptionRequired: true,
    canAccess(state: AppState) {
      return !!state.teamBrand
    },
  },

  {
    id: 'brand-staff',
    path: '/a/program/staff/',
    name: 'Staff',
    icon: peopleOutline,
    subscriptionRequired: true,
    canAccess(state: AppState) {
      return !!state.teamBrand
    },
  },

  {
    id: 'brand-subscription',
    path: '/a/program/subscription/',
    name: 'Subscription',
    icon: basketOutline,
    canAccess(state) {
      return teamBrandService.hasWriteAccess(state.teamBrand)
    },
  },

  {
    id: 'brand-settings',
    path: '/a/program/settings/',
    name: 'Settings',
    icon: cogOutline,
    subscriptionRequired: true,
    canAccess(state: AppState) {
      return (
        !!state.teamBrand && teamBrandService.hasWriteAccess(state.teamBrand)
      )
    },
  },

  {
    id: 'team',
    name: 'Team',
    canAccess(state) {
      return !!state.teamBrand
    },
    button: {
      icon: addCircle,
      routerLink: '/a/team/new/',
      requiresSubscription: true,
      canAccess(state: AppState) {
        return (
          !!state.teamBrand &&
          teamBrandService.hasWriteAccess(state.teamBrand) &&
          !!state.teamBrand.subscriptions?.length &&
          (state.user?.teams?.filter(
            (it) => it.teamBrandId === state.teamBrand?.id
          )?.length ?? 0) < state.teamBrand.subscriptions[0].plan.teamQuota
        )
      },
    },
  },

  {
    id: 'team-insights',
    path: '/a/team/insights/',
    name: 'Insights',
    icon: barChartOutline,
    subscriptionRequired: true,
    canAccess(state) {
      return !!state.team
    },
  },

  {
    id: 'team-videos',
    path: '/a/team/videos/',
    name: 'Videos',
    icon: filmOutline,
    subscriptionRequired: true,
    canAccess(state) {
      return !!state.team
    },
  },

  {
    id: 'team-schedule',
    path: '/a/team/schedule/',
    name: 'Schedule',
    icon: calendarOutline,
    subscriptionRequired: true,
    canAccess(state) {
      return !!state.team
    },
  },

  {
    id: 'team-roster',
    path: '/a/team/roster/',
    name: 'Roster',
    icon: peopleOutline,
    subscriptionRequired: true,
    disabledTooltip:
      'You cannot edit your roster until you have added at least 1 player or 1 staff member to your program.',
    disabled(state) {
      return !state.teamBrand?.personnel?.length
    },
    canAccess(state) {
      return !!state.team
    },
  },

  {
    id: 'team-settings',
    path: '/a/team/settings/',
    name: 'Settings',
    icon: cogOutline,
    subscriptionRequired: true,
    canAccess(state) {
      return !!state.team && teamService.hasWriteAccess(state.team)
    },
  },

  {
    id: 'admin',
    name: 'Admin',
    adminRequired: true,
  },

  {
    id: 'admin-account-setup',
    path: '/a/admin/account-setup/',
    name: 'Setup Account',
    icon: peopleCircleOutline,
    adminRequired: true,
  },

  {
    id: 'admin-invitations-list',
    path: '/a/admin/invitations/',
    name: 'Invitations',
    icon: mailOutline,
    adminRequired: true,
  },
]

const AppNavigation: React.FC<any> = (props) => {
  const { state } = useContext(AppContext)
  const { location, router } = useRouter()
  useSessionHistory()

  const filteredItems = useMemo(
    () =>
      nav.filter(
        (it) =>
          ((!it.canAccess || it.canAccess(state)) && !it.adminRequired) ||
          state.user?.isAdmin
      ),
    [state]
  )

  const { subscription } = useSubscription()

  if (!state.ready || !state.user) {
    return <nav {...props} />
  }

  return (
    <nav {...props}>
      <UserInfo />
      <ContextSwitcher />
      <Content className="nav-items">
        {filteredItems.map((it: any) => {
          if (it.component) {
            const Custom = it.component
            return <Custom key={it.id} />
          }

          const active = it.path === location.pathname
          const disabled =
            (typeof it.disabled === 'function'
              ? it.disabled(state)
              : it.disabled) ||
            (it.subscriptionRequired && !subscription)

          let Component: any
          if (it.path && !active && !disabled) {
            Component = it.path.startsWith('http')
              ? ({ to, children, ...rest }: any) => (
                  <a
                    href={to}
                    target="_blank"
                    rel="noopener noreferrer"
                    {...rest}
                  >
                    {children}
                  </a>
                )
              : Link
          } else {
            Component = (props: any) => <div {...props} />
          }

          const { canAccess, ...btn } = it.button ?? {}

          const showButton = it.button && (!canAccess || canAccess(state))

          return (
            <IonMenuToggle key={it.id} autoHide={false}>
              <Component
                className={css('nav-item', {
                  heading: !it.path,
                  active,
                  button: !!it.button,
                  disabled,
                })}
                to={it.path}
                title={disabled ? it.disabledTooltip : it.title}
              >
                {it.icon && <IonIcon icon={it.icon} />}
                <label>{it.name}</label>
                {showButton && (
                  <IonIcon
                    className="nav-btn pointer"
                    color="alternate"
                    onClick={() => {
                      if (btn.routerLink) {
                        return router.push(btn.routerLink, 'forward')
                      }

                      btn.handler?.()
                    }}
                    {...btn}
                  />
                )}
              </Component>
            </IonMenuToggle>
          )
        })}
      </Content>
      <UploadProgress />
      <div className="nav-footer">
        <Logo
          className="logo-wordmark"
          color="indigo"
          type="wordmark"
          depth="full"
        />
      </div>
    </nav>
  )
}

export default styled(AppNavigation)`
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  position: relative;
  user-select: none;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  height: 100%;
  z-index: 1000;
  @media screen and (min-width: 992px) {
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 94%,
      rgba(0, 0, 0, 0.05) 98%,
      rgba(0, 0, 0, 0.1) 100%
    );
  }

  .switcher {
    transition: box-shadow ease-in-out 0.15s;
    &.scrolled {
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.05);
      border-bottom: solid 1px rgb(var(--ion-color-medium-rgb), 0.15);
    }
  }

  .nav-footer {
    border-top: solid 1px rgb(var(--ion-color-medium-rgb), 0.15);

    .logo-wordmark {
      align-self: center;
      display: block;
      margin: 10px auto;
      height: 40px;
    }
  }

  .nav-items {
    --background: transparent;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 20px;

    .nav-item {
      align-items: center;
      border-radius: 10px;
      display: grid;
      grid-template-columns: 30px 1fr auto;
      color: var(--ion-color-dark);
      font-size: 14px;
      margin: 0 5px 3px;
      padding: 9px 10px;
      position: relative;
      text-decoration: none;
      transition: background cubic-bezier(0.17, 0.67, 1, 0.74) 0.2s;

      &.disabled {
        opacity: 0.5;

        &:not(.heading):hover {
          background: none;
          cursor: default;
        }
      }

      ion-icon {
        height: 18px;
        width: 18px;
        font-size: 18px;
        margin-right: 15px;
      }

      label {
        margin: 0;
        pointer-events: none;
      }

      .switcher {
        margin: 0 5px 0 0;
      }

      &:not(.heading):hover,
      &.active {
        background: rgba(var(--ion-color-alternate-rgb), 0.1);
        cursor: pointer;
      }

      &.heading {
        grid-template-columns: 1fr auto;
        font-family: 'Roboto', sans-serif;
        font-style: italic;
        font-weight: 500;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: var(--ion-color-medium);
        font-size: 12px;
        cursor: default;
        margin: 15px 5px 0;
      }

      .nav-btn {
        margin-right: -5px;
        transition: transform linear 0.15s;
        :hover {
          filter: brightness(90%);
          transform: scale(1.1);
        }
      }
    }
  }
`
