import React, { useMemo, useState, useRef, useContext } from 'react'
import Modal from '../modals/Modal'
import styled from 'styled-components'
import { TeamGameEvent } from '../types/models'
import helpers from '../util/helpers'
import { teamGameEventService } from '../services'
import { TeamGameEventType } from '../types/interfaces'
import { IonList, IonItem, IonItemDivider } from '@ionic/react'
import {
  FormSelectField,
  FormInputField,
  CourtCanvas,
  CircleIconButton,
  Banner,
  FormPopoverSelectorField,
  PlayerPopoverSelector,
  FormToggleField,
} from '../components'
import masks from '../util/masks'
import transforms from '../util/transforms'
import { warningOutline } from 'ionicons/icons'
import { useSSEListener } from '../hooks'
import { ScoringContext } from '../contexts/ScoringContext'

export interface EditTeamGameEventModalProps extends React.ComponentProps<any> {
  onEdit?: (updated: TeamGameEvent) => any
  onDelete?: () => any
}

const EditTeamGameEventModal: React.FC<EditTeamGameEventModalProps> = ({
  onEdit,
  onDelete,
  onDidDismiss,
  ...rest
}) => {
  const { state, dispatch } = useContext(ScoringContext)
  const onDismiss = () => {
    dispatch({
      type: 'editEvent',
      value: null,
    })

    onDidDismiss?.()
  }

  const { court, league, editEvent } = state
  const [relatedEvent, setRelatedEvent] = useState<TeamGameEvent | undefined>()
  const [showRelatedPlayerSelection, setShowRelatedPlayerSelection] = useState(
    false
  )
  const courtCanvas = useRef<any>()
  const [relatedEventType, setRelatedEventType] = useState<
    TeamGameEventType | null | undefined
  >()

  const event = useMemo(() => relatedEvent ?? editEvent, [
    relatedEvent,
    editEvent,
  ])

  const type = useMemo(
    () => (event?.type ? teamGameEventService.getTypeById(event.type) : null),
    [event]
  )

  useSSEListener((e: any) => {
    if (
      e.type === 'db:update' &&
      e.detail.data?.id &&
      e.detail.data?.id === editEvent?.id
    ) {
      dispatch({
        type: 'editEvent',
        value: { ...editEvent, ...e.detail.data },
      })
    }
  }, 'team-game-event')

  const deleteEvent = () =>
    helpers.withLoading(async () => {
      if (event?.id) {
        await teamGameEventService.destroy(event.id)
        if (relatedEvent) {
          helpers.arrayDelete(editEvent?.rel, event.id)
          setRelatedEvent(undefined)
        } else {
          onDelete?.()
          onDismiss()
        }
      }
    })

  const points = useMemo(() => {
    if (event) {
      const { x, y, ppi, side } = event
      return [{ x, y, ppi, side }]
    }
    return null
  }, [event])

  const teamNames = useMemo(
    () => ({
      home: state.teamGame?.homeTeamFullName,
      away: state.teamGame?.awayTeamFullName,
    }),
    [state.teamGame]
  )

  const freethrows = useMemo(
    () => event?.rel?.filter((it) => it.type.startsWith('freethrow_')),
    [event]
  )

  const relatedEventIsHomeTeam = useMemo(
    () =>
      relatedEventType?.sharesOriginTeam
        ? editEvent?.isHomeTeam
        : !editEvent?.isHomeTeam,
    [relatedEventType, editEvent]
  )
  
  const existingRebound = event?.rel?.find((e) => {
    return e.type === 'rebound'
  })

  const addOrEditRelatedEvent = (
    type: TeamGameEventType,
    existing?: TeamGameEvent
  ) => {
    if (existing) {
      setRelatedEvent(existing)
    } else {
      setRelatedEventType(type)
      setShowRelatedPlayerSelection(true)
    }
  }

  const createRelatedEvent = (id: any, meta: any) =>
    helpers.withLoading(async () => {
      const res = await teamGameEventService.create({
        ...editEvent,
        attributes: {},
        originEventId: editEvent?.id,
        teamGamePersonnel: undefined,
        teamGamePersonnelId: id,
        teamId: state.teamId,
        type: relatedEventType?.id,
        isHomeTeam: meta.isHomeTeam,
        id: undefined,
      })

      setRelatedEvent(res)
    })

  const afterSave = (updated: TeamGameEvent) => {
    if (relatedEvent) {
      helpers.arrayReplace(editEvent?.rel, updated)
      setRelatedEvent(updated)
    }

    dispatch({
      type: 'editEventUpdated',
      value: relatedEvent ? editEvent : updated,
    })
  }

  const save = (key: string) => async (val: any) => {
    let res
    //If it's video time being modified, make sure it uses the relative time
    if (key === 'videoTime') {
      const {
        relativeTime,
        teamVideoId,
      } = helpers.getVideoDetailsForAbsoluteTime(state, val)

      res = await teamGameEventService.update(event!.id, {
        videoTime: relativeTime,
        teamVideoId,
      })
    } else {
      res = await teamGameEventService.saveHandler(event?.id, key)(val)
    }
    afterSave(res)
  }

  const saveAttribute = (key: string) => (val: string) =>
    save('attributes')({ ...(event?.attributes ?? {}), [key]: val })

  const updatePOI = (e: any) => {
    const {
      x,
      y,
      ppi,
      side,
      hoopSide,
      distance,
    } = courtCanvas.current.renderer.toRimBasedFromClickEvent(e)

    helpers.withLoading(async () => {
      if (!event) {
        return
      }

      const pointValue = courtCanvas.current.renderer.getLocationPointValue(
        x,
        y
      )

      const updated = await teamGameEventService.update(event.id, {
        x,
        y,
        ppi,
        side,
        pointValue,
        hoopSide,
        distance,
      })

      afterSave(updated)
    })
  }

  return (
    <Modal
      title={`Edit ${relatedEvent ? type?.name : 'Event'}`}
      headerAction={{
        children: 'Delete',
        color: 'danger',
        confirm: {
          message: 'Are you sure you want to delete this event?',
          handler: deleteEvent,
        },
      }}
      backButton={{
        visible: !!relatedEvent,
        onClick() {
          setRelatedEvent(undefined)
        },
      }}
      onDidDismiss={onDismiss}
      {...rest}
    >
      {event?.flagged && (
        <Banner
          className="flagged-banner"
          icon={warningOutline}
          color="warning"
          text={`${event?.allFlaggedReasonCodes?.length} issue${
            event?.allFlaggedReasonCodes?.length === 1 ? '' : 's'
          } found: ${event?.flagExplanation?.join(', ')}.`}
          small
        />
      )}
      <div className="court-container">
        <CourtCanvas
          ref={courtCanvas}
          className="court"
          overrides={league.courtOverrides}
          court={court}
          points={points}
          onClick={updatePOI}
        />
      </div>
      <IonList lines="full">
        {type.related?.map((it: TeamGameEventType) => {
          const existing = event?.rel?.find((e) => e.type === it.id)
          if(existing){
            return (
              <IonItem
                key={it.name}
                className="ion-activatable pointer"
                onClick={() => addOrEditRelatedEvent(it, existing)}
              >
              <CircleIconButton slot="start" image={it.icon} />
                <label>
                  { `${it.name}${existing.teamGamePersonnelId ? ' by #' : ''}${
                        existing.teamGamePersonnel?.jersey ?? ''
                      } ${existing.teamGamePersonnel?.fullName ?? ''}`
                    }
                </label>
              </IonItem>
            )
        }
        if(!existing && (it.id != 'rebound')){
          return (
            <IonItem
              key={it.name}
              className="ion-activatable pointer"
              onClick={() => addOrEditRelatedEvent(it, existing)}
            >
            <CircleIconButton slot="start" image={it.icon} />
              <label>
                {`Add ${it.name}${
                      it.id === 'foul' && type.id === 'shot_made'
                        ? ' (and one)'
                        : ''
                    }`}
              </label>
            </IonItem>
          )
        }
        })
        }
        
        <FormSelectField
          label="Type"
          options={teamGameEventService.typesList}
          value={(existingRebound)? event?.type: (event?.type + '_deadball')}
          save={save('type')}
          interface="alert"
          readonly
        />
        <FormToggleField
          label="User Flagged"
          checked={event?.userFlagged}
          save={save('userFlagged')}
        />
        {type?.options && (
          <>
            {type.options.map((it: any) => (
              <FormSelectField
                key={it.name}
                label={it.name}
                options={it.values.map((v: any) => [v.id, v.name])}
                value={event?.attributes?.[it.name]}
                save={saveAttribute(it.name)}
              />
            ))}
          </>
        )}
        <FormPopoverSelectorField
          label="Player"
          value={event?.teamGamePersonnelId}
          save={async (id: any, meta: any) => {
            if (event) {
              const res = await teamGameEventService.update(event?.id, {
                teamGamePersonnelId: id,
                isHomeTeam: meta.isHomeTeam,
              })
              afterSave(res)
            }
          }}
          customPopover={PlayerPopoverSelector}
          items={state.teamGame?.personnel}
          renderSelectedText={(items: any[], value: any) => {
            const item = items.find((it) => it.id === value)
            return item
              ? helpers.playerDisplay(item.jersey, item.fullName)
              : state.isSingleTeam &&
                state.isSingleTeamHome !== event?.isHomeTeam
              ? state.unscoredTeamName
              : null
          }}
          customPopoverProps={{
            isHome: event?.isHomeTeam,
            teamNames,
            isSingleTeam: state.isSingleTeam,
            isSingleTeamHome: state.isSingleTeamHome,
          }}
        />
        <FormInputField
          label={state.periodType === 'Q' ? 'Quarter' : 'Half'}
          value={event?.period}
          save={save('period')}
        />
        <FormInputField
          label="Clock"
          value={event?.clockTime}
          mask={masks.minutesAndSeconds}
          transform={transforms.minutesAndSeconds}
          save={save('clockTime')}
        />
        {event?.teamVideoId && (
          <FormInputField
            label="Video"
            value={helpers.getAbsoluteVideoTime(state, event)}
            mask={masks.minutesAndSeconds}
            transform={transforms.minutesAndSeconds}
            save={save('videoTime')}
          />
        )}
        {!!freethrows?.length && (
          <>
            <IonItemDivider sticky>Freethrows</IonItemDivider>
            {freethrows
              ?.map((it) => [it, ...(it.rel ?? [])])
              ?.flat()
              ?.map((it, i) => {
                return (
                  <IonItem
                    key={it.id}
                    className="ion-activatable pointer"
                    onClick={() => setRelatedEvent(it)}
                  >
                    {it.type === 'rebound'
                      ? `Rebound ${
                          it.teamGamePersonnel
                            ? `by ${helpers.playerDisplay(
                                it.teamGamePersonnel?.jersey,
                                it.teamGamePersonnel?.fullName
                              )}`
                            : ''
                        }`
                      : `Attempt #${i + 1} - ${
                          it.type.endsWith('miss') ? 'Missed' : 'Made'
                        }`}
                  </IonItem>
                )
              })}
          </>
        )}
      </IonList>
      <PlayerPopoverSelector
        isOpen={showRelatedPlayerSelection}
        onSelected={createRelatedEvent}
        items={state.teamGame?.personnel}
        isHome={relatedEventIsHomeTeam}
        teamNames={teamNames}
        isSingleTeam={state.isSingleTeam}
        isSingleTeamHome={state.isSingleTeamHome}
        onDidDismiss={() => setShowRelatedPlayerSelection(false)}
      />
    </Modal>
  )
}

export default styled(EditTeamGameEventModal)`
  .court-container {
    margin: 0 auto;
    width: 300px;
  }

  .flagged-banner {
    margin-bottom: 10px;
  }
`
