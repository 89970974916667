import React, { useContext, useMemo } from 'react'
import styled from 'styled-components'
import { teamSeasonService } from '../services'
import { AppContext } from '../contexts/AppContext'
import CreateModal, { CreateModalProps } from './CreateModal'

export interface AddTeamSeasonModalProps extends CreateModalProps {}

const AddTeamSeasonModal: React.FC<AddTeamSeasonModalProps> = ({
  title,
  service,
  ...rest
}) => {
  const { state } = useContext(AppContext)
  const { team } = state
  const initialData = useMemo(
    () => ({
      teamId: team?.id,
    }),
    [team]
  )

  return (
    <CreateModal
      title="Add Season"
      service={teamSeasonService}
      initialData={initialData}
      fields={teamSeasonService.fields}
      {...rest}
    />
  )
}

export default styled(React.memo(AddTeamSeasonModal))``
