import React, { useMemo } from 'react'
import styled from 'styled-components'
import { teamGameService } from '../services'
import CreateModal, { CreateModalProps } from './CreateModal'
import { TeamSeason } from '../types/models'
import { useTeamData } from '../hooks'

export interface AddTeamSeasonGameModalProps extends CreateModalProps {
  teamSeason: TeamSeason
}

const AddTeamSeasonGameModal: React.FC<AddTeamSeasonGameModalProps> = ({
  title,
  service,
  teamSeason,
  ...rest
}) => {
  const { teamBrand } = useTeamData(teamSeason.teamId)
  const initialData = useMemo(
    () => ({
      teamId: teamSeason.teamId,
      teamSeasonId: teamSeason.id,
      isHome: false,
      timezone: teamBrand?.timezone,
    }),
    [teamSeason, teamBrand]
  )

  return (
    <CreateModal
      title="Add Game"
      service={teamGameService}
      initialData={initialData}
      fields={teamGameService.fields}
      {...rest}
    />
  )
}

export default styled(React.memo(AddTeamSeasonGameModal))``
