import React, { useRef, useImperativeHandle, useState, useEffect } from 'react'
import FormField from './FormField'
import { IonToggle, IonInput } from '@ionic/react'
import styled from 'styled-components'
import notifications from '../core/notifications'

export interface FormToggleFieldProps
  extends React.ComponentProps<typeof IonToggle> {
  label?: string
  icon?: any
  save?: (val: any, e: any) => any
  saving?: boolean
  readonly?: boolean
  noIndicator?: boolean
}

const FormToggleField: React.ForwardRefRenderFunction<
  any,
  FormToggleFieldProps
> = (
  {
    className,
    icon,
    label,
    save,
    checked,
    children,
    readonly,
    saving,
    noIndicator,
    ...rest
  },
  ref
) => {
  const [error, setError] = useState(false)
  const [isSaving, setIsSaving] = useState(saving)
  const [isChecked, setIsChecked] = useState(checked)
  const input = useRef<any>()
  useImperativeHandle(ref, () => input.current)

  const onIonChange = async (e: any) => {
    const newValue = e.detail.checked
    setIsChecked(newValue)

    if (save && checked !== newValue) {
      setIsSaving(!noIndicator)
      setError(false)
      try {
        await save(newValue, e)
      } catch (e) {
        notifications.errorToast(e)
        setError(true)
      } finally {
        setIsSaving(false)
      }
    }
  }

  useEffect(() => {
    setIsSaving(saving)
  }, [saving])

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  return (
    <FormField
      label={label}
      icon={icon}
      saving={isSaving}
      error={error}
      className={className}
      lines="full"
    >
      {readonly ? (
        <IonInput value={checked ? 'Yes' : 'No'} readonly />
      ) : (
        <IonToggle
          ref={input}
          onIonChange={onIonChange}
          checked={isChecked}
          {...rest}
        />
      )}
      {children}
    </FormField>
  )
}

export default styled(React.memo(React.forwardRef(FormToggleField)))`
  ion-toggle {
    padding-left: 0;
  }

  ion-label {
    flex: 0 0 auto;
  }
`
