import React, { useRef } from 'react'
import Content from '../components/Content'
import {
  IonModal,
  IonFooter,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
} from '@ionic/react'
import GenericButton, { GenericButtonProps } from '../components/GenericButton'
import styled from 'styled-components'
import { arrowBack } from 'ionicons/icons'

export interface ModalProps extends React.ComponentProps<any> {
  isOpen?: boolean
  title?: string
  closeButtonText?: string

  onDidDismiss?: () => any
  backButton?: GenericButtonProps
  headerAction?: GenericButtonProps
  footerButtons?: GenericButtonProps[]
  maxHeight?: number
  maxWidth?: number
  toolbar?: any
  cancelButtonAction?: () => any
  hideCloseButton?: boolean
}

const Modal: React.FC<ModalProps> = ({
  isOpen = true,
  closeButtonText = 'Close',
  hideCloseButton = false,
  onDidDismiss,
  title,
  headerAction,
  children,
  footerButtons,
  toolbar,
  backButton,
  cancelButtonAction,
  ...rest
}) => {
  const ref = useRef<any>()
  if (!cancelButtonAction) {
    cancelButtonAction = () => ref.current?.dismiss()
  }

  return (
    <IonModal
      ref={ref}
      mode="md"
      isOpen={true}
      onDidDismiss={onDidDismiss}
      {...rest}
    >
      {title && (
        <IonHeader className="modal-header">
          <IonToolbar>
            {backButton && (
              <IonButtons slot="start">
                <GenericButton
                  fill="clear"
                  shape="round"
                  color="medium"
                  icon={arrowBack}
                  {...backButton}
                />
              </IonButtons>
            )}
            <IonTitle>{title}</IonTitle>
            {headerAction && (
              <GenericButton slot="end" fill="clear" {...headerAction} />
            )}
            {!footerButtons && !hideCloseButton && (
              <GenericButton
                onClick={cancelButtonAction}
                slot="end"
                fill="clear"
              >
                {closeButtonText}
              </GenericButton>
            )}
          </IonToolbar>
        </IonHeader>
      )}
      {toolbar}
      <Content className="modal-content">{children}</Content>
      {footerButtons && (
        <IonFooter className="modal-footer">
          <IonToolbar>
            {footerButtons.map((btn) => (
              <GenericButton slot="end" fill="clear" {...btn} />
            ))}
          </IonToolbar>
        </IonFooter>
      )}
    </IonModal>
  )
}

export default styled(Modal)`
  --max-height: ${(props) =>
    props.maxHeight ? `${props.maxHeight}px` : '95vh'};
  --max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : '95vw')};

  @media (min-width: 600px) {
    --max-width: ${(props) =>
      props.maxWidth ? `${props.maxWidth}px` : '600px'};
  }

  --border-radius: 10px;
  .modal-header,
  .modal-footer {
    padding: 0 10px;
  }

  ion-header {
    &::after {
      display: none;
    }
  }

  ion-title {
    color: var(--ion-color-medium);
    font-size: 20px;
    font-weight: normal;
    padding: 0 5px;
  }
`
