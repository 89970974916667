import ModelBaseService from './model-base'
import { DataField } from '../util/data-field'
import validation from '../util/validation'
import masks from '../util/masks'

export class TeamBrandPersonnelService extends ModelBaseService {
  protected modelName = 'team-brand-personnel'

  public get filters(): DataField[] | undefined {
    return [
      {
        label: 'Active',
        key: 'isActive',
        type: 'filter-select',
        options: [
          [true, 'Yes'],
          [false, 'No'],
        ],
      },
    ]
  }

  protected get standardFields(): DataField[] {
    return [
      {
        label: 'First Name',
        key: 'firstName',
        type: 'input',
      },
      {
        label: 'Last Name',
        key: 'lastName',
        type: 'input',
      },
      {
        label: 'Mobile #',
        key: 'primaryPhone',
        type: 'input',
        mask: masks.usPhone,
        validate: validation.usPhone,
      },
      {
        label: 'Email',
        key: 'primaryEmail',
        type: 'input',
        inputType: 'email',
      },
    ]
  }

  public get fields(): DataField[] {
    return this.standardFields
  }
  async updateStatus(id: string, isActive: boolean) {
    return await this.update(id, {
      isActive,
    })
  }
}

export default new TeamBrandPersonnelService()
