import {
  alertController,
  AlertOptions,
  AlertInput,
  actionSheetController,
  ActionSheetOptions,
} from '@ionic/core'
import { Shortcuts } from 'shortcuts'

class Dialogs {
  private shortcuts = new Shortcuts()
  async create(options: AlertOptions) {
    this.shortcuts.reset()
    const alert = await alertController.create(options)

    await alert.present()

    return alert
  }

  async actionSheet(
    header: string,
    buttons: ActionSheetOptions['buttons'],
    options?: Partial<ActionSheetOptions>
  ) {
    const sheet = await actionSheetController.create({
      header,
      buttons,
      ...(options ?? {}),
    })

    await sheet.present()
    return sheet
  }

  async simple(
    message: string,
    header?: string,
    options?: Partial<AlertOptions>
  ) {
    options = options ?? {}
    return await this.create({
      message,
      header,
      buttons: [
        {
          text: 'OK',
        },
      ],
      ...options,
    })
  }

  async confirm(
    message: string,
    header: string,
    onConfirm: () => any,
    onCancel?: () => any,
    options?: Partial<AlertOptions>
  ) {
    options = options ?? {}
    return await this.create({
      message,
      header,
      buttons: [
        {
          text: 'Cancel',
          handler: onCancel,
        },
        {
          text: 'Yes',
          role: 'destructive',
          handler: onConfirm,
        },
      ],
      ...options,
    })
  }

  async confirmYesNo(
    message: string,
    header: string,
    onConfirm: () => any,
    onCancel?: () => any,
    options?: Partial<AlertOptions>
  ) {
    options = options ?? {}
    return await this.create({
      message,
      header,
      buttons: [
        {
          text: 'No',
          handler: onCancel,
        },
        {
          text: 'Yes',
          handler: onConfirm,
        },
      ],
      ...options,
    })
  }

  async input(
    header: string,
    inputs: AlertInput[],
    onSave: (value: any) => any,
    onCancel?: () => any,
    options: Partial<AlertOptions> = {}
  ) {
    const dialog = await this.create({
      header,
      inputs,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            this.shortcuts.reset()
            onCancel?.()
          },
        },
        {
          text: 'Save',
          handler: (e: any) => {
            this.shortcuts.reset()
            onSave(e)
          },
        },
      ],
      ...options,
    })

    this.shortcuts.add({
      shortcut: 'enter',
      handler: () => {
        const buttons: any = document.querySelectorAll('ion-alert button')
        buttons?.[1].click?.()
      },
    })

    const firstInput: any = document.querySelector('ion-alert input')
    firstInput.select()

    return dialog
  }

  async dismiss() {
    await alertController.dismiss()
  }
}

export default new Dialogs()
