import React, { useState, useEffect } from 'react'
import { IonList, IonItem, IonIcon } from '@ionic/react'
import styled from 'styled-components'
import GenericButton from './GenericButton'
import { addCircleOutline } from 'ionicons/icons'
import { DataField, renderDataFields } from '../util/data-field'
import ModelBaseService from '../services/model-base'
import helpers from '../util/helpers'

export interface AddManyListProps extends React.ComponentProps<any> {
  items?: any[]
  buttonText?: string
  fields: DataField[]
  service: ModelBaseService
  defaults?: { [key: string]: any }
  onItemAdded?: (item: any) => any
}

const AddManyList: React.FC<AddManyListProps> = ({
  items,
  fields,
  buttonText,
  service,
  defaults,
  onItemAdded,
  ...rest
}) => {
  const [data, setData] = useState(items)
  const [newItem, setNewItem] = useState<any>()

  const update = (key: string) => (val: string) =>
    setNewItem((it: any) => ({ ...it, [key]: val }))
  const addItem = () => setNewItem(defaults ?? {})
  const save = () =>
    helpers.withLoading(async () => {
      const res = await service.create(newItem)
      setData((d: any) => [...(d ?? []), res])
      onItemAdded?.(res)
      setNewItem(null)
    })

  useEffect(() => {
    setData(items)
  }, [items])

  return (
    <IonList {...rest}>
      {data?.map((it: any) => (
        <IonItem key={it.id}>
          {renderDataFields(
            false,
            fields,
            it,
            service.entitySaveHandler(it.id)
          )}
        </IonItem>
      ))}
      {newItem && (
        <IonItem>
          {renderDataFields(false, fields, newItem, update)}
          <IonIcon icon={addCircleOutline} onClick={save} />
        </IonItem>
      )}
      <GenericButton fill="clear" icon={addCircleOutline} onClick={addItem}>
        {buttonText}
      </GenericButton>
    </IonList>
  )
}

export default styled(React.memo(AddManyList))`
  ion-item {
    display: grid;
  }
`
