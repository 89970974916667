import React from 'react'
import styled from 'styled-components'
import { IonIcon } from '@ionic/react'
import { useShortcuts } from '../hooks'

export interface CircleIconButtonProps extends React.ComponentProps<any> {
  icon?: any
  image?: string
  color?: string
  shortcut?: string
  shortcutAction?: (e: any) => any
}

const CircleIconButton: React.FC<CircleIconButtonProps> = ({
  icon,
  image,
  color,
  shortcut,
  children,
  shortcutAction,
  ...rest
}) => {
  useShortcuts(
    shortcut && (shortcutAction || rest.onClick)
      ? {
          [shortcut]: shortcutAction ?? rest.onClick,
        }
      : {}
  )

  return (
    <button {...rest}>
      {icon && <IonIcon icon={icon} />}
      {image && <img src={image} alt="i" />}
      {shortcut && <span className="shortcut">{shortcut}</span>}
      {children}
    </button>
  )
}

export default styled(React.memo(CircleIconButton))`
  align-items: center;
  appearance: none;
  background-color: ${(props) => `var(--ion-color-${props.color ?? 'medium'})`};
  border: solid 1px white;
  border-radius: 100%;
  color: ${(props) => `var(--ion-color-${props.color ?? 'medium'}-contrast)`};
  font-size: 16px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  height: 40px;
  margin-right: 10px;
  outline: none;
  width: 40px;

  .shortcut {
    font-size: 12px;
  }

  :hover {
    filter: brightness(110%);
  }

  img {
    height: 22px;
    width: 22px;
  }

  ion-icon {
    border-radius: 100%;
    font-size: 22px;
    color: ${(props) => `var(--ion-color-${props.color ?? 'medium'}-contrast)`};
  }
`
