import config from '../core/config'

declare global {
  interface Window {
    gapi?: any
    google?: any
  }
}

class GoogleService {
  private pickerReady = false
  private resolvers: any[] = []

  private onScriptLoaded() {
    window.gapi?.load('picker', () => {
      this.pickerReady = true
      this.resolvers.forEach((it) => it())
    })
  }

  private loadScript() {
    if (!document.getElementById('gapi')) {
      const script = document.createElement('script')
      script.id = 'gapi'
      script.onload = this.onScriptLoaded.bind(this)
      script.src = 'https://apis.google.com/js/api.js'
      document.head.append(script)
    }
  }

  private doShowPicker(token: string, onComplete: (res: any) => any) {
    const picker = new window.google.picker.PickerBuilder()
      .addView(
        new window.google.picker.View(window.google.picker.ViewId.DOCS_VIDEOS)
      )
      .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
      .setOAuthToken(token)
      .setDeveloperKey(config.googlePickerAPIKey)
      .setCallback(onComplete)
      .build()
    picker.setVisible(true)
  }

  async showPicker(token: string, onComplete: (res: any) => any) {
    if (this.pickerReady) {
      this.doShowPicker(token, onComplete)
      return
    }

    await new Promise((resolve) => {
      this.resolvers.push(resolve)
      this.initialize()
    })

    await this.showPicker(token, onComplete)
  }
  initialize() {
    this.loadScript()
  }
}

export default new GoogleService()
