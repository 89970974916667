import React, { useMemo, useContext, useCallback } from 'react'
import styled from 'styled-components'
import { TeamGameEvent } from '../types/models'
import helpers from '../util/helpers'
import { teamGameService, teamGameEventService } from '../services'
import css from 'classnames'
import { GenericButton } from '.'
import { IonIcon } from '@ionic/react'
import { flag, personAdd, filmOutline } from 'ionicons/icons'
import { ScoringContext } from '../contexts/ScoringContext'
import Jersey from './Jersey'
import _ from 'lodash'

export interface TeamGameEventRowProps extends React.ComponentProps<any> {
  event: TeamGameEvent
  highlighted?: boolean
}

const TeamGameEventRow: React.FC<TeamGameEventRowProps> = ({
  event,
  className,
  highlighted,
  ...rest
}) => {
  const { state, dispatch, gameClock, videoPlayer, goToVideoTime } = useContext(
    ScoringContext
  )
  const { events, regulationPeriods = 0, periodType, teamGame } = state
  const { isHomeTeam } = event

  const fill = useMemo(
    () => teamGameService.getJerseyFill(state.teamGame, event.isHomeTeam),
    [state.teamGame, event]
  )

  const stroke = useMemo(
    () => teamGameService.getJerseyStroke(state.teamGame, event.isHomeTeam),
    [state.teamGame, event]
  )

  const teamName = useMemo(
    () => teamGameService.getTeamNameByHome(teamGame, isHomeTeam),
    [teamGame, isHomeTeam]
  )
  const playerName = useMemo(() => event.teamGamePersonnel?.fullName, [event])

  const type = useMemo(
    () => teamGameEventService.getEventType(event.type)?.name,
    [event]
  )

  const score = useMemo(() => teamGameEventService.tally(events, event), [
    event,
    events,
  ])

  const assignPlayer = useCallback(
    (e: any) => {
      e.preventDefault()
      e.stopPropagation()
      const activeEvent = teamGameEventService.toScoreGamePageEvent(event)

      if (!activeEvent?.location) {
        return
      }

      activeEvent.location.screenX = e.screenX
      activeEvent.location.screenY = e.screenY

      dispatch({
        type: 'set',
        value: {
          activeEvent,
        },
      })
      return false
    },
    [dispatch, event]
  )

  const goToEventInVideo = useCallback(
    (e: any) => {
      e.preventDefault()
      e.stopPropagation()

      goToVideoTime((helpers.getAbsoluteVideoTime(state, event) ?? 0) - 4)
    },
    [event, goToVideoTime, state]
  )

  const isOvertime = useMemo(
    () => regulationPeriods && event.period > regulationPeriods,
    [regulationPeriods, event.period]
  )

  const onClick = useCallback(() => {
    videoPlayer.current?.pause()
    gameClock.current?.stop()
    dispatch({
      type: 'editEvent',
      value: event,
    })
  }, [videoPlayer, event, gameClock, dispatch])

  return (
    <div
      className={css(className, { flagged: event?.flagged, highlighted })}
      onClick={onClick}
      {...rest}
    >
      <time>
        {helpers.secondsToTimeString(event.clockTime, {
          millis: false,
          hours: false,
        })}{' '}
        {isOvertime ? 'OT' : periodType}
        {isOvertime ? event.period - regulationPeriods : event.period}
      </time>
      <Jersey
        className="jersey"
        color={fill}
        stroke={stroke}
        number={helpers.getInitials(teamName)}
      />
      <div className="event-details">
        <span
          className={css('event-text', { bold: event.type === 'shot_made' })}
        >
          {event.type === 'foul'
            ? _.startCase(event.attributes?.type).replace(' ', '-') + ' '
            : ''}
          {event.type.match(/^shot/i) ? `${event.pointValue}pt ` : ''}
          {type}
          {event.type === 'jumpball' &&
            ` won by ${
              event.isHomeTeam
                ? state.teamGame?.homeTeamFullName
                : state.teamGame?.awayTeamFullName
            }`}
          {event.teamGamePersonnelId
            ? ` ${
                event.type === 'substitution'
                  ? `${event.attributes?.subIn ? 'in' : 'out'}`
                  : 'by'
              } ${helpers.playerDisplay(
                event.teamGamePersonnel?.jersey,
                playerName
              )}`
            : ''}
        </span>
        {!!event.rel?.length && (
          <div className="related-events">
            {event.rel
              .map((it) => [it, ...(it.rel ?? [])])
              .flat()
              .map((it) => (
                <div key={it.id} className="related-event">
                  {it.type === 'rebound'
                    ? `${
                        it.isHomeTeam === event.isHomeTeam ? 'Offensive' : ''
                      } `
                    : ''}
                  {teamGameEventService.getEventType(it.type)?.name}{' '}
                  {it.teamGamePersonnelId
                    ? `by ${helpers.playerDisplay(
                        it.teamGamePersonnel?.jersey,
                        it.teamGamePersonnel?.fullName
                      )}`
                    : `by ${
                        it.isHomeTeam
                          ? state.teamGame?.homeTeamFullName
                          : state.teamGame?.awayTeamFullName
                      }`}
                </div>
              ))}
          </div>
        )}
      </div>
      {event.flagged && (
        <IonIcon
          className="flag"
          icon={flag}
          color={event.userFlagged ? 'basketball' : 'warning'}
          title={event.flagExplanation?.join(', ') ?? ''}
        />
      )}
      {!event?.teamGamePersonnelId &&
        ['score_adjustment', 'freethrow_violation', 'jumpball'].indexOf(
          event?.type
        ) === -1 &&
        (!state.isSingleTeam ||
          state.isSingleTeamHome === event.isHomeTeam) && (
          <GenericButton
            size="small"
            fill="clear"
            color="light"
            className="btn-assign-player"
            onClick={assignPlayer}
            icon={personAdd}
          />
        )}
      <GenericButton
        size="small"
        fill="clear"
        color="light"
        className="btn-assign-player"
        onClick={goToEventInVideo}
        icon={filmOutline}
      />
      <div className="score">
        <strong>
          {score.home} - {score.away}
        </strong>
      </div>
    </div>
  )
}

export default styled(React.memo(TeamGameEventRow))`
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  padding: 10px 15px;
  margin-bottom: 10px;
  flex: 0 0 auto;
  transition: background ease-in-out 0.15s;

  &.highlighted {
    background: rgba(var(--ion-color-warning-rgb), 0.2);
  }

  &.flagged {
    // background: rgba(var(--ion-color-warning-rgb), 0.2);
  }

  .event-text {
    &.bold {
      color: var(--ion-color-alternate);
      font-weight: bold;
    }
  }

  .jersey {
    flex: 0 0 40px;
    margin-right: 20px;
    height: 40px;
    width: 40px;
  }

  .related-events {
    display: flex;
    flex-direction: column;
  }

  .related-event {
    font-size: 12px;
    color: var(--ion-color-step-300);
  }

  &:hover {
    background: rgba(255, 255, 255, 0.05);
  }

  .flag {
    margin-right: 5px;
  }

  .event-details {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
  }

  .score {
    flex: 0 0 auto;
    margin-left: 20px;
  }

  .player,
  .team {
    flex: 0 0 auto;
    margin-right: 15px;
    font-size: 14px;
  }

  .team {
    color: rgb(200, 200, 200);
  }

  time {
    font-weight: bold;
    flex: 0 0 100px;
  }

  img {
    flex: 0 0 auto;
    height: 35px;
    width: 35px;
    margin-right: 10px;
  }
`
