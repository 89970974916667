import React, { useState, useRef } from 'react'
import { IonAvatar, IonIcon, IonSpinner } from '@ionic/react'
import styled from 'styled-components'
import { cameraOutline } from 'ionicons/icons'
import notifications from '../core/notifications'
import css from 'classnames'
import helpers from '../util/helpers'

export interface AvatarProps extends React.ComponentProps<any> {
  src?: string
  name: string
  email?: string
  borderWidth?: number
  borderColor?: string
  backgroundColor?: string
  color?: string
  allowEdit?: boolean
  onImageSelected?: (file: File) => Promise<any>
}

const Avatar: React.FC<AvatarProps> = ({
  src,
  name,
  email,
  allowEdit,
  onImageSelected,
  ...rest
}) => {
  const fileRef = useRef<any>()
  const image = useRef<any>()
  const [uploading, setUploading] = useState(false)
  const [error, setError] = useState(false)
  const onImageError = () => setError(true)

  if (email && !src) {
    src = helpers.getGravatarUrl(email)
  }

  const onFileChange = async (e: any) => {
    const file = e.target.files?.[0]
    if (file) {
      try {
        setUploading(true)
        await onImageSelected?.(file)
      } catch (e) {
        notifications.errorToast(e)
      } finally {
        setUploading(false)
        fileRef.current.value = ''
      }
    }
  }

  const chooseFile = () => {
    if (!uploading) {
      fileRef.current?.click()
    }
  }

  return (
    <IonAvatar {...rest}>
      {src && !error ? (
        <img ref={image} src={src} onError={onImageError} alt={name} />
      ) : name || !allowEdit ? (
        <span className="initials">
          {helpers.getInitials(name ?? 'Unknown')}
        </span>
      ) : (
        <IonIcon icon={cameraOutline} />
      )}
      {allowEdit && (
        <div
          className={css('edit-overlay', { uploading })}
          onClick={chooseFile}
        >
          {uploading ? (
            <IonSpinner name="crescent" />
          ) : (
            <IonIcon icon={cameraOutline} />
          )}
          <input
            ref={fileRef}
            type="file"
            style={{ display: 'none' }}
            onChange={onFileChange}
            accept=".png,.jpg,.jpeg,.gif,.tiff,.bmp"
          />
        </div>
      )}
    </IonAvatar>
  )
}

export default styled(Avatar)`
  align-items: center;
  background: ${(props) =>
    props.backgroundColor ?? 'var(--ion-color-alternate-tint)'};
  color: ${(props) => props.color ?? 'var(--ion-color-medium-contrast)'};
  border: solid ${(props) => props.borderWidth ?? 1}px
    ${(props) => props.borderColor ?? 'var(--ion-color-secondary)'};
  display: flex;
  justify-content: center;
  position: relative;
  height: ${(props) => props.size ?? 48}px;
  width: ${(props) => props.size ?? 48}px;
  font-size: ${(props) => (props.size ?? 48) / 2.5}px;

  img {
    object-fit: cover;
  }

  --border-radius: 3px;

  :hover .edit-overlay,
  .edit-overlay.uploading {
    opacity: 1;
    backdrop-filter: blur(8px);
    pointer-events: all;
  }

  .initials {
    margin-top: 2px;
    font-family: 'Fugaz One', cursive;
  }

  .edit-overlay {
    align-items: center;
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(0);
    border-radius: 3px;
    cursor: pointer;
    color: var(--ion-color-dark);
    display: flex;
    font-size: ${(props) => (props.size ?? 48) / 3}px;
    justify-content: center;
    position: absolute;
    pointer-events: none;
    opacity: 0;
    transform: translate3d(0);
    transition: all linear 0.2s;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
`
