import { League } from './../types/models'
import StateBaseService from './state-base'
import { http } from '../core'
import { DataField } from '../util/data-field'

class LeagueService extends StateBaseService {
  protected stateKey = 'leagues'
  protected modelName = 'league'

  protected async additionalState(data: any) {
    return {}
  }

  public get fields(): DataField[] {
    return []
  }

  async request() {
    const { data } = await http.authorizedRequest({
      method: 'GET',
      url: '/v1/league/',
    })

    return data
  }

  getLeague(data: League[], leagueId: string, overrides: Partial<League> = {}) {
    let league = data?.find((it) => it.id === leagueId)

    if (league) {
      league = {
        ...league,
        ...overrides,
      }
    }

    return league ?? data[0]
  }
}

export default new LeagueService()
