import React from 'react'
import GenericButton, { GenericButtonProps } from './GenericButton'
import styled from 'styled-components'
import { IonIcon } from '@ionic/react'
import { caretDown } from 'ionicons/icons'
import { dialogs } from '../core'
import { useRouter } from '../hooks'

export interface ActionToolbarProps extends React.ComponentProps<any> {
  buttons?: GenericButtonProps[]
  danger?: GenericButtonProps | false | null | undefined
}

const ActionToolbar: React.FC<ActionToolbarProps> = ({
  buttons = [],
  danger,
  children,
  ...rest
}) => {
  const { router } = useRouter()
  danger = danger ?? buttons?.find?.((it: any) => it.color === 'danger')

  const showActionSheet = () =>
    dialogs.actionSheet('Actions', [
      ...buttons?.map((it) => ({
        text: it.children?.toString(),
        handler() {
          if (it.routerLink) {
            router.push(it.routerLink, it.routerDirection ?? 'forward')
          }

          ;(it as any)?.onClick?.()
        },
      })),
      ...(danger
        ? [
            {
              text: danger.children?.toString(),
              role: 'destructive',
              handler() {
                ;(danger as any)?.onClick?.()
              },
            },
          ]
        : []),
    ])

  return (
    <div {...rest}>
      <div className="actions--tools">
        {buttons
          ?.filter?.((it: any) => it !== danger)
          .map((it, i) => (
            <GenericButton key={i} fill="clear" size="small" {...it} />
          ))}
        {danger && (
          <>
            <div className="flex-spacer" />
            <GenericButton
              size="small"
              fill="clear"
              color="danger"
              {...danger}
            />
          </>
        )}
        {children}
      </div>
      <GenericButton
        onClick={showActionSheet}
        className="actions--menu"
        fill="clear"
      >
        Actions
        <IonIcon icon={caretDown} slot="end" />
      </GenericButton>
    </div>
  )
}

export default styled(React.memo(ActionToolbar))`
  background: var(--ion-color-secondary-tint);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ion-button {
    flex: 0 0 auto;
  }

  .actions--tools {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .actions--menu {
    display: none;
  }

  @media (max-width: 800px) {
    justify-content: flex-end;
    .actions--tools {
      display: none;
    }

    .actions--menu {
      display: block;
    }
  }
`
