import ModelBaseService from './model-base'
import { DataField } from '../util/data-field'

class TeamSeasonService extends ModelBaseService {
  protected modelName = 'team-season'
  public get fields(): DataField[] {
    return [
      {
        key: 'name',
        label: 'Name',
        type: 'input',
        required: true,
      },
      {
        key: 'startDate',
        label: 'Start Date',
        type: 'date',
        required: true,
      },
      {
        key: 'endDate',
        label: 'End Date',
        type: 'date',
        required: true,
      },
      {
        key: 'notes',
        label: 'Notes',
        type: 'textarea',
      },
    ]
  }
}

export default new TeamSeasonService()
