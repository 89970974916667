import React, { useContext, useCallback, useMemo } from 'react'
import { IonContent } from '@ionic/react'
import VirtualList from './VirtualList'
import { ScoringContext } from '../contexts/ScoringContext'
import styled from 'styled-components'
import { TeamGameEventRow } from '.'
import { useSSEListener } from '../hooks'

export interface ScoringEventFeedProps {}

const ScoringEventFeed: React.FC<ScoringEventFeedProps> = (props) => {
  const { state, dispatch, feed } = useContext(ScoringContext)

  const renderEventRow = useCallback(
    ({ data, style, index, highlighted }: any) => (
      <TeamGameEventRow
        key={index}
        event={data[index]}
        style={style}
        highlighted={highlighted}
      />
    ),
    []
  )

  const setEvents = useCallback(
    (events: any) => {
      dispatch({
        type: 'set',
        value: {
          events,
        },
      })
    },
    [dispatch]
  )

  const feedUrl = useMemo(() => `/v1/team-game/${state.teamGameId}/events/`, [
    state.teamGameId,
  ])

  useSSEListener((e: any) => {
    if (e.type === 'db:create') {
      const eventType = e.detail?.data?.type
      if (eventType?.startsWith('clock_')) {
        dispatch({
          type: 'clockEvent',
          value: e.detail.data,
        })
      } else {
        feed.current?.refresh()
      }
    }
  }, 'team-game-event')

  return (
    <div {...props}>
      <IonContent className="feed-content" scrollY={false}>
        <VirtualList
          ref={feed}
          url={feedUrl}
          model="team-game-event"
          ignoredEvents={['db:create']}
          orderBy={[
            ['period', 'clockTime', 'order', 'createdAt'],
            ['desc', 'asc', 'desc', 'desc'],
          ]}
          itemSize={70}
          onLoad={setEvents}
          zeroState={{
            title: `No events recorded yet`,
            text: `Click the court to get started or learn more about how to use this tool.`,
            button: {
              fill: 'clear',
              size: 'small',
              children: 'Learn More',
            },
          }}
          renderItem={renderEventRow}
        />
      </IonContent>
    </div>
  )
}

export default styled(React.memo(ScoringEventFeed))`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  flex: 1 1 auto;
  overflow: hidden;

  .feed-content {
    --background: var(--ion-color-dark);
    --color: white;
    --ion-item-background: transparent;
    --ion-item-color: white;
  }

  .feed-content {
    flex: 1 1 auto;
  }
`
