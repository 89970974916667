import React from 'react'
import Modal, { ModalProps } from './Modal'
import styled from 'styled-components'
import { IonList, IonItem } from '@ionic/react'

export interface ShortcutKey {
  key: string
  description: string
}

export interface KeyboardShortcutsModalProps extends ModalProps {
  shortcuts: ShortcutKey[]
  section?: string
}

const KeyboardShortcutsModal: React.FC<KeyboardShortcutsModalProps> = ({
  shortcuts,
  section,
  ...rest
}) => {
  return (
    <Modal title={`${section} Shortcuts`} {...rest}>
      <IonList lines="none">
        {shortcuts.map((it) => (
          <IonItem key={it.key} className="shortcut">
            <span className="shortcut--key">{it.key}</span>
            <p className="shortcut--description">{it.description}</p>
          </IonItem>
        ))}
      </IonList>
    </Modal>
  )
}

export default styled(React.memo(KeyboardShortcutsModal))`
  .shortcut {
    .shortcut--key {
      background: var(--ion-color-step-50);
      border: solid 1px var(--ion-color-step-100);
      border-radius: 8px;
      font-family: Courier;
      color: var(--ion-color-step-700);
      font-size: 13px;
      font-weight: bold;
      margin-right: 20px;
      padding: 5px 10px;
      text-align: center;
      width: 150px;
    }

    .shortcut--description {
    }
  }
`
