import { TeamPersonnelService } from './team-personnel'
import { DataField } from '../util/data-field'
import { listsService } from '.'

class TeamPlayerService extends TeamPersonnelService {
  public get fields(): DataField[] {
    return [
      {
        key: 'position',
        label: 'Position',
        type: 'select',
        options: listsService.positions,
        required: true,
      },
      {
        key: 'homeJersey',
        label: 'Home Jersey',
        type: 'input',
        required: true,
      },
      {
        key: 'awayJersey',
        label: 'Away Jersey',
        type: 'input',
        required: true,
      },
      {
        key: 'isStarter',
        label: 'Starter',
        type: 'toggle',
      },
    ]
  }
}

export default new TeamPlayerService()
