import React from 'react'
import { TeamGame } from '../types/models'
import styled from 'styled-components'
import {
  IonList,
  IonItem,
  IonLabel,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonText,
} from '@ionic/react'
import { teamGameService } from '../services'
import GameDate from '../components/GameDate'
export interface TeamBrandGamesListProps extends React.ComponentProps<any> {
  data?: TeamGame[]
  title?: string
}
const TeamBrandGamesList: React.FC<TeamBrandGamesListProps> = ({
  data,
  title,
  ...rest
}) => {
  return (
    <IonCard {...rest}>
      <IonCardHeader>
        <IonCardTitle>{title}</IonCardTitle>
      </IonCardHeader>
      <IonList {...rest}>
        {data?.length ? (
          data?.map((it) => {
            return (
              <IonItem key={it.id} routerLink={`/a/team/game/${it.id}/`} detail>
                <GameDate date={it} slot="start" />
                <IonLabel>
                  <IonText className="ion-multiline ion-textwrap">
                    <h4>
                      {it.isHome ? 'vs' : 'at'}{' '}
                      {teamGameService.renderOpponentName(it)}
                    </h4>
                    <p className="subtext">
                      {teamGameService.renderGameTime(it, 'h:mma')}
                    </p>
                  </IonText>
                </IonLabel>
              </IonItem>
            )
          })
        ) : (
          <IonItem lines="none">
            <IonLabel className="ion-text-center">No {title}</IonLabel>
          </IonItem>
        )}
      </IonList>
    </IonCard>
  )
}

export default styled(TeamBrandGamesList)``
