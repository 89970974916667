import React, { useContext, useEffect, useState, useMemo } from 'react'
import Modal from './Modal'
import { ScoringContext } from '../contexts/ScoringContext'
import { IonItem, IonLabel, IonIcon } from '@ionic/react'
import { GenericButton, Banner } from '../components'
import { teamVideoService } from '../services'
import { checkmarkCircle, cloudOfflineOutline } from 'ionicons/icons'
import { useRouter } from '../hooks'

export interface ScoringLocalVideoModalProps {
  onDidDismiss?: () => any
}

const ScoringLocalVideoModal: React.FC<ScoringLocalVideoModalProps> = ({
  onDidDismiss,
}) => {
  const { state, dispatch } = useContext(ScoringContext)
  const { router, replaceURL } = useRouter()
  const [files, setFiles] = useState<{ [key: number]: any }>({})

  const localOnly = useMemo(
    () =>
      state.manifest?.src
        ?.map((it, i) => (it ? -1 : i))
        ?.filter((it) => it > -1),
    [state.manifest]
  )

  const isComplete = useMemo(
    () => Object.keys(files).length === localOnly?.length,
    [files, localOnly]
  )

  useEffect(() => {
    if (isComplete) {
      dispatch({
        type: 'setLocalFiles',
        value: {
          files,
        },
      })
    }
  }, [isComplete, dispatch, files])

  return (
    <Modal
      isOpen={true}
      backdropDismiss={false}
      title="Videos Unavailable"
      onDidDismiss={onDidDismiss}
      cancelButtonAction={() => {
        if (!isComplete) {
          if (router.canGoBack()) {
            router.back()
          } else {
            replaceURL(`/a/team/game/${state.teamGameId}/`)
          }
          onDidDismiss?.()
        }
      }}
      closeButtonText="Cancel"
    >
      <Banner
        icon={cloudOfflineOutline}
        color="warning"
        text="Some of the videos for this game are not available in the cloud. To
        continue, please select the files from your computer."
        small
      />
      {localOnly?.map((it) => (
        <IonItem key={it}>
          <IonLabel>{state.manifest?.filenames?.[it]}</IonLabel>
          <input
            type="file"
            id={`lf-${it}`}
            onChange={async (e: any) => {
              const file = e.target.files[0]
              const hash = file && (await teamVideoService.getFileHash(file))
              if (!file) {
                return alert('Please select a file.')
              }

              setFiles((files) => {
                delete files[it]
                if (file && hash === state.manifest?.hashes?.[it]) {
                  const id = state.manifest.ids[it]
                  files[it] = URL.createObjectURL(file)
                  teamVideoService.localFiles[id] = file
                } else {
                  alert('Please select the correct file.')
                }
                return { ...files }
              })
            }}
            accept={teamVideoService.acceptedVideoExtensions}
            style={{ display: 'none' }}
          />
          {!!files[it] ? (
            <IonIcon slot="end" color="success" icon={checkmarkCircle} />
          ) : (
            <GenericButton
              slot="end"
              fill="clear"
              onClick={() => document.getElementById(`lf-${it}`)?.click()}
            >
              Choose...
            </GenericButton>
          )}
        </IonItem>
      ))}
    </Modal>
  )
}

export default React.memo(ScoringLocalVideoModal)
