import React, { useContext, useMemo, useRef } from 'react'
import { League } from '../types/models'
import { AppContext } from '../contexts/AppContext'
import loading from '../core/loading'
import { leagueService, listsService } from '../services'
import { dialogs } from '../core'
import {
  FormSelectField,
  CourtCanvas,
  FormInputField,
  FormToggleField,
} from '.'
import transforms from '../util/transforms'
import masks from '../util/masks'
import validation from '../util/validation'

export interface LeagueSettingsProps {
  accountId: string
  leagueId: string | null | undefined
  overrides?: Partial<League>
  saveLeagueOverride?: (key: string, val: string) => any
  saveCourtOverride?: (key: string, val: string) => any
  updateLeague: (leagueId: string) => any
  onUpdate?: (key: string, val: string) => any
}

const LeagueSettings: React.FC<LeagueSettingsProps> = ({
  leagueId,
  accountId,
  overrides,
  saveLeagueOverride,
  saveCourtOverride,
  updateLeague,
  onUpdate,
}) => {
  const leagueSelect = useRef<any>()
  const { state, dispatch } = useContext(AppContext)

  const base: League | undefined = useMemo(
    () => state.leagues.find((it) => it.id === leagueId),
    [leagueId, state]
  )

  const league = useMemo(() => {
    return {
      ...(base ?? {}),
      ...(overrides ?? {}),
    }
  }, [base, overrides])

  const court = useMemo(() => {
    const court = state.courts.find((it) => it.id === base?.courtId)
    return {
      ...court,
      ...(base?.courtOverrides ?? {}),
      ...(overrides?.courtOverrides ?? {}),
    }
  }, [state, base, overrides])

  const useRestrictedArc = useMemo(
    () => league?.courtOverrides?.showRestrictedArc ?? court?.showRestrictedArc,
    [league, court]
  )

  const save = async (value: string) => {
    if (value !== 'new') {
      return updateLeague(value)
    } else {
      await dialogs.input(
        'Create Custom League',
        [
          {
            type: 'text',
            name: 'name',
            placeholder: 'League Name',
          },
        ],
        async ({ name }: any) => {
          await loading.create({
            message: 'Creating custom league',
          })
          const res = await leagueService.create({
            ...league,
            name,
            accountId,
          })
          dispatch({
            type: 'customLeagueAdded',
            value: res,
          })
          await updateLeague(res.id)
          loading.dismiss()
        },
        () => {
          if (leagueSelect.current) {
            leagueSelect.current.value = league?.id
          }
        }
      )
    }
  }

  const saveLeague = (key: string) => async (val: any) => {
    if (!league?.id) {
      return
    }

    if (saveLeagueOverride) {
      await saveLeagueOverride(key, val)
    } else {
      const res = await leagueService.update(league.id, { [key]: val })
      dispatch({
        type: 'customLeagueUpdated',
        value: res,
      })
    }
  }

  const saveCourt = (key: string) => async (val: string) => {
    if (saveCourtOverride) {
      await saveCourtOverride(key, val)
    } else {
      const current = league?.courtOverrides ?? []
      await saveLeague('courtOverrides')({ ...current, [key]: val })
      onUpdate?.(key, val)
    }
  }

  return (
    <>
      <FormSelectField
        ref={leagueSelect}
        label="Type"
        value={league.id}
        save={save}
        options={[
          ...(state.leagues.map((it) => [it.id, it.name]) as [any, any][]),
          ['new', 'Create Custom League Settings'],
        ]}
      />

      <div
        className="court-preview"
        style={{
          borderBottom: 'solid 1px var(--ion-color-light)',
          padding: 15,
        }}
      >
        <CourtCanvas maxWidth={300} court={court} nonInteractive />
      </div>
      <FormInputField
        label="Court Length"
        value={court?.widthInches}
        disabled={!league}
        readonly={!league?.accountId}
        mask={masks.width}
        transform={transforms.width}
        validate={validation.courtLength}
        save={saveCourt('widthInches')}
      />

      <FormInputField
        label="Court Width"
        value={court?.heightInches}
        readonly={!league?.accountId}
        disabled={!league}
        mask={masks.height}
        transform={transforms.height}
        validate={validation.courtWidth}
        save={saveCourt('heightInches')}
      />

      <FormSelectField
        label="Period Type"
        value={league?.periodType}
        save={saveLeague('periodType')}
        readonly={!league?.accountId}
        disabled={!league}
        options={listsService.periods}
      />

      <FormInputField
        label="Period Length"
        value={league?.periodDuration}
        disabled={!league}
        readonly={!league?.accountId}
        mask={masks.minutesAndSeconds}
        validate={validation.period}
        transform={transforms.minutesAndSeconds}
        save={saveLeague('periodDuration')}
      />

      <FormInputField
        label="Overtime Period Length"
        value={league?.overtimeDuration}
        disabled={!league}
        readonly={!league?.accountId}
        mask={masks.minutesAndSeconds}
        validate={validation.overtime}
        transform={transforms.minutesAndSeconds}
        save={saveLeague('overtimeDuration')}
      />

      <FormInputField
        label="1&1 Foul Bonus Threshold"
        value={league?.firstFoulThreshold}
        disabled={!league}
        readonly={!league?.accountId}
        validate={validation.positiveInteger}
        save={saveLeague('firstFoulThreshold')}
      />

      <FormInputField
        label="Double Foul Bonus Threshold"
        value={league?.secondFoulThreshold}
        disabled={!league}
        readonly={!league?.accountId}
        validate={validation.positiveInteger}
        save={saveLeague('secondFoulThreshold')}
      />

      <FormSelectField
        label="Foul Reset"
        value={league?.foulReset}
        save={saveLeague('foulReset')}
        readonly={!league?.accountId}
        disabled={!league}
        options={listsService.foulResets}
      />

      <FormToggleField
        label="Uses Shot Clock"
        checked={league?.usesShotClock}
        disabled={!league}
        readonly={!league?.accountId}
        save={saveLeague('usesShotClock')}
      />

      {league?.usesShotClock && (
        <FormInputField
          label="Shot Clock Length"
          value={league?.shotClockDuration}
          disabled={!league}
          readonly={!league?.accountId}
          validate={validation.shotClock}
          save={saveLeague('shotClockDuration')}
        />
      )}

      <FormToggleField
        label="Use Restricted Arc"
        checked={useRestrictedArc}
        disabled={!league}
        readonly={!league?.accountId}
        save={saveCourt('showRestrictedArc')}
      />

      {useRestrictedArc && (
        <FormInputField
          label="Restricted Arc (Inches)"
          value={
            league?.courtOverrides?.restrictedArcInches ??
            court?.restrictedArcInches
          }
          readonly={!league?.accountId}
          validate={validation.restrictedArc}
          save={saveCourt('restrictedArcInches')}
          disabled={!league}
        />
      )}
    </>
  )
}

export default React.memo(LeagueSettings)
