import { Team, TeamBrand, TeamGameSetup } from '../types/models'
import {
  teamGameEventService,
  teamGameService,
  leagueService,
  courtService,
  teamVideoService,
} from '../services'
import React, {
  createContext,
  Dispatch,
  useReducer,
  useContext,
  useMemo,
  useRef,
  RefObject,
  useCallback,
} from 'react'
import { TeamGameEventTypes } from '../services/team-game-event'
import {
  ScoringAction,
  ScoringPageEvent,
  ScoringState,
} from '../types/interfaces'
import _ from 'lodash'
import { AppContext } from './AppContext'
import helpers from '../util/helpers'

//TODO Move substitution to a single API call with all params
async function logSubstitution(
  state: ScoringState,
  isHomeTeam: boolean,
  teamGamePersonnelId: string,
  subIn: boolean
) {
  const event = {
    type: TeamGameEventTypes.substitution,
    isHomeTeam,
    clockTime: state.clock,
    videoTime: helpers.getCurrentRelativeVideoTime(state),
    period: state.period,
    court: state.court,
    league: state.league,
    teamGamePersonnelId,
    teamId: state.teamId,
    isOrigin: true,
  } as ScoringPageEvent

  await teamGameEventService.createEvent(event, {
    attributes: {
      subIn,
    },
    teamGameId: state.teamGameId,
    teamVideoId: state.currentTeamVideoId,
  })
}

async function logClockEvent(state: ScoringState, startClock: boolean) {
  //Don't log clock events once the scoring is complete
  if (isScoringComplete(state)) {
    return
  }

  const event = {
    type: startClock
      ? TeamGameEventTypes.clock_start
      : TeamGameEventTypes.clock_stop,
    isHomeTeam: true,
    clockTime: state.clock,
    videoTime: helpers.getCurrentRelativeVideoTime(state),
    period: state.period,
    court: state.court,
    league: state.league,
    teamId: state.teamId,
    isOrigin: true,
  } as ScoringPageEvent

  await teamGameEventService.createEvent(event, {
    teamGameId: state.teamGameId,
    teamVideoId: state.currentTeamVideoId,
  })
}

async function logJumpball(state: ScoringState, isHomeTeam: boolean) {
  await teamGameEventService.createEvent(
    {
      ...state.jumpballEvent,
      isHomeTeam,
      teamVideoId: state.currentTeamVideoId,
      teamId: state.teamId,
    } as ScoringPageEvent,
    {
      teamGameId: state.teamGameId,
    }
  )
}

async function adjustScore(
  state: ScoringState,
  isHomeTeam: boolean,
  adjustment: number
) {
  const event = {
    type: TeamGameEventTypes.score_adjustment,
    pointValue: adjustment,
    isHomeTeam,
    period: state.period,
    court: state.court,
    league: state.league,
    teamId: state.teamId,
    clockTime: state.clock,
    videoTime: helpers.getCurrentRelativeVideoTime(state),
    isOrigin: true,
  } as ScoringPageEvent

  await teamGameEventService.createEvent(event, {
    teamGameId: state.teamGameId,
    teamVideoId: state.currentTeamVideoId,
  })
}

const saveState = _.debounce(async (state: ScoringState) => {
  if (state.ready && state.teamGameId && !isScoringComplete(state)) {
    await teamGameService.saveState(state)
  }
}, 1000)

function reducer(state: ScoringState, action: ScoringAction) {
  let updated: ScoringState = state

  switch (action.type) {
    case 'set':
      updated = { ...state, ...action.value }
      break

    case 'editEventUpdated':
      if (!state.editEvent) {
        return state
      }

      return { ...state, editEvent: action.value }
    case 'startGame':
      updated = {
        ...state,
        started: true,
        startVideoTime: helpers.getCurrentAbsoluteVideoTime(state),
        startTimestamp: Date.now(),
        clockRunning: true,
        showJumpballPopover: true,
        jumpballEvent: {
          league: state.league,
          court: state.court,
          type: teamGameEventService.types.jumpball,
          period: 1,
          clockTime: state.periodDuration,
          videoTime: helpers.getCurrentRelativeVideoTime(state),
          teamVideoId: state.currentTeamVideoId,
          isOrigin: true,
          isHomeTeam: true,
        },
      }
      logClockEvent(state, true)
      break
    case 'jumpball':
      logJumpball({ ...state }, action.value)
      updated = {
        ...state,
        showJumpballPopover: false,
        jumpballEvent: null,
      }
      break
    case 'toggleClock':
      const startClock = action.value.start === true

      //State is not different, just return
      if (startClock === state.clockRunning) {
        return state
      }

      if (action.value.logEvent !== false) {
        logClockEvent(state, startClock)
      }

      updated = {
        ...state,
        clockRunning: startClock,
      }
      break
    case 'editEvent':
      return { ...state, editEvent: action.value }
    case 'clockEvent':
      let m = state.manifest
      if (m) {
        m.clock = _.orderBy([...(m.clock ?? []), action.value], ['videoTime'])
      }
      return { ...state, manifest: m }
    case 'incrementClock':
      //returning so it doesn't trigger save
      let newTime = state.clock + action.value
      let clockRunning = state.clockRunning
      if (Math.floor(newTime) === -1) {
        clockRunning = false
        newTime = 0
        logClockEvent(state, false)
        updated = { ...state, clock: newTime, clockRunning }
      } else {
        return { ...state, clock: newTime }
      }
      break
    case 'endPeriod':
      updated = {
        ...state,
        showGameSummaryDialog: true,
      }
      break
    case 'startPeriod':
      updated = { ...state, clockRunning: true }
      logClockEvent(state, true)
      break
    case 'endGame':
      updated = { ...state, showGameSummaryDialog: true }
      break
    case 'startOT':
      updated = { ...state, clockRunning: true, period: state.period + 1 }
      logClockEvent(state, true)
      break
    case 'endOT':
      updated = { ...state }
      break
    case 'setLocalFiles':
      if (state.manifest) {
        const src = state.manifest?.src ?? []
        Object.entries(action.value.files).forEach(([k, v]: any) => {
          src[k] = v
        })
        updated = {
          ...state,
          manifest: {
            ...state.manifest,
            src,
          },
        }
      }
      break
    case 'gameSummaryUpdate':
      const homeOffset =
        action.value.updatedHomeScore - action.value.currentHomeScore
      const awayOffset =
        action.value.updatedAwayScore - action.value.currentAwayScore

      if (homeOffset !== 0) {
        adjustScore(state, true, homeOffset)
      }

      if (awayOffset !== 0) {
        adjustScore(state, false, awayOffset)
      }

      const isComplete =
        (state.periodType === 'Q' && state.period > 3) ||
        (state.periodType === 'H' && state.period > 1)

      const overtimePeriods =
        state.periodType === 'Q' ? state.period - 4 : state.period - 2

      if (action.value.resetStarters === true) {
        const resetPlayers = (isHome: boolean) => {
          //Don't bother with single team scoring
          if (state.isSingleTeam && state.isSingleTeamHome !== isHome) {
            return
          }

          const starters = teamGameService.getActiveTeamGamePlayers(
            state.teamGame!,
            isHome
          )

          const bench = teamGameService.getEligibleTeamGamePlayers(
            state.teamGame!,
            isHome
          )

          const active = isHome ? state.activeHome : state.activeAway
          const eligible = isHome ? state.eligibleHome : state.eligibleAway

          const nonStarting = active?.filter((it) => it.isStarting === false)
          const starting = eligible?.filter((it) => it.isStarting === true)
          const nextPeriodState = {
            ...state,
            clock: state.periodDuration,
            period: state.period + 1,
          }

          nonStarting?.forEach((it) =>
            logSubstitution(nextPeriodState, isHome, it.id, false)
          )

          starting?.forEach((it) =>
            logSubstitution(nextPeriodState, isHome, it.id, true)
          )

          if (isHome) {
            state.activeHome = starters
            state.eligibleHome = bench
          } else {
            state.activeAway = starters
            state.eligibleAway = bench
          }
        }

        resetPlayers(true)
        resetPlayers(false)
      }

      if (
        action.value.updatedHomeScore !== action.value.updatedAwayScore &&
        isComplete &&
        state.teamGame
      ) {
        const final = {
          homeFinalScore: action.value.updatedHomeScore,
          awayFinalScore: action.value.updatedAwayScore,
          overtimePeriods,
          isScoringComplete: true,
        }

        teamGameService.update(state.teamGame.id, final)
        updated = {
          ...state,
          teamGame: {
            ...state.teamGame,
            ...final,
          },
          showGameSummaryDialog: false,
        }
      } else {
        updated = {
          ...state,
          period: state.period + 1,
          clock:
            isComplete && state.league.overtimeDuration
              ? state.league.overtimeDuration
              : state.periodDuration,
          showGameSummaryDialog: false,
        }

        //Only switch side of the court on Start of H2 or Q3
        if (
          (state.periodType === 'H' && updated.period === 2) ||
          (state.periodType === 'Q' && updated.period === 3)
        ) {
          updated.homeTeamIsLeft = !state.homeTeamIsLeft
        }
      }

      break
    case 'swapActivePlayer':
      const isHomeTeam = action.value.isHomeTeam
      const eligibleKey = isHomeTeam ? 'eligibleHome' : 'eligibleAway'
      const activeKey = isHomeTeam ? 'activeHome' : 'activeAway'
      const eligible = state[eligibleKey]
      const active = state[activeKey]

      if (!eligible || !active) {
        return state
      }

      const sub = eligible.find((it) => it.id === action.value.to)

      if (action.value.from) {
        const current = active[action.value.index]

        if (current) {
          eligible.push(current)

          //TODO Move substitution to a single API call with all params
          logSubstitution(state, isHomeTeam, current.id, false)
        }
      }

      if (sub) {
        _.remove(eligible, sub)
        if (!action.value.from) {
          active.push(sub)
        } else {
          active[action.value.index] = sub
        }

        //TODO Move substitution to a single API call with all params
        logSubstitution(state, isHomeTeam, sub.id, true)
      }

      updated = {
        ...state,
        [activeKey]: active,
        [eligibleKey]: eligible,
      }
      break
  }
  saveState(updated)
  return updated
}

export interface Score {
  home: number
  away: number
}

export function renderEventSummary(
  event: ScoringPageEvent,
  state: ScoringState,
  origin = false
) {
  return (
    <div key={event.type.id} className={origin ? 'origin' : undefined}>
      {event.type.pointValueRelevant ? `${event.pointValue}pt ` : ''}
      {event.type.headerText ?? event.type.name}
      {event.jerseyNumber
        ? ` by ${helpers.playerDisplay(event.jerseyNumber, event.jerseyName)}`
        : ` by ${
            event.teamId
              ? event.isHomeTeam
                ? state.teamGame?.homeTeamFullName
                : state.teamGame?.awayTeamFullName
              : '...'
          }`}
    </div>
  )
}

export interface ScoringContextProps {
  dispatch: Dispatch<ScoringAction>
  state: ScoringState
  score: Score
  isOvertime: boolean
  completed: boolean
  periodDisplay: string
  gameClock: RefObject<any>
  videoPlayer: RefObject<any>
  feed: RefObject<any>
  goToVideoTime: (time: number) => void
}

const initial = {
  clock: 0,
  period: 1,
  videoTime: 0,
  videoStart: 0,
  videoEnd: 0,
  videoPlaying: false,
  clockRunning: false,
  showGameSummaryDialog: false,
  periodType: 'Q',
  periodDuration: 0,
  isHome: true,
  isLive: false,
  homeTeamIsLeft: true,
  homeRosterIsLeft: true,
  started: false,
  showJumpballPopover: false,
  isSingleTeam: false,
  isSingleTeamHome: false,
  totalVideoDuration: 0,
} as ScoringState

const ScoringContext = createContext<ScoringContextProps>({
  state: initial,
  dispatch: () => undefined,
  score: {
    home: 0,
    away: 0,
  },
  isOvertime: false,
  completed: false,
  periodDisplay: '',
  gameClock: React.createRef(),
  videoPlayer: React.createRef(),
  feed: React.createRef(),
  goToVideoTime: () => {},
})

export interface ScoringContextProviderProps {
  setup: TeamGameSetup
  type: string
}

export function isScoringComplete(state: ScoringState) {
  return state.teamGame?.isScoringComplete === true
}

const ScoringContextProvider: React.FC<ScoringContextProviderProps> = ({
  children,
  type,
  setup,
}) => {
  const { state: appState } = useContext(AppContext)
  const { leagues, courts, user } = appState
  const { locked, manifest, teamGame, teamVideos } = setup
  const teamGameId = teamGame.id
  const isLive = type === 'live'
  const { state: gameState } = teamGame
  const teamId = teamGame.teamId
  const team = user?.teams.find((it: Team) => it.id === teamId)
  const teamBrand = user?.teamBrands?.find(
    (it: TeamBrand) => it.id === team?.teamBrandId
  )

  const league = leagueService.getLeague(
    leagues,
    teamGame.leagueId ?? teamBrand?.leagueId ?? '',
    teamGame.leagueOverrides ?? {}
  )

  const periodType = league.periodType === 'HALF' ? 'H' : 'Q'
  const periodDuration = league.periodDuration ?? 0
  const court = courtService.getCourt(
    courts,
    league.courtId,
    teamGame.leagueOverrides?.courtOverrides ?? {}
  )
  const title = `Scoring ${teamGameService.renderMatchup(teamGame)}`
  const isHome = !!teamGame.isHome
  const clock = gameState?.clockTime ?? periodDuration
  const currentTeamVideoId =
    gameState?.currentTeamVideoId ?? teamVideos?.[0]?.id
  const videoTime = gameState?.videoTime ?? 0
  const [start, end] = teamGameService.getStartAndEndForManifestIndex(
    manifest,
    manifest.ids.indexOf(currentTeamVideoId)
  )
  const videoStart = gameState?.videoStart ?? start
  const videoEnd = gameState?.videoEnd ?? end
  const period = gameState?.period ?? 1
  const started = gameState?.started ?? false
  const homeTeamIsLeft = gameState?.homeTeamIsLeft ?? true
  const homeRosterIsLeft = gameState?.homeRosterIsLeft ?? true
  const activeHome = teamGameService.getActiveTeamGamePlayers(
    teamGame,
    true,
    gameState?.activeHomeRosterIds
  )
  const activeAway = teamGameService.getActiveTeamGamePlayers(
    teamGame,
    false,
    gameState?.activeAwayRosterIds
  )
  const eligibleHome = teamGameService.getEligibleTeamGamePlayers(
    teamGame,
    true,
    gameState?.activeHomeRosterIds
  )
  const eligibleAway = teamGameService.getEligibleTeamGamePlayers(
    teamGame,
    false,
    gameState?.activeAwayRosterIds
  )

  let totalVideoDuration = 0

  //Check local file cache and use those
  if (manifest) {
    manifest.src =
      manifest.src?.map((it: string, i: number) => {
        if (!it) {
          const id = manifest.ids[i]
          const local = teamVideoService.localFiles[id]
          if (local) {
            return URL.createObjectURL(local)
          }
        }

        return it
      }) ?? []
    totalVideoDuration = _.sum(manifest.durations)
  }

  const initialState = {
    isLive,
    teamGame,
    team,
    videoPlaying: false,
    clockRunning: false,
    showGameSummaryDialog: false,
    teamBrand,
    league,
    court,
    title,
    periodType,
    periodDuration,
    period,
    isHome,
    manifest,
    teamId,
    teamGameId,
    clock,
    videoTime,
    videoStart,
    videoEnd,
    locked,
    currentTeamVideoId,
    activeHome,
    activeAway,
    eligibleHome,
    eligibleAway,
    started,
    homeTeamIsLeft,
    homeRosterIsLeft,
    ready: true,
    regulationPeriods: periodType === 'Q' ? 4 : 2,
    events: [],
    showJumpballPopover: false,
    isSingleTeam: teamGame.scoringType === 'SINGLE_TEAM',
    isSingleTeamHome: teamGame.isHome,
    singleTeamName: teamGame.isHome
      ? teamGame.homeTeamFullName
      : teamGame.awayTeamFullName,
    unscoredTeamName: teamGame.opponentFullName,
    totalVideoDuration,
  }

  const gameClock = useRef<any>()
  const videoPlayer = useRef<any>()
  const feed = useRef<any>()

  const [state, dispatch] = useReducer(reducer, initialState)

  const score = useMemo(() => teamGameEventService.tally(state.events), [
    state.events,
  ])

  const isOvertime = useMemo(
    () => !!state.regulationPeriods && state.period > state.regulationPeriods,
    [state.regulationPeriods, state.period]
  )

  const completed = useMemo(() => isScoringComplete(state), [state])

  const periodDisplay = useMemo(
    () =>
      `${isOvertime ? 'OT' : state.periodType}${
        isOvertime && state.regulationPeriods
          ? state.period - state.regulationPeriods
          : state.period
      }`,
    [isOvertime, state.periodType, state.period, state.regulationPeriods]
  )

  const goToVideoTime = useCallback(
    (videoTime: number) => {
      videoPlayer.current?.pause()

      _.defer(() => {
        dispatch({
          type: 'set',
          value: {
            videoTime: Math.min(Math.max(videoTime, 0), state.totalVideoDuration),
          },
        })

        _.defer(() => videoPlayer.current?.play())
      })
    },
    [dispatch, state.totalVideoDuration]
  )

  const value = useMemo(
    () => ({
      state,
      dispatch,
      score,
      isOvertime,
      completed,
      periodDisplay,
      gameClock,
      videoPlayer,
      goToVideoTime,
      feed,
    }),
    [
      state,
      dispatch,
      goToVideoTime,
      periodDisplay,
      score,
      isOvertime,
      completed,
    ]
  )

  return (
    <ScoringContext.Provider value={value}>{children}</ScoringContext.Provider>
  )
}

const ScoringContextConsumer = ScoringContext.Consumer

export { ScoringContext, ScoringContextProvider, ScoringContextConsumer }
