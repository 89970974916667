import React, { useContext, useRef, useState } from 'react'
import styled from 'styled-components'
import { AppContext } from '../contexts/AppContext'
import { Avatar } from '.'
import { IonIcon, IonItem } from '@ionic/react'
import { userService } from '../services'
import { chevronDown } from 'ionicons/icons'
import { useClickOutside } from '../hooks'
import css from 'classnames'

export interface UserMenuProps extends React.ComponentProps<any> {
  arrowColor: string
  hideArrow?: boolean
}

const UserMenu: React.FC<UserMenuProps> = ({
  arrowColor = 'medium',
  hideArrow = false,
  className,
  ...props
}) => {
  const dropdown = useRef<any>()
  const { state } = useContext(AppContext)
  const [isOpen, setIsOpen] = useState(false)
  const close = () => setIsOpen(false)
  useClickOutside(dropdown, close)

  if (!state.isAuthenticated) {
    return <div />
  }

  return (
    <div className={css(className, { 'no-arrow': hideArrow })} {...props}>
      <Avatar
        borderColor="white"
        src={state.user?.avatarURL}
        name={state.user?.fullName}
        email={userService.getPrimaryEmail(state.user)?.email}
        size={40}
        onClick={() => setIsOpen(true)}
      />
      {!hideArrow && (
        <IonIcon
          color={arrowColor}
          icon={chevronDown}
          onClick={() => setIsOpen(true)}
        />
      )}
      {isOpen && (
        <div ref={dropdown} className="dropdown">
          <IonItem routerLink="/a/dashboard/" lines="full">
            Dashboard
          </IonItem>
          <IonItem routerLink="/a/profile/" lines="full">
            Profile
          </IonItem>
          <IonItem routerLink="/logout/" lines="full">
            Logout
          </IonItem>
        </div>
      )}
    </div>
  )
}

export default styled(UserMenu)`
  align-items: center;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto auto;
  margin: 10px;
  position: relative;
  ion-avatar {
    flex: 0 0 auto;
    margin-right: 8px;
  }

  ion-icon {
    color: white;
    font-size: 22px;
  }

  &.no-arrow {
    .dropdown {
      &:before {
        right: 17px;
      }
    }
  }

  .dropdown {
    background: white;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    position: absolute;
    top: 100%;
    right: -7px;
    width: 200px;
    z-index: 100;

    ion-item {
      --ion-item-background: transparent;

      &:last-child {
        --border-color: transparent;
      }
    }

    &:before {
      border: solid 10px transparent;
      border-bottom-color: white;
      content: '';
      display: block;
      top: 0;
      right: 0;
      position: absolute;
      transform: translate(-8px, -100%);
    }
  }
`
