import { Plugins } from '@capacitor/core'
import http from './http'
const { Storage } = Plugins

export interface LoginCredentials {
  username: string
  password: string
}

class Authentication {
  private _tk: string = 'whistle'

  async getAccessToken(forceMainToken = false) {
    return undefined
    const item = await Storage.get({ key: this._tk })
    if (forceMainToken) {
      return item?.value
    }
    return sessionStorage.getItem(this._tk) ?? item?.value
  }

  async setAccessToken(value: string) {
    await Storage.set({ key: this._tk, value })
  }

  async clear() {
    await Storage.clear()
    sessionStorage.clear()
  }

  async loadState() {
    const accessToken = await this.getAccessToken()
    return {
      isAuthenticated: !!accessToken,
      accessToken,
    }
  }

  async login(credentials: LoginCredentials) {
    const { data } = await http.request({
      method: 'POST',
      url: '/v1/token/',
      data: credentials,
    })

    if (!data.token) {
      return false
    }
    await this.setAccessToken(data.token)
  }
}

export default new Authentication()
