class AppConfig {
  readonly baseURL: string =
    process.env.REACT_APP_BASE_URL ?? 'https://api.jumpscout.com'
  readonly gaStreamId = process.env.REACT_APP_GA_STREAM_ID ?? 'UA-165890954-1'
  readonly title = process.env.REACT_APP_PAGE_TITLE ?? 'JumpScout'
  readonly isProduction = process.env.NODE_ENV === 'production'
  readonly isDevelopment = process.env.NODE_ENV !== 'production'
  readonly isServiceWorkerEnabled = process.env.REACT_APP_SW_ENABLED ?? false
  readonly version = process.env.REACT_APP_VERSION ?? 'development'
  readonly logEvents = process.env.LOG_EVENTS ?? false
  readonly googlePickerAPIKey =
    process.env.GOOGLE_PICKER_API_KEY ??
    'AIzaSyASvs2gfqaKdaKPEO1EEI-5qtjCqdrPnsY'
  readonly recaptchaKey =
    process.env.RECAPTCHA_KEY ?? '6LfH-qkZAAAAAGkpaTQVsgV44xaAvvyPPn0c1LUj'
  readonly stripePK =
    process.env.REACT_APP_STRIPE_PK ??
    'pk_test_51HDYjVACJg6vii2DIg63vKQGw191sGd3d9zuZxbN6a66KDCSqLKHDd8OGcdyHZhKf9pXlBoousfMOSM8FquiMWwA00HvCqbb2h'
}

export default new AppConfig()
