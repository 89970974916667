import React, {
  useMemo,
  useContext,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react'
import {
  Content,
  AvatarHeader,
  Header,
  ActionButton,
  Banner,
} from '../components'
import { IonList } from '@ionic/react'
import { useData, useRouter, useSSEListener } from '../hooks'
import notifications from '../core/notifications'
import { NotFound } from '../pages'
import ModelBaseService from '../services/model-base'
import { renderDataFields, DataField } from '../util/data-field'
import { GenericButtonProps } from './GenericButton'
import { AppContext, AppState } from '../contexts/AppContext'
import { ActionButtonProps } from './ActionButton'
import loading from '../core/loading'
import { dialogs } from '../core'
import ActionToolbar from './ActionToolbar'
import { trashOutline } from 'ionicons/icons'
import { BannerProps } from './Banner'

export interface EditContentProps {
  service: ModelBaseService
  supportsAvatar?: boolean
  getParentEntity?: (state: AppState, data: any) => any
  parentRequired?: boolean
  buttons?: GenericButtonProps | GenericButtonProps[]
  renderName?: (data: any) => any
  entityId?: string
  entity?: any
  noHeader?: boolean
  readonlyAvatar?: boolean
  renderTitle?: (data: any) => any
  renderSubtitle?: (data: any) => any
  renderAvatarURL?: (data: any) => any
  afterUpdate?: (changes: any, updated?: any) => any
  backgroundColor?: string
  actionButton?: ActionButtonProps
  toolbarButtons?:
    | GenericButtonProps[]
    | ((data?: any, onUpdate?: (res: any) => any) => GenericButtonProps[])
  allowDestroy?: boolean | Function
  afterDestroyURL?: string
  fields?: DataField[]
  destroyText?: string
  destroyDialogText?: string
  model?: string
  banner?: ((data?: any) => BannerProps | null) | BannerProps | null | false
}

const EditContent: React.ForwardRefRenderFunction<any, EditContentProps> = (
  {
    service,
    supportsAvatar,
    readonlyAvatar,
    getParentEntity,
    parentRequired,
    buttons,
    children,
    renderName = (data?: any) => data?.fullName ?? '',
    renderTitle = (data?: any) => data?.fullName ?? '',
    entityId,
    entity,
    noHeader,
    backgroundColor,
    actionButton,
    allowDestroy,
    toolbarButtons,
    afterDestroyURL,
    renderSubtitle,
    renderAvatarURL,
    destroyText = 'Delete',
    destroyDialogText = 'Are you sure you want to delete this?',
    fields,
    afterUpdate,
    model,
    banner,
  },
  ref
) => {
  const { replaceURL } = useRouter()
  const { state } = useContext(AppContext)
  const [destroyed, setDestroyed] = useState(false)

  const loaded = useData(entityId && service.getEndpointUrl(entityId))

  const { updateData } = loaded
  const data = useMemo(() => entity ?? loaded.data, [loaded.data, entity])
  const ready = useMemo(() => (entity ? true : loaded.ready), [
    loaded.ready,
    entity,
  ])
  const name = useMemo(() => renderName?.(data), [data, renderName])

  const parentEntity = useMemo(() => ready && getParentEntity?.(state, data), [
    getParentEntity,
    ready,
    data,
    state,
  ])

  useImperativeHandle(ref, () => ({
    get data() {
      return data
    },
  }))

  useEffect(() => {
    setDestroyed(false)
  }, [entityId, entity])

  const canEdit = useMemo(
    () => service.hasWriteAccess(parentEntity?.role ?? data?.role),
    [service, data, parentEntity]
  )

  const title = useMemo(() => renderTitle?.(data), [renderTitle, data])
  const subtitle = useMemo(() => renderSubtitle?.(data), [renderSubtitle, data])
  const avatarURL = useMemo(() => data?.avatarURL ?? renderAvatarURL?.(data), [
    data,
    renderAvatarURL,
  ])

  const customBanner = useMemo(() => {
    if (!ready) return null

    if (banner instanceof Function) {
      return banner(data)
    }

    return banner
  }, [banner, data, ready])

  useSSEListener((e: any) => {
    if (model) {
      if (e.detail.data.id === entityId) {
        if (e.type === 'db:destroy') {
          setDestroyed(true)
        } else {
          updateData(e.detail.data)
        }
      }
    }
  }, model)

  if (!ready) {
    return <div />
  } else if ((!parentEntity && parentRequired) || !data) {
    return <NotFound />
  }

  const patch = async (fn: any) => {
    const d = await fn()
    if (entity) {
      Object.assign(entity, d)
    } else {
      updateData(d)
    }
  }

  const save = (key: string) => async (val: string) =>
    patch(async () => {
      const changes = { [key]: val }
      const res = await service.update(data.id, changes)
      afterUpdate?.(changes, res)
      return res
    })

  const updateAvatar = async (file: File) =>
    patch(async () => {
      const res = await service.uploadAvatar(data.id, file)
      afterUpdate?.({ avatarURL: res.avatarURL }, res)
      return res
    })

  const destroy = async () => {
    try {
      await loading.create()
      if (typeof allowDestroy === 'function') {
        await allowDestroy()
      } else {
        await service.destroy(data.id)
      }
      replaceURL(afterDestroyURL ?? '/a/dashboard/')
    } catch (e) {
      notifications.errorToast(e)
    } finally {
      await loading.dismiss()
    }
  }

  const confirmDestroy = () =>
    dialogs.confirm(destroyDialogText, 'Confirm', destroy)

  return (
    <>
      {supportsAvatar ? (
        <AvatarHeader
          src={avatarURL}
          name={name}
          email={data.primaryEmail}
          onImageSelected={!readonlyAvatar && updateAvatar}
          buttons={buttons}
          title={title}
          subtitle={subtitle}
          backgroundColor={backgroundColor}
        />
      ) : (
        !noHeader && <Header title={title} buttons={buttons} />
      )}
      {(toolbarButtons || allowDestroy) && (
        <ActionToolbar
          buttons={
            typeof toolbarButtons === 'function'
              ? toolbarButtons(data, updateData)
              : toolbarButtons
          }
          danger={
            allowDestroy && {
              children: destroyText,
              onClick: confirmDestroy,
            }
          }
        />
      )}
      {destroyed && (
        <Banner
          icon={trashOutline}
          color="danger"
          text="This item has been deleted and can no longer be edited."
          small
        />
      )}
      {customBanner && <Banner small {...customBanner} />}
      <Content>
        <IonList>
          {renderDataFields(
            false,
            fields ??
              service.fields.map((field) => ({
                ...field,
                readonly: !canEdit || destroyed,
              })),
            data,
            save
          )}
          {children}
        </IonList>
        {actionButton && <ActionButton {...actionButton} />}
      </Content>
    </>
  )
}

export default React.forwardRef(EditContent)
