import { TeamBrand, TeamGameSetup, GroupedRoster } from './../types/models'
import { Team } from '../types/models'
import ModelBaseService from './model-base'
import { DataField } from '../util/data-field'
import { listsService } from '.'
import { http } from '../core'
import _ from 'lodash'
import helpers from '../util/helpers'

class TeamService extends ModelBaseService {
  protected modelName = 'team'
  public get fields(): DataField[] {
    return [
      {
        label: 'Level',
        key: 'level',
        type: 'select',
        options: listsService.teamLevels,
      },
    ]
  }

  getTeamLevelDisplay(team?: Team, teamBrand?: TeamBrand) {
    if (!team) {
      return ''
    }

    switch (team.level) {
      case 1:
        return 'Varsity'
      case 2:
        return 'Junior Varsity'
      case 3:
        return 'Freshman'
      case 4:
        return 'Other'
      default:
        return teamBrand?.name ?? 'Unknown'
    }
  }

  getTeamDisplay(team?: Team, teamBrand?: TeamBrand): string {
    return this.getTeamLevelDisplay(team, teamBrand)
  }

  getTeamGenderAndLevelDisplay(team?: Team, teamBrand?: TeamBrand): string {
    if (!team || !teamBrand) {
      return ''
    }

    return `${teamBrand?.gender}${
      team.level > 0 ? ` / ${this.getTeamLevelDisplay(team, teamBrand)}` : ''
    }`
  }

  getFullTeamDisplay(team?: Team, teamBrand?: TeamBrand): string {
    if (!team) {
      return ''
    }

    return `${teamBrand?.gender}${
      team.level > 0 ? ` - ${this.getTeamLevelDisplay(team, teamBrand)}` : ''
    } - ${teamBrand?.mascot}`
  }

  async getActiveRosterPlayers(teamId: string) {
    const { data } = await http.authorizedRequest({
      url: `/v1/team/${teamId}/active-roster/players/`,
    })

    return data
  }

  getGamePlayer(data: any, setup: TeamGameSetup) {
    const gamePlayers = setup?.teamGame?.personnel ?? []
    return gamePlayers.find(
      (gp: any) =>
        (gp.teamBrandPersonnelId ?? gp.unmanagedTeamPersonnelId) ===
        (data.teamBrandPersonnelId ?? data.id)
    )
  }

  getGroupedRoster(collection: any[], setup: TeamGameSetup): GroupedRoster {
    const roster = collection ?? []

    const grouped = _.groupBy(_.filter(roster, helpers.isPlayer), (it: any) => {
      const gamePlayer = this.getGamePlayer(it, setup)
      it.teamGamePersonnel = gamePlayer
      return gamePlayer
        ? gamePlayer.isStarting
          ? 'starters'
          : 'active'
        : 'inactive'
    })

    return {
      starters: _.orderBy(grouped.starters, 'teamGamePersonnel.order') ?? [],
      active: _.orderBy(grouped.active, 'teamGamePersonnel.order') ?? [],
      inactive: _.orderBy(grouped.inactive, 'lastName') ?? [],
    }
  }

  getStaff(isHome: boolean, setup: TeamGameSetup) {
    const staff = _.filter(
      setup.teamGame.personnel ?? [],
      (it: any) => !helpers.isPlayer(it) && it.isHomeTeam === isHome
    )

    return _.orderBy(staff, 'lastName')
  }

  getJerseyNumber(player: any, isHome: boolean, reverse: boolean = false) {
    const home = reverse ? !isHome : isHome
    const { awayJersey, homeJersey } = player
    return (home ? homeJersey : awayJersey) ?? ''
  }

  getPosition(player: any) {
    return player?.position
  }
}

export default new TeamService()
