import React, { useContext, useMemo } from 'react'
import styled from 'styled-components'
import { teamBrandStaffService } from '../services'
import { AppContext } from '../contexts/AppContext'
import CreateModal, { CreateModalProps } from './CreateModal'

export interface AddTeamBrandStaffModalProps extends CreateModalProps {}

const AddTeamBrandStaffModal: React.FC<AddTeamBrandStaffModalProps> = ({
  title,
  service,
  ...rest
}) => {
  const { state } = useContext(AppContext)
  const { teamBrand } = state
  const initialData = useMemo(
    () => ({
      teamBrandId: teamBrand?.id,
    }),
    [teamBrand]
  )

  return (
    <CreateModal
      title="Add Staff Member"
      service={teamBrandStaffService}
      supportsAvatar
      initialData={initialData}
      fields={teamBrandStaffService.fields}
      {...rest}
    />
  )
}

export default styled(React.memo(AddTeamBrandStaffModal))``
