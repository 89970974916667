import { TeamBrand, Team } from './../types/models'
import { http, authentication } from '../core'
import { User } from '../types/models'
import StateBaseService from './state-base'
import { DataField } from '../util/data-field'

export interface UserRegistration extends Partial<User> {
  email: string
  password: string
  accountName: string
  code: string
}

class UserService extends StateBaseService {
  protected modelName = 'user'
  protected stateKey = 'user'
  protected selectedTeamKey = 'teamId'
  protected selectedTeamBrandKey = 'teamBrandId'
  public get fields(): DataField[] {
    return [
      {
        key: 'firstName',
        label: 'First Name',
        type: 'input',
        required: true,
      },
      {
        key: 'lastName',
        label: 'Last Name',
        type: 'input',
        required: true,
      },
      {
        key: 'jobTitle',
        label: 'Title',
        type: 'input',
      },
      {
        key: 'username',
        label: 'Username',
        type: 'input',
        required: true,
      },
      {
        key: 'communications',
        label: 'Communications',
        type: 'select',
        options: [
          [1, 'Email'],
          [2, 'SMS'],
          [3, 'Both'],
        ],
        required: true,
      },
    ]
  }

  protected async additionalState(data: any) {
    const teamBrand = await this.selectedItemOrFirst(
      data?.teamBrands,
      this.selectedTeamBrandKey
    )

    const team = await this.selectedItemOrFirst(
      data?.teams?.filter(
        (it: any) => !teamBrand || it.teamBrandId === teamBrand.id
      ),
      this.selectedTeamKey
    )

    const { accounts } = data
    delete data.accounts

    return {
      teamBrand,
      team,
      accounts,
      hasNotifications: !!data?.notifications?.length,
    }
  }

  async updateSelectedTeamBrand(teamBrand?: TeamBrand) {
    this.setStorage(this.selectedTeamBrandKey, teamBrand?.id)
  }

  async updateSelectedTeam(team?: Team) {
    this.setStorage(this.selectedTeamKey, team?.id)
  }

  async register(user: UserRegistration) {
    const { data } = await http.authorizedRequest({
      method: 'POST',
      url: '/v1/user/',
      data: user,
    })

    if (data.token) {
      await authentication.setAccessToken(data.token)
    }

    return data
  }

  async request() {
    const { data } = await http.authorizedRequest({
      method: 'GET',
      url: '/v1/user/',
    })

    return data
  }

  getPrimaryEmail(user?: User | Partial<User>) {
    return user?.emails?.find((it) => it.primary) ?? user?.emails?.[0]
  }

  getPrimaryPhone(user?: User | Partial<User>) {
    return (
      user?.phoneNumbers?.find((it) => it.primary) ?? user?.phoneNumbers?.[0]
    )
  }

  async updatePrimaryEmail(email: string) {
    const { data } = await http.authorizedRequest({
      method: 'POST',
      url: this.getEndpointUrl('email'),
      data: {
        email,
        primary: true,
      },
    })

    return data
  }

  async updatePrimaryPhone(phone: string) {
    const { data } = await http.authorizedRequest({
      method: 'POST',
      url: this.getEndpointUrl('phone'),
      data: {
        phone,
        primary: true,
      },
    })

    return data
  }

  async changePassword(currentPassword: string, newPassword: string) {
    const { data } = await http.authorizedRequest({
      method: 'POST',
      url: this.getEndpointUrl('password'),
      data: {
        currentPassword,
        newPassword,
      },
    })

    return data
  }

  async getMatchingCoachNames(coachName: string | undefined) {
    const { data } = await http.authorizedRequest({
      method: 'GET',
      url: this.getEndpointUrl(null, 'coach-names', coachName),
    })

    return data
  }

  async createNewTokenForUser(username: string) {
    const { data } = await http.authorizedRequest({
      method: 'POST',
      url: this.getEndpointUrl('createtoken'),
      data: {
        username: username.length ? username : undefined,
      },
    })
    return data
  }

  async forgotPassword(username: string, email: string, code: string) {
    const { data } = await http.authorizedRequest({
      method: 'POST',
      url: this.getEndpointUrl('password/reset'),
      data: {
        username: username.length ? username : undefined,
        email: email.length ? email : undefined,
        code,
      },
    })

    return data
  }

  async resetPassword(value: string, token: string, password: string) {
    const { data } = await http.authorizedRequest({
      method: 'POST',
      url: this.getEndpointUrl('password/reset/complete'),
      data: {
        value,
        token,
        password,
      },
    })

    return data
  }

  async resendVerification(value: string, type: 'EMAIL' | 'PHONE') {
    const { data } = await http.authorizedRequest({
      method: 'POST',
      url: this.getEndpointUrl('verify/resend'),
      data: {
        value,
        type,
      },
    })

    return data
  }

  async verify(value: string, type: 'EMAIL' | 'PHONE', code: string) {
    const { data } = await http.authorizedRequest({
      method: 'POST',
      url: this.getEndpointUrl('verify'),
      data: {
        value,
        type,
        code,
      },
    })

    return data
  }

  async acceptInvitation(id: string, d: any) {
    const { data } = await http.request({
      method: 'POST',
      url: `/v1/invitation/${id}/accept/`,
      data: d,
    })

    if (data.token) {
      await authentication.setAccessToken(data.token)
    }

    return data
  }
}

export default new UserService()
