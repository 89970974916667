import React, { useMemo } from 'react'
import { TeamBrandChecklistStatus } from '../types/models'
import styled from 'styled-components'
import {
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
} from '@ionic/react'
import { checkmarkCircle, ellipseOutline } from 'ionicons/icons'
import css from 'classnames'

export interface TeamBrandChecklistProps extends React.ComponentProps<any> {
  data?: TeamBrandChecklistStatus
}

const ChecklistItems = [
  {
    key: 'staff',
    text: 'Add Staff',
    path: '/a/program/staff/',
  },
  {
    key: 'players',
    text: 'Add Players',
    path: '/a/program/players/',
  },
  {
    key: 'seasons',
    text: 'Add a season',
    path: '/a/team/schedule/',
  },
  {
    key: 'games',
    text: 'Add a schedule',
    path: '/a/team/schedule/',
  },
  {
    key: 'roster',
    text: 'Build your roster',
    path: '/a/team/roster/',
  },
  {
    key: 'videos',
    text: 'Add a video',
    path: '/a/team/videos/',
  },
  {
    key: 'scored',
    text: 'Score a game',
    path: '/a/team/schedule/',
  },
]

const TeamBrandChecklist: React.FC<TeamBrandChecklistProps> = ({
  data,
  ...rest
}) => {
  const completed = useMemo(
    () => Object.values(data ?? {}).filter((it) => it === true).length,
    [data]
  )

  if (!data) {
    return null
  }

  return (
    <IonCard {...rest}>
      <div className="checklist--image" />
      <IonCardHeader>
        <IonCardTitle>Checklist</IonCardTitle>
        <IonCardSubtitle>
          {completed} / {ChecklistItems.length} Complete
        </IonCardSubtitle>
      </IonCardHeader>
      <IonList {...rest}>
        {ChecklistItems.map((it) => {
          const completed = (data as any)[it.key] === true

          return (
            <IonItem key={it.key} routerLink={it.path} detail>
              <IonIcon
                icon={completed ? checkmarkCircle : ellipseOutline}
                color={completed ? 'success' : undefined}
                slot="start"
              />
              <IonLabel className={css({ 'line-through': completed })}>
                {it.text}
              </IonLabel>
            </IonItem>
          )
        })}
      </IonList>
    </IonCard>
  )
}

export default styled(TeamBrandChecklist)`
  .checklist--image {
    background: url('/assets/marketing/JumpScout_Team_Gradient@2x.png')
      no-repeat;
    background-size: cover;
    background-position: center center;
    height: 150px;
  }
`
