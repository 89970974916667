import React from 'react'
import styled from 'styled-components'
import GenericButton from './GenericButton'
export default styled(({ data, refresh, ...rest }) => (
  <div {...rest}>
    <h4>{data.message}</h4>
    <GenericButton onClick={refresh}>Retry</GenericButton>
  </div>
))`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  button {
    margin-top: 25px;
  }
`
