import React, { useMemo, useState, useEffect } from 'react'
import {
  TeamGameSetup,
  UnmanagedTeam,
  UnmanagedTeamPersonnel,
  TeamGamePersonnel,
} from '../types/models'
import {
  IonList,
  IonItemDivider,
  IonIcon,
  IonReorderGroup,
  IonItem,
  IonReorder,
  IonLabel,
  IonText,
} from '@ionic/react'
import {
  teamGameService,
  teamGamePersonnelService,
  unmanagedTeamService,
  teamService,
  listsService,
  teamBrandService,
} from '../services'
import {
  Banner,
  GenericButton,
  FormDynamicSelectField,
  FormColorField,
} from '.'
import { warningOutline, addCircleOutline, trashOutline } from 'ionicons/icons'
import helpers from '../util/helpers'
import notifications from '../core/notifications'
import FormInputField from './FormInputField'
import { appLoading } from '../core'
import styled from 'styled-components'
import _ from 'lodash'
import validation from '../util/validation'
import FormSelectField from './FormSelectField'
import Modal from '../modals/Modal'
import css from 'classnames'
import colors from '../util/colors'

interface AddUnmanagedPlayerProps extends React.ComponentProps<any> {
  unmanagedTeam: UnmanagedTeam
  onAdded?: (
    unmangedTeam: UnmanagedTeam,
    personnel: Partial<UnmanagedTeamPersonnel>
  ) => any
}

interface AddUnmanagedTeamStaffProps {
  unmanagedTeam: UnmanagedTeam
  onAdded?: (
    unmangedTeam: UnmanagedTeam,
    personnel: Partial<UnmanagedTeamPersonnel>
  ) => any
}

export interface TeamGameSetupRosterProps extends React.ComponentProps<any> {
  setup: TeamGameSetup
  isHome: boolean
  updateData: (e: any) => any
}

const AddUnmanagedPlayer = styled(
  ({ unmanagedTeam, onAdded, ...rest }: AddUnmanagedPlayerProps) => {
    const [unmanagedPlayer, setUnmanagedPlayer] = useState<any>({})
    const updateUnmanagedPlayer = (key: string) => async (val: any) =>
      setUnmanagedPlayer((p: any) => ({ ...p, [key]: val }))

    const saveOpponentPlayer = async (val?: string) => {
      try {
        await appLoading.create()
        const { jersey, ...player } = unmanagedPlayer
        const data = {
          unmanagedTeamId: unmanagedTeam.id,
          homeJersey: jersey,
          awayJersey: jersey,
          role: 'PLAYER',
          ...player,
        }

        if (!player.position) {
          return notifications.toast('Position is required', 'Error')
        }

        if (!player.lastName) {
          return notifications.toast('Last Name is required', 'Error')
        }

        //If enter is pressed, use that current value
        if (val) {
          data.firstName = val
        }

        const res = await unmanagedTeamService.addPersonnel(data)
        onAdded?.(unmanagedTeam, res)
        setUnmanagedPlayer({})
      } catch (e) {
        notifications.errorToast(e)
      } finally {
        appLoading.dismiss()
      }
    }

    return (
      <div {...rest}>
        <FormInputField
          placeholder="#"
          value={unmanagedPlayer.jersey}
          validate={validation.jersey}
          save={updateUnmanagedPlayer('jersey')}
          noIndicator
        />
        <FormInputField
          value={unmanagedPlayer.position}
          save={updateUnmanagedPlayer('position')}
          placeholder="P"
          noIndicator
        />
        <FormInputField
          placeholder="Last Name"
          value={unmanagedPlayer.lastName}
          save={updateUnmanagedPlayer('lastName')}
          noIndicator
        />
        <FormInputField
          placeholder="First Name"
          value={unmanagedPlayer.firstName}
          save={updateUnmanagedPlayer('firstName')}
          onEnterPress={saveOpponentPlayer}
          noIndicator
        />
        <IonIcon
          icon={addCircleOutline}
          color="primary"
          onClick={() => saveOpponentPlayer()}
        />
      </div>
    )
  }
)`
  align-items: center;
  display: grid;
  border-bottom: solid 1px var(--ion-color-light);
  grid-template-columns: 80px 80px auto auto 42px;

  ion-item {
    --border-color: transparent;
  }

  ion-icon {
    cursor: pointer;
    font-size: 22px;
    margin: auto 10px;
  }
`

const AddUnmanagedTeamStaff = styled(
  ({ unmanagedTeam, onAdded, ...rest }: AddUnmanagedTeamStaffProps) => {
    const [staff, setStaff] = useState<any>({
      role: 'COACH',
    })

    const update = (key: string) => async (val: any) =>
      setStaff((p: any) => ({ ...p, [key]: val }))

    const saveStaff = async () => {
      try {
        await appLoading.create()
        const data = {
          unmanagedTeamId: unmanagedTeam.id,
          ...staff,
        }

        const res = await unmanagedTeamService.addPersonnel(data)
        await onAdded?.(unmanagedTeam, res)
        setStaff({ role: 'COACH' })
      } catch (e) {
        notifications.errorToast(e)
      } finally {
        appLoading.dismiss()
      }
    }

    return (
      <div {...rest}>
        <FormInputField
          placeholder="First Name"
          save={update('firstName')}
          value={staff.firstName}
          noIndicator
        />
        <FormInputField
          placeholder="Last Name"
          save={update('lastName')}
          value={staff.lastName}
          noIndicator
        />
        <FormInputField
          placeholder="Email"
          save={update('email')}
          value={staff.email}
          noIndicator
        />
        <FormSelectField
          options={listsService.rosterRoles}
          save={update('role')}
          value={staff.role}
          placeholder="Role"
          noIndicator
        />
        <IonIcon icon={addCircleOutline} color="primary" onClick={saveStaff} />
      </div>
    )
  }
)`
  align-items: center;
  display: grid;
  border-bottom: solid 1px var(--ion-color-light);
  grid-template-columns: 1fr 1fr 1fr 1fr 42px;

  ion-item {
    --border-color: transparent;

    ion-select {
      max-width: 100%;
    }
  }

  ion-icon {
    cursor: pointer;
    font-size: 22px;
    margin: auto 10px;
  }
`

const TeamGameSetupRoster: React.FC<TeamGameSetupRosterProps> = ({
  setup,
  isHome,
  updateData,
  ...rest
}) => {
  const [showAddStaffModal, setShowAddStaffModal] = useState(false)
  const [players, setPlayers] = useState<any>([])
  const [staff, setStaff] = useState<any>([])
  const [newStaff, setNewStaff] = useState<any>({})

  const updateNewStaff = (key: string) => async (val: any) =>
    setNewStaff((n: any) => ({ ...n, [key]: val }))

  const unmanagedTeam = useMemo(
    () =>
      isHome
        ? setup.teamGame?.homeUnmanagedTeam
        : setup.teamGame?.awayUnmanagedTeam,
    [isHome, setup]
  )

  const swatches = useMemo(() => {
    const team = isHome
      ? setup.teamGame?.homeTeam?.teamBrand ?? setup.teamGame?.homeUnmanagedTeam
      : setup.teamGame?.awayTeam?.teamBrand ?? setup.teamGame?.awayUnmanagedTeam

    return teamBrandService.getSwatches(team)
  }, [setup, isHome])

  const primaryColor = useMemo(
    () =>
      isHome
        ? setup.teamGame?.homeTeamPrimaryColor
        : setup.teamGame?.awayTeamPrimaryColor,
    [setup.teamGame, isHome]
  )

  const strokePrimaryColor = useMemo(
    () => (colors.isDark(primaryColor) ? '#FFFFFF' : '#000000'),
    [primaryColor]
  )
  useEffect(() => {
    const collection = isHome ? setup.homeRoster : setup.awayRoster
    const groups = teamService.getGroupedRoster(collection ?? [], setup)
    const gameStaff = teamService.getStaff(isHome, setup)
    const val = [...groups.starters, 'Active', ...groups.active]

    if (!unmanagedTeam) {
      val.push('Inactive', ...groups.inactive)
    }

    setPlayers(val)

    setStaff(gameStaff ?? [])
  }, [isHome, setup, unmanagedTeam])

  const starters = useMemo(
    () =>
      setup.teamGame?.personnel?.filter(
        (it: any) => it.isHomeTeam === isHome && it.isStarting
      )?.length ?? 0,
    [isHome, setup]
  )

  const updateGamePlayer = (teamGamePersonnel: any, key: string) => async (
    val: string
  ) => {
    try {
      const res = await teamGamePersonnelService.update(teamGamePersonnel.id, {
        [key]: val,
      })

      helpers.arrayReplace(setup.teamGame?.personnel ?? [], res)
      updateData({ ...setup })
    } catch (e) {
      notifications.errorToast(e)
    }
  }

  const onRosterReorder = async (e: any) => {
    const { from, to, complete } = e.detail
    try {
      const activeIndex = players.indexOf('Active')
      const rosterIndex = players.indexOf('Inactive')
      const hasInactive = rosterIndex !== -1
      const isActive =
        !hasInactive || (from > to ? to <= rosterIndex : to < rosterIndex)

      const isStarting = from > to ? to <= activeIndex : to < activeIndex
      //Offset the order if isActive to account for the header row. This is messy
      const order = hasInactive && isActive && !isStarting ? to - 1 : to
      const item = players[from]
      const itemId = item.teamBrandPersonnelId ?? item.id
      const key = item.teamBrandPersonnelId
        ? 'teamBrandPersonnelId'
        : 'unmanagedTeamPersonnelId'

      const gamePlayer = setup.teamGame?.personnel?.find(
        (it: any) => it[key] === itemId
      )

      const existingStarters =
        setup.teamGame?.personnel?.filter(
          (it: any) => it.isHomeTeam === isHome && it.isStarting
        ) ?? []

      if (
        isStarting &&
        !gamePlayer?.isStarting &&
        existingStarters.length >= 5
      ) {
        notifications.toast(
          'You already have the maximum amount of starters.',
          'Error'
        )
        return complete({ listOrReorder: false })
      }

      if (gamePlayer) {
        _.remove(setup.teamGame?.personnel ?? [], gamePlayer)
      }

      if (isActive) {
        const res = await teamGameService.addPersonnel({
          teamGameId: setup.teamGame.id,
          [key]: item.teamBrandPersonnelId ?? item.id,
          jersey:
            gamePlayer?.jersey ?? teamService.getJerseyNumber(item, isHome),
          lastName: item.lastName,
          firstName: item.firstName,
          position: gamePlayer?.position ?? teamService.getPosition(item),
          isHomeTeam: isHome,
          isStarting,
          order,
          role: 'PLAYER',
        })
        setup.teamGame.personnel = res
      } else if (gamePlayer) {
        await teamGameService.removePersonnel(setup.teamGame.id, gamePlayer.id)
      }

      setPlayers([...helpers.arrayMove(players, from, to)])
      updateData({ ...setup })
      complete()
    } catch (e) {
      notifications.errorToast(e)
      complete({ listOrReorder: false })
    }
  }

  const onUnmanagedPlayerAdded = async (
    unmanagedTeam: UnmanagedTeam,
    res: any
  ) => {
    const roster = isHome ? setup.homeRoster : setup.awayRoster
    roster.push(res)
    updateData({ ...setup })
    setPlayers((r: any) => [...r, res])
    const added = await teamGameService.addPersonnel({
      teamGameId: setup.teamGame?.id,
      isHomeTeam: isHome,
      unmanagedTeamPersonnelId: res.id,
      position: res.position,
      jersey: res.homeJersey,
      isStarting: false,
      order: roster.length,
      role: res.role,
    })

    setup.teamGame.personnel = added
    updateData({ ...setup })
  }

  const removeUnmanagedPlayer = (player: UnmanagedTeamPersonnel) => async () =>
    helpers.withLoading(async () => {
      await unmanagedTeamService.removePersonnel(
        player.unmanagedTeamId,
        player.id
      )
      _.remove(players, player)
      setPlayers([...players])
      const arr = isHome ? setup.homeRoster : setup.awayRoster
      _.remove(arr, player)
      updateData({ ...setup })
    })

  const addStaff = () =>
    helpers.withLoading(async () => {
      const res = await teamGameService.addPersonnel({
        teamGameId: setup.teamGame?.id,
        isHomeTeam: isHome,
        ...newStaff,
      })

      setup.teamGame.personnel = res
      updateData({ ...setup })
      setNewStaff({ role: 'COACH' })
      setShowAddStaffModal(false)
    })

  const removeStaff = (staff: TeamGamePersonnel) => () =>
    helpers.withLoading(async () => {
      const res = await teamGameService.removePersonnel(
        staff.teamGameId,
        staff.id
      )

      if (unmanagedTeam && staff.unmanagedTeamPersonnelId) {
        await unmanagedTeamService.removePersonnel(
          unmanagedTeam.id,
          staff.unmanagedTeamPersonnelId
        )
      }

      setup.teamGame.personnel = res
      updateData({ ...setup })
    })

  const onUnmanagedStaffAdded = async (team: UnmanagedTeam, staff: any) => {
    const res = await teamGameService.addPersonnel({
      teamGameId: setup.teamGame?.id,
      isHomeTeam: isHome,
      unmanagedTeamPersonnelId: staff.id,
      role: staff.role,
    })

    setup.teamGame.personnel = res
    updateData({ ...setup })
  }

  return (
    <IonList lines="full" {...rest}>
      <IonItemDivider className="team-name">
        {isHome ? 'Home' : 'Away'}:{' '}
        {teamGameService.getTeamNameByHome(setup.teamGame, isHome)}
      </IonItemDivider>
      <FormColorField
        entity={setup.teamGame}
        label="Jersey Color"
        fallback={primaryColor}
        swatches={swatches}
        save={teamGameService.saveHandler(
          setup.teamGame.id,
          isHome ? 'homeTeamJerseyFill' : 'awayTeamJerseyFill'
        )}
        field={isHome ? 'homeTeamJerseyFill' : 'awayTeamJerseyFill'}
      />
      <FormColorField
        entity={setup.teamGame}
        field={isHome ? 'homeTeamJerseyStroke' : 'awayTeamJerseyStroke'}
        save={teamGameService.saveHandler(
          setup.teamGame.id,
          isHome ? 'homeTeamJerseyStroke' : 'awayTeamJerseyStroke'
        )}
        swatches={swatches}
        fallback={strokePrimaryColor}
        label="Jersey Outline Color"
      />
      {(setup.teamGame.scoringType !== 'SINGLE_TEAM' ||
        setup.teamGame.isHome === isHome) && (
        <>
          <IonItemDivider sticky>Starters</IonItemDivider>
          {starters < 5 && (
            <Banner
              text="You have less than 5 starters setup"
              color="warning"
              icon={warningOutline}
              small
            />
          )}
          <IonReorderGroup
            disabled={setup.locked}
            onIonItemReorder={onRosterReorder}
          >
            {players?.map((it: any) => {
              if (typeof it === 'string') {
                return (
                  <IonItemDivider key={it} sticky>
                    {it}
                  </IonItemDivider>
                )
              }

              const { teamGamePersonnel } = it
              const jersey =
                teamGamePersonnel?.jersey ??
                teamService.getJerseyNumber(it, setup.teamGame?.isHome)
              const position =
                teamGamePersonnel?.position ?? teamService.getPosition(it)

              const conflict =
                jersey &&
                (setup?.teamGame?.personnel?.filter(
                  (it) => it.isHomeTeam === isHome && it.jersey === jersey
                )?.length ?? 0) > 1

              return (
                <IonItem
                  key={it.id}
                  className={css({
                    conflict,
                  })}
                >
                  <div className="player-row">
                    <FormInputField
                      placeholder="#"
                      value={jersey}
                      save={updateGamePlayer(teamGamePersonnel, 'jersey')}
                      readonly={
                        !teamGamePersonnel && !it.unmanagedTeamPersonnelId
                      }
                      validate={validation.jersey}
                      noIndicator
                      className="jersey"
                      clearInput={false}
                    />
                    <FormInputField
                      placeholder="P"
                      value={position}
                      save={updateGamePlayer(teamGamePersonnel, 'position')}
                      readonly={
                        !teamGamePersonnel && !it.unmanagedTeamPersonnelId
                      }
                      noIndicator
                      clearInput={false}
                    />
                    <FormInputField
                      value={`${it.lastName}${
                        it.firstName ? `, ${it.firstName}` : ''
                      }`}
                      readonly
                    />
                  </div>
                  {it.unmanagedTeamId && !setup.locked && (
                    <GenericButton
                      className="delete-unmanaged-player"
                      icon={trashOutline}
                      fill="clear"
                      color="danger"
                      slot="end"
                      onClick={removeUnmanagedPlayer(it)}
                    />
                  )}
                  <IonReorder slot="end" />
                </IonItem>
              )
            })}
          </IonReorderGroup>
          {!!unmanagedTeam && !setup.locked && (
            <AddUnmanagedPlayer
              unmanagedTeam={unmanagedTeam}
              onAdded={onUnmanagedPlayerAdded}
            />
          )}
          <IonItemDivider sticky>Staff</IonItemDivider>
          {_.orderBy(staff, ['rank']).map((it: TeamGamePersonnel) => (
            <IonItem key={it.id} className="staff-row">
              <IonLabel>
                <IonText className="ion-multiline">
                  <div>{teamGameService.getStaffName(it)}</div>
                  <span className="subtext">{helpers.roleLabel(it)}</span>
                </IonText>
              </IonLabel>
              <GenericButton
                className="delete-unmanaged-player"
                icon={trashOutline}
                fill="clear"
                color="danger"
                slot="end"
                onClick={removeStaff(it)}
              />
            </IonItem>
          ))}
          {!!unmanagedTeam ? (
            <AddUnmanagedTeamStaff
              onAdded={onUnmanagedStaffAdded}
              unmanagedTeam={unmanagedTeam}
            />
          ) : (
            <GenericButton
              icon={addCircleOutline}
              fill="clear"
              toggleClick={setShowAddStaffModal}
            >
              Add Staff
            </GenericButton>
          )}
        </>
      )}
      {showAddStaffModal && (
        <Modal
          title="Add Staff"
          closeButtonText="Cancel"
          onDidDismiss={() => setShowAddStaffModal(false)}
          headerAction={{
            children: 'Add',
            onClick: addStaff,
          }}
        >
          <IonList>
            <FormDynamicSelectField
              url={`/v1/team-game/${setup.teamGame?.id}/options/staff/`}
              label="Staff Member"
              save={updateNewStaff('teamBrandPersonnelId')}
              noIndicator
            />
            <FormSelectField
              label="Role"
              options={listsService.rosterRoles}
              save={updateNewStaff('role')}
              noIndicator
            />
          </IonList>
        </Modal>
      )}
    </IonList>
  )
}

export default styled(TeamGameSetupRoster)`
  .team-name {
    border-bottom: none;
  }

  .delete-unmanaged-player {
    display: none;
  }

  ion-item:hover {
    .delete-unmanaged-player {
      display: block;
    }
  }

  .player-row {
    background: transparent;
    display: grid;
    grid-template-columns: 50px 70px auto;
    width: 100%;
  }

  .conflict .jersey {
    --background: var(--ion-color-warning);
  }
`
