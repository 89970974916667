import React, { useMemo, useState, useEffect } from 'react'
import { FormInputField } from '.'
import styled from 'styled-components'
import { FormInputFieldProps } from './FormInputField'
import _ from 'lodash'
export interface FormColorFieldProps extends FormInputFieldProps {
  entity: any
  field: string
  fallback?: string
  swatches?: string[]
}

const FormColorField: React.FC<FormColorFieldProps> = ({
  entity,
  field,
  save,
  label,
  fallback,
  swatches,
  ...rest
}) => {
  const name = useMemo(() => entity?.[`${field}Name`] ?? label, [
    entity,
    field,
    label,
  ])
  const value = useMemo(() => entity?.[field] ?? fallback, [
    entity,
    field,
    fallback,
  ])
  const [inputValue, setInputValue] = useState<any>('#FFFFFF')

  const saveColor = useMemo(
    () =>
      _.debounce(() => {
        const element = document.getElementById(`cp-${field}`) as any
        if (element && element.value) {
          save?.(element.value, null)
        }
      }, 1000),
    [field, save]
  )

  const updateColor = (e: any) => {
    setInputValue(e.target.value)
    saveColor()
  }

  const selectSwatch = (color: string) => () => {
    setInputValue(color)
    saveColor()
  }

  useEffect(() => {
    setInputValue(value ?? '#FFFFFF')
  }, [value])

  return (
    <FormInputField
      label={name ?? 'Color'}
      save={save}
      value={inputValue}
      end={
        !!swatches?.length && (
          <div className="swatches">
            {swatches.map((it, i) => (
              <div
                key={i}
                className="swatch"
                onClick={selectSwatch(it)}
                style={{
                  backgroundColor: it,
                }}
              />
            ))}
          </div>
        )
      }
      {...rest}
    >
      <input
        type="color"
        id={`cp-${field}`}
        onChange={updateColor}
        value={inputValue}
        style={{ marginRight: 7 }}
        slot="start"
      />
    </FormInputField>
  )
}

export default styled(React.memo(FormColorField))`
  .swatches {
    display: grid;
    grid-template-columns repeat(${(props) =>
      props.swatches?.length ?? 1}, 1fr);
    grid-column-gap: 10px;
    margin: 10px 0;

    .swatch {
      border: solid 1px var(--ion-color-medium);
      border-radius: 100%;
      cursor: pointer;
      height: 20px;
      width: 20px;
    }
  }
`
