import React, { useRef, useCallback } from 'react'
import styled from 'styled-components'
import Content from './Content'
import { useClickOutside } from '../hooks'
import { userNotificationService } from '../services'
import { UserNotification } from '../types/models'
import { List, UserNotificationRow } from '.'
import { notifications } from 'ionicons/icons'
import usePortal from 'react-useportal'

export interface UserNotificationsFlyoutProps
  extends React.ComponentProps<any> {
  onDidDismiss?: () => any
}

const renderNotificationRow = (notif: UserNotification) => (
  <UserNotificationRow key={notif.id} notification={notif} />
)

const UserNotificationsFlyout: React.FC<UserNotificationsFlyoutProps> = ({
  onDidDismiss,
  ...rest
}) => {
  const { Portal } = usePortal()
  const ref = useRef<any>()
  const close = useCallback(() => onDidDismiss?.(), [onDidDismiss])
  useClickOutside(ref, close)

  return (
    <Portal>
      <div ref={ref} {...rest}>
        <Content noScrollIndicator>
          <List
            url={userNotificationService.getEndpointUrl(null)}
            renderItem={renderNotificationRow}
            zeroState={{
              icon: notifications,
              title: 'No Notifications',
              button: {},
              small: true,
              readonly: true,
            }}
          />
        </Content>
      </div>
    </Portal>
  )
}

export default styled(UserNotificationsFlyout)`
  background-color: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  height: 200px;
  position: absolute;
  top: 60px;
  left: 10px;
  width: 300px;
  z-index: 1000;
  --ion-item-background: transparent;

  &:before {
    border: solid 10px transparent;
    border-bottom-color: white;
    content: '';
    display: block;
    top: 0;
    left: 50%;
    position: absolute;
    transform: translate(4px, -100%);
  }

  ion-content {
    --background: transparent;
  }

  ion-item {
    font-size: 14px;
    --min-height: 30px;
  }
`
