import { TeamBrandSubscription } from './../types/models'
declare global {
  interface Window {
    fbq: any
    gtag: any
  }
}

class Conversion {
  trackRegistration() {
    try {
      window.fbq?.('track', 'CompleteRegistration')
      window.gtag?.('event', 'sign_up', {
        event_category: 'engagement',
      })
    } catch (e) {
      console.error()
    }
  }

  trackSubscribe(sub: TeamBrandSubscription) {
    try {
      window.fbq?.('track', 'Subscribe', {
        value: sub.plan.priceUSD,
        currency: 'USD',
      })
      window.gtag?.('event', 'purchase', {
        event_category: 'ecommerce',
      })
    } catch (e) {
      console.error(e)
    }
  }
}

export default new Conversion()
