import React from 'react'
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
} from '@ionic/react'
import styled from 'styled-components'

export interface ChartCardProps extends React.ComponentProps<any> {
  title?: string
  subtitle?: string
}

const ChartCard: React.FC<ChartCardProps> = ({
  title,
  subtitle,
  children,
  ...rest
}) => {
  return (
    <IonCard {...rest}>
      <IonCardHeader>
        <IonCardTitle>{title}</IonCardTitle>
        {subtitle && <IonCardSubtitle>{subtitle}</IonCardSubtitle>}
      </IonCardHeader>
      <IonCardContent>{children}</IonCardContent>
    </IonCard>
  )
}

export default styled(ChartCard)`
  @media (max-width: 400px) {
    margin: 0;
    ion-card-content {
      padding: 10px;
    }
  }
`
