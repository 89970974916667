import { TeamBrand, UnmanagedTeam } from '../types/models'
import ModelBaseService from './model-base'
import { DataField } from '../util/data-field'
import { listsService } from '.'
import { http } from '../core'
import google from './google'
import helpers from '../util/helpers'

class TeamBrandService extends ModelBaseService {
  protected modelName = 'team-brand'
  public get fields(): DataField[] {
    return [
      {
        key: 'name',
        label: 'Name',
        type: 'input',
        required: true,
      },
      {
        key: 'mascot',
        label: 'Mascot',
        type: 'input',
        required: true,
      },
      {
        key: 'gender',
        label: 'Gender',
        type: 'select',
        options: listsService.teamGenders,
      },
      {
        key: 'timezone',
        label: 'Timezone',
        type: 'select',
        options: listsService.timezones,
      },
    ]
  }
  getPrimaryBrandColor(teamBrand?: TeamBrand) {
    if (!teamBrand) {
      return null
    }

    return teamBrand.color1
  }

  getSwatches(team: TeamBrand | UnmanagedTeam | null | undefined): string[] {
    if (!team) {
      return []
    }

    const swatches = [team.color1, team.color2, team.color3, team.color4]
    return swatches.filter((it) => !!it) as string[]
  }

  async authorizeGoogle(teamBrandId: string) {
    const newWindow = window.open('about:blank') as any
    const { data } = await http.authorizedRequest({
      method: 'GET',
      url: this.getEndpointUrl(teamBrandId, 'oauth/google'),
    })

    newWindow.location = data.url
  }

  async authorizeMicrosoft(teamBrandId: string) {
    const newWindow = window.open('about:blank') as any
    const { data } = await http.authorizedRequest({
      method: 'GET',
      url: this.getEndpointUrl(teamBrandId, 'oauth/microsoft'),
    })

    newWindow.location = data.url
  }

  async getGoogleToken(teamBrandId: string) {
    const { data } = await http.authorizedRequest({
      method: 'GET',
      url: this.getEndpointUrl(teamBrandId, 'oauth/google/credentials'),
    })

    return data
  }

  async getMicrosoftToken(teamBrandId: string) {
    const { data } = await http.authorizedRequest({
      method: 'GET',
      url: this.getEndpointUrl(teamBrandId, 'oauth/microsoft/credentials'),
    })

    return data?.token
  }

  async showGooglePicker(teamBrandId: string, onComplete: (res: any) => any) {
    await helpers.withLoading(async () => {
      const { token } = await this.getGoogleToken(teamBrandId)
      await google.showPicker(token, onComplete)
    })
  }
  getMicrosoftCredentials(teamBrand: TeamBrand | null | undefined) {
    return teamBrand?.credentials?.find(
      (it) => it.active && it.provider === 'MICROSOFT'
    )
  }

  isMicrosoftConnected(teamBrand: TeamBrand | null | undefined) {
    return !!this.getMicrosoftCredentials(teamBrand)
  }

  getGoogleCredentials(teamBrand: TeamBrand | null | undefined) {
    return teamBrand?.credentials?.find(
      (it) => it.active && it.provider === 'GOOGLE'
    )
  }

  isGoogleConnected(teamBrand: TeamBrand | null | undefined) {
    return !!this.getGoogleCredentials(teamBrand)
  }
}

export default new TeamBrandService()
