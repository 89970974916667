import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { useEventListener } from '../hooks'
import helpers from '../util/helpers'
import { TeamGameEvent, TeamGameManifest } from '../types/models'
import css from 'classnames'

export interface ZoomParams {
  level: number
  window: { pan: number }
}

export interface ScoringVideoEventTimelineZoomProps
  extends React.ComponentProps<any> {
  duration?: number
  max?: number
  min?: number
  minTime?: number
  maxTime?: number
  step?: number
  events?: TeamGameEvent[]
  onZoom?: (params: ZoomParams) => any
  selectedEventIndex?: number
  manifest: TeamGameManifest
}

function getWindowWidthFromResize({
  step,
  offset,
  max,
  min,
  totalWidth,
  currentWidth,
  left,
}: {
  [key: string]: number
}) {
  const maxTotalSteps = max / step
  const stepSize = totalWidth / maxTotalSteps
  const maxSteps = Math.floor((totalWidth - left) / stepSize)
  const minSteps = min / step
  const currentSteps = Math.round(currentWidth / stepSize)
  const change = Math.round(offset / stepSize)
  const steps = helpers.clamp(currentSteps + change, minSteps, maxSteps)
  const width = helpers.clamp(
    steps * stepSize,
    minSteps * stepSize,
    totalWidth - left
  )

  return {
    left,
    width,
    ratio: width / totalWidth,
    level: totalWidth / width,
  }
}

const ScoringVideoEventTimelineZoom: React.FC<ScoringVideoEventTimelineZoomProps> = ({
  duration = 0,
  max = 10,
  min = 1,
  onZoom,
  step = 0.5,
  events,
  minTime,
  maxTime,
  manifest,
  selectedEventIndex = -1,
  ...rest
}) => {
  const zoomWindow = useRef<any>()
  const handle = useRef<any>()
  const [left, setLeft] = useState<any>(0)
  const [zoom, setZoom] = useState(1)
  const [dragging, setDragging] = useState(false)
  const [resizing, setResizing] = useState(false)
  const [windowWidth, setWindowWidth] = useState<any>(`100%`)
  const [dragStart, setDragStart] = useState(0)
  const [barWidth, setBarWidth] = useState(0)
  const [ratio, setRatio] = useState(1)
  const [leftRatio, setLeftRatio] = useState(0)

  const onHandleDown = (e: any) => {
    setDragging(true)
    const { x } = handle.current.getBoundingClientRect()
    setDragStart(e.clientX - x)
  }

  const onHandleUp = (e: any) => {
    setDragging(false)
    setResizing(false)
    setDragStart(e.clientX)
  }

  const resizeWindow = (clientX: number) => {
    const { x, width } = zoomWindow.current.getBoundingClientRect()
    const { width: handleWidth } = handle.current.getBoundingClientRect()
    const offset = clientX - x - handleWidth - left
    const {
      level,
      width: newWidth,
      ratio: newRatio,
    } = getWindowWidthFromResize({
      offset,
      min,
      max,
      totalWidth: width,
      currentWidth: handleWidth,
      left,
      step,
    })

    setZoom(level)
    setWindowWidth(newWidth)
    setRatio(newRatio)
    onZoom?.({
      level,
      window: {
        pan: left / (width - newWidth),
      },
    })
  }

  const moveWindow = (clientX: number) => {
    const { x, width } = zoomWindow.current.getBoundingClientRect()
    const { width: handleWidth } = handle.current.getBoundingClientRect()
    const pos = helpers.clamp(clientX - x - dragStart, 0, width - handleWidth)
    setLeft(pos)
    setLeftRatio(pos / width)
    onZoom?.({
      level: zoom,
      window: {
        pan: pos / (width - handleWidth),
      },
    })
  }

  const onHandleMove = (e: any) => {
    if (!zoomWindow.current) {
      return
    }

    const { clientX } = e

    if (resizing) {
      resizeWindow(clientX)
    } else if (dragging) {
      e.preventDefault()
      moveWindow(clientX)
    }
  }

  const onResizeHandleDown = (e: any) => {
    e.stopPropagation()
    setResizing(true)
    return false
  }

  const onResize = () => {
    const { width } = zoomWindow.current.getBoundingClientRect()
    const newHandleWidth = ratio * width
    const newLeft = leftRatio * width
    setBarWidth(width)
    setWindowWidth(newHandleWidth)
    setLeft(newLeft)
    onZoom?.({
      level: zoom,
      window: {
        pan: newLeft / (width - newHandleWidth),
      },
    })
  }

  useEventListener('mouseup', onHandleUp)
  useEventListener('touchend', onHandleUp)
  useEventListener('mousemove', onHandleMove)
  useEventListener('touchmove', onHandleMove)
  useEventListener('resize', onResize)

  return (
    <div ref={zoomWindow} {...rest}>
      <div
        ref={(e) => {
          if (e) {
            setBarWidth(e.getBoundingClientRect().width)
          }
        }}
        className={css('zoom-window--overview timeline--container', {
          'has-selection': selectedEventIndex > -1,
        })}
      >
        {events?.map((it: TeamGameEvent, index: number) => (
          <span
            className={css('timeline--event', {
              selected: index === selectedEventIndex,
              'is-home-team': it.isHomeTeam,
              [it.type]: !it.flagged,
              flagged: it.flagged,
            })}
            id={it.id}
            key={it.id}
            style={{
              left: helpers.getPositionFromValue({
                value: helpers.getCombinedVideoTime(it, manifest),
                min: minTime,
                max: maxTime,
                width: barWidth,
              }),
            }}
          />
        ))}
      </div>
      <div
        ref={handle}
        className="zoom-window--handle"
        onMouseDown={onHandleDown}
        onTouchStart={onHandleDown}
        style={{
          left,
          width: windowWidth,
        }}
      >
        <div
          className="zoom-window--handle__resize"
          onMouseDown={onResizeHandleDown}
          onTouchStart={onResizeHandleDown}
        />
      </div>
    </div>
  )
}

export default styled(React.memo(ScoringVideoEventTimelineZoom))`
  border: solid 1px #a0a0a0;
  border-radius: 10px;
  flex: 0 0 30px;
  position: relative;
  margin-bottom: 10px;
  user-select: none;

  &:after {
    background: #a0a0a0;
    display: block;
    content: '';
    left: 0;
    right: 0;
    position: absolute;
    height: 1px;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 0;
  }

  .zoom-window--overview {
    position: absolute;
    left: 0;
    right: 10px;
    top: 0;
    bottom: 0;

    .timeline--event {
      bottom: 3px;

      &.is-home-team {
        top: 3px;
      }
    }
  }

  .zoom-window--handle {
    border: solid 2px var(--ion-color-warning);
    border-radius: 9px;
    cursor: grab;
    height: 30px;
    position: relative;
    z-index: 3;

    .zoom-window--handle__resize {
      background: var(--ion-color-warning);
      cursor: ew-resize;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 10px;

      &:after,
      &:before {
        border-top-right-radius: 9px;
        border-bottom-right-radius: 9px;
        background: rgba(var(--ion-color-warning-contrast-rgb), 0.7);
        display: block;
        content: '';
        position: absolute;
        top: 6px;
        bottom: 6px;
        width: 1px;
      }

      &:before {
        left: 4px;
      }

      &:after {
        right: 3px;
      }
    }
  }
`
