import React, {
  useState,
  useRef,
  useCallback,
  useContext,
  useMemo,
} from 'react'
import styled from 'styled-components'
import { IonIcon } from '@ionic/react'
import { caretDown } from 'ionicons/icons'
import css from 'classnames'
import { useClickOutside, useRouter } from '../hooks'
import { AppContext, getTeamBrand, getTeam } from '../contexts/AppContext'
import { teamService } from '../services'
import _ from 'lodash'

export interface ContextSwitcherProps extends React.ComponentProps<any> {}

const ContextSwitcher: React.FC<ContextSwitcherProps> = ({
  className,
  ...rest
}) => {
  const ref = useRef<any>()
  const { state, dispatch } = useContext(AppContext)
  const { replaceURL } = useRouter()
  const { team } = state
  const [isOpen, setIsOpen] = useState(false)

  const options = useMemo(() => {
    const teams = state.user?.teams ?? []
    return _.sortBy(
      teams.map((team: any) => {
        const teamBrand = getTeamBrand(state, team)
        return {
          id: team.id,
          teamId: team.id,
          teamBrandId: teamBrand?.id,
          fullName: teamBrand?.fullName,
          name: teamBrand?.name,
          mascot: teamBrand?.mascot,
          level: teamService.getTeamGenderAndLevelDisplay(team, teamBrand),
          avatar: teamBrand?.avatarURL,
        }
      }),
      'name'
    )
  }, [state])

  const selected = useMemo(() => options.find((it) => it.id === team?.id), [
    options,
    team,
  ])

  const enabled = useMemo(() => options.length > 1, [options])

  const close = useCallback(() => setIsOpen(false), [])
  useClickOutside(ref, close)

  const selectItem = (it: any) => () => {
    const team = getTeam(state, it)
    const teamBrand = getTeamBrand(state, it)

    replaceURL('/a/dashboard/')

    dispatch({
      type: 'changeContext',
      value: {
        team,
        teamBrand,
      },
    })
  }

  if (!selected) {
    return null
  }

  return (
    <div
      className={css(className, {
        open: isOpen,
        enabled,
      })}
      ref={ref}
      onClick={() => setIsOpen(enabled && !isOpen)}
      {...rest}
    >
      <label>
        <span className="name">{selected?.fullName}</span>
        <div className="sublabel">{selected?.level}</div>
      </label>
      {enabled && (
        <IonIcon className="context-switcher--caret" icon={caretDown} />
      )}
      {isOpen && (
        <div className="drop-down">
          {options?.map((it) => (
            <div
              key={it.id}
              onClick={selectItem(it)}
              className="drop-down-option"
            >
              <label>{it.fullName}</label>
              <div className="sublabel">{it.level}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default styled(ContextSwitcher)`
  align-items: center;
  background-color: rgba(var(--ion-color-dark-rgb), 0.1);
  border-radius: 10px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: auto 1fr auto;
  padding: 9px 10px;
  position: relative;
  margin: 0 5px 5px;
  max-width: 190px;

  .context-switcher--caret {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }

  &.enabled {
    cursor: pointer;
    padding: 9px 0px 9px 10px;
    &:hover {
      background: rgba(var(--ion-color-dark-rgb), 0.1);
    }
  }

  .name {
    display: block;
    font-size: 12px;
    overflow: hidden;
    color: var(--ion-color-dark);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.open {
    background: rgba(0, 0, 0, 0.05);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  ion-avatar {
    margin-right: 12px;
  }

  label {
    pointer-events: none;
    max-width: 130px;
  }

  .sublabel {
    color: var(--ion-color-medium);
    font-size: 10px;
  }

  ion-icon {
    color: rgba(var(--ion-color-medium-rgb), 0.3);
    font-size: 14px;
    margin: 0 3px 0 0;
  }

  .drop-down {
    background: var(--ion-color-light);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(100%);
    max-height: 200px;
    overflow-y: auto;
    z-index: 10;
  }

  .drop-down-option {
    border-radius: 10px;
    color: var(--ion-color-dark);
    font-size: 14px;
    flex: 1 1 auto;
    margin: 5px;
    padding: 9px 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    :hover {
      background: rgba(var(--ion-color-primary-rgb), 0.1);
    }
  }
`
