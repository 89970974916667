import React, { useRef, useImperativeHandle } from 'react'
import { Page } from '../components'
import { useRouter } from '../hooks'
import EditContent, { EditContentProps } from './EditContent'
import { IonPage } from '@ionic/react'

export type CombinedProps = EditContentProps &
  React.ComponentProps<typeof IonPage>

export interface EditPageProps extends CombinedProps {}

const EditPage: React.ForwardRefRenderFunction<any, any> = (
  {
    service,
    supportsAvatar,
    getParentEntity,
    parentRequired,
    buttons,
    children,
    renderName,
    readonlyAvatar,
    renderTitle,
    backgroundColor,
    actionButton,
    allowDestroy,
    afterDestroyURL,
    renderSubtitle,
    renderAvatarURL,
    entity,
    toolbarButtons,
    destroyText,
    destroyDialogText,
    afterUpdate,
    model,
    banner,
    ...rest
  },
  ref
) => {
  const content = useRef<any>()
  const { query } = useRouter()

  useImperativeHandle(ref, () => content.current)

  const contentProps = {
    service,
    supportsAvatar,
    getParentEntity,
    parentRequired,
    buttons,
    children,
    renderName,
    readonlyAvatar,
    renderAvatarURL,
    entityId: query.id,
    entity,
    renderTitle,
    renderSubtitle,
    backgroundColor,
    actionButton,
    allowDestroy,
    afterDestroyURL,
    toolbarButtons,
    destroyText,
    destroyDialogText,
    afterUpdate,
    model,
    banner,
  }

  return (
    <Page {...rest}>
      <EditContent ref={content} {...contentProps} />
    </Page>
  )
}

export default React.forwardRef(EditPage)
