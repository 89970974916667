import ModelBaseService from './model-base'
import { DataField } from '../util/data-field'

class UserNotificationService extends ModelBaseService {
  protected modelName = 'user-notification'
  public get fields(): DataField[] {
    return []
  }
}

export default new UserNotificationService()
