import { TeamBrandPersonnelService } from './team-brand-personnel'
import { DataField } from '../util/data-field'
import { listsService } from '.'

class TeamBrandStaffService extends TeamBrandPersonnelService {
  public get fields(): DataField[] {
    return [
      ...this.standardFields,
      {
        key: 'role',
        label: 'Role',
        type: 'select',
        options: listsService.staffRoles,
      },
    ]
  }
}

export default new TeamBrandStaffService()
