import React, {
  useRef,
  useImperativeHandle,
  useLayoutEffect,
  useCallback,
} from 'react'
import CourtRenderer, { MarkerOptions, LegendOptions } from '../util/court-renderer'
import { useEventListener } from '../hooks'
import { Court } from '../types/models'
import styled from 'styled-components'
export interface CourtCanvasProps extends React.ComponentProps<any> {
  court: Court
  overrides?: Partial<Court>
  points?: MarkerOptions[]
  maxWidth?: number
  nonInteractive?: boolean
  type?: 'TOPHALF' | 'FULL'
  legends?:LegendOptions[]
  regions?:number[]
  fillcourtcolor?: boolean //'true'|'false'
}

const CourtCanvas: React.ForwardRefRenderFunction<any, CourtCanvasProps> = (
  { court, overrides, points, maxWidth, nonInteractive, type, legends,regions,fillcourtcolor, ...rest },
  ref
) => {
  const canvas = useRef<any>()
  const renderer = useRef<any>()
  const timer = useRef<any>()

  useImperativeHandle(ref, () => ({
    get canvas() {
      return canvas.current
    },
    get renderer() {
      return renderer.current
    },
    drawPoint(options: MarkerOptions) {
      renderer.current?.drawPoint(options)
    },
    drawLegend(options: LegendOptions) {
      renderer.current?.drawLegend(options)
    }
  }))

  const draw = useCallback(() => {
    const element = canvas.current
    if (element) {
      //Remove hardcoded variables set by paper
      element.style.width = '100%'
      element.style.height = 'auto'
      renderer.current?.dispose()
      renderer.current = new CourtRenderer(element, court, overrides, type, fillcourtcolor)
      if (canvas.current?.clientWidth > 0 && canvas.current?.clientHeight > 0) {
        clearInterval(timer.current)
        renderer.current.render()
        if (points?.length) {
          points.forEach((it) => renderer.current.drawPoint(it))
        }
      }
      if(legends?.length){
        legends.forEach((it, idx)=> {renderer.current.drawLegend(it, idx, points)})
      }
      if(regions?.length){
        regions.forEach((it, idx)=> {renderer.current.drawRegion(it, idx, points )})
      }
    }
  }, [court, overrides, points, type, legends, regions, fillcourtcolor])

  useEventListener('resize', draw)
  useLayoutEffect(() => {
    clearTimeout(timer.current)
    const interval = setInterval(() => {
      const { height, width } = canvas.current?.getBoundingClientRect() ?? {}
      if (height > 0 && width > 0) {
        draw()
        clearInterval(interval)
      }
    }, 100)
    timer.current = interval
    return () => clearInterval(interval)
  }, [draw])

  return (
    <canvas
      ref={canvas}
      style={{
        minWidth: 300,
      }}
      draggable={false}
      {...rest}
    />
  )
}

export default styled(React.memo(React.forwardRef(CourtCanvas)))`
  cursor: ${(props) => (props.nonInteractive ? 'default' : 'pointer')};
  width: 100%;
  ${(props) =>
    props.maxWidth ? `max-width: ${props.maxWidth}px !important;` : ''}
  min-width: 300px;
  user-select: none;
`
