import { useState, useEffect, MutableRefObject } from 'react'

export default function useOnScreen(
  ref: MutableRefObject<any>,
  rootMargin = '0px'
) {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const current = ref.current
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting)
      },
      {
        rootMargin,
      }
    )
    if (current) {
      observer.observe(current)
    }
    return () => {
      observer.unobserve(current)
    }
  }, [ref, rootMargin])

  return isIntersecting
}
