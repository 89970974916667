import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from 'react'
import styled from 'styled-components'
import { useImperativeHandle } from 'react'
import {
  play,
  pause,
  playBack,
  playForward,
  playSkipForward,
  stopwatch,
} from 'ionicons/icons'
import helpers from '../util/helpers'
import GenericButton from './GenericButton'
import { useMouseUpOutside, useEventListener, useShortcuts } from '../hooks'
import { teamGameService } from '../services'
import { VideoEventTimeline } from '.'
import LoadingSpinner from './LoadingSpinner'
import css from 'classnames'
import { ScoringContext } from '../contexts/ScoringContext'
import ScoringLocalVideoModal from '../modals/ScoringLocalVideoModal'
import _ from 'lodash'
import { ScoringState } from '../types/interfaces'

export interface ScoringVideoPlayerProps {}

function getClockForVideoTime(state: ScoringState, videoTime: number) {
  const { manifest, isLive, startVideoTime = 0 } = state

  if (isLive) {
    return {
      clock: state.clock,
      period: state.period,
    }
  }

  const { clock } = manifest ?? {}

  const latest = _.last(clock)

  if (
    latest &&
    (latest.period < state.period || latest.clockTime >= state.clock)
  ) {
    return {}
  }

  const event =
    clock?.find(
      (it) =>
        it.type === 'clock_stop' && it.videoTime && it.videoTime > videoTime
    ) ?? clock?.filter((it) => it.type === 'clock_stop')?.reverse()?.[0]

  if (videoTime <= startVideoTime || !event?.videoTime) {
    return {
      clock: state.periodDuration,
      period: 1,
    }
  }

  const eventAbsoluteVideoTime = helpers.getAbsoluteVideoTime(state, event)

  if (eventAbsoluteVideoTime < videoTime) {
    return {
      period: event.period,
      clock: event.clockTime,
    }
  }

  const diff = eventAbsoluteVideoTime - videoTime

  return {
    period: event.period,
    clock: diff + event.clockTime,
  }
}

const ScoringVideoPlayer: React.FC<ScoringVideoPlayerProps> = ({ ...rest }) => {
  const { state, dispatch, videoPlayer } = useContext(ScoringContext)
  const { events } = state
  const manifest = useMemo(() => state.manifest, [state.manifest])
  const [index, setIndex] = useState<any>()
  const [start, setStart] = useState<any>()
  const [ready, setReady] = useState(false)
  const [duration, setDuration] = useState(0)
  const [showLocalVideoModal, setShowLocalVideoModal] = useState(false)
  const wasPlaying = useRef<any>(false)
  const lastVideoTime = useRef<any>(0)
  const video = useRef<any>()
  const range = useRef<any>()

  useEffect(() => {
    if (manifest?.src?.length) {
      setIndex(0)
      setDuration(manifest.totalDuration)
    }
  }, [manifest])

  useEffect(() => {
    const missing = manifest?.src?.filter((it) => !it)
    setShowLocalVideoModal(!!missing?.length)
  }, [manifest])

  useEffect(() => {
    lastVideoTime.current = state.videoTime
    if (video.current && !state.videoPlaying) {
      video.current.currentTime = state.videoTime - start
    }
  }, [dispatch, state.videoTime, start, state.videoPlaying])

  useEffect(() => {
    if (state.clockRunning && !state.videoPlaying) {
      video.current?.play()
    }
  }, [state.clockRunning, state.videoPlaying])

  useEffect(() => {
    if (manifest) {
      setIndex((i: number) => {
        for (let ii = 0; ii < manifest.durations.length; ii++) {
          const [s, e] = teamGameService.getStartAndEndForManifestIndex(
            manifest,
            ii
          )
          if (
            state.videoTime >= s &&
            (ii + 1 === manifest.durations.length || state.videoTime < e)
          ) {
            if (i !== ii) {
              dispatch({
                type: 'set',
                value: {
                  videoStart: s,
                  videoEnd: e,
                  currentTeamVideoId: manifest.ids[ii],
                },
              })

              if (!wasPlaying.current) {
                wasPlaying.current = state.videoPlaying
              }
            }
            return ii
          }
        }
      })
    }
  }, [state.videoTime, manifest, dispatch, state.videoPlaying])

  useEffect(() => {
    const [s] = teamGameService.getStartAndEndForManifestIndex(manifest, index)
    setStart(s)

    if (wasPlaying.current) {
      video.current?.play()
    }
  }, [index, manifest])

  const playVideo = useCallback(() => {
    if (!ready) {
      return
    }
    video.current?.play()
  }, [ready])

  const pauseVideo = useCallback(() => {
    if (!ready) {
      return
    }

    video.current?.pause()

    dispatch({
      type: 'toggleClock',
      value: {
        start: false,
        logEvent: false,
      },
    })
  }, [ready, dispatch])

  const toggleClocks = useCallback(() => {
    if (
      !ready ||
      !state.started ||
      (state.clock === state.periodDuration && state.period > 1)
    ) {
      return
    }

    let clockRunning
    if (state.clockRunning || state.videoPlaying) {
      clockRunning = false
      video.current?.pause()
    } else {
      video.current?.play()
      clockRunning = true
    }

    dispatch({
      type: 'set',
      value: {
        clockRunning,
      },
    })
  }, [
    ready,
    state.clock,
    state.periodDuration,
    state.period,
    state.clockRunning,
    state.videoPlaying,
    state.started,
    dispatch,
  ])

  const toggleClock = useCallback(() => {
    if (!state.videoPlaying) {
      video.current?.play()
      dispatch({
        type: 'set',
        value: {
          videoPlaying: true,
        },
      })
    }

    dispatch({
      type: 'toggleClock',
      value: {
        start: !state.clockRunning,
      },
    })
  }, [dispatch, state.clockRunning, state.videoPlaying])

  const seek = (e: any) => {
    const videoTime = e.detail?.value ?? e
    const clock = getClockForVideoTime(state, videoTime)
    dispatch({
      type: 'set',
      value: {
        videoTime,
        ...clock,
      },
    })
  }

  const startSeek = () => {
    wasPlaying.current = state.videoPlaying
    pauseVideo()
  }
  const endSeek = () => {
    if (wasPlaying.current) {
      _.delay(() => playVideo(), 500)
    }

    wasPlaying.current = false
  }

  const advance = useCallback(
    (
      millis: number,
      direction: 'forward' | 'backward',
      updateClock = true
    ) => () => {
      if (!ready) {
        return
      }

      const currentMillis = state.videoTime * 1000
      const newMillis =
        currentMillis + (direction === 'backward' ? millis * -1 : millis)

      const newTime = newMillis / 1000
      const videoTime = Math.min(Math.max(0, newTime), duration)
      let updates: any = {}

      if (updateClock) {
        updates = getClockForVideoTime(state, videoTime)

        if (
          !updates.clock &&
          (state.clock !== state.periodDuration || direction === 'backward')
        ) {
          const offset = millis / 1000
          updates.clock =
            direction === 'forward'
              ? state.clock - offset
              : state.clock + offset

          if (updates.clock > state.periodDuration) {
            updates.period = Math.max(state.period - 1, 1)
            updates.clock = updates.clock - state.periodDuration
          } else if (updates.clock < 0) {
            updates.period = state.period + 1
            updates.clock = state.periodDuration - updates.clock
          }
        }
      }

      dispatch({
        type: 'set',
        value: {
          videoTime,
          ...updates,
        },
      })

      if (video.current) {
        video.current.currentTime = videoTime - start
      }
    },
    [ready, dispatch, state, start, duration]
  )

  const showAdvanceToLatest = useMemo(() => {
    const videoTime = events?.[0]?.videoTime
    return videoTime && state.videoTime < videoTime
  }, [events, state.videoTime])

  const advanceToLatest = useCallback(() => {
    const event = events?.[0]
    const videoTime = event?.videoTime
    const clockTime = event?.clockTime
    if (videoTime) {
      dispatch({
        type: 'set',
        value: {
          videoTime,
          clock: clockTime,
        },
      })
    }
  }, [events, dispatch])

  useImperativeHandle(videoPlayer, () => ({
    advance: (millis: number, direction: 'forward' | 'backward') => {
      advance(millis, direction)()
    },
    play: playVideo,
    pause: pauseVideo,
  }))

  const shortcuts = useMemo(
    () =>
      (!state.activeEvent && !state.editEvent
        ? {
            right: advance(500, 'forward'),
            left: advance(500, 'backward'),
            'CmdOrCtrl+right': advance(1000, 'forward'),
            'CmdOrCtrl+left': advance(1000, 'backward'),
            'Alt+right': advance(10000, 'forward'),
            'Alt+left': advance(10000, 'backward'),
            x: toggleClocks,
            space: toggleClock,
            'CmdOrCtrl+]': advanceToLatest,
            z: () => {
              state.videoPlaying ? pauseVideo() : playVideo()
            },
          }
        : {}) as any,
    [
      advanceToLatest,
      toggleClock,
      toggleClocks,
      state.editEvent,
      state.activeEvent,
      state.videoPlaying,
      advance,
      pauseVideo,
      playVideo,
    ]
  )

  useShortcuts(shortcuts)

  useEventListener('focus', () => {
    if (wasPlaying.current) {
      playVideo()
    }
  })

  useEventListener('blur', () => {
    wasPlaying.current = state.videoPlaying
    pauseVideo()
  })

  useEventListener('scoring:video:play', () => playVideo())
  useEventListener('scoring:video:pause', () => pauseVideo())

  useMouseUpOutside(range, endSeek)

  return (
    <div {...rest}>
      <div className="video-container">
        {manifest?.src?.map((it, i) => {
          const isCurrentVideo = index === i
          if (!isCurrentVideo) {
            return null
          }

          return (
            <video
              ref={video}
              key={i}
              src={it}
              onLoadedData={(e: any) => {
                setReady(true)
              }}
              onPause={(e: any) => {
                const value: any = {
                  videoPlaying: false,
                }

                dispatch({
                  type: 'set',
                  value,
                })
              }}
              onPlay={() =>
                dispatch({
                  type: 'set',
                  value: {
                    videoPlaying: true,
                  },
                })
              }
              onTimeUpdate={(e: any) => {
                dispatch({
                  type: 'set',
                  value: {
                    videoTime: e.target.currentTime + start,
                  },
                })
              }}
              preload={state.videoTime > 0 ? 'auto' : 'metadata'}
              poster={manifest?.poster}
              style={{
                border: `solid 3px var(--ion-color-${
                  state.clockRunning ? 'success' : 'danger'
                })`,
              }}
            />
          )
        })}
        {!ready && (
          <div className="video-loading">
            <LoadingSpinner />
          </div>
        )}
      </div>
      <VideoEventTimeline
        value={state.videoTime}
        teamGame={state.teamGame}
        dispatch={dispatch}
        max={duration}
        onStartSeek={startSeek}
        onEndSeek={endSeek}
        onChange={seek}
        step={0.1}
        leftSlot={helpers.secondsToTimeString(state.videoTime, {
          millis: true,
        })}
        rightSlot={helpers.secondsToTimeString(duration, { millis: true })}
      />
      <div className={css('controls', { ready })}>
        <GenericButton
          icon={playBack}
          onClick={advance(6000, 'backward', false)}
          fill="clear"
          color="light"
          title="Backward 6 seconds"
        >
          6s
        </GenericButton>
        <GenericButton
          icon={playBack}
          onClick={advance(3000, 'backward')}
          fill="clear"
          color="light"
          title="Backward 3 seconds"
          indicatorIcon={stopwatch}
        >
          3s
        </GenericButton>
        <GenericButton
          icon={playBack}
          onClick={advance(500, 'backward')}
          fill="clear"
          color="light"
          title="Backward 1/2 second"
          indicatorIcon={stopwatch}
        />
        {state.videoPlaying ? (
          <GenericButton
            icon={pause}
            onClick={pauseVideo}
            fill="clear"
            color="light"
            title="Pause video"
          />
        ) : (
          <GenericButton
            icon={play}
            onClick={playVideo}
            fill="clear"
            color="light"
            title="Play video"
          />
        )}
        {state.videoTime !== duration && (
          <GenericButton
            icon={stopwatch}
            onClick={toggleClocks}
            disabled={
              !state.started ||
              (state.clock === state.periodDuration && state.period > 1)
            }
            fill="clear"
            title="Start/stop clock and video"
            color={
              state.videoPlaying || state.clockRunning ? 'basketball' : 'light'
            }
          />
        )}
        <GenericButton
          icon={playForward}
          onClick={advance(500, 'forward')}
          fill="clear"
          color="light"
          title="Forward 1/2 second"
          indicatorIcon={stopwatch}
        />
        <GenericButton
          icon={playForward}
          onClick={advance(3000, 'forward')}
          fill="clear"
          color="light"
          title="Forward 3 seconds"
          indicatorIcon={stopwatch}
        >
          3s
        </GenericButton>
        <GenericButton
          icon={playForward}
          onClick={advance(6000, 'forward', false)}
          fill="clear"
          color="light"
          title="Forward 6 seconds"
        >
          6s
        </GenericButton>
        <GenericButton
          icon={playSkipForward}
          onClick={advanceToLatest}
          fill="clear"
          color="light"
          title="Advance video to latest event"
          disabled={!showAdvanceToLatest}
        />
      </div>
      {showLocalVideoModal && (
        <ScoringLocalVideoModal
          onDidDismiss={() => setShowLocalVideoModal(false)}
        />
      )}
    </div>
  )
}

export default styled(React.memo(ScoringVideoPlayer))`
  height: auto;
  outline: none;
  width: 100%;
  position: relative;

  .video-container {
    position: relative;
    video {
      border-radius: 8px;
      height: auto;
      width: 100%;
    }

    .video-loading {
      background: rgba(0, 0, 0, 0.4);
      border-radius: 8px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 4px;
    }
  }

  ion-range {
    --padding: 0;
  }

  .controls {
    align-items: center;
    display: flex;
    justify-content: center;
    opacity: 0.5;

    ion-button {
      font-size: 12px;
    }

    &.ready {
      opacity: 1;
    }
  }
`
