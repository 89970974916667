import React, { useRef, useImperativeHandle, useState, useEffect } from 'react'
import FormField, { FormFieldProps } from './FormField'
import AutoComplete, { AutoCompleteProps } from './AutoComplete'
import notifications from '../core/notifications'

export interface FormAutoCompleteFieldProps extends AutoCompleteProps {
  label?: string
  position?: FormFieldProps['position']
  icon?: any
  save?: (val: string, e: any) => Promise<any>
  saving?: boolean
  remove?: () => any
  onLabelEdit?: (label: string) => any
  noIndicator?: boolean
  required?: boolean
}

const FormAutoCompleteField: React.ForwardRefRenderFunction<
  any,
  FormAutoCompleteFieldProps
> = (
  {
    type = 'text',
    className,
    icon,
    label,
    position,
    save,
    value,
    required,
    children,
    saving,
    remove,
    onLabelEdit,
    noIndicator,
    onItemSelected,
    ...rest
  },
  ref
) => {
  const [error, setError] = useState(false)
  const [invalid, setInvalid] = useState(false)
  const [isSaving, setIsSaving] = useState(saving)
  const [inputValue, setInputValue] = useState(value)
  const input = useRef<any>()
  useImperativeHandle(ref, () => input.current)

  const itemSelected = async (id: any, val: any) => {
    if (required && !id) {
      return setInvalid(true)
    }

    setInvalid(false)
    if (save) {
      setIsSaving(!noIndicator)
      try {
        await save(id, {})
      } catch (e) {
        notifications.errorToast(e)
        setError(true)
      } finally {
        setIsSaving(false)
      }
    }

    onItemSelected?.(id, val)
  }

  useEffect(() => {
    setIsSaving(saving)
  }, [saving])

  useEffect(() => {
    setInputValue(value)
  }, [value])

  return (
    <FormField
      label={label}
      icon={icon}
      position={position}
      saving={isSaving}
      invalid={invalid}
      className={className}
      remove={remove}
      onLabelEdit={onLabelEdit}
      error={error}
      required={required}
    >
      <AutoComplete
        ref={input}
        type={type}
        onItemSelected={itemSelected}
        value={inputValue}
        required={required}
        {...rest}
      />
      {children}
    </FormField>
  )
}

export default React.memo(React.forwardRef(FormAutoCompleteField))
