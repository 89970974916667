import React from 'react'
import {
  FormInputField,
  FormSelectField,
  FormToggleField,
  FormDateField,
  FormTextareaField,
  FormAutoCompleteField,
  FormDynamicSelectField,
  FormColorField,
  PickOne,
} from '../components'
import moment from 'moment'
import _ from 'lodash'
import { IonDatetime } from '@ionic/react'

export interface DataField {
  key: string
  label: string | ((data?: any) => string)
  labelKey?: string
  type:
    | 'select'
    | 'input'
    | 'toggle'
    | 'filter-select'
    | 'filter-date'
    | 'filter-datetime'
    | 'dynamic-select'
    | 'date'
    | 'datetime'
    | 'text'
    | 'textarea'
    | 'autocomplete'
    | 'pick-one'
    | 'custom'
    | 'color'

  inputType?: HTMLIonInputElement['type']
  url?: string
  autocompleteKey?: string
  renderAutocompleteLabel?: (data: any) => any
  customComponent?: any
  render?: (entity: any, save: any) => any
  options?: [any, any][]
  readonly?: boolean
  editOnly?: boolean
  max?: string
  min?: string
  required?: boolean
  nullable?: boolean
  validate?: (val: string) => boolean
  transform?: (val: any, input: boolean) => any
  mask?: string
  multiple?: boolean
  dateTimeOptions?: Partial<React.ComponentProps<typeof IonDatetime>>
  onEnterPress?: (key: string, val?: any) => any
}
export function renderDataFields(
  isCreate: boolean,
  fields: DataField[],
  entity: any,
  save: any
) {
  return fields
    .filter((it) => !isCreate || !it.editOnly)
    .map((field) => renderDataField(isCreate, field, entity, save))
}
export function renderDataField(
  isCreate: boolean,
  field: DataField,
  entity: any,
  save: any
) {
  const label =
    typeof field.label === 'string' ? field.label : field?.label?.(entity)
  const defaults = {
    label,
    key: field.key,
    save: save(field.key),
    noIndicator: isCreate,
    required: isCreate && field.required,
  }
  let value = entity?.[field.key]
  switch (field.type) {
    case 'select':
    case 'filter-select':
      let options = field.options ?? []

      if (field.type === 'filter-select') {
        value = value ?? '!all'
        options = [['!all', 'All'], ...options]
      }

      return (
        <FormSelectField
          {...defaults}
          options={options}
          value={value}
          readonly={field.readonly}
          multiple={field.multiple}
        />
      )
    case 'pick-one':
      return <PickOne {...defaults} value={value} options={field.options} />
    case 'toggle':
      return (
        <FormToggleField
          {...defaults}
          checked={value}
          readonly={field.readonly}
        />
      )
    case 'date':
    case 'datetime':
    case 'filter-date':
    case 'filter-datetime':
      const withTime = !!field.type.match(/time/)
      let max = field.max ?? moment().add(10, 'years').format('YYYY-MM-DD')
      let min = field.min ?? moment().add(-10, 'years').format('YYYY-MM-DD')
      return (
        <FormDateField
          {...defaults}
          withTime={withTime}
          min={min}
          max={max}
          value={value}
          readonly={field.readonly}
          {...(field.dateTimeOptions ?? {})}
        />
      )
    case 'textarea':
    case 'text':
      return (
        <FormTextareaField
          {...defaults}
          value={value}
          readonly={field.readonly ?? field.type === 'text'}
        />
      )
    case 'autocomplete':
      if (field.autocompleteKey) {
        value = _.get(entity, field.autocompleteKey)
      }

      return (
        <FormAutoCompleteField
          {...defaults}
          url={field.url}
          value={value}
          renderItemLabel={field.renderAutocompleteLabel}
          readonly={field.readonly}
        />
      )
    case 'dynamic-select':
      return (
        <FormDynamicSelectField
          {...defaults}
          url={field.url}
          value={value}
          readonly={field.readonly}
          multiple={field.multiple}
        />
      )
    case 'color':
      return (
        <FormColorField
          {...defaults}
          entity={entity}
          field={field.key}
          readonly={field.readonly}
          onLabelEdit={field.labelKey ? save(field.labelKey) : undefined}
        />
      )
    case 'custom':
      if (field.render) {
        return field.render(entity, defaults.save)
      }

      const Component = field.customComponent
      return (
        <Component {...defaults} value={entity} readonly={field.readonly} />
      )
    default:
      return (
        <FormInputField
          {...defaults}
          nullable={field.nullable}
          type={field.inputType}
          value={value}
          readonly={field.readonly}
          validate={field.validate}
          mask={field.mask}
          transform={field.transform}
          onEnterPress={
            field?.onEnterPress
              ? (val: any) => field?.onEnterPress?.(field.key, val)
              : undefined
          }
        />
      )
  }
}
