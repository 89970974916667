export interface QueueTask {
  fn: () => any
  resolve: (r?: any) => any
  reject: (r?: any) => any
}
export default class Queue {
  private _q: QueueTask[] = []
  private current: QueueTask | undefined | null = null
  constructor() {
    setInterval(this.monitor.bind(this), 1000)
  }

  private async monitor() {
    if (!this.current && this._q.length > 0) {
      const task = this._q.shift() as QueueTask
      this.current = task
      try {
        await task.fn()
        task?.resolve()
      } catch (e) {
        task?.reject()
      } finally {
        this.current = null
      }
    }
  }

  async add(fn: () => any) {
    return new Promise((resolve, reject) => {
      this._q.push({
        resolve,
        reject,
        fn,
      })
    })
  }
}
