import React, {
  useState,
  useRef,
  useImperativeHandle,
  useMemo,
  useContext,
  useCallback,
} from 'react'
import styled from 'styled-components'
import WheelMenu from './WheelMenu'
import { teamGameEventService, teamGameService } from '../services'
import { CourtDimensions, CourtPosition } from '../util/court-renderer'
import { CourtCanvas } from '.'
import { usePortal, useShortcuts } from '../hooks'
import { IonIcon } from '@ionic/react'
import { swapHorizontal } from 'ionicons/icons'
import { ScoringPageEvent, TeamGameEventType } from '../types/interfaces'
import { ScoringContext } from '../contexts/ScoringContext'
import helpers from '../util/helpers'
import Jersey from './Jersey'

export interface ScoringCourtProps {}

export interface ScreenLocation {
  x: number
  y: number
}

const ScoringCourt: React.ForwardRefRenderFunction<any, ScoringCourtProps> = (
  { ...rest },
  ref
) => {
  const { state, dispatch, completed, gameClock, videoPlayer } = useContext(
    ScoringContext
  )

  const onEvent = (activeEvent: any) =>
    dispatch({ type: 'set', value: { activeEvent } })

  const { court, league } = state
  const courtRef = useRef<any>()
  const { Portal } = usePortal({
    bindTo: document.getElementById('app') ?? document.body,
  })
  useImperativeHandle(ref, () => courtRef.current)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [courtPosition, setCourtPosition] = useState<
    CourtPosition | null | undefined
  >()
  const [position, setPosition] = useState<ScreenLocation>()
  const [pointValue, setPointValue] = useState<number>(0)
  const [dimensions, setDimensions] = useState<
    CourtDimensions | null | undefined
  >()
  const [eventPeriod, setEventPeriod] = useState<any>()
  const [eventClock, setEventClock] = useState<any>()
  const [eventVideoTime, setEventVideoTime] = useState<any>()

  const onClick = (e: any) => {
    const { canvas, renderer } = courtRef.current ?? {}

    //Don't allow if game is complete or not started
    if (!canvas || !renderer || completed || !state.started) {
      return
    }

    if (!state.isLive) {
      gameClock.current?.stop?.()
    }

    videoPlayer.current?.pause?.()
    setEventPeriod(state.period)
    setEventClock(state.clock)
    setEventVideoTime(helpers.getCurrentRelativeVideoTime(state))

    const {
      x,
      y,
      side,
      height,
      width,
      ppi,
      distance,
      hoopSide,
    } = renderer.toRimBasedFromClickEvent(e)
    setDimensions({
      height,
      width,
      ppi,
    })
    setPosition({ x: e.clientX, y: e.clientY })
    setCourtPosition({ x, y, side, distance, hoopSide })

    setPointValue(renderer.getLocationPointValue(x, y))
    setIsMenuOpen(true)
  }

  const handlerFor = (type: TeamGameEventType) => () => {
    const { renderer } = courtRef.current ?? {}
    if (renderer && dimensions && position && courtPosition) {
      let isHomeTeam =
        (courtPosition.side === 'left' && state.homeTeamIsLeft) ||
        (courtPosition.side === 'right' && !state.homeTeamIsLeft)

      if (type.id === 'foul') {
        //Default to the other team
        isHomeTeam = !isHomeTeam
        //Play the video only to see the foul details
        videoPlayer.current?.play?.()
      }

      const feet = renderer.getLocationFeet(courtPosition.x, courtPosition.y)
      let offset = -1
      if (type.zeroToSixOffset && feet <= 6) {
        offset -= type.zeroToSixOffset
      } else if (type.sixToEighteenOffset && feet > 6 && feet <= 18) {
        offset -= type.sixToEighteenOffset
      } else if (type.eighteenPlusOffset && feet > 18) {
        offset -= type.eighteenPlusOffset
      }

      const event: ScoringPageEvent = {
        isHomeTeam,
        type,
        location: {
          ...courtPosition,
          ...dimensions,
          screenX: position.x,
          screenY: position.y,
        },
        league,
        court,
        pointValue,
        period: eventPeriod,
        clockTime: eventClock - offset,
        videoTime: eventVideoTime + offset,
        isOrigin: true,
      }

      onEvent(event)
    }
  }

  const wheelItems = [
    {
      image: teamGameEventService.types.shot_made.icon,
      shortcut: teamGameEventService.types.shot_made.shortcut,
      handler: handlerFor(teamGameEventService.types.shot_made),
    },
    {
      image: teamGameEventService.types.shot_miss.icon,
      shortcut: teamGameEventService.types.shot_miss.shortcut,
      handler: handlerFor(teamGameEventService.types.shot_miss),
    },
    {
      image: teamGameEventService.types.turnover.icon,
      shortcut: teamGameEventService.types.turnover.shortcut,
      handler: handlerFor(teamGameEventService.types.turnover),
    },
    {
      image: teamGameEventService.types.foul.icon,
      shortcut: teamGameEventService.types.foul.shortcut,
      handler: handlerFor(teamGameEventService.types.foul),
    },
  ]

  useShortcuts(
    isMenuOpen
      ? {
          ...wheelItems.reduce((obj: any, it: any) => {
            obj[it.shortcut] = () => {
              it.handler()
              setIsMenuOpen(false)
            }
            return obj
          }, {}),
          esc: () => {
            setIsMenuOpen(false)
          },
          q: () => {
            setIsMenuOpen(false)
          },
        }
      : {}
  )

  const homeTeamName = useMemo(
    () => teamGameService.getHomeTeamName(state.teamGame),
    [state]
  )
  const awayTeamName = useMemo(
    () => teamGameService.getAwayTeamName(state.teamGame),
    [state]
  )

  const leftName = useMemo(
    () => (state.homeTeamIsLeft ? homeTeamName : awayTeamName),
    [state, homeTeamName, awayTeamName]
  )
  const rightName = useMemo(
    () => (!state.homeTeamIsLeft ? homeTeamName : awayTeamName),
    [state, homeTeamName, awayTeamName]
  )

  const leftFill = useMemo(
    () => teamGameService.getJerseyFill(state.teamGame, state.homeTeamIsLeft),
    [state.teamGame, state.homeTeamIsLeft]
  )

  const leftStroke = useMemo(
    () => teamGameService.getJerseyStroke(state.teamGame, state.homeTeamIsLeft),
    [state.teamGame, state.homeTeamIsLeft]
  )

  const rightFill = useMemo(
    () => teamGameService.getJerseyFill(state.teamGame, !state.homeTeamIsLeft),
    [state.teamGame, state.homeTeamIsLeft]
  )

  const rightStroke = useMemo(
    () =>
      teamGameService.getJerseyStroke(state.teamGame, !state.homeTeamIsLeft),
    [state.teamGame, state.homeTeamIsLeft]
  )

  const swapCourtside = useCallback(() => {
    //Don't allow if game is complete
    if (completed) {
      return
    }

    dispatch({
      type: 'set',
      value: {
        homeTeamIsLeft: !state.homeTeamIsLeft,
      },
    })
  }, [dispatch, state.homeTeamIsLeft, completed])

  return (
    <div {...rest}>
      <CourtCanvas
        ref={courtRef}
        overrides={league.courtOverrides}
        court={court}
        onClick={onClick}
      />
      <div className="team-court-side">
        <div className="team-court-side--display">
          <Jersey
            className="jersey"
            number={helpers.getInitials(leftName)}
            color={leftFill}
            stroke={leftStroke}
            height={30}
          />
          <span>{leftName}</span>
        </div>
        <IonIcon onClick={swapCourtside} icon={swapHorizontal} />
        <div className="team-court-side--display">
          <span>{rightName}</span>
          <Jersey
            className="jersey"
            number={helpers.getInitials(rightName)}
            color={rightFill}
            stroke={rightStroke}
            height={30}
          />
        </div>
      </div>
      <Portal>
        <WheelMenu
          position={position}
          isOpen={isMenuOpen}
          onWillDismiss={() => setIsMenuOpen(false)}
          items={wheelItems}
        />
      </Portal>
    </div>
  )
}

export default styled(React.memo(React.forwardRef(ScoringCourt)))`
  width: 100%;

  .team-court-side {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    margin: 5px 0;
    user-select: none;

    .team-court-side--display {
      align-items: center;
      display: flex;

      .jersey {
        flex: 0 0 auto;
        margin-right: 10px;
      }

      span {
        flex: 1 1 auto;
        font-size: 13px;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:last-child {
        justify-content: flex-end;

        span {
          text-align: right;
        }

        .jersey {
          margin-right: 0;
          margin-left: 10px;
        }
      }
    }
  }
`
