import React, { useContext, useMemo } from 'react'
import styled from 'styled-components'
import { IonGrid, IonRow, IonCol } from '@ionic/react'
import { PlayerInsights } from '../types/models'
import { AppContext } from '../contexts/AppContext'
import _ from 'lodash'
import 'chartjs-plugin-annotation'
//@ts-expect-error
import { Line, Chart, HorizontalBar } from 'react-chartjs-2'
import { ChartCard } from '.'
import {annotation} from './ChartConfig'

Chart.plugins.register({
  id: 'chartjs-plugin-annotation',
})

export interface InsightsFieldGoalChartsProps {
  data?: PlayerInsights
}

const InsightsFieldGoalCharts: React.FC<InsightsFieldGoalChartsProps> = ({
  data,
  ...props
}) => {
  const { state } = useContext(AppContext)
  const league = useMemo(
    () => state.leagues.find((it) => it.id === state.teamBrand?.leagueId),
    [state]
  )

  const court = useMemo(
    () => state.courts.find((it) => it.id === league?.id) ?? state.courts[0],
    [state, league]
  )

  const shotsByFoot = useMemo(() => {
    return data?.shots
        ?.reduce?.((obj: any, it: any) => {
      const feet = Math.round(it.distance)
      if(feet < 31){
        obj[feet] = obj[feet] ?? []
        obj[feet].push(it)
      }
      return obj
    }, _.zipObject(_.range(0, 31)))
  }, [data])

  const shotsByFootAndHoopSide = useMemo(() => {
    return data?.shots
    ?.reduce?.((obj: any, it: any) => {
      const feet = Math.round(it.distance)
      if(feet < 31){
        obj[feet] = obj[feet] ?? {
          left: [],
          right: [],
        }
        obj[feet][it.hoopSide].push(it)
     }
      return obj
    }, _.zipObject(_.range(0, 30)))
  }, [data])

  return (
    <div {...props}>
      <IonGrid>
        <IonRow className="ion-align-items-stretch">
        <IonCol></IonCol>
          <IonCol>
            <ChartCard title="Field Goal %" subtitle="by Distance">
              <Line
                options={{
                  responsive: true,
                  annotation: annotation(court, 'vertical', 'x-axis-0'),
                  scales: {
                    xAxes: [
                      {
                        ticks: {
                          autoSkip: true,
                          maxTicksLimit: 6,
                        },
                        scaleLabel: {
                          display: true,
                          labelString: 'Distance (ft)',
                        },
                      },
                    ],
                    yAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: 'Field Goal %',
                        },
                        ticks: {
                          callback: (value: any) => `${value}%`,
                        },
                      },
                    ],
                  },
                  legend: {
                    display: false,
                  },
                }}
                data={{
                  labels: Object.keys(shotsByFoot ?? {}) ?? [],
                  datasets: [
                    {
                      label: '% of Goals',
                      fill: false,
                      lineTension: 0.1,
                      backgroundColor: 'rgba(251, 94, 28,0.4)',
                      borderColor: 'rgba(251, 94, 28,1)',
                      borderCapStyle: 'butt',
                      borderDash: [],
                      borderDashOffset: 0.0,
                      borderJoinStyle: 'miter',
                      pointBorderColor: 'rgba(251, 94, 28,1)',
                      pointBackgroundColor: '#fff',
                      pointBorderWidth: 1,
                      pointHoverRadius: 5,
                      pointHoverBackgroundColor: 'rgba(251, 94, 28,1)',
                      pointHoverBorderColor: 'rgba(220,220,220,1)',
                      pointHoverBorderWidth: 2,
                      pointRadius: 1,
                      pointHitRadius: 10,
                      data: Object.values(shotsByFoot ?? {}).map((it: any) =>
                        Math.round(
                          ((it?.filter((itr1:any) =>
                              itr1['made'] === true)?.length ?? 0) / (it?.length ?? 1)) * 100
                        )
                      ),
                    },
                  ],
                }}
              />
            </ChartCard>
          </IonCol>
          <IonCol>
            <ChartCard title="Field Goal %" subtitle="Left vs Right Side">
              <HorizontalBar
                options={{
                  responsive: true,
                  annotation: annotation(court, 'horizontal', 'y-axis-0'),
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          autoSkip: true,
                          maxTicksLimit: 6,
                        },
                        stacked: true,
                        scaleLabel: {
                          display: true,
                          labelString: 'Distance (ft)',
                        },
                      },
                    ],
                    xAxes: [
                      {
                        position: 'left',
                        scaleLabel: {
                          display: true,
                          labelString: 'Goals (%)',
                        },
                        ticks: {
                          min: -100, // maxFrequency * -1,
                          max: 100, //maxFrequency,
                          callback: (value: any) => `${Math.abs(value)}%`,
                        },
                      },
                    ],
                  },
                  legend: {
                    display: false,
                  },
                  tooltips: {
                    enabled: true,
                    callbacks: {
                      label: (tooltipItems, data) => 
                      `${
                          Math.abs(
                          parseInt(tooltipItems?.value || '0')
                        )}% Shots`
                      
                        ,
                    },
                  },
                }}
                data={{
                  labels: Object.keys(shotsByFootAndHoopSide ?? {}) ?? [],
                  datasets: [
                    {
                      label: 'Left',
                      fill: false,
                      lineTension: 0.1,
                      backgroundColor: 'rgba(45, 211, 111,0.4)',
                      borderColor: 'rgba(45, 211, 111,1)',
                      borderCapStyle: 'butt',
                      borderDash: [],
                      borderDashOffset: 0.0,
                      borderJoinStyle: 'miter',
                      pointBorderColor: 'rgba(45, 211, 111,1)',
                      pointBackgroundColor: '#fff',
                      pointBorderWidth: 1,
                      pointHoverRadius: 5,
                      pointHoverBackgroundColor: 'rgba(45, 211, 111,1)',
                      pointHoverBorderColor: 'rgba(220,220,220,1)',
                      pointHoverBorderWidth: 2,
                      pointRadius: 1,
                      pointHitRadius: 10,
                      data: Object.values(shotsByFootAndHoopSide ?? {}).map((it: any) => 
                        ((it?.left.filter((itr1:any) =>
                        itr1['made'] === true)?.length ?? 0)/(it?.left.length > 1 ? it?.left.length: 1)) * -100
                      ),
                    },
                    {
                      label: 'Right',
                      fill: false,
                      lineTension: 0.1,
                      backgroundColor: 'rgba(82, 96, 157,0.4)',
                      borderColor: 'rgba(82, 96, 157,1)',
                      borderCapStyle: 'butt',
                      borderDash: [],
                      borderDashOffset: 0.0,
                      borderJoinStyle: 'miter',
                      pointBorderColor: 'rgba(82, 96, 157,1)',
                      pointBackgroundColor: '#fff',
                      pointBorderWidth: 1,
                      pointHoverRadius: 5,
                      pointHoverBackgroundColor: 'rgba(82, 96, 157,1)',
                      pointHoverBorderColor: 'rgba(220,220,220,1)',
                      pointHoverBorderWidth: 2,
                      pointRadius: 1,
                      pointHitRadius: 10,
                      data: Object.values(shotsByFootAndHoopSide ?? {}).map((it: any) => 
                        ((it?.right.filter((itr1:any) =>
                        itr1['made'] === true).length ?? 0)/((it?.right.length > 1 ? it?.right.length: 1))) * 100                      
                        ),
                    },
                  ],
                }}
              />
            </ChartCard>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  )
}

export default styled(React.memo(InsightsFieldGoalCharts))``
