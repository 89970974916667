import React, { useRef, useState, useMemo, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { GenericButton, ScoringActivePlayer, ScoringRosterSelect } from '.'
import { useShortcuts } from '../hooks'
import { teamGameEventService, teamGameService } from '../services'
import { ScoringPageEvent, ScoringPlayerSelection } from '../types/interfaces'
import { ScoringContext } from '../contexts/ScoringContext'
import _ from 'lodash'

export interface ScoringEventPlayerSelectionProps {
  event: ScoringPageEvent
  onSelection: (selection: ScoringPlayerSelection) => any
  origin: ScoringPageEvent
  subtitle?: string
  isFreethrowRebound: boolean
}
const ScoringEventPlayerSelectionComponent: React.FC<ScoringEventPlayerSelectionProps> = ({
  event,
  onSelection,
  origin,
  subtitle,
  isFreethrowRebound,
  ...rest
}) => {
  const { state } = useContext(ScoringContext)
  const [isHomeTeam, setIsHomeTeam] = useState(event?.isHomeTeam)
  const [shortcutsDisabled, setShortcutsDisabled] = useState(false)
  const select = useRef<any>()
  const choose = (selection: ScoringPlayerSelection) => () => {
    setShortcutsDisabled(false)
    onSelection(selection)
  }

  const choosePlayer = (teamGamePersonnel: any) => () => {
    choose({
      teamGamePersonnel,
      jerseyName: teamGamePersonnel.fullName,
      jerseyNumber: teamGamePersonnel.jersey,
      teamId: state.teamId,
      isHomeTeam,
    })()
  }

  useEffect(() => {
    setIsHomeTeam(event?.isHomeTeam)
  }, [event])

  const roster = useMemo(
    () => (isHomeTeam ? state.activeHome : state.activeAway),
    [state, isHomeTeam]
  )

  const starters = useMemo(
    () =>
      event.type.showAllPlayers === true
        ? [...(state.activeHome ?? []), ...(state.activeAway ?? [])]
        : teamGameEventService.filterRoster(roster, event, origin),
    [roster, event, origin, state]
  )

  const chooseBench = () => {
    setShortcutsDisabled(true)
    select.current?.click()
  }

  const skipSelection = () => {
    choose({
      teamId: state.teamId,
      isHomeTeam,
    })()
  }

  const otherTeam = () => setIsHomeTeam(!isHomeTeam)

  const showTeamPlayers = useMemo(
    () => !state.isSingleTeam || isHomeTeam === state.isSingleTeamHome,
    [state.isSingleTeam, state.isSingleTeamHome, isHomeTeam]
  )

  const teamName = useMemo(
    () => teamGameService.getTeamNameByHome(state.teamGame, isHomeTeam),
    [state.teamGame, isHomeTeam]
  )

  const shortcuts = !shortcutsDisabled
    ? {
        ...(showTeamPlayers
          ? teamGameService
              .sortByJerseyNumber(starters)
              ?.reduce((obj: any, it: any, i: number) => {
                obj[i + 1] = choosePlayer(it)
                return obj
              }, {})
          : _.zipObject(_.range(1, 5), _.fill(new Array(5), skipSelection))),
        r: chooseBench,
        e: skipSelection,
        w: otherTeam,
      }
    : {}

  useShortcuts(shortcuts)

  return (
    <div {...rest}>
      {subtitle && <span className="help-text">{subtitle}</span>}
      <div className="team">
        <div className="team-players">
          {showTeamPlayers ? (
            teamGameService
              .sortByJerseyNumber(starters)
              ?.map((it: any, i: number) => {
                const number = it.jersey
                const { lastName, firstName, id } = it
                return (
                  <ScoringActivePlayer
                    key={id}
                    player={{
                      id,
                      number,
                      lastName,
                      firstName,
                      isHomeTeam,
                    }}
                    shortcut={i + 1}
                    onClick={choosePlayer(it)}
                  />
                )
              })
          ) : (
            <ScoringActivePlayer
              player={{
                lastName: teamName,
                isHomeTeam,
              }}
              shortcut={1}
              onClick={skipSelection}
            />
          )}
        </div>
      </div>
      {showTeamPlayers && (
        <div className="other-options">
          <GenericButton
            expand="block"
            color="light"
            size="small"
            fill="clear"
            onClick={skipSelection}
          >
            Later (e)
          </GenericButton>
          <GenericButton
            expand="block"
            color="light"
            size="small"
            fill="clear"
            onClick={chooseBench}
          >
            Bench (r)
          </GenericButton>
        </div>
      )}
      {(event.type.allowOtherTeamSelection || isFreethrowRebound) && (
        <GenericButton
          expand="block"
          color="light"
          size="small"
          fill="clear"
          onClick={otherTeam}
        >
          Other Team (w)
        </GenericButton>
      )}
      <ScoringRosterSelect
        ref={select}
        cancelText="Cancel"
        state={state}
        isHomeTeam={isHomeTeam}
        event={event}
        origin={origin}
        onIonCancel={() => setShortcutsDisabled(false)}
        onSelected={(value: string) => {
          if (!value) {
            return
          }

          const players = isHomeTeam ? state.eligibleHome : state.eligibleAway
          const player = players?.find((it) => it.id === value)

          if (player) {
            choosePlayer(player)()
          }
        }}
      />
    </div>
  )
}

export default styled(React.memo(ScoringEventPlayerSelectionComponent))`
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 100%;

  .other-options {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  ion-button {
    text-transform: capitalize;
    flex: 1 1 50%;
  }

  .help-text {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .team {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;

    .team-players {
      display: grid;
      grid-template-rows: repeat(5, 1fr);
      width: 100%;
      margin-bottom: 15px;
    }
  }
`
