import { useMemo, useState, useEffect, useCallback } from 'react'
import useEventListener from './useEventListener'
import { teamVideoService } from '../services'

export default function useVideoUploadStatus(videoId: string) {
  const [progress, setProgress] = useState(0)
  const [error, setError] = useState<any>()
  const [status, setStatus] = useState<any>()
  const event = useMemo(() => `video:${videoId}`, [videoId])

  useEffect(() => {
    const upload = teamVideoService.uploads[videoId]
    if (upload) {
      setProgress(upload.totalLoaded / upload.totalSize)
      setStatus('UPLOADING')
    }
  }, [videoId])

  const handler = useCallback(({ detail }) => {
    switch (detail.type) {
      case 'progress':
        setProgress(detail.percentComplete)
        setStatus('UPLOADING')
        break
      case 'status':
        setStatus(detail.status)
        setError(detail.error)
        break
    }
  }, [])

  useEventListener(event, handler)
  return {
    progress,
    error,
    status,
  }
}
