import React, {
  createContext,
  useReducer,
  Dispatch,
  useCallback,
  useMemo,
} from 'react'
import { User, Court, League, Team, TeamBrand, Account } from '../types/models'
import { userService, eventsService } from '../services'
import _ from 'lodash'
import { useEventListener } from '../hooks'
import { authentication } from '../core'
import helpers from '../util/helpers'

export interface UpdateAppStateAction {
  type:
    | 'set'
    | 'sse'
    | 'notificationSeen'
    | 'setTeam'
    | 'setTeamBrand'
    | 'customLeagueAdded'
    | 'customLeagueUpdated'
    | 'teamBrandAdded'
    | 'teamAdded'
    | 'changeContext'
    | 'setInvitation'
    | 'initialize'
  value: any
}

export interface AppState {
  isAuthenticated: boolean
  accessToken?: string
  user?: User
  courts: Court[]
  leagues: League[]
  accounts?: Account[]
  teamBrand?: TeamBrand
  team?: Team
  ready: boolean
  hasNotifications: boolean
  inviteId?: string
  inviteToken?: string
}

export function refreshState() {
  window.dispatchEvent(new CustomEvent('user:refresh'))
}

function updateTeamOrBrand(
  model: 'teamBrand' | 'team',
  state: AppState,
  event: any
) {
  const { type, detail } = event
  const { data } = detail
  const { user } = state
  if (!user) {
    return
  }

  const entity = state[model]
  const collection: any[] =
    user[model === 'team' ? 'teams' : 'teamBrands'] ?? []

  switch (type) {
    case 'db:create':
      refreshState()
      break
    case 'db:update':
      const current = _.find(collection, (it: any) => it.id === data.id) as any
      if (current) {
        const index = collection.indexOf(current)
        collection[index] = { ...current, ...data }
        if (entity?.id === data.id) {
          state[model] = { ...entity, ...data }
        }
      }
      break
    case 'db:destroy':
      _.remove(collection, (it: any) => it.id === data.id)
      if (entity?.id === data.id) {
        state[model] = undefined
      }
      break
  }
}

function handleServerSentEvent(state: AppState, event: any) {
  const { type, detail } = event
  const { model, data } = detail
  const { user } = state

  switch (model) {
    case 'user':
      if (type === 'db:destroy') {
        authentication.clear()
        return { isAuthenticated: false, user: null, accessToken: null }
      }

      const { accounts, ...rest } = data

      return {
        ...state,
        user: rest,
        accounts: accounts,
        hasNotifications: !!user?.notifications?.length,
      }

    case 'team-brand-subscription':
      const teamBrand = state.user?.teamBrands?.find(
        (it) => it.id === data.teamBrandId
      )

      if (teamBrand) {
        teamBrand.subscriptions = [data]
        let updated = {
          ...state,
          user: {
            ...state.user,
            teamBrands: [
              ...helpers.arrayReplace(state.user?.teamBrands, teamBrand),
            ],
          },
        }

        if (state.teamBrand?.id === data.teamBrandId) {
          updated.teamBrand = { ...teamBrand }
        }

        return updated
      }
      break

    case 'account':
      return {
        ...state,
        accounts: helpers.arrayReplace(state.accounts, data),
      }

    case 'user-notification':
      if (type !== 'db:create') {
        if (user?.notifications) {
          _.remove(user.notifications, (it) => it.id === data.id)
        }
      } else {
        const notifs = user?.notifications ?? []
        if (user) {
          notifs.push(data)
          user.notifications = notifs
        }
      }

      return {
        ...state,
        hasNotifications: !!user?.notifications?.length,
      }

    case 'team-brand':
      updateTeamOrBrand('teamBrand', state, event)
      break

    case 'team':
      updateTeamOrBrand('team', state, event)
      break
  }

  return { ...state, user }
}

const reducer = (state: AppState, action: UpdateAppStateAction) => {
  switch (action.type) {
    case 'initialize':
      if (state.ready) {
        return state
      }

      return { ...state, ...action.value, ready: true }
    case 'set':
      return { ...state, ...action.value }

    case 'sse':
      return handleServerSentEvent(state, action.value)

    case 'notificationSeen':
      _.remove(
        state.user?.notifications ?? [],
        (it: any) => it.id === action.value
      )
      return { ...state, hasNotifications: !!state.user?.notifications?.length }

    case 'setTeam':
      userService.updateSelectedTeam(action.value)
      return { ...state, team: action.value }

    case 'setTeamBrand':
      const team = state.user?.teams?.find(
        (it: any) => it.teamBrandId === action.value.id
      )
      userService.updateSelectedTeam(team)
      userService.updateSelectedTeamBrand(action.value)
      return { ...state, teamBrand: action.value, team }

    case 'changeContext':
      userService.updateSelectedTeam(action.value.team)
      userService.updateSelectedTeamBrand(action.value.teamBrand)
      return {
        ...state,
        team: action.value.team,
        teamBrand: action.value.teamBrand,
      }

    case 'customLeagueAdded':
      return { ...state, leagues: [...state.leagues, action.value] }

    case 'customLeagueUpdated':
      _.remove(state.leagues, (it) => it.id === action.value.id)
      return { ...state, leagues: [...state.leagues, action.value] }

    case 'setInvitation':
      localStorage.setItem('inviteId', action.value.id)
      localStorage.setItem('inviteToken', action.value.token)
      return {
        ...state,
        inviteId: action.value.id,
        inviteToken: action.value.token,
      }

    case 'teamBrandAdded':
      userService.updateSelectedTeamBrand(action.value)
      userService.updateSelectedTeam(action.value.teams[0])

      return {
        ...state,
        user: {
          ...state.user,
          teamBrands: [action.value, ...(state.user?.teamBrands ?? [])],
          teams: [...action.value.teams, ...(state.user?.teams ?? [])],
        },
        team: action.value.teams[0],
        teamBrand: action.value,
      }

    case 'teamAdded':
      userService.updateSelectedTeam(action.value)
      return {
        ...state,
        user: {
          ...state.user,
          teams: [action.value, ...(state.user?.teams ?? [])],
        },
        team: action.value,
      }
  }
}

export interface AppContextProps {
  dispatch: Dispatch<UpdateAppStateAction>
  state: AppState
  refresh: () => any
  hasFeature: (feature: string) => boolean
}

const initial = {
  isAuthenticated: false,
  courts: [],
  leagues: [],
  hasNotifications: false,
  ready: false,
  inviteId: localStorage.getItem('inviteId'),
  inviteToken: localStorage.getItem('inviteToken'),
} as AppState

const AppContext = createContext<AppContextProps>({
  state: initial,
  dispatch: () => undefined,
  refresh: () => undefined,
  hasFeature: (feature: string) => false,
})

export interface AppContextProviderProps {
  initialState?: AppState
}

export function getTeamBrand(state: AppState, data?: any) {
  return state.user?.teamBrands?.find((it) => it.id === data?.teamBrandId)
}

export function getTeam(state: AppState, data?: any) {
  return state.user?.teams?.find((it) => it.id === data?.teamId)
}

const AppContextProvider: React.FC<AppContextProviderProps> = ({
  children,
  initialState = {},
}) => {
  const fullInitialState = {
    ...initial,
    ...initialState,
  }

  const [state, dispatch] = useReducer(reducer, fullInitialState)

  const hasFeature = useCallback(
    (feature: string) =>
      state.user?.isAdmin ||
      (state.user?.features && state.user?.features?.indexOf(feature) > -1),
    [state.user]
  )

  const refresh = useCallback(async () => {
    const data = await userService.load()
    dispatch({
      type: 'set',
      value: data,
    })
  }, [dispatch])

  const value = useMemo(
    () => ({
      state,
      dispatch,
      hasFeature,
      refresh,
    }),
    [state, dispatch, refresh, hasFeature]
  )

  const onEvent = useCallback(
    (event: any) => {
      dispatch({
        type: 'sse',
        value: event,
      })
    },
    [dispatch]
  )

  useEventListener('state:refresh', value.refresh)
  useEventListener('event', onEvent, eventsService)

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

const AppContextConsumer = AppContext.Consumer

export { AppContext, AppContextProvider, AppContextConsumer }
