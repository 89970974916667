import { Court } from './../types/models'
import StateBaseService from './state-base'
import { http } from '../core'
import { DataField } from '../util/data-field'

class CourtService extends StateBaseService {
  protected stateKey = 'courts'
  protected modelName = 'courts'

  protected async additionalState(data: any) {
    return {}
  }

  public get fields(): DataField[] {
    return []
  }

  async request() {
    const { data } = await http.authorizedRequest({
      method: 'GET',
      url: '/v1/courts/',
    })

    return data
  }

  getCourt(data: Court[], courtId: string, overrides: Partial<Court> = {}) {
    let court = data?.find((it) => it.id === courtId)

    if (court) {
      court = {
        ...court,
        ...overrides,
      }
    }

    return court ?? data[0]
  }
}

export default new CourtService()
