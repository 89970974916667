import React, { useState, useEffect, useMemo } from 'react'
import PopoverSelector, { PopoverSelectorProps } from './PopoverSelector'
import { TeamGamePersonnel } from '../types/models'
import styled from 'styled-components'
import helpers from '../util/helpers'
import _ from 'lodash'
import { GenericButton } from '.'
import { IonButtons } from '@ionic/react'

export interface PlayerPopoverSelector {
  items?: TeamGamePersonnel[]
  isOpen: PopoverSelectorProps['isOpen']
  onSelected?: PopoverSelectorProps['onSelected']
  onDidDismiss?: PopoverSelectorProps['onDidDismiss']
  isHome?: boolean
  teamNames?: { home?: string; away?: string }
  isSingleTeam: boolean
  isSingleTeamHome: boolean
}

const PlayerPopoverSelector: React.FC<PlayerPopoverSelector> = ({
  items,
  isHome,
  teamNames,
  isSingleTeam,
  isSingleTeamHome,
  onDidDismiss,
  ...rest
}) => {
  const [isHomeTeam, setIsHomeTeam] = useState(isHome)
  const displayPlayers = useMemo(
    () => !isSingleTeam || isSingleTeamHome === isHomeTeam,
    [isSingleTeam, isSingleTeamHome, isHomeTeam]
  )

  const players = useMemo(
    () =>
      (displayPlayers
        ? _.sortBy(
            items?.filter(
              (it) => it.isHomeTeam === isHomeTeam && helpers.isPlayer(it)
            ),
            (it) => parseInt(it.jersey ?? '0')
          )?.map((it) => [
            it.id,
            helpers.playerDisplay(it.jersey, it.fullName),
            { isHomeTeam: it.isHomeTeam },
          ])
        : [
            [
              null,
              isHomeTeam ? teamNames?.home : teamNames?.away,
              { isHomeTeam },
            ],
          ]) as [any, any, any?][],
    [isHomeTeam, teamNames, displayPlayers, items]
  )

  useEffect(() => {
    setIsHomeTeam(isHome)
  }, [isHome])

  return (
    <PopoverSelector
      header={
        <>
          <h4 className="team-name">
            {teamNames ? (isHomeTeam ? teamNames.home : teamNames.away) : ''}
          </h4>
          <IonButtons slot="end">
            <GenericButton
              fill="clear"
              size="small"
              color="primary"
              shape="round"
              onClick={() => setIsHomeTeam(!isHomeTeam)}
            >
              Other Team
            </GenericButton>
            <GenericButton
              fill="clear"
              size="small"
              color="danger"
              shape="round"
              onClick={onDidDismiss}
            >
              Cancel
            </GenericButton>
          </IonButtons>
        </>
      }
      onDidDismiss={onDidDismiss}
      items={players}
      {...rest}
    />
  )
}

export default styled(React.memo(PlayerPopoverSelector))`
  .team-name {
    font-size: 14px;
  }

  ion-button {
    font-size: 12px;
  }
`
