import { useState, useEffect, useMemo, useContext } from 'react'
import { useRouter } from '.'
import { invitationService } from '../services'
import { AppContext } from '../contexts/AppContext'
export default function useInvitationDetails() {
  const { state, dispatch } = useContext(AppContext)
  const { query, location } = useRouter()
  const [id, setId] = useState<any>()
  const [token, setToken] = useState<any>()
  const [details, setDetails] = useState<any>()
  const [loading, setLoading] = useState(true)
  const [invalid, setInvalid] = useState(false)
  const [init, setInit] = useState(false)

  useEffect(() => {
    try {
      let { id, token } = invitationService.decodeInvite(query)
      if (!id) {
        id = state.inviteId
        token = state.inviteToken
      }

      if (id && token && !init) {
        setId(id)
        setToken(token)
        setInit(true)
        dispatch({
          type: 'setInvitation',
          value: {
            id,
            token,
          },
        })
      }
    } catch (e) {
      setInvalid(true)
    }
  }, [query, dispatch, state, init])

  useEffect(() => {
    if (id && token && !invalid) {
      ;(async () => {
        try {
          setLoading(true)
          const res = await invitationService.getInviteDetails(id, token)
          setDetails(res)
        } catch (e) {
          console.log(e)
          setInvalid(true)

          localStorage.removeItem('inviteId')
          localStorage.removeItem('inviteToken')

          if (location.pathname === '/invite/accept/') {
            window.location.href = '/'
          }
        } finally {
          setLoading(false)
        }
      })()
    }
  }, [id, token, invalid, location, dispatch])

  return useMemo(
    () => ({
      id,
      token,
      details,
      loading,
      invalid,
    }),
    [id, token, details, loading, invalid]
  )
}
