import { http } from '../core'

class OneDriveService {
  async getFileInfo(token: string, file: string[]) {
    const { data } = await http.request({
      baseURL: '',
      url: `${file[0]}/drives/${file[1]}/items/${file[2]}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return data
  }
}

export default new OneDriveService()
