import React, { useRef, useState, useEffect, useImperativeHandle } from 'react'
import { useEventListener } from '../hooks'
import styled from 'styled-components'

export interface FloatingVideoPlayerProps {
  minimized: boolean
  src?: string
  poster?: string
  title?: string
}

const FloatingVideoPlayer: React.ForwardRefRenderFunction<
  any,
  FloatingVideoPlayerProps
> = ({ minimized, src, poster, ...rest }, ref) => {
  const [dragging, setDragging] = useState(false)
  const [x, setX] = useState(10)
  const [y, setY] = useState(10)
  const video = useRef<any>()
  const dragpoint = useRef<any>()

  useImperativeHandle(ref, () => ({
    play() {
      video.current?.play()
    },

    pause() {
      video.current?.pause()
    },
  }))

  useEffect(() => {
    if (minimized) {
      setX(10)
      setY(10)
    } else {
      video.current.style.top = 'initial'
      setX(0)
      setY(0)
    }
  }, [minimized])

  useEventListener('mousemove', (e: any) => {
    if (dragging && video.current) {
      const rect = video.current.getBoundingClientRect()
      const top = Math.min(
        e.clientY - dragpoint.current[1],
        window.innerHeight - rect.height
      )
      const left = Math.min(
        e.clientX - dragpoint.current[0],
        window.innerWidth - rect.width
      )
      video.current.style.top = `${Math.max(top, 0)}px`
      video.current.style.left = `${Math.max(left, 0)}px`
    }
  })

  useEventListener('mouseup', (e: any) => {
    setDragging(false)
  })

  const onVideoClick = (e: any) => {
    if (minimized) {
      e.preventDefault()
      e.stopPropagation()
      return false
    }
  }

  const onVideoMouseDown = (e: any) => {
    setDragging(!!minimized)
    const rect = e.target.getBoundingClientRect()
    const x = e.clientX - rect.left
    const y = e.clientY - rect.top
    dragpoint.current = [x, y]
  }

  return (
    <video
      ref={video}
      onClick={onVideoClick}
      onMouseDown={onVideoMouseDown}
      style={{
        left: minimized ? x : 0,
        bottom: minimized ? y : 0,
        height: minimized ? 400 : '100vh',
        width: minimized ? 650 : '100vw',
        transition: dragging ? undefined : 'all ease-in-out .175s',
      }}
      poster={poster}
      src={src}
      controls
      {...rest}
    />
  )
}

export default styled(React.forwardRef(FloatingVideoPlayer))`
  border-radius: ${(props: FloatingVideoPlayerProps) =>
    props.minimized ? '8px' : 0};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  object-fit: cover;
  position: absolute;
  outline: none;
  resize: ${(props) => (props.minimized ? 'both' : 'none')};
`
