import React from 'react'
import { IonApp, setupConfig } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import './theme'
import { AppContextProvider } from './contexts/AppContext'
import AppRouterOutlet from './components/AppRouterOutlet'
import { ImpersonationNotice } from './components'
setupConfig({
  animated: false,
  statusTap: true,
  menuType: 'overlay',
  mode: 'md',
})

const App: React.FC = () => {
  return (
    <IonApp id="app">
      <IonReactRouter>
        <AppContextProvider>
          <AppRouterOutlet />
        </AppContextProvider>
      </IonReactRouter>
      {sessionStorage.getItem('whistle') != null &&
      sessionStorage.getItem('whistle') != '' ? (
        <ImpersonationNotice />
      ) : (
        ''
      )}
    </IonApp>
  )
}

export default App
