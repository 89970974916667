import { User } from './../types/models'
import authentication from './authentication'
import { Dispatch } from 'react'
import { UpdateAppStateAction } from '../contexts/AppContext'
import config from './config'
import * as serviceWorker from '../serviceWorker'
import { Plugins } from '@capacitor/core'
import device from './device'
const { SplashScreen } = Plugins

class Initialization {
  analyticsEnabled = false

  private async authStatus() {
    return await authentication.loadState()
  }

  private async userData() {
    // return await coreData.load()
  }

  private async initializedAuthorizedUser(user: User) {
    // await eventsService.connect()
  }

  async onAuthenticated(dispatch: Dispatch<UpdateAppStateAction>) {
    // const data = await this.userData()
    // await this.initializedAuthorizedUser(data.user)
    // localStorage.removeItem('inviteId')
    // localStorage.removeItem('inviteToken')
    // dispatch({
    //   type: 'set',
    //   value: {
    //     accessToken: await authentication.getAccessToken(),
    //     isAuthenticated: true,
    //     inviteId: null,
    //     inviteToken: null,
    //     ...data,
    //   },
    // })
  }

  private updateServiceWorkerRegistration(isAuthorized: boolean) {
    if (isAuthorized && config.isServiceWorkerEnabled) {
      serviceWorker.register()
    } else {
      serviceWorker.unregister()
    }
  }

  async initialize() {
    const auth = await this.authStatus()
    let userData: any = {}

    this.updateServiceWorkerRegistration(!!auth.accessToken)

    if (auth.accessToken) {
      userData = await this.userData()
      await this.initializedAuthorizedUser(userData.user)
    }

    if (!auth.accessToken || !userData.user?.isAdmin) {
      this.analyticsEnabled = true
    }

    return { ...auth, ...userData }
  }

  complete() {
    if (device.isNative) {
      SplashScreen.hide()
    }
  }
}

export default new Initialization()
