import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import Modal, { ModalProps } from './Modal'
import { List, Avatar, AvatarHeader } from '../components'
import { AppContext } from '../contexts/AppContext'
import {
  teamPersonnelService,
  teamService,
  teamPlayerService,
  teamStaffService,
  listsService,
} from '../services'
import { IonItem, IonLabel, IonList, IonText } from '@ionic/react'
import { useFilters } from '../hooks'
import { renderDataFields } from '../util/data-field'
import loading from '../core/loading'
import notifications from '../core/notifications'
import helpers from '../util/helpers'

export interface AddTeamPersonnelModalProps extends ModalProps {
  onDidCreate?: (res?: any) => any
}

const AddTeamPersonnelModal: React.FC<AddTeamPersonnelModalProps> = ({
  title,
  service,
  ...rest
}) => {
  const [person, setPerson] = useState<any>()
  const [data, setData] = useState<any>({
    isStarter: false,
  })

  const { state } = useContext(AppContext)
  const { team } = state

  const save = async () => {
    try {
      await loading.create()
      if (
        data.role === 'PLAYER' &&
        (!data.awayJersey || !data.homeJersey || !data.position)
      ) {
        return notifications.toast(
          'Please fill out all of the required fields',
          'Error',
          { color: 'danger' }
        )
      }
      const res = await teamPersonnelService.create(data)
      rest.onDidCreate?.(res)
      rest.onDidDismiss?.()
    } catch (e) {
      notifications.errorToast(e)
    } finally {
      loading.dismiss()
    }
  }

  useEffect(() => {
    const role = helpers.isPlayer(person)
      ? 'PLAYER'
      : listsService.rosterRoles.find(([key]) => key === person?.role)?.[0] ??
        'HEAD_COACH'

    setData((data: any) => ({
      ...data,
      teamId: team?.id,
      teamBrandPersonnelId: person?.id,
      role,
    }))
  }, [person, team])

  const renderPlayerSelection = (item: any) => {
    return (
      <IonItem
        key={item.id}
        className="ion-activatable pointer"
        onClick={() => setPerson(item)}
        detail
      >
        <Avatar
          slot="start"
          src={item.avatarURL}
          name={item.fullName}
          email={item.primaryEmail}
        />
        <IonLabel>
          <IonText className="ion-multiline">
            <h3>{item.fullName}</h3>
            <span className="subtext">{helpers.roleLabel(item)}</span>
          </IonText>
        </IonLabel>
      </IonItem>
    )
  }

  const { search, filtersBar } = useFilters({})
  const update = (key: string) => (val: string) =>
    setData((data: any) => ({ ...data, [key]: val }))

  const url = teamService.getEndpointUrl(team?.id, 'roster/eligible', search)

  const onDidCancel = () => {
    if (person) {
      setPerson(null)
    } else {
      rest.onDidDismiss?.()
    }
  }

  return (
    <Modal
      title="Add To Roster"
      closeButtonText="Cancel"
      backButton={{
        onClick: onDidCancel,
        visible: !!person,
      }}
      toolbar={!person ? filtersBar : null}
      headerAction={
        person
          ? {
              children: 'Add',
              onClick: save,
            }
          : null
      }
      {...rest}
    >
      {person ? (
        <IonList>
          <AvatarHeader
            src={person.avatarURL}
            name={person.fullName}
            email={person.primaryEmail}
            title={person.fullName}
            small
            noBackButton
          />
          {renderDataFields(
            true,
            helpers.isPlayer(person)
              ? teamPlayerService.fields
              : teamStaffService.fields,
            data,
            update
          )}
        </IonList>
      ) : (
        <List
          url={url}
          zeroState={{
            title: `No eligible personnel to add`,
            text: 'You can add more players or staff in the program section.',
            button: {
              children: 'Add Player',
              routerLink: '/a/program/players/',
              onClick() {
                rest.onDidDismiss?.()
              },
            },
          }}
          renderItem={renderPlayerSelection}
        />
      )}
    </Modal>
  )
}

export default styled(React.memo(AddTeamPersonnelModal))``
