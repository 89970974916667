import moment from 'moment'
import 'moment-timezone'

class ListsService {
  display(collection: [any, any][], val?: any) {
    return collection.find(([v]) => v === val)?.[1] ?? val
  }

  get roles(): [any, any][] {
    return [['ADMIN', 'Admin'], ...this.staffRoles]
  }

  get writeableRoles(): string[] {
    return ['ADMIN', 'COACH', 'ASSISTANT_COACH', 'STATISTICIAN']
  }

  get programTypes(): [any, any][] {
    return [
      ['highschool', 'High School'],
      ['college', 'College'],
      ['middleschool', 'Middle School'],
      ['club', 'Club'],
      ['other', 'Other'],
    ]
  }

  get scoringTypes(): [any, any][] {
    return [
      ['STANDARD', 'Both Teams'],
      ['SINGLE_TEAM', 'Single Team'],
    ]
  }

  get foulResets(): [any, any][] {
    return [
      ['NONE', 'None'],
      ['PERIOD', '1st, 2nd, 3rd Quarters'],
      ['HALF', 'Half'],
    ]
  }

  get timezones(): [any, any][] {
    const zones = moment.tz.zonesForCountry('US').map((it) => [it, it]) as [
      any,
      any
    ][]
    return [
      ['US/Eastern', 'US/Eastern'],
      ['US/Central', 'US/Central'],
      ['US/Mountain', 'US/Mountain'],
      ['US/Pacific', 'US/Pacific'],
      ...zones,
    ]
  }

  get teamLevels(): [any, any][] {
    return [
      [0, 'None'],
      [1, 'Varsity'],
      [2, 'Junior Varsity'],
      [3, 'Freshman'],
      [4, 'Other'],
    ]
  }

  get positions(): [any, any][] {
    return [
      ['G', 'Guard'],
      ['PG', 'Point Guard'],
      ['SG', 'Shooting Guard'],
      ['F', 'Forward'],
      ['SF', 'Small Forward'],
      ['PF', 'Power Forward'],
      ['C', 'Center'],
    ]
  }

  get periods(): [any, any][] {
    return [
      ['QUARTER', '4 Quarters'],
      ['HALF', '2 Halves'],
    ]
  }

  get genders(): [any, any][] {
    return [
      ['Female', 'Female'],
      ['Male', 'Male'],
      ['Other', 'Other'],
    ]
  }

  get teamGenders(): [any, any][] {
    return [
      ['Male', 'Male'],
      ['Female', 'Female'],
      ['Coed', 'Coed'],
    ]
  }

  get staffRoles(): [any, any][] {
    return [
      ['COACH', 'Coach'],
      ['STATISTICIAN', 'Statistician'],
      ['OTHER', 'Other'],
    ]
  }

  get rosterRoles(): [any, any][] {
    return [
      ['HEAD_COACH', 'Head Coach'],
      ['ASSISTANT_COACH', 'Assistant Coach'],
      ['STATISTICIAN', 'Statistician'],
    ]
  }

  get gradeLevels(): [any, any][] {
    return [
      [0, 'N/A'],
      [8, 'Middle School'],
      [9, 'Freshman'],
      [10, 'Sophomore'],
      [11, 'Junior'],
      [12, 'Senior'],
      [13, 'Alumni'],
    ]
  }
}

export default new ListsService()
