import React, {
  useImperativeHandle,
  useRef,
  useEffect,
  useContext,
} from 'react'
import styled from 'styled-components'
import helpers from '../util/helpers'
import { pickers, device, dialogs } from '../core'
import { PickerColumn } from '@ionic/core'
import _ from 'lodash'
import GenericButton, { GenericButtonProps } from './GenericButton'
import { play, removeOutline, pause, add } from 'ionicons/icons'
import { useEventListener } from '../hooks'
import { ScoringContext } from '../contexts/ScoringContext'
import ScoringJumpballPopover from './ScoringJumpballPopover'

export interface ScoringGameClockProps extends React.ComponentProps<any> {}

const gameClockOptions = (minutes: number, seconds: number) =>
  [
    {
      name: 'minutes',
      selectedIndex: minutes,
      options: _.range(0, 60).map((it) => ({
        value: it,
        text: it.toString(),
      })),
    },
    {
      name: 'seconds',
      selectedIndex: seconds,
      options: _.range(0, 60).map((it) => ({
        value: it,
        text: it.toString(),
      })),
    },
  ] as PickerColumn[]

const periodOptions = (value: number) =>
  [
    {
      name: 'periods',
      selectedIndex: value - 1,
      options: _.range(1, 10).map((it) => ({
        value: it,
        text: it.toString(),
      })),
    },
  ] as PickerColumn[]

const defaultButtonProps = {
  fill: 'clear',
  color: 'light',
  className: 'clock-button',
} as GenericButtonProps

const ScoringGameClock: React.FC<ScoringGameClockProps> = ({ ...rest }) => {
  const {
    state,
    dispatch,
    periodDisplay,
    completed,
    score,
    gameClock,
  } = useContext(ScoringContext)
  const interval = useRef<any>()
  const wasRunning = useRef<any>(false)
  const jumpballButtonEvent = useRef<any>()

  const start = (e: any, logEvent: boolean = true) => {
    if (state.started) {
      dispatch({
        type: 'toggleClock',
        value: {
          start: true,
          logEvent,
        },
      })
    }
  }
  const stop = () => {
    dispatch({
      type: 'toggleClock',
      value: { start: false },
    })
  }

  useEffect(() => {
    if (state.clockRunning) {
      interval.current = setInterval(
        () =>
          dispatch({
            type: 'incrementClock',
            value: -0.05,
          }),
        50
      )
    } else {
      clearInterval(interval.current)
    }
  }, [state.clockRunning, dispatch])

  useImperativeHandle(gameClock, () => ({
    start,
    stop,
  }))

  useEventListener('focus', () => {
    if (wasRunning.current) {
      dispatch({
        type: 'toggleClock',
        value: { start: true, logEvent: false },
      })
    }
  })

  useEventListener('blur', () => {
    wasRunning.current = state.clockRunning
    dispatch({
      type: 'toggleClock',
      value: { start: false, logEvent: false },
    })
  })

  const showGameClockPicker = () => {
    if (!state.started || completed) {
      return
    }

    const save = (minutes: number, seconds: number) =>
      dispatch({
        type: 'set',
        value: {
          clock: minutes * 60 + seconds,
        },
      })

    if (device.isNative) {
      const [minutes, seconds] = helpers.secondsToMinutesAndSeconds(state.clock)
      const columns = gameClockOptions(minutes, seconds)
      pickers.create(columns, {
        onSave: (value: any) =>
          save(value.minutes.value, value.minutes.seconds),
      })
    } else {
      dialogs.input(
        'Game Clock',
        [
          {
            type: 'text',
            name: 'clock',
            value: helpers.secondsToTimeString(state.clock, { hours: false }),
            placeholder: 'Time MM:SS',
          },
        ],
        (value: any) => {
          const [minutes, seconds] = value.clock.split(':')
          save(parseInt(minutes), parseInt(seconds))
        }
      )
    }
  }

  const showPeriodPicker = () => {
    if (!state.started || completed) {
      return
    }

    const save = (period: any) =>
      dispatch({
        type: 'set',
        value: {
          period: parseInt(period),
        },
      })

    if (device.isNative) {
      const columns = periodOptions(state.period)
      pickers.create(columns, {
        onSave: (value: any) => save(value.periods.value),
      })
    } else {
      dialogs.input(
        'Period',
        [
          {
            type: 'number',
            name: 'period',
            value: state.period,
            placeholder: 'Period',
          },
        ],
        (value: any) => save(value.period)
      )
    }
  }

  const addSeconds = (seconds: number) => () => {
    if (!state.started) {
      return
    }

    dispatch({
      type: 'set',
      value: {
        clock: helpers.clamp(state.clock + seconds, 0, state.periodDuration),
      },
    })
  }

  const transitionPeriod = () => {
    const isEnd = state.clock === 0
    dispatch({
      type: isEnd ? 'endPeriod' : 'startPeriod',
      value: {},
    })
  }

  const startGame = (e: any) => {
    e.persist()
    jumpballButtonEvent.current = e
    dispatch({
      type: 'startGame',
      value: {},
    })
  }

  const endGame = () => {
    dispatch({
      type: score.home === score.away ? 'endPeriod' : 'endGame',
      value: {},
    })
  }

  let controls

  if (!state.teamGame?.homeFinalScore) {
    if (
      state.clock === 0 &&
      ((state.periodType === 'H' && state.period > 1) ||
        (state.periodType === 'Q' && state.period > 3))
    ) {
      controls = (
        <GenericButton fill="clear" color="basketball" onClick={endGame}>
          {score.home === score.away ? 'End Regulation' : 'End Game'}
        </GenericButton>
      )
    } else if (
      state.clock === 0 ||
      (state.clock === state.periodDuration && state.period > 1)
    ) {
      controls = (
        <GenericButton
          fill="clear"
          color="basketball"
          onClick={transitionPeriod}
        >
          {state.clock === 0 ? 'End' : 'Start'}{' '}
          {state.periodType === 'Q' ? 'Quarter' : 'Half'}
        </GenericButton>
      )
    } else {
      controls = (
        <>
          {state.clockRunning ? (
            <GenericButton
              icon={pause}
              onClick={stop}
              {...defaultButtonProps}
            />
          ) : (
            <GenericButton
              disabled={!state.started}
              icon={play}
              onClick={start}
              {...defaultButtonProps}
            />
          )}
          <GenericButton
            icon={add}
            disabled={!state.started}
            onClick={addSeconds(1)}
            {...defaultButtonProps}
          />
          <GenericButton
            icon={removeOutline}
            disabled={!state.started}
            onClick={addSeconds(-1)}
            {...defaultButtonProps}
          />
        </>
      )
    }
  }

  return (
    <div {...rest}>
      <div className="game-clock">
        {(!state.started || state.showJumpballPopover) && (
          <GenericButton fill="clear" color="basketball" onClick={startGame}>
            Start Game
          </GenericButton>
        )}
        {controls}
        <time className="clock" onClick={showGameClockPicker}>
          {helpers.secondsToTimeString(state.clock, {
            hours: false,
            millis: false,
          })}
        </time>
        <span className="period" onClick={showPeriodPicker}>
          {periodDisplay}
        </span>
        <ScoringJumpballPopover event={jumpballButtonEvent.current} />
      </div>
    </div>
  )
}

export default styled(React.memo(ScoringGameClock))`
  text-align: center;
  .clock-button {
    --padding-start: 5px;
    --padding-end: 5px;
  }

  .game-clock {
    align-items: center;
    color: white;
    display: inline-flex;
    font-size: 22px;
    font-weight: bold;
    height: 30px;
    margin: 15px auto;
    padding: 3px 15px 3px 0;

    .clock {
      margin-right: 5px;
    }

    .clock,
    .period {
      border-radius: 10px;
      cursor: pointer;
      padding: 5px 10px;
      transition: background ease-in-out 0.15s;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }
`
