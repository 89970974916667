import { useState, useMemo } from 'react'
import useEventListener from './useEventListener'
export default function useViewport() {
  const [height, setHeight] = useState(window.innerHeight)
  const [width, setWidth] = useState(window.innerWidth)

  useEventListener('resize', () => {
    setHeight(window.innerHeight)
    setWidth(window.innerWidth)
  })

  return useMemo(() => ({ height, width }), [height, width])
}
