import React, { useRef, useEffect, useContext, useState } from 'react'
import styled from 'styled-components'
import { UserNotification } from '../types/models'
import { IonItem, IonLabel, IonText, IonIcon } from '@ionic/react'
import {
  informationCircleOutline,
  heartOutline,
  basketballOutline,
  peopleCircleOutline,
  personCircleOutline,
  filmOutline,
} from 'ionicons/icons'
import { useOnScreen } from '../hooks'
import { userNotificationService } from '../services'
import { AppContext } from '../contexts/AppContext'
export interface UserNotificationRowProps
  extends React.ComponentProps<typeof IonItem> {
  notification: UserNotification
}

function getIcon(notification: UserNotification) {
  switch (notification.icon) {
    case 'heart':
      return heartOutline
    case 'basketball':
      return basketballOutline
    case 'people':
      return peopleCircleOutline
    case 'person':
      return personCircleOutline
    case 'video':
      return filmOutline
    default:
      return informationCircleOutline
  }
}

const UserNotificationRow: React.FC<UserNotificationRowProps> = ({
  notification,
  ...rest
}) => {
  const [hasSeen, setHasSeen] = useState(!!notification.seenAt)
  const { dispatch } = useContext(AppContext)
  const ref = useRef<any>()
  const isOnScreen = useOnScreen(ref)

  useEffect(() => {
    if (isOnScreen && !notification.seenAt) {
      userNotificationService.update(notification.id, {
        seenAt: new Date(),
      })

      dispatch({
        type: 'notificationSeen',
        value: notification.id,
      })
    }
  }, [notification, isOnScreen, dispatch])

  useEffect(() => setHasSeen(!!notification.seenAt), [notification])

  return (
    <IonItem ref={ref} {...rest}>
      <IonIcon slot="start" icon={getIcon(notification)} />
      <IonLabel>
        <IonText className="ion-multiline ion-text-wrap">
          <strong>{notification.title}</strong>
          <p className="text">{notification.text}</p>
        </IonText>
      </IonLabel>
      {!hasSeen && <div slot="end" className="unread" />}
    </IonItem>
  )
}

export default styled(React.memo(UserNotificationRow))`
  .text {
    color: var(--ion-color-medium);
    font-size: 12px;
  }

  ion-icon {
    margin: 0 10px 0 0;
  }

  .unread {
    background-color: var(--ion-color-basketball);
    border-radius: 100%;
    height: 6px;
    width: 6px;
  }
`
