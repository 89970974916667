import React from 'react'
import styled from 'styled-components'

export interface TeamVideoPlayerProps extends React.ComponentProps<any> {}

const TeamVideoPlayer: React.FC<TeamVideoPlayerProps> = ({
  value,
  className,
}) => {
  return (
    <div className={className}>
      <video src={value?.src} poster={value?.poster} controls />
    </div>
  )
}

export default styled(TeamVideoPlayer)`
  border-bottom: solid 1px var(--ion-color-light);
  display: flex;
  padding: 15px 0;

  video,
  img {
    border-radius: 10px;
    max-width: 500px;
    margin: auto;
    height: auto;
  }
`
