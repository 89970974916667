import React, { useContext, useMemo, useRef } from 'react'
import styled from 'styled-components'
import { IonGrid, IonRow, IonCol } from '@ionic/react'
import CourtCanvas from './CourtCanvas'
import { PlayerInsights } from '../types/models'
import { AppContext } from '../contexts/AppContext'
import _ from 'lodash'
import 'chartjs-plugin-annotation'
//@ts-expect-error
import { Line, Chart, HorizontalBar } from 'react-chartjs-2'
import { ChartCard } from '.'
import {annotation, scales} from './ChartConfig'

Chart.plugins.register({
  id: 'chartjs-plugin-annotation',
})

export interface InsightsShotChartsProps {
  data?: PlayerInsights
}

const InsightsShotCharts: React.FC<InsightsShotChartsProps> = ({
  data,
  ...props
}) => {
  const courtCanvas = useRef<any>()
  const { state } = useContext(AppContext)
  const league = useMemo(
    () => state.leagues.find((it) => it.id === state.teamBrand?.leagueId),
    [state]
  )

  const court = useMemo(
    () => state.courts.find((it) => it.id === league?.id) ?? state.courts[0],
    [state, league]
  )

  // const clusters = useMemo(
  //   () =>
  //     data?.shots?.reduce((obj: any, it: any) => {
  //       const p = (it.x + it.y) * it.ppi
  //       const match = obj.find(
  //         (e: any) => Math.abs((e.x + e.y) * e.ppi - p) < 4
  //       )

  //       if (match) {
  //         match.points.push(it)

  //         if (it.made) {
  //           match.made++
  //         }

  //         match.count++
  //         const totalPPI = _.sumBy(match.points, 'ppi')
  //         const totalX = _.sumBy(match.points, (it: any) => it.x * it.ppi)
  //         const totalY = _.sumBy(match.points, (it: any) => it.y * it.ppi)
  //         match.ppi = totalPPI / match.points.length
  //         match.x = totalX / match.ppi / match.points.length
  //         match.y = totalY / match.ppi / match.points.length
  //       } else {
  //         obj.push({
  //           ...it,
  //           points: [it],
  //           made: it.made ? 1 : 0,
  //           count: 1,
  //         })
  //       }

  //       return obj
  //     }, []),
  //   [data]
  // )

  const shotsByFoot = useMemo(() => {
    return data?.shots?.reduce?.((obj: any, it: any) => {
      const feet = Math.round(it.distance)
      if(feet < 31){
        obj[feet] = obj[feet] ?? []
        obj[feet].push(it)
      }
      return obj
    }, _.zipObject(_.range(0, 31)))
  }, [data])

  const shotsByFootAndHoopSide = useMemo(() => {
    return data?.shots?.reduce?.((obj: any, it: any) => {
      const feet = Math.round(it.distance)
      if(feet < 31){
        obj[feet] = obj[feet] ?? {
          left: [],
          right: [],
        }
        obj[feet][it.hoopSide].push(it)
    }
      return obj
    }, _.zipObject(_.range(0, 30)))
  }, [data])

  const maxFrequency = useMemo(() => {
    const vals = Object.values(shotsByFootAndHoopSide ?? {})

    if (!vals.length) {
      return 30
    }

    let max = 0

    vals.forEach((it: any) => {
      const lengthMax = Math.max(it?.left?.length ?? 0, it?.right?.length ?? 0)
      max = Math.max(lengthMax, max)
    })

    return (max +5)-((max + 5) % 5) // Get the scale to next 5. max frequency of 42 will take chart to 47
  }, [shotsByFootAndHoopSide])

  return (
    <div {...props}>
      <IonGrid>
        <IonRow className="ion-align-items-stretch">
          <IonCol>
            <ChartCard title="Shot Chart">
              <div className="top-half">
                <CourtCanvas
                  ref={courtCanvas}
                  points={data?.shots?.map((it: any) => ({
                    ...it,
                    size: 5,
                    color: '#444444', // : '#eb445a',
                    filled: it.made ? true: false
                  }))}
                  court={court}
                  type="TOPHALF"
                  fillcourtcolor = {true}
                  nonInteractive
                />
              </div>
            </ChartCard>
          </IonCol>
          {/* <IonCol></IonCol>
          </IonRow>
          <IonRow className="ion-align-items-stretch"> */}
          <IonCol>
            <ChartCard title="Field Goal Attempt Frequency" subtitle="% by Distance">
              <Line
                options={{
                  responsive: true,
                  annotation: annotation(court, 'vertical', 'x-axis-0'),
                  scales: scales('Distance (ft)',  'Frequency %'),
                  legend: {
                    display: false,
                  },
                }}
                data={{
                  labels: Object.keys(shotsByFoot ?? {}) ?? [],
                  datasets: [
                    {
                      label: '% of Shots',
                      fill: false,
                      lineTension: 0.1,
                      backgroundColor: 'rgba(251, 94, 28,0.4)',
                      borderColor: 'rgba(251, 94, 28,1)',
                      borderCapStyle: 'butt',
                      borderDash: [],
                      borderDashOffset: 0.0,
                      borderJoinStyle: 'miter',
                      pointBorderColor: 'rgba(251, 94, 28,1)',
                      pointBackgroundColor: '#fff',
                      pointBorderWidth: 1,
                      pointHoverRadius: 5,
                      pointHoverBackgroundColor: 'rgba(251, 94, 28,1)',
                      pointHoverBorderColor: 'rgba(220,220,220,1)',
                      pointHoverBorderWidth: 2,
                      pointRadius: 1,
                      pointHitRadius: 10,
                      data: Object.values(shotsByFoot ?? {}).map((it: any) =>
                        Math.round(
                          ((it?.length ?? 0) / (data?.shots?.length ?? 0)) * 100
                        )
                      ),
                    },
                  ],
                }}
              />
            </ChartCard>
          </IonCol>
          <IonCol>
            <ChartCard title="Field Goal Attempt Frequency" subtitle="Left vs Right Side">
              <HorizontalBar
                options={{
                  responsive: true,
                  annotation: annotation(court, 'horizontal', 'y-axis-0'),
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          autoSkip: true,
                          maxTicksLimit: 6,
                        },
                        stacked: true,
                        scaleLabel: {
                          display: true,
                          labelString: 'Distance (ft)',
                        },
                      },
                    ],
                    xAxes: [
                      {
                        position: 'left',
                        scaleLabel: {
                          display: true,
                          labelString: 'Shots',
                        },
                        ticks: {
                          min: maxFrequency * -1,
                          max: maxFrequency,
                          callback: (value: any) => Math.abs(value),
                        },
                      },
                    ],
                  },
                  legend: {
                    display: false,
                  },
                  tooltips: {
                    enabled: true,
                    callbacks: {
                      label: (tooltipItems, data) =>
                        `${Math.abs(
                          parseInt(tooltipItems.value ?? '0')
                        )} Shots`,
                    },
                  },
                }}
                data={{
                  labels: Object.keys(shotsByFootAndHoopSide ?? {}) ?? [],
                  datasets: [
                    {
                      label: 'Left',
                      fill: false,
                      lineTension: 0.1,
                      backgroundColor: 'rgba(45, 211, 111,0.4)',
                      borderColor: 'rgba(45, 211, 111,1)',
                      borderCapStyle: 'butt',
                      borderDash: [],
                      borderDashOffset: 0.0,
                      borderJoinStyle: 'miter',
                      pointBorderColor: 'rgba(45, 211, 111,1)',
                      pointBackgroundColor: '#fff',
                      pointBorderWidth: 1,
                      pointHoverRadius: 5,
                      pointHoverBackgroundColor: 'rgba(45, 211, 111,1)',
                      pointHoverBorderColor: 'rgba(220,220,220,1)',
                      pointHoverBorderWidth: 2,
                      pointRadius: 1,
                      pointHitRadius: 10,
                      data: Object.values(shotsByFootAndHoopSide ?? {}).map(
                        (it: any) => (it?.left?.length ?? 0) * -1
                      ),
                    },
                    {
                      label: 'Right',
                      fill: false,
                      lineTension: 0.1,
                      backgroundColor: 'rgba(82, 96, 157,0.4)',
                      borderColor: 'rgba(82, 96, 157,1)',
                      borderCapStyle: 'butt',
                      borderDash: [],
                      borderDashOffset: 0.0,
                      borderJoinStyle: 'miter',
                      pointBorderColor: 'rgba(82, 96, 157,1)',
                      pointBackgroundColor: '#fff',
                      pointBorderWidth: 1,
                      pointHoverRadius: 5,
                      pointHoverBackgroundColor: 'rgba(82, 96, 157,1)',
                      pointHoverBorderColor: 'rgba(220,220,220,1)',
                      pointHoverBorderWidth: 2,
                      pointRadius: 1,
                      pointHitRadius: 10,
                      data: Object.values(shotsByFootAndHoopSide ?? {}).map(
                        (it: any) => it?.right?.length ?? 0
                      ),
                    },
                  ],
                }}
              />
            </ChartCard>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  )
}

export default styled(React.memo(InsightsShotCharts))``
