import { useRef, useEffect } from 'react'
export default function useEventListener(
  eventName: string,
  handler: Function,
  element: any = window
) {
  const savedHandler = useRef<any>()
  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    const isSupported = element && element.addEventListener
    if (!isSupported) return

    const eventListener = (event: any) => savedHandler.current(event)

    // Add event listener
    element.addEventListener(eventName, eventListener)

    // Remove event listener on cleanup
    return () => {
      element.removeEventListener(eventName, eventListener)
    }
  }, [eventName, element])
}
