import React from 'react'
import { useReducer, useCallback } from 'react'
import FiltersBar, { FiltersBarProps } from '../components/FiltersBar'
import _ from 'lodash'

export interface useFiltersState {
  filters: { [key: string]: any }
  search?: string
}

export interface useFiltersStateAction {
  type: string
  value: any
}

function reducer(state: useFiltersState, action: useFiltersStateAction) {
  switch (action.type) {
    case 'set':
      return { ...state, ...action.value }
    case 'clearFilter':
      delete state.filters[action.value]
      return { ...state }
    case 'setFilter':
      return {
        ...state,
        filters: { ...state.filters, ...action.value },
      }
  }
}

export default function useFilters(props: FiltersBarProps) {
  const [state, dispatch] = useReducer(reducer, {
    filters: props.defaults ?? {},
  })

  const setFilter = useCallback(
    (key: string, value: any) =>
      dispatch({
        type: 'setFilter',
        value: {
          [key]: value,
        },
      }),
    [dispatch]
  )

  const clearFilter = useCallback(
    (key: string) =>
      dispatch({
        type: 'clearFilter',
        value: key,
      }),
    [dispatch]
  )

  const resetFilters = useCallback(
    () => dispatch({ type: 'set', value: { filters: props.defaults ?? {} } }),
    [dispatch, props]
  )

  const setSearch = useCallback(
    (search?: string) => dispatch({ type: 'set', value: { search } }),
    [dispatch]
  )

  return {
    ...state,
    setFilter,
    resetFilters,
    setSearch,
    isDefaults:
      !props.defaults ||
      (_.isEqual(state.filters, props.defaults) && !state.search?.length),
    filtersBar: (
      <FiltersBar
        {...props}
        {...state}
        resetFilters={resetFilters}
        clearFilter={clearFilter}
        setFilter={setFilter}
        onSearch={setSearch}
      />
    ),
  }
}
